<klp-form [formGroup]="manualCardUploadForm">
	<klp-form-element [caption]="'Administration' | translate" direction="vertical">
		<app-administration-picker formControlName="administration"></app-administration-picker>
	</klp-form-element>
	<klp-form-element [caption]="'Transaction interface' | translate" direction="vertical">
		<app-interface-picker formControlName="transactionInterface"></app-interface-picker>
	</klp-form-element>
	<klp-form-element [caption]="'Card statement' | translate" direction="vertical">
		<klp-form-error error="fileType">{{ 'Wrong file type. Only PDF is allowed.' | translate }}</klp-form-error>
		<klp-form-file-input formControlName="cardStatement"></klp-form-file-input>
	</klp-form-element>
</klp-form>
