import { Injectable } from '@angular/core';
import { NodeSettings, TreeNode } from '~/app/shared/ui/custom-xml-export/interfaces/treeNode';
import { isNullOrUndefined, isValueSet } from '#/util/values';
import { v4 as generateId } from 'uuid';

@Injectable({
	providedIn: 'root',
})
export class TreeLogicService {
	private structureTree: Array<TreeNode>;

	public setStructureTree(tree: Array<TreeNode>): void {
		this.structureTree = tree;
	}

	public getStructureTree(): Array<TreeNode> {
		return this.structureTree;
	}

	public getCustomFolderNode(): TreeNode {
		return {
			id: generateId(),
			type: 'node',
			name: 'Custom Folder',
			label: 'custom_folder',
			value: null,
			parent: null,
			children: [],
			settings: new NodeSettings(),
			attachedData: null,
			isNodeToPreventNodeBecomingLeaf: false,
			isCustomValue: false,
		};
	}

	public getCustomValueNode(): TreeNode {
		return {
			id: generateId(),
			type: 'leaf',
			name: 'Custom Value',
			label: 'custom_value',
			value: null,
			parent: null,
			children: [],
			settings: new NodeSettings(),
			attachedData: null,
			isNodeToPreventNodeBecomingLeaf: false,
			isCustomValue: true,
		};
	}

	public allowDropBuilderTree(srcNode: TreeNode, destNode: TreeNode, treeNodes: Array<TreeNode>): boolean {
		if (isNullOrUndefined(destNode) && this.isTopLevelNode(srcNode)) {
			// destNode will be undefined on a drop on an empty tree. When the first drop is made,
			// it should be a node that is on the top level of the allowed structure
			return true;
		}

		if (isNullOrUndefined(destNode) || this.isDropInsideOfItself(srcNode, destNode)) {
			// Nodes are not allowed to be dropped inside of its own children to prevent the tree from collapsing on itself.
			return false;
		}

		if (this.isDropValidForDraggedNodeAndChildren(srcNode, destNode)) {
			// A drop may only be made if it is done within the context of its parent.
			// Nodes are allowed to be dropped as deep down as the user likes but no further up than its highest parent.
			// Besides that, all children of the dropped node must still be in a valid position after the drop is made.
			return true;
		}

		return false;
	}

	public findChildrenOfNodeInStructureTree(node: TreeNode): Array<TreeNode> {
		const nodeInTree = this.findNodeInTree(node, this.structureTree);
		return nodeInTree.children;
	}

	public getDeepChildrenOfNode(node: TreeNode): Array<TreeNode> {
		const children = [...node.children];
		for (const child of children) {
			children.push(...child.children);
		}
		return children.filter((child) => !child.isNodeToPreventNodeBecomingLeaf);
	}

	public findChildrenOfNodeAndChildrenOfParentsInStructureTree(node: TreeNode): Array<TreeNode> {
		let childrenOfNode = this.findChildrenOfNodeInStructureTree(node);
		if (!isValueSet(childrenOfNode)) {
			childrenOfNode = [];
		}
		let childrenOfParent = [];
		if (isValueSet(node.parent)) {
			childrenOfParent = this.findChildrenOfNodeAndChildrenOfParentsInStructureTree(node.parent);
		}
		return [...childrenOfParent, ...childrenOfNode];
	}

	public findParentOfNodeInStructureTree(node: TreeNode): TreeNode {
		const parentOfNodeInTree = this.findParentOfNodeInTree(node, this.structureTree);
		return parentOfNodeInTree;
	}

	public findNodeInStructureTreeByValue(value: string): TreeNode {
		return this.findNodeInTreeByValue(value, this.structureTree);
	}

	public findAllParentsOfNode(node: TreeNode): Array<TreeNode> {
		const result = [];
		result.push(node.parent);
		if (isValueSet(node.parent)) {
			result.push(...this.findAllParentsOfNode(node.parent));
		}
		return result;
	}

	private isTopLevelNode(srcNode: TreeNode): boolean {
		// We look at this.structureTree to find the top level structureTree because this structure is always valid.
		const topLevelNodeKeys = this.structureTree.map((node) => node.value);
		if (topLevelNodeKeys.includes(srcNode.value)) {
			return true;
		} else {
			return false;
		}
	}

	private isDropValidForDraggedNodeAndChildren(draggedNode: TreeNode, destNode: TreeNode): boolean {
		const isDraggedNodeAllowed = this.doesNodeHaveParentOrContextOfDestNode(draggedNode, destNode);
		// The dragged node must be allowed to be dropped on the destNode.
		if (!isDraggedNodeAllowed) {
			return false;
		}

		let areChildrenOfDraggedNodeAllowedForDrop = true;
		if (draggedNode.children?.length > 0) {
			areChildrenOfDraggedNodeAllowedForDrop = this.isDropValidForChildrenOfNode(draggedNode, destNode, draggedNode);
		}

		return areChildrenOfDraggedNodeAllowedForDrop;
	}

	private isDropValidForChildrenOfNode(node: TreeNode, destNode: TreeNode, draggedNode: TreeNode): boolean {
		const isDropValidForChildrenOfNodeArray = node.children?.map((child) => {
			// We check if the location of the child would still be valid in the tree if the draggedNode didn't have a parent.
			const isNodeAllowedInDraggedTree = this.isAllowedParentOfNodeInGivenTree(child, draggedNode);
			// We check if the location of the child would still be valid if it was dropped on destNode.
			const isNodeAllowedAtDestNode = this.doesNodeHaveParentOrContextOfDestNode(child, destNode);

			// If the child is either valid in a secluded tree or valid as a node at destNode, the drop is valid for that child.
			const isDropValidForChild = isNodeAllowedInDraggedTree || isNodeAllowedAtDestNode;

			// Check recursively if the drop is valid for the children of the child.
			let isDropValidForChildrenOfChild = true;
			if (child.children?.length > 0) {
				isDropValidForChildrenOfChild = this.isDropValidForChildrenOfNode(child, destNode, draggedNode);
			}

			return isDropValidForChild && isDropValidForChildrenOfChild;
		});
		return !isDropValidForChildrenOfNodeArray.includes(false);
	}

	private isAllowedParentOfNodeInGivenTree(node: TreeNode, pointInTree: TreeNode): boolean {
		if (node.isNodeToPreventNodeBecomingLeaf) {
			// If the node is a nodeToPreventFolderFromBecomingLeaf. Always allow it.
			return true;
		}
		const nodeInRightTree = this.findNodeInTree(node, this.structureTree);
		const parentOfNodeInRightTree = nodeInRightTree.parent;
		if (parentOfNodeInRightTree === null) {
			// If the node is a top level node, the given parent is always allowed for the node
			return true;
		}

		// We now check if the direct parent of the node in the RIGHT tree
		// is anywhere in the parents of the node in the LEFT tree from a given point.
		// EX:
		// - Bookings
		// 		- Custom folder (Given point)
		// 				- Receipt
		// 						- ReceiptID
		//
		// node = ReceiptID
		// allParentsOfNodeFromPoint = [Receipt, Custom folder]
		// parentOfNodeInRightTree = Receipt
		// isParentOfNodeFound = true
		const allParentsOfNodeFromPoint = this.findAllParentsOfNodeFromPointInTree(node, pointInTree);
		const foundParentInLeftTree = allParentsOfNodeFromPoint.find((parentOfNodeFromPoint) => {
			return parentOfNodeFromPoint?.label === parentOfNodeInRightTree?.label;
		});
		const isParentOfNodeFound = isValueSet(foundParentInLeftTree);
		return isParentOfNodeFound;
	}

	private doesNodeHaveParentOrContextOfDestNode(srcNode: TreeNode, destNode: TreeNode): boolean {
		const parentOfSrcNodeInRightTree = this.findParentOfNodeInTree(srcNode, this.structureTree);
		if (isNullOrUndefined(parentOfSrcNodeInRightTree)) {
			// If parent of srcNode is a root-node (null) we know that destNode will have it as a parent.
			return true;
		}

		const allParentsOfDestNode = this.findAllParentsOfNode(destNode);

		// Check if the parent of the srcNode is anywhere in all of the parents of the destNode.
		const doesNodeHaveParentOrContextOfDestNode = allParentsOfDestNode.some((parentOfDestNode) => {
			if (isNullOrUndefined(parentOfDestNode)) {
				// If the parent of destNode is a root-node (null) we need to check if the parent of the srcNode is also a root-node.
				// Dropping on a destNode that is a top level node can only be done with another top-level node.
				return isNullOrUndefined(parentOfSrcNodeInRightTree);
			}

			const allowedByContext = parentOfDestNode.settings.context?.some(
				(contextNode) => contextNode.value === parentOfSrcNodeInRightTree.value,
			);
			return parentOfDestNode.value === parentOfSrcNodeInRightTree.value || allowedByContext;
		});

		return doesNodeHaveParentOrContextOfDestNode;
	}

	private findAllParentsOfNodeFromPointInTree(node: TreeNode, pointInTree: TreeNode): Array<TreeNode> {
		const result = [];
		result.push(node.parent);
		if (isValueSet(node.parent) && node.parent !== pointInTree) {
			result.push(...this.findAllParentsOfNodeFromPointInTree(node.parent, pointInTree));
		}
		return result;
	}

	private findParentOfNodeInTree(node: TreeNode, tree: Array<TreeNode>): TreeNode {
		let parentOfNode: TreeNode = null;
		if (isValueSet(node.parent)) {
			const foundNode = this.findNodeInTree(node, tree);
			parentOfNode = foundNode.parent;
		}
		return parentOfNode;
	}

	private findNodeInTreeByValue(value: string, tree: Array<TreeNode>) {
		for (let i = 0; i < tree.length; i++) {
			const treeNode = tree[i];
			if (treeNode.value === value) {
				return treeNode;
			}

			if (isValueSet(treeNode.children)) {
				const recursiveFoundNode = this.findNodeInTreeByValue(value, treeNode.children);
				if (isValueSet(recursiveFoundNode)) {
					return recursiveFoundNode;
				}
			}
		}
		return null;
	}

	private findNodeInTree(node: TreeNode, tree: Array<TreeNode>): TreeNode {
		for (let i = 0; i < tree.length; i++) {
			const treeNode = tree[i];
			// If the node is a custom value/folder node, check for the label instead of the value.
			// We do this because the value of these structureTree are null and will collide with the nodeToPreventNodeFromBecomingLeaf node (also null)
			if (node.label === this.getCustomValueNode().label || node.label === this.getCustomFolderNode().label) {
				if (node.label === treeNode.label) {
					return treeNode;
				}
			} else if (treeNode.value === node.value) {
				return treeNode;
			}

			if (isValueSet(treeNode.children)) {
				const recursiveFoundNode = this.findNodeInTree(node, treeNode.children);
				if (isValueSet(recursiveFoundNode)) {
					return recursiveFoundNode;
				}
			}
		}
		return null;
	}

	private isDropInsideOfItself(srcNode: TreeNode, destNode: TreeNode): boolean {
		function getAllChildrenIdsOfNode(node: TreeNode): Array<string> {
			const allChildrenOfNode = [];
			node.children?.forEach((child) => {
				allChildrenOfNode.push(child.id);
				if (child.children) {
					allChildrenOfNode.push(...getAllChildrenIdsOfNode(child));
				}
			});
			return allChildrenOfNode;
		}

		return getAllChildrenIdsOfNode(srcNode).includes(destNode?.id);
	}
}
