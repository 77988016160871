import { Inject, Injectable } from '@angular/core';
import { APIService } from '../../api/services/api.service';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
import { APINotificationsService } from '../../api/services/apinotifications.service';
import { NotificationService } from '../../services/notification.service';
import { Strings } from '../../helpers/strings';
import { Agreement, Preferences, Subscription, Trial, User, UserRole } from '#/models/user/user.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import * as Url from 'url-parse';
import { LocalStorageService } from 'angular-2-local-storage';
import { AppState } from '~/app/reducers';
import { Store } from '@ngrx/store';
import { CompanyApiService } from '#/services/company/company-api.service';
import { Company } from '#/models/company/company.model';
import { isNullOrUndefined, isValueSet, stringIsSetAndFilled } from '#/util/values';
import { take } from 'rxjs/operators';
import { AuthManagerService } from '~/app/services/auth-manager.service';
import { Login, Logout, UpdateKey } from './models/user.actions';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TranslateUtilService } from '~/app/services/translate-util.service';
import { BehaviorSubject } from 'rxjs';
import { CrispChatService } from '##/crisp-chat.service';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	public fakeuserrole: UserRole;
	private getCurrentUserPromise: Promise<any>;
	private currentUser: User;
	private userCompany: Promise<Company>;
	public userObservable: BehaviorSubject<User>;

	constructor(
		protected apiService: APIService,
		@Inject(DOCUMENT) protected document: any,
		protected notifications: APINotificationsService,
		protected notificationService: NotificationService,
		public localStorageService: LocalStorageService,
		private companyApiService: CompanyApiService,
		private store: Store<AppState>,
		private authManagerService: AuthManagerService,
		private router: Router,
		private translate: TranslateService,
		private translateUtilService: TranslateUtilService,
		private crispChatService: CrispChatService,
	) {
		this.userObservable = new BehaviorSubject<User>(new User());

		this.store.select('user').subscribe(async (userState) => {
			this.userObservable.next(userState.currentUser);
			this.currentUser = userState.currentUser;
			if (stringIsSetAndFilled(this.currentUser?.company)) {
				this.userCompany = this.companyApiService.getCompany(this.currentUser.company);
			} else {
				this.userCompany = Promise.resolve(null);
			}
			this.crispChatService.showOrHideCrispChat(await this.userCompany);
		});

		// for company impersonation
		this.store.select('company')?.subscribe((value: any) => {
			if (isValueSet(this.currentUser)) {
				this.currentUser.company = value.company?.id;
			}
		});
	}

	login(email, password) {
		return this.apiService.post('/api/v1/user/login', { email, password }, 0, null, false);
	}

	getCurrentLoggedUser(): User {
		return this.currentUser;
	}

	getUserChangedSubject() {
		return this.userObservable.asObservable();
	}

	oAuthAppLogin(provider: string, token: string, email?: string) {
		const body = {
			accesstoken: token,
			email: '',
			app: 'com.klippa.app',
		};

		if (email) {
			body.email = email;
		}

		return this.apiService
			.post('/api/v1/user/oauth/register/' + provider + '/authorizeapp', body, 0, null, false)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	user() {
		return this.apiService
			.get('/api/v1/user')
			.then((r) => {
				this.checkFakeRole(r);
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	getUserFromApi(): Promise<User> {
		return this.apiService
			.get('/api/v1/user')
			.then((r) => {
				this.checkFakeRole(r);
				return Promise.resolve(new User(r.data.user));
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	register(email, password, language: string = '', trial: boolean = false, trialcode: string = '') {
		return this.apiService
			.post('/api/v1/user', { email, password, language, trial, trialcode }, 0, null, false)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	storeStateFromURL(redirectURL: string) {
		try {
			const url = new Url(redirectURL);
			let query = url.query;
			if (query.charAt(0) === '?') {
				query = query.substring(1);
			}

			if (query) {
				const queryParts = query.split('&');
				if (queryParts && queryParts.length > 0) {
					queryParts.forEach((queryPart) => {
						const querySubParts = queryPart.split('=');
						if (querySubParts.length >= 2) {
							if (querySubParts[0] === 'state') {
								this.addKnownState(querySubParts[1]);
							}
						}
					});
				}
			}
		} catch (e) {}
	}

	getKnownStates(): string[] {
		return this.localStorageService.get('sso_states') as string[];
	}

	addKnownState(state: string) {
		let knownStates = this.getKnownStates();
		if (!knownStates) {
			knownStates = [];
		}

		knownStates.push(state);
		this.localStorageService.set('sso_states', knownStates);
	}

	stateIsKnown(state: string): boolean {
		if (!this.localStorageService.isSupported) {
			return true;
		}

		let knownStates = this.getKnownStates();
		if (!knownStates) {
			knownStates = [];
		}

		for (const knownState of knownStates) {
			if (knownState === state) {
				return true;
			}
		}

		return false;
	}

	initSSOLogin(email: string) {
		const redirecturi = environment.web_root + '/login/sso';
		return this.apiService
			.post(
				'/api/v1/user/sso',
				{
					redirecturi,
					email,
				},
				0,
				null,
				false,
			)
			.then((r) => {
				this.storeStateFromURL(r['data']['url']);
				this.document.location.href = r['data']['url'];
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	finalizeSSOLogin(token: string) {
		return this.apiService
			.get('/api/v1/user/sso/token/' + token, 0, null, false)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	finalizeSSOOauth2Login(code: string, state: string) {
		if (!this.stateIsKnown(state)) {
			this.notificationService.error('State is unknown.');
			return Promise.reject('state_unknown');
		}
		return this.apiService
			.post(
				'/api/v1/user/sso/oauth2/authorize',
				{
					code,
					state,
				},
				0,
				null,
				false,
			)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	initOAuthRegister(provider: string, trial: boolean = false, trialcode: string = '') {
		const redirecturi = environment.web_root + '/oauth_authorized/' + provider + '/register';
		this.notificationService.info('You are being redirected to ' + Strings.capitalize(provider) + '.');
		return this.apiService
			.post(
				'/api/v1/user/oauth/register/' + provider + '/dialog',
				{
					redirecturi,
					trial,
					trialcode,
				},
				0,
				null,
				false,
			)
			.then((r) => {
				this.storeStateFromURL(r['data']['url']);
				this.document.location.href = r['data']['url'];
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	initOAuthRegisterAuthorized(provider: string, code: string, state: string, email?: string, language?: string): Promise<any> {
		if (!this.stateIsKnown(state)) {
			this.notificationService.error('State is unknown.');
			return Promise.reject();
		}

		return this.apiService
			.post(
				'/api/v1/user/oauth/register/' + provider + '/authorize',
				{
					code,
					state,
					email,
					language,
				},
				0,
				null,
				false,
			)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				return Promise.reject(r);
			});
	}

	initOAuthConnectAuthorized(provider: string, code: string, state: string) {
		if (!this.stateIsKnown(state)) {
			this.notificationService.error('State is unknown.');
			return Promise.reject();
		}

		return this.apiService.post('/api/v1/user/oauth/connect/' + provider + '/authorize', {
			code,
			state,
		});
	}

	initOAuthConnect(provider: string) {
		const redirecturi = environment.web_root + '/oauth_authorized/' + provider + '/connect';
		this.notificationService.info('You are being redirected to ' + Strings.capitalize(provider) + '.');
		return this.apiService
			.post('/api/v1/user/oauth/connect/' + provider + '/dialog', { redirecturi })
			.then((r) => {
				this.storeStateFromURL(r['data']['url']);
				this.document.location.href = r['data']['url'];
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	initOAuthDisconnect(provider: string): Promise<User> {
		return this.apiService
			.post('/api/v1/user/oauth/disconnect/' + provider + '', {})
			.then((r) => {
				return new User(r.data.user);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				throw r;
			});
	}

	requestPasswordReset(email: string) {
		return this.apiService
			.post('/api/v1/user/reset_password', { email }, 0, null, false)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				return Promise.reject(r);
			});
	}

	requestValidationMail(email: string) {
		return this.apiService
			.post('/api/v1/user/activate_mail/resend', { email }, 0, null, false)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				return Promise.reject(r);
			});
	}

	resendValidationMail(email) {
		this.requestValidationMail(email)
			.then((r) => {
				const message = this.notifications.translate.instant(
					_('Your mail address has not been validated yet. Please check your email and then retry your action.'),
				);
				this.notificationService.info(message, { timeout: 10000 });
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
			});
	}

	activateMail(email: string, key: string) {
		return this.apiService
			.post('/api/v1/user/activate_mail', { email, key }, 0, null, false)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				return Promise.reject(r);
			});
	}

	resetPassword(email: string, key: string, password: string) {
		return this.apiService
			.post('/api/v1/user/reset_password/confirm', { email, key, password }, 0, this.apiService.getAuthManagerService(), false)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				return Promise.reject(r);
			});
	}

	resetValidate(email: string, emailkey: string, passwordkey: string, password: string) {
		return this.apiService
			.post('/api/v1/user/reset_validate', { email, emailkey, passwordkey, password }, 0, this.apiService.getAuthManagerService(), false)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				return Promise.reject(r);
			});
	}

	checkFakeRole(r) {
		if (this.fakeuserrole) {
			r.data.user.originaluserrole = r.data.user.userrole;
			r.data.user.userrole = this.fakeuserrole;
		}
	}

	needsMFAVerification() {
		return this.apiService.getFromApi('user/currentKey').then((res) => res.data.mfa_needed);
	}

	getCurrentUser() {
		if (!this.getCurrentUserPromise) {
			this.getCurrentUserPromise = this.apiService
				.get('/api/v1/user')
				.then((r) => {
					this.getCurrentUserPromise = null;
					this.checkFakeRole(r);
					return Promise.resolve(r);
				})
				.catch((r) => {
					this.getCurrentUserPromise = null;
					return Promise.reject(r);
				});
		}

		return this.getCurrentUserPromise;
	}

	getCurrentUserParsed() {
		return this.getCurrentUser().then((res) => new User(res.data.user));
	}

	updateUser(user: User): Promise<User> {
		const payload = {
			gender: user.getGender(),
			name: user.getName(),
			dateofbirth: user.getDateofBirth(),
			allowImpersonation: user.allowImpersonation,
			preferences: {
				ocr: user.getPreferences().ocr,
				tags: user.getPreferences().tags,
				groups: user.getPreferences().groups,
				vatmode: user.getPreferences().vatmode,
				paymentinfo: user.getPreferences().paymentinfo,
				financetype: user.getPreferences().financetype,
				receipt_type: user.getPreferences().receipt_type,
				currency: user.getPreferences().currency,
				language: user.getPreferences().language,
				travel_expense_declaration_cents_per_km: user.getPreferences().travel_expense_declaration_cents_per_km,
				ocr_mode: user.getPreferences().ocr_mode,
				display_mode: user.getPreferences().display_mode,
				show_miles_instead_of_km: user.getPreferences().show_miles_instead_of_km,
				notification_frequency: user.getPreferences().notification_frequency,
				notification_moment: user.getPreferences().notification_moment,
			},
			password: user.password,
			currentpassword: user.currentpassword,
		};
		return this.apiService
			.patch('/api/v1/user', payload)
			.then((r) => {
				this.checkFakeRole(r);
				return new User(r.data.user);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				throw r;
			});
	}

	updatePreferences(preferences: Preferences): Promise<User> {
		const payload = {
			ocr: preferences.ocr,
			tags: preferences.tags,
			groups: preferences.groups,
			vatmode: preferences.vatmode,
			paymentinfo: preferences.paymentinfo,
			financetype: preferences.financetype,
			receipt_type: preferences.receipt_type,
			currency: preferences.currency,
			language: preferences.language,
			travel_expense_declaration_cents_per_km: preferences.travel_expense_declaration_cents_per_km,
			ocr_mode: preferences.ocr_mode,
			display_mode: preferences.display_mode,
			show_miles_instead_of_km: preferences.show_miles_instead_of_km,
			notification_frequency: preferences.notification_frequency,
			notification_moment: preferences.notification_moment,
			transactionInterfaceDisplayMode: preferences.transactionInterfaceDisplayMode,
		};
		return this.apiService
			.patch('/api/v1/user/preferences', payload)
			.then((r) => {
				return new User(r.data.user);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				throw r;
			});
	}

	deleteAccount(currentPassword: string) {
		const payload = {
			password: currentPassword,
		};
		return this.apiService
			.post('/api/v1/user/delete', payload)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	changeEmail(currentPassword: string, newEmail: string) {
		const payload = {
			password: currentPassword,
			email: newEmail,
		};
		return this.apiService
			.post('/api/v1/user/emailChange', payload)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	changeEmailConfirmOld(token: string) {
		const payload = {
			token: token,
		};
		return this.apiService
			.post('/api/v1/user/emailChange/confirm/old', payload)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	changeEmailConfirmNew(token: string): Promise<User> {
		const payload = {
			token: token,
		};
		return this.apiService
			.post('/api/v1/user/emailChange/confirm/new', payload)
			.then((r) => {
				return new User(r.data.user);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				throw r;
			});
	}

	companyInviteConfirm(token: string): Promise<User> {
		const payload = {
			token: token,
		};

		return this.apiService
			.post('/api/v1/user/companyInvite/confirm', payload)
			.then((r) => {
				return new User(r.data.user);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				throw r;
			});
	}

	deleteConfirm(token) {
		const payload = {
			delete_key: token,
		};
		return this.apiService
			.post('/api/v1/user/delete/confirm', payload)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	gdprExport() {
		return this.apiService
			.post('/api/v1/user/GDPRExport', {})
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	getAvailableAgreements() {
		return this.apiService
			.get('/api/v1/user/availableAgreements')
			.then((r) => {
				return Promise.resolve(r.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	acceptAgreement(agreement: Agreement): Promise<User> {
		const payload = {
			type: agreement.getType(),
			version: agreement.getVersion(),
		};
		return this.apiService
			.post('/api/v1/user/acceptAgreement', payload)
			.then((r) => {
				const user = new User(r['data']['user']);
				return Promise.resolve(user);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	getTrial(trialCode: string): Promise<Trial> {
		return this.apiService
			.get(`/api/v1/user/trialcode?code=${trialCode}`, 0, null, false)
			.then((r) => {
				const trial = new Trial({ trialCode: trialCode, ...r.data });
				return Promise.resolve(trial);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	startTrial(trial: Trial): Promise<User> {
		const payload = {
			trialcode: trial.getTrialCode(),
		};
		return this.apiService
			.post('/api/v1/user/getTrial', payload)
			.then((r) => {
				const user = new User(r.data.user);
				return Promise.resolve(user);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	getProCreditCard(token, plan): Promise<User> {
		const payload = {
			plan: plan,
			token: token,
			type: 'pro',
		};
		return this.apiService
			.post('/api/v1/user/payment/stripe/cc', payload)
			.then((r) => {
				const user = new User(r.data.user);
				return Promise.resolve(user);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	getProiDeal(redirectURI, plan) {
		const payload = {
			redirecturi: redirectURI,
			plan: plan,
			type: 'pro',
		};
		return this.apiService
			.post('/api/v1/user/payment/stripe/ideal/dialog', payload)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	getProiDealRecurring(redirectURI, plan) {
		const payload = {
			redirecturi: redirectURI,
			plan: plan,
			type: 'pro',
		};
		return this.apiService
			.post('/api/v1/user/payment/stripe/ideal_recurring/dialog', payload)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	getProiDealConfirm(source) {
		const payload = {
			source: source,
		};
		return this.apiService
			.post('/api/v1/user/payment/stripe/ideal/confirm', payload)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				return Promise.reject(e);
			});
	}

	getProiDealRecurringConfirm(source) {
		const payload = {
			source: source,
		};
		return this.apiService
			.post('/api/v1/user/payment/stripe/ideal_recurring/confirm', payload)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				return Promise.reject(e);
			});
	}

	cancelSubscription(subscription: Subscription): Promise<User> {
		return this.apiService
			.delete(`/api/v1/user/subcriptions/${subscription.getID()}`)
			.then((r) => {
				const user = new User(r.data.user);
				return Promise.resolve(user);
			})
			.catch((e) => {
				return Promise.reject(e);
			});
	}

	getKlippaMailVcard(): Promise<Blob> {
		return this.apiService
			.getBlob(`/api/v1/user/inboundmailvcard`)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	resetKlippaMailKey(currentPassword: string): Promise<User> {
		const payload = { currentpassword: currentPassword };
		return this.apiService
			.patch(`/api/v1/user/inboundmailkey`, payload)
			.then((r) => {
				return Promise.resolve(new User(r.data));
			})
			.catch((e) => {
				return Promise.reject(e);
			});
	}

	registerPushToken(token: string, type: string = 'fcm'): Promise<any> {
		const payload = {
			app: 'com.klippa.app',
			type: type,
			regid: token,
		};
		return this.apiService
			.post('/api/v1/user/push', payload)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	applePayment(transactionReceipt: string): Promise<User> {
		const payload = {
			receiptdata: transactionReceipt,
		};
		return this.apiService
			.post('/api/v1/user/payment/apple', payload)
			.then((r) => {
				return Promise.resolve(new User(r.data.user));
			})
			.catch((e) => {
				return Promise.reject(e);
			});
	}

	androidPayment(productid: string, token: string): Promise<User> {
		const payload = {
			productid: productid,
			token: token,
		};
		return this.apiService
			.post('/api/v1/user/payment/android', payload)
			.then((r) => {
				return Promise.resolve(new User(r.data.user));
			})
			.catch((e) => {
				return Promise.reject(e);
			});
	}

	getProStripeCheckoutRecurring(redirectURI, plan) {
		const payload = {
			redirecturi: redirectURI,
			plan: plan,
			type: 'pro',
		};
		return this.apiService
			.post('/api/v1/user/payment/stripe/checkout/dialog', payload)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	getProStripeCheckoutRecurringConfirm(session_id) {
		const payload = {
			session_id: session_id,
		};
		return this.apiService
			.post('/api/v1/user/payment/stripe/checkout/confirm', payload)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				return Promise.reject(e);
			});
	}

	getProStripeUpdateRecurring(redirectURI, subscription_id) {
		const payload = {
			redirecturi: redirectURI,
			subscription_id: subscription_id,
		};
		return this.apiService
			.post('/api/v1/user/payment/stripe/update/dialog', payload)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	getProStripeUpdateRecurringConfirm(session_id) {
		const payload = {
			session_id: session_id,
		};
		return this.apiService
			.post('/api/v1/user/payment/stripe/update/confirm', payload)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				return Promise.reject(e);
			});
	}

	async getHomePage() {
		if (isNullOrUndefined(this.currentUser)) {
			// if for some weird reason we don't have a user, just fall back to login page
			// in practice, this should not happen though
			return '/login';
		}
		const company: Company = await this.userCompany;

		if (isValueSet(company) && company.modules?.report?.enabled) {
			if (company?.modules?.cardReports.enabled) {
				if (this.currentUser.hasRole(UserRole.CompanyExpenseSubmitter)) {
					return '/reports/card';
				} else if (this.currentUser.canManageExpenses()) {
					return '/manage-reports/card';
				}
			}
			if (this.currentUser.canManageExpenses()) {
				return '/manage-reports';
			}
			return '/reports';
		}

		if (isValueSet(company)) {
			if (this.currentUser.canUseDashboard() && company.canUseExpenses()) {
				return '/dashboard';
			} else if (this.currentUser.canManageExpenses() && company.canUseExpenses()) {
				return '/dm/dashboard';
			} else if (this.currentUser.canUseInvoices() && company.canUseInvoices()) {
				return '/dm/invoices';
			}
			return '/settings';
		} else {
			return this.currentUser.getHomePageRegardlessOfCompanySettings();
		}
	}

	isUserConnectedToCurrentCompany(currentCompanyId: string): boolean {
		return this.currentUser.company === currentCompanyId;
	}

	public initializeUser(state): Promise<User> {
		return new Promise<User>((res, rej) => {
			return this.store
				.select('user')
				.pipe(take(1))
				.subscribe((val) => {
					// User already loaded, redirect.
					if (val.currentUser != null) {
						return res(val.currentUser);
					}

					if (this.authManagerService.getKey() !== null && this.authManagerService.getKey() !== '') {
						return this.getCurrentUser()
							.then((r) => {
								const userPayload = new User(r['data']['user']);
								this.store.dispatch(new Login({ user: userPayload }));

								if (userPayload.getPreferences() && userPayload.getPreferences().language !== '') {
									this.translateUtilService.setLanguage(userPayload.getPreferences().language);
								}

								this.store.dispatch(new UpdateKey({ key: this.authManagerService.getKey(), secret: this.authManagerService.getSecret() }));
								return res(userPayload);
							})
							.catch((r) => {
								this.notifications.handleAPIError(r);

								// Trigger logout for the user. Key error.
								this.store.dispatch(new Logout());
								this.notificationService.info(
									this.translate.instant(_('An error occurred while fetching your profile. Please login again.')),
								);

								this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
								return res(null);
							});
					} else {
						// Not logged in at all. Redirect.
						this.notificationService.info(this.translate.instant(_('Please login to access this page.')));

						this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
						return res(null);
					}
				});
		});
	}
}
