import { isValueSet, stringIsSetAndFilled } from '../../util/values';
import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import {
	CompanyPaymentMethod,
	PaymentMethodData,
	PaymentMethodListAPIRequest,
	PaymentMethodTransaction,
	PaymentMethodTransactionData,
	PaymentMethodTransactionListAPIRequest,
} from '#/models/company/payment-method.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Company } from '#/models/company/company.model';
import { ImportResult } from '#/models/import-result.model';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { SetCompanyPaymentMethods } from '#/models/company/company.actions';
import { CompanyService } from '#/services/company/company.service';
import { StoreService } from '~/app/services/store.service';

@Injectable({
	providedIn: 'root',
})
export class PaymentMethodService {
	constructor(
		private apiService: APIService,
		private notifications: APINotificationsService,
		private store: StoreService,
		private companyService: CompanyService,
	) {}

	public getCompanyOfLoggedUser(): Company {
		return this.companyService.getCompanyOfLoggedUser();
	}

	getPaymentMethodsByIDs(filter: PaymentMethodListAPIRequest, ids: Array<string>): Promise<PaymentMethodData> {
		filter.max = 9999;
		filter.list = true;
		return this.apiService
			.post(filter.getRequestURL(), { ids })
			.then((r) => {
				return new PaymentMethodData(r.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getPaymentMethod(id: string, companyId: string = null): Promise<CompanyPaymentMethod> {
		let url = '/api/v1/paymentMethod/' + id;
		if (stringIsSetAndFilled(companyId)) {
			url = '/api/v1/company/' + companyId + '/paymentMethod/' + id;
		}

		return this.apiService
			.get(url)
			.then((r) => new CompanyPaymentMethod(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getPaymentMethodTransactions(
		paymentMethodTransactionListAPIRequest: PaymentMethodTransactionListAPIRequest,
	): Promise<PaymentMethodTransactionData> {
		return this.apiService
			.get(paymentMethodTransactionListAPIRequest.getRequestURL())
			.then((r) => new PaymentMethodTransactionData(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getPaymentMethods(paymentMethodListAPIRequest: PaymentMethodListAPIRequest): Promise<PaymentMethodData> {
		return this.apiService
			.get(paymentMethodListAPIRequest.getRequestURL())
			.then((r) => new PaymentMethodData(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	createPaymentMethod(companyId: string, paymentMethod: CompanyPaymentMethod): Promise<CompanyPaymentMethod> {
		let url = '/api/v1/paymentMethod';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/paymentMethod';
		}

		return this.apiService
			.post(url, paymentMethod)
			.then((r) => new CompanyPaymentMethod(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	updatePaymentMethod(companyId: string, paymentMethod: CompanyPaymentMethod): Promise<CompanyPaymentMethod> {
		let url = '/api/v1/paymentMethod/' + paymentMethod.id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/paymentMethod/' + paymentMethod.id;
		}

		return this.apiService
			.patch(url, paymentMethod)
			.then((r) => new CompanyPaymentMethod(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	deletePaymentMethod(companyId: string, id: string): Promise<any> {
		let url = '/api/v1/paymentMethod/' + id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/paymentMethod/' + id;
		}

		return this.apiService
			.delete(url)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	createPaymentMethodTransaction(
		companyId: string,
		paymentMethod: CompanyPaymentMethod,
		paymentMethodTransaction: PaymentMethodTransaction,
	): Promise<PaymentMethodTransaction> {
		let url = '/api/v1/paymentMethod/' + paymentMethod.id + '/transactions';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/paymentMethod/' + paymentMethod.id + '/transactions';
		}

		return this.apiService
			.post(url, paymentMethodTransaction)
			.then((r) => new PaymentMethodTransaction(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	updatePaymentMethodTransaction(
		companyId: string,
		paymentMethod: CompanyPaymentMethod,
		paymentMethodTransaction: PaymentMethodTransaction,
	): Promise<PaymentMethodTransaction> {
		let url = '/api/v1/paymentMethod/' + paymentMethod.id + '/transactions/' + paymentMethodTransaction.id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/paymentMethod/' + paymentMethod.id + '/transactions/' + paymentMethodTransaction.id;
		}
		// @ts-ignore
		return (
			this.apiService
				.patch(url, paymentMethodTransaction)
				// TODO: Check what the correct return needs te be.
				.then((r) => new CompanyPaymentMethod(r.data))
				.catch((e) => {
					this.notifications.handleAPIError(e);
					throw e;
				})
		);
	}

	deletePaymentMethodTransaction(companyId: string, paymentMethod: CompanyPaymentMethod, id: string): Promise<any> {
		let url = '/api/v1/paymentMethod/' + paymentMethod.id + '/transactions/' + id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/paymentMethod/' + paymentMethod.id + '/transactions/' + id;
		}

		return this.apiService.delete(url).catch((e) => {
			this.notifications.handleAPIError(e);
			throw e;
		});
	}

	importPaymentMethods(company: Company, file: File, skipFirstRow = true): Promise<ImportResult> {
		const formData = new FormData();
		formData.append('file', file);

		if (skipFirstRow) {
			formData.append('skip_first_row', '1');
		}

		return this.apiService
			.post(`/api/v1/company/${company.getID()}/import/paymentMethods`, formData)
			.then((r) => {
				return Promise.resolve(new ImportResult(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	public initializePaymentMethods() {
		/* We need the current company before we load the payment methods. */
		return new Promise<Array<CompanyPaymentMethod>>((res, rej) => {
			return this.store
				.select('company')
				.pipe(map((value) => (value as any)?.company?.id))
				.pipe(distinctUntilChanged())
				.subscribe((companyId) => {
					if (isValueSet(this.companyService.getCompanyOfLoggedUser()) && stringIsSetAndFilled(companyId)) {
						const filters = new PaymentMethodListAPIRequest();
						filters.company = companyId;
						return this.getPaymentMethods(filters)
							.then((paymentMethodData) => {
								this.store.dispatch(new SetCompanyPaymentMethods({ companyPaymentMethods: paymentMethodData.payment_methods }));
								res(paymentMethodData.payment_methods);
							})
							.catch((e) => {
								rej(e);
							});
					} else {
						/* No company, resolve anyway. */
						res(null);
					}
				});
		});
	}
}

export enum PaymentMethod {
	None = '',
	Private = 'private',
	Cash = 'cash',
	PIN = 'pin',
	Bank = 'bank',
	CreditCard = 'creditcard',
	Other = 'other',
	NotPaid = 'not_paid',
}

export const staticPaymentMethods: Array<{
	id: PaymentMethod;
	name: string;
}> = [
	{
		id: PaymentMethod.None,
		name: _('None'),
	},
	{
		id: PaymentMethod.Bank,
		name: _('Bank'),
	},
	{
		id: PaymentMethod.Cash,
		name: _('Cash'),
	},
	{
		id: PaymentMethod.CreditCard,
		name: _('CreditCard'),
	},
	{
		id: PaymentMethod.NotPaid,
		name: _('Not paid'),
	},
	{
		id: PaymentMethod.Other,
		name: _('Other'),
	},
	{
		id: PaymentMethod.PIN,
		name: _('PIN'),
	},
	{
		id: PaymentMethod.Private,
		name: _('Private'),
	},
];
