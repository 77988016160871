import { apiToFrontend } from './transformer';
import { PreTransactionFrontendModel } from './frontendModel';
import { Attachment } from '#/models/transaction/attachment';

export class PreTransactionApiModel {
	id: string;
	user: string;
	company: string;
	transactionInterfaceId: string;
	description: string;
	purchaseDate: string; // DATEYMD
	currency: string;
	amount: number;
	originalCurrency?: string;
	exchangeRate?: number;
	originalAmount?: number;
	vatItems: Array<{
		currency: string;
		amount: number;
		vatPercentage: number;
	}>;
	administration?: string;
	project?: string;
	costCenter?: string;
	costUnit?: string;
	merchant?: string;
	category?: string;
	ocrEnhancedFiles?: Array<string>;

	constructor(data?: any) {
		Object.assign(this, data);
	}

	public transformToFrontend(loggedUserId: string): PreTransactionFrontendModel {
		return apiToFrontend(this, loggedUserId, PreTransactionFrontendModel);
	}
}
