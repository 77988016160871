import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { Random } from '../helpers/random';
import { Logout } from '~/app/modules/user/models/user.actions';
import { ResetCompany } from '#/models/company/company.actions';
import { setUser } from '~/app/util/sentry';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/reducers';
import { hmac_sha512 } from '../util/crypto';
import { APIService } from '~/app/api/services/api.service';
import { firstValueFrom } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { stringIsSetAndFilled } from '#/util/values';

@Injectable({
	providedIn: 'root',
})
export class AuthManagerService {
	static LOCALSTORAGE_KEY = 'key';
	static LOCALSTORAGE_SECRET = 'secret';
	static LOCALSTORAGE_USER_ID = 'userId';
	static LOCALSTORAGE_REFRESHTOKEN = 'refreshToken';
	static LOCALSTORAGE_ACTIVE_ACCOUNT = 'activeAccount';
	static API_VERSION = 'v1.0.11';
	private nonceCounter = 0;

	constructor(
		private localStorageService: LocalStorageService,
		private store: Store<AppState>,
		private http: HttpClient,
		private cookieService: CookieService,
	) {}

	async getHeaders(accountIndex: number = this.getActiveAccountID()): Promise<HttpHeaders> {
		const key = this.getKey(accountIndex);
		const secret = this.getSecret(accountIndex);
		const timeStamp = Math.round(new Date().getTime() / 1000).toString();
		const nonce = this.getNonce();
		const signature = await hmac_sha512(secret, timeStamp + nonce);
		const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone.toString();
		let headers = new HttpHeaders();
		if (stringIsSetAndFilled(key)) {
			headers = headers.append('X-Auth-Key', key);
		}
		headers = headers.append('X-Auth-Timestamp', timeStamp);
		headers = headers.append('X-Auth-Nonce', nonce);
		headers = headers.append('X-Auth-Signature', signature);
		headers = headers.append('X-Api-Version', AuthManagerService.API_VERSION);
		headers = headers.append('User-Timezone', userTimezone);
		return headers;
	}

	// Nonce is a random string per request that makes sure that a request
	// can only be used once in case of MITM attacks. We add a counter to make
	// sure to generate a random nonce in case of multiple requests at once
	// because Math.random is time based.
	getNonce(): string {
		const nonce = Random.randomStringLowerUpper(12) + this.nonceCounter.toString();
		this.nonceCounter++;

		// Reset counter when we go over 9999 so our nonce won't get longer
		// than 16 chars, which is not allowed by the API.
		if (this.nonceCounter > 9999) {
			this.nonceCounter = 0;
		}
		return nonce;
	}

	clearTokens(accountIndex: number = this.getActiveAccountID()) {
		this.setUserID(null, accountIndex);
		this.setKey(null, accountIndex);
		this.setSecret(null, accountIndex);
		this.setRefreshToken(null, accountIndex);
	}

	public async logOut(): Promise<void> {
		try {
			const headers = await this.getHeaders();
			await firstValueFrom(this.http.get(APIService.BASE_URL + '/api/v1/user/logout', { headers: headers }));
		} catch (_) {}

		this.cookieService.delete('betaAuth', '', '.klippa.com');
		this.localStorageService.remove('sso_states');
		this.localStorageService.remove('connection_state');
		this.localStorageService.remove('filters-personal');
		this.localStorageService.remove('filters-expenses');
		this.localStorageService.remove('filters-invoices');
		this.localStorageService.remove('filters-stats-user');
		this.localStorageService.remove('filters-stats-company');
		this.localStorageService.remove('filters-report-declarations-list-v2022-07');
		this.localStorageService.remove('filters-report-declarations-manage-list-v2022-07');
		this.clearTokens();
		this.store.dispatch(new Logout());
		this.store.dispatch(new ResetCompany());

		setUser({});
	}

	getActiveAccountID(): number {
		return this.localStorageService.get(AuthManagerService.LOCALSTORAGE_ACTIVE_ACCOUNT) as number;
	}

	setActiveAccountID(accountIndex: number) {
		this.localStorageService.set(AuthManagerService.LOCALSTORAGE_ACTIVE_ACCOUNT, accountIndex);
	}

	getKey(accountIndex: number = this.getActiveAccountID()): string {
		return this.localStorageService.get('account_' + accountIndex + '_' + AuthManagerService.LOCALSTORAGE_KEY) as string;
	}

	setKey(value: string, accountIndex: number = this.getActiveAccountID()) {
		this.localStorageService.set('account_' + accountIndex + '_' + AuthManagerService.LOCALSTORAGE_KEY, value);
	}

	getSecret(accountIndex: number = this.getActiveAccountID()): string {
		return this.localStorageService.get('account_' + accountIndex + '_' + AuthManagerService.LOCALSTORAGE_SECRET) as string;
	}

	setSecret(value: string, accountIndex: number = this.getActiveAccountID()) {
		this.localStorageService.set('account_' + accountIndex + '_' + AuthManagerService.LOCALSTORAGE_SECRET, value);
	}

	getRefreshToken(accountIndex: number = this.getActiveAccountID()): string {
		return this.localStorageService.get('account_' + accountIndex + '_' + AuthManagerService.LOCALSTORAGE_REFRESHTOKEN) as string;
	}

	setRefreshToken(value: string, accountIndex: number = this.getActiveAccountID()) {
		this.localStorageService.set('account_' + accountIndex + '_' + AuthManagerService.LOCALSTORAGE_REFRESHTOKEN, value);
	}

	getUserID(accountIndex: number = this.getActiveAccountID()): string {
		return this.localStorageService.get('account_' + accountIndex + '_' + AuthManagerService.LOCALSTORAGE_USER_ID);
	}

	setUserID(value: string, accountIndex: number = this.getActiveAccountID()) {
		this.localStorageService.set('account_' + accountIndex + '_' + AuthManagerService.LOCALSTORAGE_USER_ID, value);
	}
}
