import { User } from '#/models/user/user.model';
import { stringIsSetAndFilled } from '#/util/values';
import { Injectable } from '@angular/core';
import { UserService } from '~/app/modules/user/user.service';
import { TransactionEditorService } from '#/services/transaction/transaction-editor.service';
import { CompanyService } from '#/services/company/company.service';

export enum FilterOnGroupEnum {
	LoggedInUser = 'LoggedInUser',
	TxUser = 'TxUser',
	DontFilter = 'DontFilter',
}

export type FilterOnGroup = keyof typeof FilterOnGroupEnum;

export interface TransactionOwnerDependentPicker {
	txId: string;
	filterOnGroupsOf: FilterOnGroup;
	getGroups: () => Promise<string[]>;
}

@Injectable({
	providedIn: 'root',
})
export class TransactionOwnerDependantPickerService {
	constructor(
		private userService: UserService,
		private transactionEditorService: TransactionEditorService,
		private companyService: CompanyService,
	) {}

	public async getGroups(txId: string, filterOnGroupsOf: FilterOnGroup): Promise<string[]> {
		if (filterOnGroupsOf === FilterOnGroupEnum.DontFilter) {
			return null;
		}
		const currentUser: User = this.userService.getCurrentLoggedUser();

		if (filterOnGroupsOf === FilterOnGroupEnum.LoggedInUser) {
			return currentUser.companygroups;
		}

		if (!stringIsSetAndFilled(txId)) {
			return currentUser.companygroups;
		}

		const tx = await this.transactionEditorService.getTransaction(txId);
		if (tx.user === currentUser.id) {
			return currentUser.companygroups;
		}

		if (filterOnGroupsOf === FilterOnGroupEnum.TxUser) {
			return await this.companyService.getCompanyUserById(tx.user).then((user: User) => {
				return user?.companygroups;
			});
		}
		// Fallback value
		return currentUser.companygroups;
	}
}
