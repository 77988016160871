<div class="modal-header">
	<h4 class="modal-title" translate [translateParams]="{ nodeName: node.name }">Settings for %nodeName%</h4>
	<button type="button" class="close" aria-label="Close" (click)="dismissModal()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<klp-form [formGroup]="nodeSettingsForm">
	<div class="modal-body">
		<klp-form-element
			class="formElement"
			caption="{{ 'Custom label:' | translate }}"
			*ngIf="node.attachedData?.showCustomLabel || node.type === 'node' || node.isCustomValue"
		>
			<klp-form-text-input formControlName="custom_label"></klp-form-text-input>
		</klp-form-element>

		<klp-form-element class="formElement" caption="{{ 'Context: ' | translate }}" *ngIf="node.settings?.allowedContext?.length > 0">
			<div>
				<klp-form-select
					formControlName="context"
					placeholder="{{ 'Pick one or more contexts' | translate }}"
					[multiple]="true"
					[options]="contextOptions"
					(ngModelChange)="validateContextChangeAndUpdateOptions()"
				></klp-form-select>
			</div>
		</klp-form-element>

		<klp-form-element class="formElement" caption="{{ 'Value:' | translate }}" *ngIf="node.isCustomValue">
			<klp-form-text-input formControlName="value"></klp-form-text-input>
		</klp-form-element>

		<klp-form-element class="formElement" caption="{{ 'Date format:' | translate }}" *ngIf="node.attachedData?.showCustomDateFormat">
			<app-date-format-picker formControlName="dateFormat"></app-date-format-picker>
		</klp-form-element>

		<header class="tableHeader" translate>Attributes:</header>
		<table class="table table-sm">
			<thead>
				<tr>
					<th translate>Name</th>
					<th translate>Value</th>
					<th translate>Dynamic</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngIf="getAttributeControls().length === 0">
					<td colspan="3"><p translate>There are no attributes, you can add one by pressing the 'Add attribute' button</p></td>
				</tr>
				<tr *ngFor="let attribute of getAttributeControls(); let i = index" class="attributeRow">
					<td>
						<klp-form-element direction="vertical">
							<klp-form-text-input [formControl]="attribute.controls.name"></klp-form-text-input>
						</klp-form-element>
					</td>
					<td>
						<klp-form-element direction="vertical">
							<klp-form-text-input
								*ngIf="attribute.get('isDynamicValue').value === false"
								[formControl]="attribute.controls.value"
							></klp-form-text-input>

							<klp-form-select
								*ngIf="attribute.get('isDynamicValue').value === true"
								[formControl]="attribute.controls.value"
								placeholder="{{ 'Select a dynamic value' | translate }}"
								[options]="dynamicAttributeOptions"
							></klp-form-select>
						</klp-form-element>
					</td>
					<td>
						<klp-form-element direction="vertical">
							<klp-form-toggle
								[formControl]="attribute.controls.isDynamicValue"
								(ngModelChange)="clearAttributeValue(attribute)"
							></klp-form-toggle>
						</klp-form-element>
					</td>
					<td>
						<app-button variant="redFilled" size="small" (click)="deleteAttribute(i)" translate>Delete</app-button>
					</td>
				</tr>
			</tbody>
		</table>
		<app-button variant="greenFilled" size="small" (click)="addAttribute()" translate>Add attribute</app-button>
	</div>

	<div class="modal-footer">
		<app-button (click)="dismissModal()"><span translate>Cancel</span></app-button>
		<klp-form-submit-button [submitCallback]="save"><span translate>Save</span></klp-form-submit-button>
	</div>
</klp-form>
