export enum Errors {
	OAuthEmailTaken = 400029,
	OAuthNoEmail = 400030,
	NoReviewsFound = 1300003,
	ReceiptBookErrorWithDescription = 500039,
	IntegrationsCallErrorWithData = 110047,
	InputValidationError = 100000,
	EmailNotValidatedError = 400008,
	ErrorCodeCreditcardStatementCreateError = 1500003,
	CodeCreditcardStatementNoLinesError = 1500009,
}
