export class Address {
	AddressLine1: string;
	AddressLine2: string;
	AddressLine3: string;
	ZipCode: string;
	State: string;
	City: string;
	Country: string;
	Addition: string;
	Number: string;
	HouseNumber: string;
	NumberFull: string;
	Street: string;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}
