import { Injectable } from '@angular/core';
import { Category, CompanyCategoryData, CompanyCategoryListAPIRequest } from '#/models/company/category.model';
import { ImportResultCount } from '#/models/import-result.model';
import { CompanyDimensionsService } from '#/services/company/dimension/company-dimensions.service';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { filterUndefinedValues } from '#/util/objects';

@Injectable({
	providedIn: 'root',
})
export class CompanyCategoryService extends CompanyDimensionsService {
	getCompanyCategories(companyCategoryListAPIRequest: CompanyCategoryListAPIRequest): Promise<CompanyCategoryData> {
		companyCategoryListAPIRequest.list = true;
		const requestBody = {
			types: undefined,
		};
		if (arrayIsSetAndFilled(companyCategoryListAPIRequest.types)) {
			requestBody.types = companyCategoryListAPIRequest.types;
		}
		return this.apiService
			.post(companyCategoryListAPIRequest.getRequestURL(), filterUndefinedValues(requestBody))
			.then((r) => {
				return new CompanyCategoryData(r.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getCompanyCategoriesByIDs(companyCategoryListAPIRequest: CompanyCategoryListAPIRequest, ids: string[]): Promise<CompanyCategoryData> {
		companyCategoryListAPIRequest.list = true;
		companyCategoryListAPIRequest.max = 9999;
		return this.apiService
			.post(companyCategoryListAPIRequest.getRequestURL(), {
				ids,
			})
			.then((r) => {
				return Promise.resolve(new CompanyCategoryData(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	importCompanyCategories(companyId: string, file: File, skipFirstRow = true): Promise<ImportResultCount> {
		const formData = new FormData();
		formData.append('file', file);

		if (skipFirstRow) {
			formData.append('skip_first_row', '1');
		}

		let url = '/api/v1/companyCategory/import';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCategory/import';
		}

		return this.apiService
			.post(url, formData)
			.then((r) => {
				return Promise.resolve(new ImportResultCount(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	bulkCompanyCategories(companyId: string, type: string, categories: Category[]): Promise<ImportResultCount> {
		let url = '/api/v1/companyCategory/bulk';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCategory/bulk';
		}

		return this.apiService
			.post(url, {
				type,
				categories,
			})
			.then((r) => {
				return Promise.resolve(new ImportResultCount(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	createCompanyCategory(companyId: string, category: Category): Promise<Category> {
		let url = '/api/v1/companyCategory';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCategory';
		}

		return this.apiService
			.post(url, category)
			.then((r) => {
				return Promise.resolve(new Category(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	updateCompanyCategory(companyId: string, category: Category): Promise<Category> {
		let url = '/api/v1/companyCategory/' + category.id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCategory/' + category.id;
		}

		return this.apiService
			.patch(url, category)
			.then((r) => {
				return Promise.resolve(new Category(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	getCompanyCategory(companyId: string, id: string): Promise<Category> {
		let url = '/api/v1/companyCategory/' + id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCategory/' + id;
		}
		if (CompanyDimensionsService.requestCache.has(url)) {
			return CompanyDimensionsService.requestCache.get(url);
		}
		const promise = this.apiService
			.get(url)
			.then((r) => {
				CompanyDimensionsService.requestCache.delete(url);
				return Promise.resolve(new Category(r.data));
			})
			.catch((e) => {
				CompanyDimensionsService.requestCache.delete(url);
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
		CompanyDimensionsService.requestCache.set(url, promise);
		return promise;
	}

	getCompanyCategoriesFilteredOnPresets(
		ListAPIRequest: CompanyCategoryListAPIRequest,
		presetOptions: string[],
	): Promise<CompanyCategoryData> {
		return this.getCompanyCategories(ListAPIRequest).then((data) => {
			const tempData = data;
			if (arrayIsSetAndFilled(presetOptions)) {
				tempData.company_categories = tempData.company_categories.filter((item) => presetOptions.includes(item.id));
			}
			return tempData;
		});
	}

	deleteCompanyCategory(companyId: string, id: string): Promise<any> {
		let url = '/api/v1/companyCategory/' + id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCategory/' + id;
		}

		return this.apiService
			.delete(url)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}
}
