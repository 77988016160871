import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlatTreeNode } from '~/app/shared/ui/custom-xml-export/interfaces/treeNode';

@Component({
	selector: 'app-tree-node',
	templateUrl: './tree-node.component.html',
	styleUrls: ['./tree-node.component.scss'],
})
export class TreeNodeComponent {
	@Input() node: FlatTreeNode;
	@Input() isNode: boolean;
	@Input() isExpanded: boolean;
	@Input() showNodeSettingsButtons: boolean = true;
	@Input() disabled: boolean = false;

	@Output() onToggleNode: EventEmitter<FlatTreeNode> = new EventEmitter<FlatTreeNode>();
	@Output() onOpenNodeSettingsModal: EventEmitter<FlatTreeNode> = new EventEmitter<FlatTreeNode>();

	toggleNode() {
		this.onToggleNode.emit(this.node);
	}

	openNodeSettingsModal() {
		this.onOpenNodeSettingsModal.emit(this.node);
	}

	nodeHasSettings(): boolean {
		const settings = this.node.settings;
		return (
			!!settings.custom_label ||
			!!settings.value ||
			!!settings.dateFormat ||
			settings.attributes?.length > 0 ||
			settings.context?.length > 0
		);
	}
}
