import { Component, Input, OnInit } from '@angular/core';
import { CompanyService } from '#/services/company/company.service';
import { CompanyAdministrationService } from '#/services/company/company-administration.service';
import { stringIsSetAndFilled } from '#/util/values';

@Component({
	selector: 'app-company-administration-label',
	templateUrl: './company-administration-label.component.html',
	styleUrls: ['./company-administration-label.component.scss'],
})
export class CompanyAdministrationLabelComponent implements OnInit {
	@Input() public administrationId: string;
	public administrationNamePromise: Promise<string>;
	private companyId: string;

	constructor(private companyService: CompanyService, private companyAdministrationService: CompanyAdministrationService) {
		this.companyId = companyService.getCompanyOfLoggedUser()?.id;
	}

	ngOnInit(): void {
		if (stringIsSetAndFilled(this.administrationId)) {
			this.administrationNamePromise = this.companyAdministrationService
				.getCompanyAdministration(this.companyId, this.administrationId)
				.then((administration) => administration.name);
		}
	}
}
