import { Component, Input } from '@angular/core';

export enum IconVariants {
	alert = 'ti-alert',
	arrowDown = 'ti-arrow-down',
	arrowUp = 'ti-arrow-up',
	arrowLeft = 'ti-angle-left',
	arrowRight = 'ti-angle-right',
	barChart = 'ti-bar-chart',
	barChartAlt = 'ti-bar-chart-alt',
	calendar = 'ti-calendar',
	check = 'ti-check',
	close = 'fa-times',
	cloudUp = 'cloud-up',
	copy = 'ei-copy',
	cross = 'fa-times',
	download = 'ei-download-1',
	dots = 'ti-more-alt',
	dotsVertical = 'fa-ellipsis-v',
	verticalDots = 'verticalDots',
	email = 'ti-email',
	folder = 'ti-folder',
	info = 'ti-info-alt',
	lineChart = 'ti-stats-up',
	menu = 'ti-menu',
	mobile = 'ti-mobile',
	penToSquare = 'ti-pencil-alt',
	plus = 'fa-plus',
	pieChart = 'ti-pie-chart',
	refresh = 'ei-refresh',
	search = 'ei-search',
	speechBubble = 'ti-comment-alt',
	time = 'ti-time',
	trash = 'ti-trash',
	world = 'ti-world',
	settings = 'ti-settings',
	newWindow = 'ti-new-window',
}

export enum IconColors {
	primary = 'primary',
	success = 'success',
	warning = 'warning',
	danger = 'danger',
	dark = 'dark',
	info = 'info',
	accepted = 'accepted',
	gray = 'gray', // default
	white = 'white',
	black = 'black',
}

export enum IconSize {
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large',
}

@Component({
	selector: 'app-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
	@Input() variant: keyof typeof IconVariants;
	@Input() size: IconSize = IconSize.SMALL;
	@Input() color: IconColors = IconColors.gray;
	public variants = IconVariants;
	public colors = IconColors;
}
