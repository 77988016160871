import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/reducers';
import { User } from '#/models/user/user.model';
import { Company } from '#/models/company/company.model';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '~/environments/environment';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable()
export class TranslationReplaceService {
	protected user: User;
	protected company: Company;
	protected destroyCallbacks = [];

	constructor(private store: Store<AppState>, protected translate: TranslateService) {
		const userStoreSubscription = this.store.select('user').subscribe((val) => {
			this.user = val.currentUser;
		});

		const companySubscription = this.store.select('company').subscribe((val) => {
			this.company = val.company;
		});

		this.destroyCallbacks.push(() => {
			userStoreSubscription.unsubscribe();
			companySubscription.unsubscribe();
		});
	}

	public destroy() {
		this.destroyCallbacks.forEach((cb) => cb());
	}

	public getTranslation(key: string, fallback: string, plural = false) {
		if (this.company && this.company.modules && this.company.modules.rename_company_units.enabled) {
			if (key === 'companycostunit') {
				return this.company.modules.rename_company_units.costUnit.get(plural, fallback);
			} else if (key === 'companycostcenter') {
				return this.company.modules.rename_company_units.costCenter.get(plural, fallback);
			} else if (key === 'companyproject') {
				return this.company.modules.rename_company_units.project.get(plural, fallback);
			} else if (key === 'companycategory') {
				return this.company.modules.rename_company_units.category.get(plural, fallback);
			} else if (key === 'companygroup') {
				return this.company.modules.rename_company_units.group.get(plural, fallback);
			}
		}
		return fallback;
	}

	get myKlippa() {
		if (environment.feature_flags.my_klippa_title) {
			return environment.feature_flags.my_klippa_title;
		} else if (this.company && this.company.isadministrationoffice) {
			return this.translate.instant(_('My documents'));
		} else if (this.user && this.user.canSubmitExpenses()) {
			return this.translate.instant(_('My expenses'));
		} else {
			return this.translate.instant(_('My Klippa'));
		}
	}

	/* Shorthand getters for common translated fields. */
	get costUnit() {
		return this.getTranslation('companycostunit', this.translate.instant(_('Cost unit')));
	}
	get costCenter() {
		return this.getTranslation('companycostcenter', this.translate.instant(_('Cost center')));
	}
	get project() {
		return this.getTranslation('companyproject', this.translate.instant(_('Project')));
	}
	get category() {
		return this.getTranslation('companycategory', this.translate.instant(_('Category')));
	}
	get group() {
		return this.getTranslation('companygroup', this.translate.instant(_('Group')));
	}
	get administration() {
		return this.getTranslation('companyadministration', this.translate.instant(_('Administration')));
	}

	get costUnits() {
		return this.getTranslation('companycostunit', this.translate.instant(_('Cost units')), true);
	}
	get costCenters() {
		return this.getTranslation('companycostcenter', this.translate.instant(_('Cost centers')), true);
	}
	get projects() {
		return this.getTranslation('companyproject', this.translate.instant(_('Projects')), true);
	}
	get categories() {
		return this.getTranslation('companycategory', this.translate.instant(_('Categories')), true);
	}
	get groups() {
		return this.getTranslation('companygroup', this.translate.instant(_('Groups')), true);
	}
	get administrations() {
		return this.getTranslation('companyadministration', this.translate.instant(_('Administrations')), true);
	}

	get product_title() {
		return environment.feature_flags.product_title || 'Klippa';
	}

	get companyUnits() {
		/* For company unit string interpolation. */
		return {
			costUnit: this.costUnit,
			costCenter: this.costCenter,
			project: this.project,
			category: this.category,
			group: this.group,
			administration: this.administration,
			costUnits: this.costUnits,
			costCenters: this.costCenters,
			projects: this.projects,
			categories: this.categories,
			product_title: this.product_title,
			groups: this.groups,
			administrations: this.administrations,
		};
	}
}
