import { Injectable } from '@angular/core';
import { SetSubCompany } from '#/models/company/company.actions';
import { Company } from '#/models/company/company.model';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/reducers';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute, ChildrenOutletContexts, Router } from '@angular/router';
import { CompanyApiService } from '#/services/company/company-api.service';
import { isValueSet } from '#/util/values';
import { IntegrationService } from '#/services/integrationService';

@Injectable({
	providedIn: 'root',
})
// Any additions to this service should be done in the Shared folder,
// this setCompany function has not been moved yet because it relies on the store which we do not use in mobile
export class SubCompanyServiceDeprecated {
	public subCompanyChanged: Subject<Company> = new Subject<Company>();
	private routeSubscription: Subscription;

	constructor(
		protected store: Store<AppState>,
		protected router: Router,
		private integrationService: IntegrationService,
		private companyApiService: CompanyApiService,
		private outlets: ChildrenOutletContexts,
	) {}

	public async setCompany(company: Company, refresh = true): Promise<void> {
		// We get the company from the API because the company being parsed might not be the most recent version.
		// Because the views are dependant on this value, we want to show the user the most recent version of the company.
		const apiCompany = await this.companyApiService.getCompany(company.id);
		this.store.dispatch(new SetSubCompany({ company: apiCompany }));
		this.integrationService.setIntegrationForCompany(apiCompany);
		this.subCompanyChanged.next(apiCompany);

		const queryParams = { c: apiCompany ? apiCompany.id : '' };
		const parent = this.getParentRoute(this.getActiveRoute());
		const route = this.getSubCompanyHomePage(company);
		this.router.navigate([route], { relativeTo: parent, queryParams });
	}

	private getSubCompanyHomePage(company: Company): string {
		if (company?.modules?.report?.enabled) {
			if (company?.modules?.cardReports.enabled) {
				return '/reports/card';
			}
			return '/reports';
		}
		if (company?.canUseExpenses()) {
			return '/dm/dashboard';
		}
		if (company?.canUseInvoices()) {
			return '/dm/invoices';
		}
		return '/settings';
	}

	private getActiveRoute(): ActivatedRoute {
		let childOutlet = this.outlets.getContext('primary');
		while (isValueSet(childOutlet.children.getContext('primary'))) {
			childOutlet = childOutlet.children.getContext('primary');
		}
		return childOutlet.route;
	}

	private getParentRoute(activeRoute: ActivatedRoute): ActivatedRoute {
		let parentOutlet = activeRoute.parent;
		while (Object.keys(parentOutlet.snapshot.params).length > 0) {
			parentOutlet = parentOutlet.parent;
		}
		return parentOutlet;
	}

	private onDestroy(): void {
		this.routeSubscription.unsubscribe();
	}
}
