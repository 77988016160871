import { Injectable } from '@angular/core';
import { Company, CompanyData, CompanySubCompaniesData, CompanySubCompaniesListAPIRequest } from '#/models/company/company.model';
import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { CompanyUsersFilterAPIRequest } from '#/models/company/companyUsersApiRequestFilter';
import { CompanyUserData } from '#/models/company/companyUser.model';
import { stringIsSetAndFilled } from '#/util/values';
import { User, UserRole } from '#/models/user/user.model';
import { ImportCreditcardFileData } from '#/models/company/import-creditcard-file.model';
import { CompensationRule } from '#/models/company/compensationRule.model';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { CurrentApproversResponse } from '#/models/currentApprovers.model';
import { ExportFormat } from '#/models/company/exporterInterfaces';

@Injectable({
	providedIn: 'root',
})
export class CompanyApiService {
	constructor(private apiService: APIService, private notifications: APINotificationsService) {}

	getCompanies(start: number = 0, searchQuery: string = null): Promise<CompanyData> {
		let url = `/api/v1/company?start=${start}`;
		if (stringIsSetAndFilled(searchQuery)) {
			url += `&search=${searchQuery}`;
		}
		return this.apiService
			.get(url)
			.then((r) => new CompanyData(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getCompaniesByIds(ids: Array<string>): Promise<Array<Company>> {
		return this.apiService
			.post(`/api/v1/company/list`, {
				ids,
			})
			.then((r) => Company.fromData(r.data.companies))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	async getCompany(id: string): Promise<Company> {
		return this.apiService
			.get(`/api/v1/company/${id}`, 2 * 1000)
			.then((r) => new Company(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return e;
			});
	}

	private deleteCompanyFromCache(companyId: string) {
		this.apiService.deleteFromCacheWhereUrlStartsWith(`company/${companyId}`);
	}

	public uploadCreditcardFile(companyId: string, file: File): Promise<ImportCreditcardFileData> {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('skip_first_row', 'true');
		return this.apiService
			.post(`/api/v1/company/${companyId}/import/velos`, formData)
			.then((r) => {
				return r.data;
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getCompanyUsers(companyId: string, filter?: CompanyUsersFilterAPIRequest): Promise<CompanyUserData> {
		return this.apiService
			.get(`/api/v1/company/${companyId}/user${filter ? filter.getQueryString() : ''}`)
			.then((r) => new CompanyUserData(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getCompanyUsersByIds(
		companyId: string,
		ids: Array<string>,
		filter: CompanyUsersFilterAPIRequest = new CompanyUsersFilterAPIRequest(),
	): Promise<CompanyUserData> {
		if (!arrayIsSetAndFilled(ids)) {
			return Promise.resolve(new CompanyUserData());
		}
		filter.max = 9999;
		return this.apiService
			.post(`/api/v1/company/${companyId}/user/list${filter ? filter.getQueryString() : ''}`, {
				ids,
			})
			.then((r) => new CompanyUserData(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getCompanyIcon(company: Company): Promise<Blob> {
		try {
			return this.apiService.getBlob(`/api/v1/company/${company.getID()}/icon`);
		} catch (e) {
			return Promise.resolve(undefined);
		}
	}
	patchCompanyIcon(company: Company, enable: boolean, icon: File): Promise<Company> {
		this.deleteCompanyFromCache(company.id);
		const formData = new FormData();
		formData.append('enable', enable.toString());
		formData.append('icon', icon);

		return this.apiService
			.patch(`/api/v1/company/${company.getID()}/icon`, formData)
			.then((r) => new Company(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	async getCustomCompanyLogo(
		company: Company,
		version: 'small_logo_dark' | 'large_logo_dark' | 'small_logo_light' | 'large_logo_light',
	): Promise<Blob> {
		try {
			return this.apiService.getBlob(`/api/v1/company/${company.getID()}/modules/CustomLogo/${version}`);
		} catch (e) {
			return Promise.resolve(undefined);
		}
	}

	createCompany(company: Company): Promise<Company> {
		return this.apiService
			.post(`/api/v1/company`, company)
			.then((r) => new Company(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	patchCompany(company: Company): Promise<Company> {
		this.deleteCompanyFromCache(company.id);
		if (company.trial && !company.trial.trialend) {
			/* Sometimes, trial end date is not set. The API will throw an error if trial end is null or undefined. */
			delete company.trial.trialend;
		}
		return this.apiService
			.patch(`/api/v1/company/${company.getID()}`, company)
			.then((r) => new Company(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	deleteCompany(company: Company): Promise<Company> {
		this.deleteCompanyFromCache(company.id);
		return this.apiService.delete(`/api/v1/company/${company.getID()}`).catch((e) => {
			this.notifications.handleAPIError(e);
			throw e;
		});
	}

	downloadImportTemplate(company: Company, template: string): Promise<Blob> {
		return this.apiService
			.postBlob(`/api/v1/company/${company.getID()}/import/template`, {
				template,
			})
			.then((r) => r)
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getCompanySubCompanies(companySubCompaniesListAPIRequest: CompanySubCompaniesListAPIRequest): Promise<CompanySubCompaniesData> {
		return this.apiService.get(companySubCompaniesListAPIRequest.getRequestURL()).then((r) => new CompanySubCompaniesData(r.data));
	}

	createSubCompany(companyId: string, company: Company): Promise<Company> {
		this.deleteCompanyFromCache(company.id);
		return this.apiService
			.post(`/api/v1/company/${companyId}/sub_companies`, company)
			.then((r) => new Company(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getAllActiveAndPaidForUsers(companyID: string): Promise<any> {
		return this.apiService.getFromApi('company/' + companyID + '/user/count');
	}

	public getAllCompanyApprovers(companyId: string): Promise<{ data: { company: Company; users: Array<User> } }> {
		const companyInvoiceApproverCode = UserRole.CompanyInvoiceApprover;
		const companyManagerCode = UserRole.CompanyManager;
		return this.apiService.get(`/api/v1/company/${companyId}/user?role=${companyInvoiceApproverCode},${companyManagerCode}`);
	}

	public getCompanyCompensationRules(companyId: string): Promise<Array<CompensationRule>> {
		return this.apiService
			.get(`/api/v1/company/${companyId}/compensationRules`)
			.then((r) => r.data.map((e) => new CompensationRule(e)))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getCompensationRuleById(companyId: string, compensationRuleID: string): Promise<CompensationRule> {
		return this.apiService
			.get(`/api/v1/company/${companyId}/compensationRules/${compensationRuleID}`)
			.then((r) => new CompensationRule(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public createCompensationRule(compensationRule: CompensationRule, companyId: string): Promise<CompensationRule> {
		return this.apiService
			.post(`/api/v1/company/${companyId}/compensationRules`, compensationRule)
			.then((r) => new Company(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public editCompensationRule(compensationRule: CompensationRule, companyId: string): Promise<CompensationRule> {
		return this.apiService
			.patch(`/api/v1/company/${companyId}/compensationRules/${compensationRule.id}`, compensationRule)
			.then((r) => new Company(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public deleteCompensationRule(compensationRuleId: string, companyId: string): Promise<void> {
		return this.apiService.delete(`/api/v1/company/${companyId}/compensationRules/${compensationRuleId}`).catch((e) => {
			this.notifications.handleAPIError(e);
			throw e;
		});
	}

	public getCompanyApproversWithPendingTasks(
		companyId: string,
		expensesOrInvoices: 'expenses' | 'invoices' | 'both',
	): Promise<CurrentApproversResponse> {
		let queryString = '';
		if (expensesOrInvoices !== 'both') {
			queryString = `?isinvoice=${expensesOrInvoices === 'invoices' ? 1 : 0}`;
		}
		return this.apiService
			.get(`/api/v1/stats/company/${companyId}/approvers${queryString}`)
			.then((r) => r.data)
			.catch((e) => {
				this.notifications.handleAPIError(e);
			});
	}

	public exportCompanyData(fileType: ExportFormat, companyIds: Array<string>): Promise<string> {
		const companyData: Array<{ type: string; id: string }> = companyIds.map((id) => ({ type: 'company', id: id }));
		return this.apiService
			.post(`/api/v1/admin/companies/export`, {
				type: fileType,
				items: companyData,
			})
			.then((r: { data: { url: string } }) => {
				return r.data.url;
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public exportSpanishCompliance(fileType: ExportFormat, companyIds: Array<string>, startDate: string, endDate: string): Promise<string> {
		const companyData: Array<{ type: string; id: string }> = companyIds.map((id) => ({ type: 'company', id: id }));
		return this.apiService
			.postToApi(`admin/companies/export/gallery`, {
				type: fileType,
				items: companyData,
				from: startDate,
				to: endDate,
			})
			.then((r: { data: { url: string } }) => {
				return r.data.url;
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}
}
