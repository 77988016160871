<ngx-async-template [promise]="exportPromise" *ngIf="innerValue">
	<ng-template ngx-async-error ngx-async-inactive let-asyncStatus="status" let-errorMessages="errorMessages">
		<app-error-message *ngIf="asyncStatus === 'error'" [errorMessages]="errorMessages"></app-error-message>

		<div class="wideContainer">
			<klp-form [formGroup]="formGroupExportForm">
				<klp-form-element [caption]="'Export format' | translate" direction="vertical">
					<klp-form-select
						(ngModelChange)="onExportFormatChange()"
						[clearable]="false"
						[options]="exportFormatOptions"
						formControlName="type"
					></klp-form-select>
				</klp-form-element>

				<klp-form-element
					*ngIf="variant === 'createTemplate' && isExportFormat('xml')"
					[caption]="'XML builder' | translate"
					direction="vertical"
				>
					<app-custom-xml-export formControlName="columns" [exportType]="exportType" [userRole]="userRole"></app-custom-xml-export>
				</klp-form-element>

				<klp-form-element [caption]="'URL' | translate" *ngIf="isExportFormat('xml')" direction="vertical">
					<klp-form-text-input formControlName="URL"></klp-form-text-input>
				</klp-form-element>

				<ng-container *ngIf="isExportFormat('pdf', 'xlsx', 'csv')">
					<ngx-async-template [promise]="columnLoaderPromise">
						<ng-template ngx-async-pending>
							<klp-form-loading-indicator></klp-form-loading-indicator>
						</ng-template>
						<ng-template ngx-async-success>
							<div class="mrg-top-10" translate>Please select the columns that you would like to export.</div>

							<div *ngIf="!getFormValue('combine_travel_and_receipts')">
								<div>
									<app-button (click)="showExportColumns = !showExportColumns" class="showExportColumns" variant="greenLink"
										>+ {{ 'Columns' | translate }}</app-button
									>
								</div>

								<div *ngIf="showExportColumns">
									<klp-form-element direction="vertical">
										<klp-form-sortable-items formControlName="columns" [useCustomScroll]="true">
											<ng-template let-index="index">
												<app-export-column
													[disabled]="disabled"
													[(ngModel)]="getFormValue('columns')[index]"
													[hideDateFormatPicker]="isExportFormat('xlsx')"
													[ngModelOptions]="{ standalone: true }"
												>
												</app-export-column>
											</ng-template>
										</klp-form-sortable-items>
									</klp-form-element>
								</div>

								<div>
									<app-button
										(click)="showExportTravelColumns = !showExportTravelColumns"
										*ngIf="showExportTravelButton"
										class="showExportColumns"
										variant="greenLink"
										>+ {{ 'Travel columns' | translate }}</app-button
									>
								</div>

								<div *ngIf="showExportTravelColumns">
									<klp-form-element direction="vertical">
										<klp-form-sortable-items formControlName="travel_columns" [useCustomScroll]="true">
											<ng-template let-index="index">
												<app-export-column
													[disabled]="disabled"
													[(ngModel)]="getFormValue('travel_columns')[index]"
													[ngModelOptions]="{ standalone: true }"
												>
												</app-export-column>
											</ng-template>
										</klp-form-sortable-items>
									</klp-form-element>
								</div>
							</div>

							<div *ngIf="getFormValue('combine_travel_and_receipts')">
								<app-button
									(click)="showExportTravelAndColumns = !showExportTravelAndColumns"
									class="showExportColumns"
									variant="greenLink"
								>
									+ {{ 'Receipt and travel columns' | translate }}
								</app-button>

								<klp-form-element direction="vertical">
									<div *ngIf="showExportTravelAndColumns">
										<klp-form-sortable-items formControlName="travel_and_receipt_columns" [useCustomScroll]="true">
											<ng-template let-index="index">
												<app-export-column
													[disabled]="disabled"
													[(ngModel)]="getFormValue('travel_and_receipt_columns')[index]"
													[ngModelOptions]="{ standalone: true }"
												>
												</app-export-column>
											</ng-template>
										</klp-form-sortable-items>
									</div>
								</klp-form-element>
							</div>
						</ng-template>
					</ngx-async-template>
				</ng-container>

				<klp-form-element *ngIf="isExportFormat('pdf')" [caption]="'Overview' | translate">
					<klp-form-checkbox class="toggleCheckbox" formControlName="overview"></klp-form-checkbox>
				</klp-form-element>

				<klp-form-element *ngIf="isExportFormat('pdf')" [caption]="'Data and image on separate pages' | translate">
					<klp-form-checkbox class="toggleCheckbox" formControlName="split_images_from_data"></klp-form-checkbox>
				</klp-form-element>

				<div *ngIf="isExportFormat('pdf') && companyExport">
					<klp-form-element [caption]="'Split per person' | translate">
						<klp-form-checkbox class="toggleCheckbox" formControlName="split_per_person"></klp-form-checkbox>
					</klp-form-element>

					<klp-form-element [caption]="'Add statistics' | translate">
						<klp-form-checkbox class="toggleCheckbox" formControlName="stats"></klp-form-checkbox>
					</klp-form-element>
				</div>

				<klp-form-element *ngIf="isExportFormat('pdf')" [caption]="'Exclude footer' | translate">
					<klp-form-checkbox class="toggleCheckbox" formControlName="empty_footer"></klp-form-checkbox>
				</klp-form-element>

				<klp-form-element
					*ngIf="isExportFormat('xlsx', 'csv') && showExportTravelButton"
					[caption]="'Combine travel declarations and receipts into 1 sheet' | translate"
				>
					<klp-form-checkbox class="toggleCheckbox" formControlName="combine_travel_and_receipts"></klp-form-checkbox>
				</klp-form-element>

				<ng-container *ngIf="isExportFormat('csv')">
					<klp-form-element [caption]="'CSV Separator' | translate" direction="vertical">
						<klp-form-select
							[clearable]="false"
							[options]="csvSeparatorOptions"
							class="selector"
							formControlName="csv_separator"
						></klp-form-select>
					</klp-form-element>

					<klp-form-element [caption]="'Decimal Separator' | translate" direction="vertical">
						<klp-form-select
							[clearable]="false"
							[options]="decimalOptions"
							class="selector"
							formControlName="decimal_separator"
						></klp-form-select>
					</klp-form-element>
				</ng-container>

				<ng-container *ngIf="isExportFormat('ubl')">
					<klp-form-element [caption]="'Add documents and images' | translate">
						<klp-form-checkbox class="toggleCheckbox" formControlName="ubl_attachments"></klp-form-checkbox>
					</klp-form-element>
					<div *ngIf="getFormValue('ubl_attachments') === true">
						<klp-form-element [caption]="'Combine attachments into one PDF' | translate">
							<klp-form-checkbox class="toggleCheckbox" formControlName="ubl_combine_attachments"></klp-form-checkbox>
						</klp-form-element>

						<klp-form-element [caption]="'Add a PDF for every UBL' | translate">
							<klp-form-checkbox class="toggleCheckbox" formControlName="ubl_add_pdf"></klp-form-checkbox>
						</klp-form-element>
					</div>
				</ng-container>
			</klp-form>
		</div>
	</ng-template>
</ngx-async-template>
