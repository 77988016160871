<td *ngFor="let cell of cells" class="cell" (click)="clickRowHandler()">
	<div [ngSwitch]="cell.type">
		<ng-template [ngSwitchCase]="'selector'">
			<!-- stopPropagation prevents clickRowHandler from executing the general click action -->
			<klp-form-checkbox [(ngModel)]="rowSelected" (click)="$event.stopPropagation()" (ngModelChange)="toggleRowSelection()">
			</klp-form-checkbox>
		</ng-template>
		<ng-template [ngSwitchCase]="'description'">
			<div class="withMaxWidth">
				<table-row-icon *ngIf="!cell.compact_mode" [description]="cell.context" [merchant]="cell.merchant"> </table-row-icon>
				{{ cell.context }}
			</div>
		</ng-template>
		<ng-template [ngSwitchCase]="'date'">
			{{ cell.context | dateFormat }}
		</ng-template>
		<ng-template [ngSwitchCase]="'date-range'">
			{{ cell.start | dateFormat }}
			<!-- Do not show dash within range if first date is invalid -->
			<span *ngIf="cell.start !== emptyDate"> - </span>
			{{ cell.end | dateFormat }}
		</ng-template>
		<ng-template [ngSwitchCase]="'link'">
			<a [routerLink]="cell.url">{{ cell.context }}</a>
		</ng-template>
		<ng-template [ngSwitchCase]="'project'">
			<div class="withMaxWidth">
				<app-company-project-label [project]="cell.context" [company]="company"></app-company-project-label>
			</div>
		</ng-template>
		<ng-template [ngSwitchCase]="'administration'">
			<div class="withMaxWidth">
				<app-company-administration-label [administrationId]="cell.context"></app-company-administration-label>
			</div>
		</ng-template>
		<ng-template [ngSwitchCase]="'cost_center'">
			<div class="withMaxWidth">
				<app-company-cost-center-label [costcenter]="cell.context" [company]="company"></app-company-cost-center-label>
			</div>
		</ng-template>
		<ng-template [ngSwitchCase]="'cost_unit'">
			<div class="withMaxWidth">
				<app-company-cost-unit-label [costunit]="cell.context" [company]="company"></app-company-cost-unit-label>
			</div>
		</ng-template>
		<ng-template [ngSwitchCase]="'category'">
			<div class="withMaxWidth">
				<app-company-category-label [category]="cell.context" [company]="company"> </app-company-category-label>
			</div>
		</ng-template>
		<ng-template [ngSwitchCase]="'paymentmethod'">
			<app-payment-method-label [paymentMethod]="cell.context"> </app-payment-method-label>
		</ng-template>
		<ng-template [ngSwitchCase]="'payment_method_id'">
			<app-company-payment-method-label [payment_method_id]="cell.context"> </app-company-payment-method-label>
		</ng-template>
		<ng-template [ngSwitchCase]="'user'">
			<app-company-user-label [userID]="cell.context"></app-company-user-label>
		</ng-template>
		<ng-template [ngSwitchCase]="'vat'">
			<strong>
				{{ [cell.amount, cell.currency] | convertToAmountWithCurrencyType | amountWithCurrency }}
			</strong>
		</ng-template>
		<ng-template [ngSwitchCase]="'amount'">
			<strong>
				{{ [cell.amount, cell.currency] | convertToAmountWithCurrencyType | amountWithCurrency }}
			</strong>
		</ng-template>
		<ng-template [ngSwitchCase]="'status_to_show'">
			<app-status-badge
				*ngIf="cell.context"
				[status]="{ status: cell.context }"
				[authorizationFlow]="cell.authorizationFlow"
				[parentItem]="row"
				[parentType]="itemName"
			>
			</app-status-badge>
		</ng-template>
		<ng-template [ngSwitchCase]="'status'">
			<app-status-badge
				*ngIf="cell.context"
				[status]="cell.context"
				[authorizationFlow]="cell.authorizationFlow"
				[parentItem]="row"
				[parentType]="itemName"
			>
			</app-status-badge>
		</ng-template>
		<ng-template [ngSwitchCase]="'document'">
			<app-attachment-missing-badge
				*ngIf="cell.show"
				[markedAsNotAvailable]="cell.context === 'noDocumentAvailable'"
				[hasAttachments]="cell.context === 'hasAttachments'"
			></app-attachment-missing-badge>
		</ng-template>

		<ng-template [ngSwitchCase]="'current_approver'">
			<app-receipt-current-approver
				*ngIf="hasAcceptedStatus() && (itemName === 'receipt' || itemName === 'transaction')"
				[receiptId]="row.id"
			></app-receipt-current-approver>
			<app-receipt-current-approver *ngIf="hasAcceptedStatus() && itemName === 'report'" [reportId]="row.id"></app-receipt-current-approver>
		</ng-template>

		<ng-template [ngSwitchCase]="'booking_status'">
			<app-booking-status-badge
				*ngIf="cell.booking_status || cell.booking_data"
				[bookingStatus]="cell.booking_status"
				[bookingData]="cell.booking_data"
				[declarationStatus]="cell.declaration_status"
				[company]="company"
			>
			</app-booking-status-badge>
		</ng-template>
		<ng-template [ngSwitchCase]="'duplicate_notifier'">
			<app-duplicate-notifier
				*ngIf="cell.context"
				(click)="$event.stopPropagation()"
				[duplicateItems]="cell.context"
				(onDuplicateClick)="clickOpenReceipt($event)"
			>
			</app-duplicate-notifier>
		</ng-template>
		<ng-template [ngSwitchCase]="'column_config'">
			<div (click)="$event.stopPropagation()">
				<app-button *ngIf="rowActions?.length > 0" size="small" [hasBorder]="false" (click)="toggleRowActions()">
					<i class="ti-more-alt"></i>
				</app-button>
				<app-action-menu
					*ngIf="showRowActions"
					placement="row"
					[actions]="rowActions"
					(onAction)="rowActionHandler($event)"
					(onDismiss)="toggleRowActions()"
				>
				</app-action-menu>
			</div>
		</ng-template>
		<ng-template ngSwitchDefault>
			<div class="withMaxWidth">
				{{ cell.context }}
			</div>
		</ng-template>
	</div>
</td>
