<div class="modal-header">
	<h4 class="modal-title" translate>Book report</h4>
	<button (click)="closeModal()" aria-label="Close" class="close" type="button">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<klp-form [formGroup]="expenseReportBookForm">
	<div class="fs-scroll-wrapper">
		<div class="modal-body">
			<klp-form-element [caption]="'Provider' | translate" direction="vertical">
				<app-form-integration-picker formControlName="provider" [autoSelectOnSingleResult]="true"> </app-form-integration-picker>
			</klp-form-element>
			<div *ngIf="expenseReportBookForm.get('provider').value">
				<klp-form-element [caption]="'Division' | translate" direction="vertical">
					<app-form-division-picker
						id="division"
						formControlName="division"
						[integrationId]="expenseReportBookForm.get('provider').value"
						[autoSelectOnSingleResult]="true"
					>
					</app-form-division-picker>
				</klp-form-element>
				<app-division-administration-warning
					[receiptDivisionId]="expenseReportBookForm.get('division').value"
					[klippaAdministrationId]="report.administration"
				></app-division-administration-warning>
				<klp-form-element *ngIf="expenseReportBookForm.get('division').value" [caption]="'Journal' | translate" direction="vertical">
					<app-form-journal-picker
						formControlName="journal"
						[integrationId]="expenseReportBookForm.get('provider').value"
						[divisionId]="expenseReportBookForm.get('division').value"
						[autoSelectOnSingleResult]="true"
					>
					</app-form-journal-picker>
				</klp-form-element>
				<klp-form-element
					[caption]="'Relation' | translate"
					*ngIf="company.preferences.dm_relation_selector && expenseReportBookForm.get('division').value"
					direction="vertical"
				>
					<app-form-integration-relation-picker
						formControlName="relation"
						[integrationId]="expenseReportBookForm.get('provider').value"
						[divisionId]="expenseReportBookForm.get('division').value"
					>
					</app-form-integration-relation-picker>
				</klp-form-element>
				<klp-form-element [caption]="'Booking date' | translate" direction="vertical">
					<klp-form-date-picker formControlName="bookingDate" [placeholder]="'Date of completing the booking' | translate">
					</klp-form-date-picker>
				</klp-form-element>
			</div>
			<app-warning-text *ngIf="isEdit" translate
				>Note: if you change the booking settings of a report, you will need to rebook all items again.</app-warning-text
			>
		</div>
	</div>
	<div class="modal-footer">
		<app-button aria-haspopup="true" aria-expanded="false" (click)="closeModal()">
			<span translate>Cancel</span>
		</app-button>
		<klp-form-submit-button [submitCallback]="bookReport">
			<span translate>Save</span>
		</klp-form-submit-button>
	</div>
</klp-form>
