import { User, UserRole } from '#/models/user/user.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Folder } from '#/models/user/folder';
import { format } from '~/app/util/i18n';
import { Period } from '#/models/period.model';
import { cloneDeep, identity, isEqual, isFunction, isUndefined, merge, omit, omitBy, pickBy } from 'lodash';
import { isNullOrUndefined, isValueSet, stringIsSetAndFilled } from '#/util/values';
import { Order } from '#/models/utils/order';
import { PaymentMethod } from '#/services/company/payment-method.service';
import { TransactionType } from './transactionType';
import { ThemeProduct } from '#/providers/themed';
import { convertDateToYMD } from '#/util/date';
import { DashboardChecklistTab } from '#/models/dashboardChecklist';
import { AccountingStatus } from '../accounting-integrations/accounting-integration-v2';

export enum dimensionKeys {
	CostCenters = 'cost_centers',
	CostUnits = 'cost_units',
	Projects = 'projects',
}

export class ReceiptBookingItem {
	ledger: string;
	vatcode: string;
	amount = 0;
	vatamount = 0;
	vatpercentage = 0;
	dimensions: { [key: string]: string } = {};
	provider: string;
	division: string;
	journal: string;
	purchaseorder: string;
	purchaseorderline: string;
	description: string;
	gAccountAmount: number = 0;
	gAccountPercentage: number = 0;
	BookVATIncluded: boolean;
	BookVATReverseCharged: boolean;
	deferred_from?: Date;
	deferred_to?: Date;
	bookingDate?: Date;
	categoryVATCode: string;

	// Internal property.
	isNew: boolean;
	fromTotalAmount = false;

	static fromData(data): ReceiptBookingItem[] {
		return data.map((item) => new ReceiptBookingItem(item));
	}

	static fromReceipt(bookingDefaults: ReceiptBookingItem = new ReceiptBookingItem(), receipt: Receipt): ReceiptBookingItem[] {
		/* Create new ReceiptBookingItems based on a receipt and booking defaults. */
		let items = [];

		// Filter out empty items.
		const vatItems = receipt.getVATItems().filter((f) => (f.amount && f.amount !== 0) || (f.percentage && f.percentage !== 0));
		if (vatItems.length > 0) {
			items = vatItems.map((vat) => {
				// Try to fix VAT calculation differences.
				let amount = Math.round((vat.getAmount() * (10000 + vat.getPercentage())) / vat.getPercentage());
				if (vatItems.length === 1) {
					// When we only have 1 VAT item, we know that the VAT calculation should be the same as the total amount.
					// Due to calculation errors, this could be a little bit off.
					const difference = receipt.getAmount() - amount;

					// We allow a max difference of 50 cents. When difference is in this range, hardcode calculation.
					if (difference >= -50 && difference <= 50) {
						amount = receipt.getAmount();
					}
				}
				return new ReceiptBookingItem({
					...bookingDefaults.clone(),
					amount: amount,
					vatamount: vat.getAmount(),
					vatpercentage: vat.getPercentage(),
					isNew: true,
					BookVATIncluded: true,
					BookVATReverseCharged: false,
				});
			});
		} else {
			items.push(
				new ReceiptBookingItem({
					...bookingDefaults.clone(),
					amount: receipt.getAmount(),
					isNew: true,
					BookVATIncluded: true,
					BookVATReverseCharged: false,
					fromTotalAmount: true,
				}),
			);
		}
		return items;
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			this.bookingDate = Date.parse(data.bookingDate) > 0 ? new Date(data.bookingDate) : null;

			this.deferred_from = Date.parse(data.deferred_from) > 0 ? new Date(data.deferred_from) : null;
			this.deferred_to = Date.parse(data.deferred_to) > 0 ? new Date(data.deferred_to) : null;

			if (data.ledger === '') {
				this.ledger = null;
			}

			if (data.vatcode === '') {
				this.vatcode = null;
			}

			if (data.provider === '') {
				this.provider = null;
			}

			if (data.division === '') {
				this.division = null;
			}

			if (isNullOrUndefined(data.BookVATIncluded)) {
				this.BookVATIncluded = true;
			}
			if (isNullOrUndefined(data.BookVATReverseCharged)) {
				this.BookVATReverseCharged = false;
			}

			if (data.purchaseorderline === '') {
				this.purchaseorderline = null;
			}

			if (data.description === '') {
				this.description = null;
			}

			if (!this.dimensions || typeof this.dimensions !== 'object') {
				this.dimensions = {};
			}

			if (data.companycostcenter) {
				this.dimensions[dimensionKeys.CostCenters] = data.companycostcenter;
			}

			if (data.costunit) {
				this.dimensions[dimensionKeys.CostUnits] = data.costunit;
			}

			if (data.project) {
				this.dimensions[dimensionKeys.Projects] = data.project;
			}

			if (this.dimensions) {
				const usedDimensionKeys = Object.keys(this.dimensions);
				if (usedDimensionKeys && usedDimensionKeys.length > 0) {
					usedDimensionKeys.forEach((key) => {
						if (this.dimensions[key] && this.dimensions[key] === '') {
							this.dimensions[key] = null;
						}
					});
				}
			}
		}
	}

	getAmount() {
		return this.amount;
	}

	getVATAmount() {
		return this.vatamount;
	}

	setAmount(amount: number) {
		const amountChanged = this.amount !== amount;
		this.amount = amount;
		if (this.isNew || amountChanged) {
			this.calculateVAT();
		}
	}

	get bookingsAmount(): string {
		if (this.amount === undefined || this.amount === null) {
			return '';
		}
		return this.amount.toString(10);
	}

	set bookingsAmount(amount: string) {
		const newAmount = parseInt(amount, 10);
		this.setAmount(newAmount);
	}

	get bookingsAmountExcl(): string {
		if (this.isNew) {
			this.calculateVAT();
		}
		const value = this.amount - this.vatamount;
		return value.toString(10);
	}

	set bookingsAmountExcl(amount: string) {
		const value = parseInt(amount, 10);
		const newAmount = value + Math.round((value / 10000) * this.vatpercentage);
		this.setAmount(newAmount);
	}

	get bookingsVATPercentage() {
		return this.vatpercentage;
	}

	set bookingsVATPercentage(percentage: number) {
		const percentageChanged = percentage !== this.vatpercentage;
		this.vatpercentage = percentage;
		if (this.isNew || percentageChanged) {
			this.calculateVAT();
		}
	}

	calculateVAT() {
		if (this.BookVATIncluded) {
			this.vatamount = Math.round((this.amount / (10000 + this.vatpercentage)) * this.vatpercentage);
		} else {
			this.vatamount = Math.round(this.amount * ((10000 + this.vatpercentage) / 10000)) - this.amount;
		}
	}

	clone(): ReceiptBookingItem {
		return new ReceiptBookingItem(JSON.parse(JSON.stringify(this)));
	}
}

export class ReceiptBookingStatus {
	isbooked: boolean;
	canbePickedUpByQueue: boolean;
	backgroundQueueError: string | undefined;
	bookedon: Date;
	externalprovider: string;
	externalid: string;
	division: string;
	journal: string;
	bookedby?: string;
	bookings?: ReceiptBookingItem[];

	static fromData(data): ReceiptBookingStatus[] {
		return data.map((item) => new ReceiptBookingStatus(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.bookedon) {
				this.bookedon = Date.parse(data.bookedon) > 0 ? new Date(data.bookedon) : null;
			}

			if (data.bookings) {
				this.bookings = ReceiptBookingItem.fromData(data.bookings);
			}
		}
	}
}

export class ReceiptCurrencyExchange {
	is_exchanged: boolean;
	from_currency: string;
	to_currency: string;
	exchange_rate: number;
	original_amount: number;

	set isExchanged(isExchanged: boolean) {
		this.is_exchanged = isExchanged;
	}

	set fromCurrency(fromCurrency: string) {
		this.from_currency = fromCurrency;
	}

	set toCurrency(toCurrency: string) {
		this.to_currency = toCurrency;
	}

	set exchangeRate(exchangeRate: number) {
		this.exchange_rate = exchangeRate;
	}

	set originalAmount(originalAmount: number) {
		this.original_amount = originalAmount;
	}

	get isExchanged() {
		return this.is_exchanged;
	}

	get fromCurrency() {
		return this.from_currency;
	}

	get toCurrency() {
		return this.to_currency;
	}

	get exchangeRate() {
		return this.exchange_rate;
	}

	get originalAmount() {
		return this.original_amount;
	}
}

export class ReceiptBarcode {
	type: string;
	value: string;
}

export class ReceiptVatItem {
	amount: number;
	original_amount: number;
	percentage: number;

	static fromData(data): ReceiptVatItem[] {
		return data.map((item) => new ReceiptVatItem(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	getAmount(): number {
		return this.amount;
	}

	getPercentage(): number {
		return this.percentage;
	}

	setPercentage(percentage: number) {
		this.percentage = percentage;
	}

	setAmount(amount: number) {
		this.amount = amount;
	}
}

export class ReceiptACL {
	role: string;
	userid: string;
	email: string;
	locked: boolean;
	accepted: boolean;
	sharedbyid: string;
	sharedbyemail: string;

	static fromData(data): ReceiptACL[] {
		return data.map((item) => new ReceiptACL(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	getEmail(): string {
		return this.email;
	}

	getAccepted(): boolean {
		return this.accepted;
	}

	getRole(): string {
		return this.role;
	}

	setEmail(email: string) {
		this.email = email;
		return this;
	}

	setRole(role: string) {
		this.role = role;
		return this;
	}

	getUserID(): string {
		return this.userid;
	}

	isLocked(): boolean {
		return this.locked;
	}

	describeRole(): string {
		const role = this.getRole();
		if (role === 'Guest') {
			return _('View receipt');
		} else if (role === 'Co-Owner') {
			return _('View receipt, edit receipt, share receipt with others');
		} else if (role === 'Owner') {
			return _('View receipt, edit receipt, share receipt with others, delete receipt');
		}
	}
}

export class StartEndDates {
	start?: Date;
	end?: Date;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			this.start = Date.parse(data.start) > 0 ? new Date(data.start) : null;
			this.end = Date.parse(data.end) > 0 ? new Date(data.end) : null;
		}
	}
}

export class ReceiptTravelDeclarationLocation {
	title: string;
	address: string;
	lat: number;
	lng: number;
	start_end_dates?: StartEndDates = new StartEndDates();

	// Internal use only.
	maps_location: any;

	static fromData(data): ReceiptTravelDeclarationLocation[] {
		return data.map((item) => new ReceiptTravelDeclarationLocation(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.start_end_dates) {
				this.start_end_dates = new StartEndDates(data.start_end_dates);
			} else {
				this.start_end_dates = new StartEndDates();
			}
		}
	}

	get description() {
		return this.title;
	}

	matches(string: string): boolean {
		/** Returns whether this items matches a given search string. */
		return this.title.toLowerCase().includes(string.toLowerCase()) || this.address.toLowerCase().includes(string.toLowerCase());
	}

	equal(other?: ReceiptTravelDeclarationLocation) {
		if (!other) {
			return false;
		}
		return (
			other.title === this.title &&
			other.address === this.address &&
			other.lat === this.lat &&
			other.lng === this.lng &&
			other.maps_location === this.maps_location &&
			JSON.stringify(other.start_end_dates) === JSON.stringify(this.start_end_dates)
		);
	}
}

export class ReceiptTravelDeclaration {
	locations: ReceiptTravelDeclarationLocation[];
	distance: number;
	route: string;
	compensation: number;
	compensation_source: string;
	extra_dates: Date[];
	extra_dates_ymd: string[];

	constructor(data = null) {
		this.locations = [];
		this.extra_dates = [];

		if (data) {
			Object.assign(this, data);

			if (data.locations) {
				this.locations = ReceiptTravelDeclarationLocation.fromData(data.locations);
			} else {
				this.locations = [];
			}

			if (data.extra_dates) {
				this.extra_dates = [];
				data.extra_dates.forEach((extra_date) => {
					this.extra_dates.push(Date.parse(extra_date) > 0 ? new Date(extra_date) : null);
				});
			} else {
				this.extra_dates = [];
			}
		}
	}

	static new(): ReceiptTravelDeclaration {
		const travel_declaration = new ReceiptTravelDeclaration();
		travel_declaration.locations = [new ReceiptTravelDeclarationLocation(), new ReceiptTravelDeclarationLocation()];
		travel_declaration.extra_dates = [];
		return travel_declaration;
	}
}

export class VirusScanResult {
	status: 'ok' | 'virus_detected' | 'pending' | 'error';
	description: string;
}

export class ReceiptFile {
	id: string;
	user: string;
	content: string;
	filename: string;
	virus_scan_result: VirusScanResult;

	// Not exposed by API, used internally.
	internal_type: string;
	blob?: Blob;
	file?: File;
	local_file?: string;
	rawData: any;
	loading: boolean;

	static fromData(data): Array<ReceiptFile> {
		return data.map((item) => new ReceiptFile(item));
	}
	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class DeclarationStatusActor {
	userid: string;
	name: string;
	email: string;
	automatic_status?: boolean;
	automatic_status_source?: string;
	automatic_status_id?: string;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	getUserID() {
		return this.userid;
	}

	getName() {
		return this.name;
	}

	getEmail() {
		return this.email;
	}
}

export enum DeclarationStatusFlag {
	NotSubmitted = 'NotSubmitted',
	ToClaim = 'ToClaim', // pending
	Claimed = 'Claimed', // processed
	Accepted = 'Accepted',
	Approved = 'Approved',
	NeedsInformation = 'NeedsInformation',
	Denied = 'Denied',
}

enum ClientSideDeclarationStatus {
	NeedsReview = 'NeedsReview',
	OcrInProgress = 'OcrInProgress',
}
export type DeclarationStatusBadge = DeclarationStatusFlag | ClientSideDeclarationStatus;
export const DeclarationStatusBadge = { ...DeclarationStatusFlag, ...ClientSideDeclarationStatus };

export const AllDeclarationStatusFlags: Array<DeclarationStatusFlag> = [
	DeclarationStatusFlag.NotSubmitted,
	DeclarationStatusFlag.ToClaim,
	DeclarationStatusFlag.Claimed,
	DeclarationStatusFlag.Accepted,
	DeclarationStatusFlag.Approved,
	DeclarationStatusFlag.NeedsInformation,
	DeclarationStatusFlag.Denied,
];

export const getCaptionForStatusFlag = (flag: DeclarationStatusFlag) => {
	switch (flag) {
		case DeclarationStatusFlag.NotSubmitted:
			return _('Not submitted');
		case DeclarationStatusFlag.ToClaim:
			return _('Pending');
		case DeclarationStatusFlag.Claimed:
			return _('Processed');
		case DeclarationStatusFlag.Accepted:
			return _('In workflow');
		case DeclarationStatusFlag.Approved:
			return _('Approved');
		case DeclarationStatusFlag.NeedsInformation:
			return _('Incomplete');
		case DeclarationStatusFlag.Denied:
			return _('Denied');
	}
};

export enum BookingProvider {
	ExactOnlineNL = 'exactonline_nl',
	ExactOnlineBE = 'exactonline_be',
	ExactOnlineDE = 'exactonline_de',
	ExactGlobe = 'exact_globe',
	Twinfield = 'twinfield',
	Informer = 'informer',
	Odoo = 'odoo',
	Unit4Multivers = 'unit4_multivers',
	Xero = 'xero',
	Sage = 'sage',
	eBoekhouden = 'eboekhouden',
	Asperion = 'asperion',
	Afas = 'afas',
	SapBydesign = 'sap_bydesign',
	QuickBooks = 'quickbooks',
	UserAPI = 'user_api',
}

export class DeclarationStatus {
	status: DeclarationStatusFlag;
	previousstatus?: DeclarationStatusFlag;
	comment: string;
	by: DeclarationStatusActor;
	date: Date;
	updatedComment: string;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			this.date = Date.parse(data.date) > 0 ? new Date(data.date) : null;

			if (data.by) {
				this.by = new DeclarationStatusActor(data.by);
			}
		}
	}

	static fromData(data): DeclarationStatus[] {
		return data.map((item) => new DeclarationStatus(item));
	}

	getStatus(): DeclarationStatusFlag {
		return this.status;
	}

	getPreviousStatus(): DeclarationStatusFlag {
		return this.previousstatus;
	}

	getComment(): string {
		return this.comment;
	}

	getBy(): DeclarationStatusActor {
		return this.by;
	}

	getDate(): Date {
		return this.date;
	}
}

export enum OCRStatus {
	Processing = 'processing',
	Processed = 'processed',
	InReview = 'review',
	Error = 'error',
}

export class DocumentSplit {
	token: string;
	page_count: number;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class DocumentSplitSubmit {
	accountnumber?: string;
	companycategory?: string;
	companycostcenter?: string;
	companycostunit?: string;
	companyproject?: string;
	currency: string;
	finance_type: string;
	group?: string;
	paymentmethod?: string;
	tags: string[];
	splits: number[];
	isinvoice: boolean;
	description: string;
	report?: string;
}

export class ReceiptAuthorizationFlowApprover {
	user_id: string;
	date: Date;

	static fromData(data): ReceiptAuthorizationFlowApprover[] {
		return data.map((item) => new ReceiptAuthorizationFlowApprover(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.date) {
				this.date = Date.parse(data.date) > 0 ? new Date(data.date) : null;
			}
		}
	}
}

export class AuthorizationFlow {
	in_flow: boolean;
	flow_id: string;
	approvers: string[];
	require_approver_order: boolean;
	require_approver_count?: number;
	approvals: ReceiptAuthorizationFlowApprover[];

	static fromData(data): AuthorizationFlow[] {
		return data.map((item) => new AuthorizationFlow(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.approvals) {
				this.approvals = ReceiptAuthorizationFlowApprover.fromData(data.approvals);
			}
		}
	}

	authorizationFlowApproverCount(): number {
		if (!this.in_flow) {
			return 0;
		}

		if (!this.require_approver_order && this.require_approver_count) {
			return this.require_approver_count;
		}

		return this.approvers.length;
	}

	authorizationFlowApprovedCount(): number {
		if (!this.in_flow) {
			return 0;
		}

		return this.approvals.length;
	}
}

export class ReceiptAuthorizationFlowStatusUser {
	user_id: string;
	user_email: string;
	user_name: string;
	date: Date;

	static fromData(data): ReceiptAuthorizationFlowStatusUser[] {
		return data.map((item) => new ReceiptAuthorizationFlowStatusUser(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			if (data.date) {
				this.date = Date.parse(data.date) > 0 ? new Date(data.date) : null;
			} else {
				this.date = null;
			}
		}
	}
}

export class ReceiptAuthorizationFlowStatus {
	has_flow: boolean;
	in_flow: boolean;
	flow_id: string;
	flow_description: string;
	require_approver_order: boolean;
	require_approver_count: number;
	approvers: ReceiptAuthorizationFlowStatusUser[];
	approvals: ReceiptAuthorizationFlowStatusUser[];
	next_approvers: ReceiptAuthorizationFlowStatusUser[];
	waiting_approvers: ReceiptAuthorizationFlowStatusUser[];

	static fromData(data): ReceiptAuthorizationFlowStatus[] {
		return data.map((item) => new ReceiptAuthorizationFlowStatus(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.approvers) {
				this.approvers = ReceiptAuthorizationFlowStatusUser.fromData(data.approvers);
			}

			if (data.approvals) {
				this.approvals = ReceiptAuthorizationFlowStatusUser.fromData(data.approvals);
			}

			if (data.next_approvers) {
				this.next_approvers = ReceiptAuthorizationFlowStatusUser.fromData(data.next_approvers);
			}

			if (data.waiting_approvers) {
				this.waiting_approvers = ReceiptAuthorizationFlowStatusUser.fromData(data.waiting_approvers);
			}
		}
	}
}

export class ReceiptOCRChecks {
	detected_bank_account_numbers: string[];

	static fromData(data): ReceiptOCRChecks[] {
		return data.map((item) => new ReceiptOCRChecks(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export interface ReceiptPresets {
	merchants: string[];
	companyadministrations: string[];
	companycategories: string[];
	companycostcenters: string[];
	companycostunits: string[];
	companyprojects: string[];
	countries: string[];
	payment_methods: string[];
	payment_method_ids: string[];
}

export class Receipt {
	transaction_interface_type: TransactionType;
	constructor(data = null) {
		if (data) {
			this.setData(data);
		} else {
			this.currency = 'EUR';
			this.type = 'receipt';
			this.finance_type = 'purchase';
			this.amount = 0;
			this.merchant = '';
			this.custommerchant = '';
			this.traveldeclaration = ReceiptTravelDeclaration.new();
			this.currency_exchange = new ReceiptCurrencyExchange();
			this.currency_exchange.isExchanged = false;
			this.start_end_dates = new StartEndDates();
		}
	}

	get Label(): string {
		return this.getDescription();
	}

	set Label(value) {
		this.description = value;
	}

	get dropDownDescription() {
		let dropdownDescription = format(new Date(), 'dd-MM-yyyy');
		if (this.description !== '') {
			dropdownDescription += ' - ' + this.description;
		}
		return dropdownDescription;
	}

	set dropDownDescription(value) {}
	id: string;
	user: string;
	accounting: {
		status: AccountingStatus;
	};
	amount: number;
	amount_of_minutes: number;
	attachments_count: number;
	noDocumentAvailable: boolean;
	createdate: Date;
	updatedate: Date;
	tags: string[];
	images: ReceiptFile[];
	documents: ReceiptFile[];
	report: string;
	description: string;
	acl: ReceiptACL[];
	currency: string;
	country: string;
	purchasedate: Date;
	dueDate: string;
	purchase_date_ymd: string;
	converteddate: string;
	vatitems: ReceiptVatItem[];
	paymentmethod?: PaymentMethod;
	payment_method_id: string; // CompanyPaymentMethod
	accountnumber: string;
	bookingstatus: ReceiptBookingStatus;
	ocr_fields: string[];
	ocr_status: OCRStatus;
	type: string;
	batchstatus?: boolean;
	barcode?: ReceiptBarcode;
	lastexportdate: Date;
	lastcompanyexportdate: Date;
	finance_type: string;
	relation_number: string;
	payment_condition: string;
	integration_payment_method: string;
	vat_deductible: boolean;
	invoice_number: string;
	isinvoice: boolean;
	provider: string;
	division: string;
	original_division?: string;
	journal: string;
	bookings?: ReceiptBookingItem[];
	validated_bookings?: boolean;
	currency_exchange?: ReceiptCurrencyExchange;
	needs_ocr_review: boolean;
	document_type: string;
	group: string;
	merchant: string;
	custommerchant: string;
	traveldeclaration: ReceiptTravelDeclaration;
	companycategory?: string;
	companycostcenter?: string;
	companycostunit?: string;
	companyproject?: string;
	companyadministration?: string;
	presets?: ReceiptPresets;
	purchaseorder?: string;
	declarationlabel?: string;
	declarationstatus?: DeclarationStatus;
	declarationstatushistory?: DeclarationStatus[];
	VATincluded: boolean;
	gAccount: boolean;
	company: string;
	correction_amount?: number;
	correction_amount_vat?: number;
	duplicate_documents: string[] = [];
	authorization_flow?: AuthorizationFlow;
	created_with_preset: string;
	ocr_checks?: ReceiptOCRChecks;
	booking_date?: Date;
	locked: boolean;
	start_end_dates?: StartEndDates = new StartEndDates();
	dates?: Array<string>;
	transaction_interface?: string;

	/* Internal use */
	header: string = null;
	tagsIndex: string;
	userIndex = '';
	descriptionInvoiceNumberIndex = '';
	isProcessingIndex: number;
	needsOCRReviewIndex: number;
	receipt_data_manually_validated: boolean;
	ocrEnhancedImages?: Array<string>;

	static fromData(data): Receipt[] {
		return data.map((item) => new Receipt(item));
	}

	setData(data: any) {
		Object.assign(this, data);
		this.createdate = Date.parse(data.createdate) > 0 ? new Date(data.createdate) : null;
		this.updatedate = Date.parse(data.updatedate) > 0 ? new Date(data.updatedate) : null;
		this.lastexportdate = Date.parse(data.lastexportdate) > 0 ? new Date(data.lastexportdate) : null;
		this.lastcompanyexportdate = Date.parse(data.lastcompanyexportdate) > 0 ? new Date(data.lastcompanyexportdate) : null;
		this.purchasedate = Date.parse(data.purchasedate) > 0 ? new Date(data.purchasedate) : null;
		if (stringIsSetAndFilled(data.purchase_date_ymd)) {
			this.purchase_date_ymd = data.purchase_date_ymd;
		} else if (isValueSet(this.purchasedate)) {
			this.purchase_date_ymd = convertDateToYMD(this.purchasedate);
		}

		if (data.booking_date) {
			this.booking_date = Date.parse(data.booking_date) > 0 ? new Date(data.booking_date) : null;
		}

		if (stringIsSetAndFilled(data.payment_condition)) {
			this.payment_condition = data.payment_condition;
		}

		if (data.bookingstatus) {
			this.bookingstatus = new ReceiptBookingStatus(data.bookingstatus);
		}

		if (data.bookings) {
			this.bookings = ReceiptBookingItem.fromData(data.bookings);
		}

		if (data.vatitems) {
			this.vatitems = ReceiptVatItem.fromData(data.vatitems);
		}

		if (data.declarationstatus) {
			this.declarationstatus = new DeclarationStatus(data.declarationstatus);
		}

		if (data.declarationstatushistory) {
			this.declarationstatushistory = DeclarationStatus.fromData(data.declarationstatushistory);
		}

		if (data.acl) {
			this.acl = ReceiptACL.fromData(data.acl);
		}

		if (data.traveldeclaration) {
			this.traveldeclaration = new ReceiptTravelDeclaration(data.traveldeclaration);
		}

		if (data.authorization_flow) {
			this.authorization_flow = new AuthorizationFlow(data.authorization_flow);
		}

		if (data.ocr_checks) {
			this.ocr_checks = new ReceiptOCRChecks(data.ocr_checks);
		}

		if (data.start_end_dates) {
			this.start_end_dates = new StartEndDates(data.start_end_dates);
		} else {
			this.start_end_dates = new StartEndDates();
		}

		/* Following properties are omitted by the API if empty. We have to explicitly set null to ensure correct database updates. */
		[
			'group',
			'merchant',
			'acl',
			'currency',
			'vatitems',
			'lineitems',
			'lineitems',
			'extraproperties',
			'declarationstatushistory',
			'declarationstatus',
			'declarationlabel',
			'company',
			'declaration',
			'companycategory',
			'companycostcenter',
			'companycostunit',
			'companyproject',
			'traveldeclaration',
		].forEach((prop) => {
			if (data[prop] === undefined) {
				this[prop] = null;
			}
		});

		this.updateIndices();
		if (this.isProcessing()) {
			this.isProcessingIndex = 1;
		} else {
			this.isProcessingIndex = 0;
		}
		if (this.needs_ocr_review) {
			this.needsOCRReviewIndex = 1;
		} else {
			this.needsOCRReviewIndex = 0;
		}
	}

	getID(): string {
		return this.id;
	}

	mergeWith(receipt_data): Receipt {
		// when fields have a value of an empty string, it means that we want to clear that field in the input.
		const suggestionFields = [
			'companyadministration',
			'companycostcenter',
			'companycostunit',
			'companyproject',
			'companycategory',
			'paymentmethod',
			'accountnumber',
			'payment_method_id',
		];
		const fieldsWithEmptyString = suggestionFields.filter((field) => receipt_data[field] === '');

		/* Merges this receipt with a new receipt data object. Keeps any existing values and doesn't override with null / undefined values, empty strings. */
		receipt_data = omitBy(pickBy(receipt_data, identity), isFunction);

		// put the empty string fields back
		fieldsWithEmptyString.forEach((e) => {
			receipt_data[e] = null; // clear the value of all fields where the value was an empty string
		});
		return new Receipt(merge({}, this, receipt_data));
	}

	getDescription(): string {
		return this.description;
	}

	getFormattedPurchaseDate(): string {
		return format(new Date(this.purchase_date_ymd), 'd MMM yyyy');
	}

	getFormattedDueDate(): string {
		return isValueSet(this.dueDate) ? format(new Date(this.dueDate), 'd MMM yyyy') : '';
	}

	getInvoiceNumber(): string {
		return this.invoice_number;
	}

	getMerchantID(): string | null {
		if (this.merchant !== '') {
			return this.merchant;
		}

		return null;
	}

	getAttachmentCount(): number {
		return this.getImages().length + this.getDocuments().length;
	}

	getImages(): ReceiptFile[] {
		if (this.images) {
			return this.images;
		}

		return [];
	}

	getVATItems(): ReceiptVatItem[] {
		if (!this.vatitems) {
			return [];
		}
		return this.vatitems;
	}

	getDocuments(): ReceiptFile[] {
		if (this.documents) {
			return this.documents;
		}

		return [];
	}

	getOCRStatus(): OCRStatus {
		return this.ocr_status;
	}

	hasOCRStatus(): boolean {
		return (
			this.ocr_status === OCRStatus.Processed ||
			this.ocr_status === OCRStatus.Error ||
			this.ocr_status === OCRStatus.InReview ||
			this.ocr_status === OCRStatus.Processing
		);
	}

	isProcessing() {
		return this.getOCRStatus() && this.getOCRStatus() === OCRStatus.Processing;
	}

	getACL(): ReceiptACL[] {
		return this.acl;
	}

	getUserID() {
		return this.getACL()[0].getUserID();
	}

	getCompanyCategory(): string {
		return this.companycategory;
	}

	getCompanyCostCenter(): string {
		return this.companycostcenter;
	}

	getCompanyCostUnit(): string {
		return this.companycostunit;
	}

	getCompanyProject(): string {
		return this.companyproject;
	}

	getIntegrationPurchaseOrder(): string {
		return this.purchaseorder;
	}

	hasIntegrationPurchaseOrder(): boolean {
		return !!this.getIntegrationPurchaseOrder() && this.isinvoice;
	}

	getProvider(): string {
		return this.provider;
	}

	getDivision(): string {
		return this.division;
	}

	getJournal(): string {
		return this.journal;
	}

	getGroupID(): string | null {
		if (this.group !== '') {
			return this.group;
		}

		return null;
	}

	getCurrency(): string {
		return this.currency;
	}

	getBookings(): ReceiptBookingItem[] {
		if (this.bookings == null) {
			return [];
		}
		return this.bookings;
	}

	getPurchaseDate(): Date {
		return this.purchasedate;
	}

	setPurchaseDate(day: number, month: number, year: number) {
		this.purchasedate = new Date(year, month - 1, day);
	}

	getUpdateDate(): Date {
		return this.updatedate;
	}

	getCreateDate(): Date {
		return this.createdate;
	}

	getFinanceTypeSymbol(): string {
		return this.finance_type === 'sale' ? '⊕' : '⊖';
	}

	getFinanceType(): string {
		return this.finance_type;
	}

	getTags(): string[] {
		if (!this.tags) {
			return [];
		}
		return this.tags;
	}

	getLastExportDate(): Date | null {
		return this.lastexportdate;
	}

	getLastCompanyExportDate(): Date | null {
		return this.lastcompanyexportdate;
	}

	getAmount(): number {
		return this.amount;
	}

	getVATAmount(): number {
		let vatAmount = 0;

		if (this.vatitems) {
			this.vatitems.forEach(function (vatitem) {
				vatAmount += vatitem.amount;
			});
		}

		return vatAmount;
	}

	getExpenseStatus(): DeclarationStatusFlag | null {
		if (this.declarationstatus) {
			return this.declarationstatus.status;
		}

		return null;
	}

	getExpenseStatusUser(): String | null {
		if (this.declarationstatushistory && this.declarationstatushistory.length > 0) {
			return this.declarationstatushistory[0].by.name;
		}

		return null;
	}

	getExpenseStatusDate(): Date | null {
		if (this.declarationstatushistory && this.declarationstatushistory.length > 0) {
			return this.declarationstatushistory[0].date;
		}

		return null;
	}

	getDeclarationStatusHistory(): DeclarationStatus[] {
		return this.declarationstatushistory;
	}

	isBooked(): boolean {
		if (this.bookingstatus?.isbooked) {
			return true;
		}
		if (this.accounting?.status === AccountingStatus.BOOKED) {
			return true;
		}
		return false;
	}

	isLocked(): boolean {
		return this.locked;
	}

	// Is this a booking dune in the background and still in the queue to be booked?
	waitingToBebooked(): boolean {
		return (
			!this.isBooked() &&
			this.bookingstatus &&
			this.bookingstatus.canbePickedUpByQueue &&
			typeof this.bookingstatus.backgroundQueueError !== 'string'
		);
	}

	// Is this a booking dune in the background and has the booking resulted in errors?
	hasBackgroundError(): boolean {
		return !this.isBooked() && this.bookingstatus && typeof this.bookingstatus.backgroundQueueError === 'string';
	}

	receiptBookingBlocked(): boolean {
		return this.isLocked() || this.isBooked() || this.waitingToBebooked();
	}

	setBookingDate(date: any) {
		this.booking_date = typeof date === 'boolean' ? null : (this.booking_date = date);
	}

	isOwner(userId: string) {
		/*
      Return true if this receipt is owned by the specified user.
      Also returns true if this receipt has no owner yet (new receipt object)
    */
		const owner = this.getOwner();
		let isOwner = true;
		if (owner && userId && userId !== owner) {
			isOwner = false;
		}
		return isOwner;
	}

	canBeSubmitted(userId: string = null) {
		/* @param userId: the ID of the user that is trying to perform this submit action. */
		return !this.declarationstatus && this.isOwner(userId);
	}

	canBeReSubmitted(userId: string = null) {
		return this.declarationstatus && this.declarationstatus.status === DeclarationStatusFlag.NeedsInformation && this.isOwner(userId);
	}

	canAddComment() {
		return (
			this.declarationstatus &&
			[DeclarationStatusFlag.NeedsInformation, DeclarationStatusFlag.ToClaim].includes(this.declarationstatus.status)
		);
	}

	canBeRetracted() {
		return (
			this.declarationstatus &&
			[DeclarationStatusFlag.ToClaim, DeclarationStatusFlag.NeedsInformation].includes(this.declarationstatus.status)
		);
	}

	renameTag(oldTag: string, newTag: string) {
		this.tags = this.tags.map((tag) => tag.replace(oldTag, newTag));
	}

	deletetag(tagToDelete: string) {
		this.tags = this.tags.filter((tag) => tag !== tagToDelete);
	}

	isBookable(): boolean {
		// Can't book when already booked.
		if (this.isBooked()) {
			return false;
		}

		// Can't be booked if the booking is already in the queue
		if (this.waitingToBebooked()) {
			return false;
		}

		// Can't book without declaration status.
		if (!this.declarationstatus) {
			return false;
		}

		// Can't book when wrong declaration status.
		if (this.declarationstatus.status !== DeclarationStatusFlag.Approved) {
			return false;
		}

		// Invoices need a relation number.
		if (this.isinvoice && !this.relation_number) {
			return false;
		}

		// Only allow booking when all required properties are set.
		if (this.provider && this.division && this.journal) {
			return true;
		}

		return false;
	}

	hasImages() {
		return this.images && this.images.length > 0;
	}

	hasDocuments() {
		return this.documents && this.documents.length > 0;
	}

	getDuplicateDocuments() {
		return this.duplicate_documents || [];
	}

	hasDuplicateDocuments() {
		return this.getDuplicateDocuments().length > 0;
	}

	shouldShowDuplicateWarning() {
		/* Ignore the warning for duplicate documents when the receipt has reached a certain status. */
		const ignoreOnStatus = [DeclarationStatusFlag.Claimed];
		return this.hasDuplicateDocuments() && !this.isBooked() && !ignoreOnStatus.includes(this.getExpenseStatus());
	}

	isApprovedOrClaimed(): boolean {
		if (this.declarationstatus) {
			return (
				this.declarationstatus.status === DeclarationStatusFlag.Approved || this.declarationstatus.status === DeclarationStatusFlag.Claimed
			);
		} else {
			return false;
		}
	}

	isReceipt(): boolean {
		return !this.isTravelDeclaration();
	}

	copyStartEndDates(toReceipt: Receipt): Receipt {
		if (this.isReceipt() && toReceipt.isTravelDeclaration()) {
			/* Copy to receipt object itself, as well as to first and last locations */
			toReceipt.start_end_dates.start = this.start_end_dates.start;
			toReceipt.start_end_dates.end = this.start_end_dates.end;
			toReceipt.traveldeclaration.locations[0].start_end_dates.start = this.start_end_dates.start;
			toReceipt.traveldeclaration.locations[toReceipt.traveldeclaration.locations.length - 1].start_end_dates.end =
				this.start_end_dates.end;
		} else if (this.isTravelDeclaration() && toReceipt.isReceipt()) {
			toReceipt.start_end_dates.start = this.traveldeclaration.locations[0].start_end_dates.start;
			toReceipt.start_end_dates.end = this.traveldeclaration.locations[this.traveldeclaration.locations.length - 1].start_end_dates.end;
		} else if (this.isReceipt() && toReceipt.isReceipt()) {
			toReceipt.start_end_dates.start = this.start_end_dates.start;
			toReceipt.start_end_dates.end = this.start_end_dates.end;
		} else if (this.isTravelDeclaration() && toReceipt.isTravelDeclaration()) {
			/* Copy to receipt object itself, as well as to first and last locations */
			toReceipt.start_end_dates.start = this.traveldeclaration.locations[0].start_end_dates.start;
			toReceipt.start_end_dates.end = this.traveldeclaration.locations[this.traveldeclaration.locations.length - 1].start_end_dates.end;
			toReceipt.traveldeclaration.locations[0].start_end_dates.start = this.traveldeclaration.locations[0].start_end_dates.start;
			toReceipt.traveldeclaration.locations[toReceipt.traveldeclaration.locations.length - 1].start_end_dates.end =
				this.traveldeclaration.locations[this.traveldeclaration.locations.length - 1].start_end_dates.end;
		}

		return toReceipt;
	}

	isTravelDeclaration(): boolean {
		if (this.type === 'travel') {
			return true;
		}
		return false;
	}

	isExpensable(user: User): boolean {
		// @todo: more conditions? Like finance type?
		return user.canSubmitExpenses();
	}

	userCanSubmitExpense(user: User): boolean {
		if (this.getExpenseStatus() != null) {
			return false;
		}

		// Only original user can submit.
		if (user && this.user !== user.id) {
			return false;
		}

		return true;
	}

	userCanRetractExpense(user: User): boolean {
		if (this.getExpenseStatus() == null) {
			return false;
		}

		// Can't retract when claimed or denied.
		if (this.getExpenseStatus() === DeclarationStatusFlag.Claimed || this.getExpenseStatus() === DeclarationStatusFlag.Denied) {
			return false;
		}

		// Can't retract when approved and booked.
		if (this.getExpenseStatus() === DeclarationStatusFlag.Approved && this.bookingstatus.isbooked) {
			return false;
		}

		// Only original user can retract.
		if (user && this.user !== user.id) {
			return false;
		}

		return true;
	}

	userCanDelete(user: User): boolean {
		if (this.getExpenseStatus() == null) {
			return true;
		}

		if (this.getExpenseStatus() === DeclarationStatusFlag.NeedsInformation || this.getExpenseStatus() === DeclarationStatusFlag.ToClaim) {
			return true;
		}

		if (this.isinvoice) {
			return true;
		}

		return false;
	}

	userCanEdit(user: User, tableType = 'expenses'): boolean {
		if (this.isLocked()) {
			return false;
		}

		if (this.isBooked()) {
			return false;
		}

		if (this.getExpenseStatus() == null) {
			return true;
		}

		if (this.getExpenseStatus() === DeclarationStatusFlag.NeedsInformation || this.getExpenseStatus() === DeclarationStatusFlag.ToClaim) {
			return true;
		}

		if (user.getAllowedUserStates(tableType).includes(this.getExpenseStatus())) {
			return true;
		}

		return false;
	}

	userCanShare(user: User): boolean {
		if (this.getExpenseStatus() != null) {
			return false;
		}

		return true;
	}

	getHeader() {
		return this.header;
	}

	setHeader(str: string) {
		this.header = str;
		return this;
	}

	inAuthorizationFlow(): boolean {
		return this.authorization_flow && this.authorization_flow.in_flow;
	}

	authorizationFlowApproverCount(): number {
		if (!this.inAuthorizationFlow()) {
			return 0;
		}

		if (!this.authorization_flow.require_approver_order && this.authorization_flow.require_approver_count) {
			return this.authorization_flow.require_approver_count;
		}

		return this.authorization_flow.approvers.length;
	}

	authorizationFlowApprovedCount(): number {
		if (!this.inAuthorizationFlow()) {
			return 0;
		}

		return this.authorization_flow.approvals.length;
	}

	authorizationFlowUserIsLastApprover(userId: string): boolean {
		if (!this.inAuthorizationFlow()) {
			return false;
		}

		if (isValueSet(this.authorization_flow.require_approver_count)) {
			// we are the last approver if our approval would satisfy the number of required approvals
			return (
				this.authorization_flow.approvals.filter((e) => e.user_id !== userId).length >= this.authorization_flow.require_approver_count - 1
			);
		}

		// The user needs to be the only user to still have to approve to be the last approver.
		const usersThatStillNeedToApprove = this.authorization_flow.approvers.filter((approverID) => {
			return !this.authorizationFlowUserHasApproved(approverID);
		});
		return usersThatStillNeedToApprove.length === 1 && usersThatStillNeedToApprove[0] === userId;
	}

	authorizationFlowUserIsNextApprover(id: string): boolean {
		if (!this.inAuthorizationFlow()) {
			return false;
		}

		// User it not approver at all.
		if (!this.authorizationFlowUserIsApprover(id)) {
			return false;
		}

		// User has already approved.
		if (this.authorizationFlowUserHasApproved(id)) {
			return false;
		}

		// Any order allowed.
		if (!this.authorization_flow.require_approver_order) {
			return true;
		}

		for (const approverID of this.authorization_flow.approvers) {
			// This user already approved.
			if (this.authorizationFlowUserHasApproved(approverID)) {
				continue;
			}

			// This is a different user than the user we are looking for, so we are not next.
			if (approverID !== id) {
				return false;
			}

			// This is the user we are looking for, so we are next.
			return true;
		}

		return false;
	}

	authorizationFlowUserIsApprover(id: string): boolean {
		if (!this.inAuthorizationFlow()) {
			return false;
		}

		// Check whether user is approver at all.
		for (const approverID of this.authorization_flow.approvers) {
			if (approverID === id) {
				return true;
			}
		}

		return false;
	}

	authorizationFlowUserHasApproved(id: string): boolean {
		if (!this.inAuthorizationFlow()) {
			return false;
		}

		// Check whether user is approver at all.
		for (const approvalID of this.authorization_flow.approvals) {
			if (approvalID.user_id === id) {
				return true;
			}
		}

		return false;
	}

	isEqualTo(receipt: Receipt, touched: boolean): boolean {
		const thisReceipt = new Receipt(omitBy(omitBy(this.clone(), isUndefined), isFunction));
		const compareReceipt = new Receipt(omitBy(omitBy(receipt.clone(), isUndefined), isFunction));

		const skipPaths = ['traveldeclaration.locations.maps_location'];

		if (!touched) {
			skipPaths.push('division');
			skipPaths.push('provider');
			skipPaths.push('journal');
			skipPaths.push('bookings');
			skipPaths.push('companycategory');
		}

		let imagesLength = 0;
		if (thisReceipt.images && compareReceipt.images) {
			imagesLength = Math.max(thisReceipt.images.length, compareReceipt.images.length);
		} else if (thisReceipt.images || compareReceipt.images) {
			imagesLength = thisReceipt.images ? thisReceipt.images.length : compareReceipt.images.length;
		}

		for (let _i = 0; _i < imagesLength; _i++) {
			skipPaths.push('images.' + _i + '.internal_type');
			skipPaths.push('images.' + _i + '.file');
			skipPaths.push('images.' + _i + '.blob');
			skipPaths.push('images.' + _i + '.loading');
		}

		let documentsLength = 0;
		if (thisReceipt.documents && compareReceipt.documents) {
			documentsLength = Math.max(thisReceipt.documents.length, compareReceipt.documents.length);
		} else if (thisReceipt.documents || compareReceipt.documents) {
			documentsLength = thisReceipt.documents ? thisReceipt.documents.length : compareReceipt.documents.length;
		}

		for (let _i = 0; _i < documentsLength; _i++) {
			skipPaths.push('documents.' + _i + '.internal_type');
			skipPaths.push('documents.' + _i + '.file');
			skipPaths.push('documents.' + _i + '.blob');
			skipPaths.push('documents.' + _i + '.loading');
		}

		const cleanReceipt = omit(thisReceipt, skipPaths);
		const cleanCompareReceipt = omit(compareReceipt, skipPaths);

		return isEqual(cleanReceipt, cleanCompareReceipt);
	}

	clone(): Receipt {
		return new Receipt(JSON.parse(JSON.stringify(this)));
	}

	public getOwner() {
		if (this.acl && this.acl.length > 0) {
			const owner = this.acl.find((a) => a.getRole() === 'Owner');
			return owner ? owner.getUserID() : null;
		}
		return null;
	}

	public updateIndices() {
		/* Updates the indices that are being used for filtering the receipts. */
		if (this.tags && this.tags.length > 0) {
			this.tagsIndex = '|' + this.tags.join('|') + '|';
		}
		if (this.getOwner()) {
			this.userIndex = this.getOwner();
		} else {
			this.userIndex = '';
		}
		this.descriptionInvoiceNumberIndex = this.getDescription() + this.getInvoiceNumber();
	}

	cloneAndfilterLocationsWithoutGeo(): Receipt {
		const receipt = this.clone();
		if (receipt.traveldeclaration && receipt.traveldeclaration.locations) {
			receipt.traveldeclaration.locations = receipt.traveldeclaration.locations.filter((l) => l.lat && l.lng);
		}
		return receipt;
	}
}

export function getBookingProviderLabel(key: string, productName: ThemeProduct): string {
	if (key === BookingProvider.ExactOnlineNL) {
		return 'ExactOnline NL';
	} else if (key === BookingProvider.ExactOnlineBE) {
		return 'ExactOnline BE';
	} else if (key === BookingProvider.ExactOnlineDE) {
		return 'ExactOnline DE';
	} else if (key === BookingProvider.Twinfield) {
		return 'Twinfield';
	} else if (key === BookingProvider.Informer) {
		return 'Informer';
	} else if (key === BookingProvider.ExactGlobe) {
		return 'Exact Globe';
	} else if (key === BookingProvider.Odoo) {
		return 'Odoo';
	} else if (key === BookingProvider.Unit4Multivers) {
		return 'Unit4 Multivers';
	} else if (key === BookingProvider.Xero) {
		return 'Xero (beta)';
	} else if (key === BookingProvider.Sage) {
		return 'Sage';
	} else if (key === BookingProvider.eBoekhouden) {
		return 'e-Boekhouden.nl';
	} else if (key === BookingProvider.Asperion) {
		return 'Asperion';
	} else if (key === BookingProvider.QuickBooks) {
		return 'QuickBooks';
	} else if (key === BookingProvider.UserAPI) {
		if (productName === ThemeProduct.KLIPPA) {
			return 'Klippa SpendControl API';
		} else {
			return productName;
		}
	}
	return key;
}

export function getExpenseLabel(status: DeclarationStatusBadge | null, companyHasTransactionInterfaces?: boolean): string | null {
	if (isValueSet(status)) {
		switch (status) {
			case DeclarationStatusBadge.ToClaim:
				return _('Pending');
			case DeclarationStatusBadge.NeedsInformation:
				return _('Incomplete');
			case DeclarationStatusBadge.Accepted:
				return _('In workflow');
			case DeclarationStatusBadge.Approved:
				return _('Approved');
			case DeclarationStatusBadge.Denied:
				return _('Denied');
			case DeclarationStatusBadge.Claimed:
				return _('Processed');
			case DeclarationStatusBadge.NotSubmitted:
				return _('Unsubmitted');
			case DeclarationStatusBadge.NeedsReview:
				return _('Needs review');
			case DeclarationStatusBadge.OcrInProgress:
				return _('Extracting data');
			default:
				return status;
		}
	}
	return null;
}

export function getExpenseLabelReversed(status: string): string | null {
	if (status) {
		if (status === 'Pending') {
			return DeclarationStatusFlag.ToClaim;
		} else if (status === 'Incomplete') {
			return DeclarationStatusFlag.NeedsInformation;
		} else if (status === 'Accepted') {
			return DeclarationStatusFlag.Accepted;
		} else if (status === 'Approved') {
			return DeclarationStatusFlag.Approved;
		} else if (status === 'Denied') {
			return DeclarationStatusFlag.Denied;
		} else if (status === 'Claimed') {
			return DeclarationStatusFlag.Claimed;
		} else {
			return status;
		}
	}
	return null;
}

export function getPaymentMethodLabel(key: string): string {
	if (key === PaymentMethod.Private) {
		return _('Private');
	} else if (key === PaymentMethod.Cash) {
		return _('Cash');
	} else if (key === PaymentMethod.PIN) {
		return _('PIN');
	} else if (key === PaymentMethod.Bank) {
		return _('Bank');
	} else if (key === PaymentMethod.CreditCard) {
		return _('CreditCard');
	} else if (key === PaymentMethod.Other) {
		return _('Other');
	} else if (key === PaymentMethod.NotPaid) {
		return _('Not paid');
	}

	return key;
}

export class ReceiptListAPIRequest {
	start: number;
	max: number;

	sort: string;
	sortorder: Order | Array<Order>;

	since?: Date;
	before?: Date;
	after?: Date;

	purchaseDatePeriod?: Period;
	submitDatePeriod?: Period;

	updateafter?: Date;
	withoutgroup?: boolean;
	sharedonly?: boolean;
	merchant?: string;
	tag?: string;
	report?: string;
	unaccepted?: boolean;
	companycategory?: string;
	companyadministration?: string;
	companygroup?: string;
	companycostcenter?: string;
	companycostunit?: string;
	companyproject?: string;
	group?: string;
	search?: string;
	isinvoice?: string;
	currency?: string;
	vatpercentage?: string;
	paymentmethod?: string;
	payment_method_id?: string;
	accountnumber?: string;
	declarationstatus?: string;
	booked?: string;
	type?: string;
	finance_type?: string;

	label?: string;
	status?: string;
	submittedbefore?: Date;
	submittedsince?: Date;
	dueDateBefore?: string;
	dueDateAfter?: string;
	user?: string;
	declarations?: boolean;
	company?: boolean;
	companyID?: string;
	usertitle?: string;
	exported?: boolean;
	auth_flow?: string;
	current_approver?: string;

	public activeFilters: number;
	public predefinedFilter: DashboardChecklistTab.TODO;

	constructor(data = null) {
		this.sharedonly = false;
		this.isinvoice = null;
		this.unaccepted = false;
		this.since = null;
		this.before = null;
		this.after = null;
		this.currency = null;
		this.activeFilters = 0;

		if (data) {
			Object.assign(this, data);

			if (data.submittedbefore) {
				this.submittedbefore = Date.parse(data.submittedbefore) > 0 ? new Date(data.submittedbefore) : null;
			}

			if (data.submittedsince) {
				this.submittedsince = Date.parse(data.submittedsince) > 0 ? new Date(data.submittedsince) : null;
			}

			if (data.since) {
				this.since = Date.parse(data.since) > 0 ? new Date(data.since) : null;
			}

			if (data.before) {
				this.before = Date.parse(data.before) > 0 ? new Date(data.before) : null;
			}

			if (data.after) {
				this.after = Date.parse(data.after) > 0 ? new Date(data.after) : null;
			}

			if (data.purchaseDatePeriod) {
				this.purchaseDatePeriod = Period.fromData(data.purchaseDatePeriod);
			}

			if (data.submitDatePeriod) {
				this.submitDatePeriod = Period.fromData(data.submitDatePeriod);
			}
		}
	}

	countActiveFilters(type: string, defaultFilters: ReceiptListAPIRequest): void {
		const compareDefaultFilters = omitBy(pickBy(defaultFilters, identity), isFunction);
		const currentFilters = omitBy(pickBy(this, identity), isFunction);

		const ignoreFields = [
			'start',
			'max',
			'sortorder',
			'sort',
			'purchaseDatePeriod',
			'declarations',
			'company',
			'submitDatePeriod',
			'activeFilters',
			'companyID',
			'isinvoice',
			'predefinedFilter',
		];
		ignoreFields.forEach((ignoreField) => {
			delete compareDefaultFilters[ignoreField];
			delete currentFilters[ignoreField];
		});

		this.activeFilters = Object.keys(currentFilters).length;
	}

	/*
	 * Compare two objects by reducing an array of keys in obj1, having the
	 * keys in obj2 as the initial value of the result. Key points:
	 *
	 * - All keys of obj2 are initially in the result.
	 *
	 * - If the loop finds a key (from obj1, remember) not in obj2, it adds
	 *   it to the result.
	 *
	 * - If the loop finds a key that are both in obj1 and obj2, it compares
	 *   the value. If it's the same value, the key is removed from the result.
	 */
	getObjectDiff(obj1, obj2) {
		const diff = Object.keys(obj1).reduce((result, key) => {
			if (!obj2.hasOwnProperty(key)) {
				result.push(key);
			} else if (isEqual(obj1[key], obj2[key])) {
				const resultKeyIndex = result.indexOf(key);
				result.splice(resultKeyIndex, 1);
			}
			return result;
		}, Object.keys(obj2));

		return diff;
	}

	getRequestURL(): string {
		let url = '/api/v1/receipt';

		if (this.declarations && this.company && this.companyID) {
			url = '/api/v1/company/' + this.companyID + '/receipt/declarations';
		} else if (this.declarations) {
			/* Legacy endpoints */
			url += '/declarations';
			if (this.company) {
				url += '/company/';
			}
		} else if (this.group) {
			url += '/group/' + this.group;
		}

		const parts = [];
		parts.push({
			key: 'max',
			value: this.max,
		});
		parts.push({
			key: 'start',
			value: this.start,
		});

		if (this.sort) {
			parts.push({
				key: 'sort',
				value: this.sort,
			});
		}

		if (this.sortorder) {
			parts.push({
				key: 'sortorder',
				value: this.sortorder,
			});
		}

		if (isValueSet(this.dueDateAfter)) {
			parts.push({
				key: 'dueDateAfter',
				value: this.dueDateAfter,
			});
		}
		if (isValueSet(this.dueDateBefore)) {
			parts.push({
				key: 'dueDateBefore',
				value: this.dueDateBefore,
			});
		}

		if (stringIsSetAndFilled(this.predefinedFilter)) {
			parts.push({
				key: 'predefinedFilter',
				value: this.predefinedFilter,
			});
		}

		return (
			url +
			'?' +
			parts.map((part) => encodeURIComponent(part.key) + '=' + encodeURIComponent(part.value)).join('&') +
			this.getFilterQuery('&')
		);
	}

	getStatisticsURL() {
		let url = '/api/v1/stats/';
		if (this.company) {
			url += 'company/';
			if (this.companyID && this.companyID !== '') {
				url += this.companyID + '/';
			}
		}
		return url;
	}

	getFilterQuery(prefix: string = '?', skip: string = ''): string {
		const parts = [];

		if (this.unaccepted != null && this.unaccepted === true) {
			parts.push({
				key: 'unaccepted',
				value: '1',
			});
		}

		if (this.sharedonly != null && this.sharedonly === true) {
			parts.push({
				key: 'sharedonly',
				value: '1',
			});
		}

		if (this.isinvoice != null && this.isinvoice === '1') {
			parts.push({
				key: 'isinvoice',
				value: '1',
			});
		}

		if (this.isinvoice != null && this.isinvoice === '-1') {
			parts.push({
				key: 'isinvoice',
				value: '-1',
			});
		}

		if (this.merchant != null && skip !== 'merchant') {
			parts.push({
				key: 'merchant',
				value: this.merchant,
			});
		}

		if (this.tag != null && skip !== 'tag') {
			parts.push({
				key: 'tag',
				value: this.tag,
			});
		}

		if (isValueSet(this.report) && skip !== 'report') {
			parts.push({
				key: 'report',
				value: this.report,
			});
		}

		if (this.group != null && skip !== 'group') {
			parts.push({
				key: 'group',
				value: this.group,
			});
		}

		if (this.search != null && skip !== 'search') {
			parts.push({
				key: 'search',
				value: this.search,
			});
		}

		if (this.vatpercentage != null && skip !== 'vatpercentage') {
			parts.push({
				key: 'vatpercentage',
				value: this.vatpercentage,
			});
		}

		if (this.currency != null && skip !== 'currency') {
			parts.push({
				key: 'currency',
				value: this.currency,
			});
		}

		// Check if this is correct when API is ready
		if (this.paymentmethod != null && skip !== 'paymentmethod') {
			parts.push({
				key: 'paymentmethod',
				value: this.paymentmethod,
			});
		}

		if (this.payment_method_id != null && skip !== 'payment_method_id') {
			parts.push({
				key: 'payment_method_id',
				value: this.payment_method_id,
			});
		}

		if (this.accountnumber != null && skip !== 'accountnumber') {
			parts.push({
				key: 'accountnumber',
				value: this.accountnumber,
			});
		}

		if (this.declarationstatus != null && skip !== 'declarationstatus') {
			parts.push({
				key: 'declarationstatus',
				value: this.declarationstatus,
			});
		}

		if (this.user != null && skip !== 'user') {
			parts.push({ key: 'user', value: this.user });
		}
		if (this.usertitle != null && skip !== 'usertitle') {
			parts.push({ key: 'usertitle', value: this.usertitle });
		}
		if (this.companycategory != null && skip !== 'companycategory') {
			parts.push({ key: 'companycategory', value: this.companycategory });
		}
		if (this.companyadministration != null && skip !== 'companyadministration') {
			parts.push({ key: 'companyadministration', value: this.companyadministration });
		}
		if (this.companycostcenter != null && skip !== 'companycostcenter') {
			parts.push({ key: 'companycostcenter', value: this.companycostcenter });
		}
		if (this.companycostunit != null && skip !== 'companycostunit') {
			parts.push({ key: 'companycostunit', value: this.companycostunit });
		}
		if (this.companyproject != null && skip !== 'companyproject') {
			parts.push({ key: 'companyproject', value: this.companyproject });
		}
		if (this.companygroup != null && skip !== 'companygroup') {
			parts.push({ key: 'companygroup', value: this.companygroup });
		}

		if (this.type != null && skip !== 'type') {
			parts.push({
				key: 'type',
				value: this.type,
			});
		}

		if (this.finance_type != null && skip !== 'finance_type') {
			parts.push({
				key: 'finance_type',
				value: this.finance_type,
			});
		}

		if (this.since instanceof Date) {
			parts.push({
				key: 'since',
				value: convertDateToYMD(this.since),
			});
		}

		if (this.before instanceof Date) {
			parts.push({
				key: 'before',
				value: convertDateToYMD(this.before),
			});
		}

		if (this.submittedsince instanceof Date) {
			parts.push({
				key: 'submittedsince',
				value: convertDateToYMD(this.submittedsince),
			});
		}

		if (this.submittedbefore instanceof Date) {
			parts.push({
				key: 'submittedbefore',
				value: convertDateToYMD(this.submittedbefore),
			});
		}

		if (this.booked != null) {
			parts.push({
				key: 'booked',
				value: this.booked,
			});
		}

		if (stringIsSetAndFilled(this.current_approver)) {
			parts.push({
				key: 'current_approver',
				value: this.current_approver,
			});
		}

		if (this.exported != null) {
			parts.push({
				key: 'exported',
				value: this.exported,
			});
		}

		if (this.auth_flow != null) {
			parts.push({
				key: 'auth_flow',
				value: this.auth_flow,
			});
		}

		if (parts.length === 0) {
			return '';
		}

		return prefix + parts.map((part) => encodeURIComponent(part.key) + '=' + encodeURIComponent(part.value)).join('&');
	}

	setSince(day: number, month: number, year: number) {
		this.since = new Date(year, month - 1, day);
	}

	setBefore(day: number, month: number, year: number) {
		this.before = new Date(year, month - 1, day);
	}

	clone(): ReceiptListAPIRequest {
		return new ReceiptListAPIRequest(JSON.parse(JSON.stringify(this)));
	}
}

export class ReceiptsExportColumn {
	type: string;
	label: string;
	value: string;
	enabled: boolean;
	add_currency_symbol?: boolean;
	date_format?: string;
	custom_label?: string;

	constructor(data?: any) {
		if (!data) {
			return;
		}

		Object.assign(this, data);
	}
}

export class ReceiptsExportItem {
	id: string;
	type: string;

	constructor(id: string, type: string) {
		this.id = id;
		this.type = type;
	}

	static fromGroup(group: Folder) {
		return new ReceiptsExportItem(group.getID(), 'group');
	}

	static fromReceipt(receipt: Receipt) {
		return new ReceiptsExportItem(receipt.getID(), 'receipt');
	}
}

export class ReceiptsExportRequest {
	columns: ReceiptsExportColumn[];
	travel_columns: ReceiptsExportColumn[];
	travel_and_receipt_columns: ReceiptsExportColumn[];
	csv_separator: string;
	decimal_separator: string;
	email: string;
	items: ReceiptsExportItem[];
	type: string;
	overview: boolean;
	combine_travel_and_receipts: boolean;
	save_template: boolean;
	company_export: boolean;
	split_per_person: boolean;
	ubl_attachments: boolean;
	ubl_combine_attachments: boolean;
	ubl_add_pdf: boolean;
	email_add_attachments: boolean;
	stats: boolean;
	timezone: string;

	constructor(data?: any) {
		this.columns = [];
		this.travel_columns = [];
		this.items = [];
		if (!data) {
			return;
		}
		Object.assign(this, data);
		if (data.columns) {
			this.columns = data.columns.map((column) => new ReceiptsExportColumn(column));
		}
		if (data.travel_columns) {
			this.travel_columns = data.travel_columns.map((column) => new ReceiptsExportColumn(column));
		}
		if (data.travel_and_receipt_columns) {
			this.travel_and_receipt_columns = data.travel_and_receipt_columns.map((column) => new ReceiptsExportColumn(column));
		}
		if (data.items) {
			this.items = data.items.map((item) => new ReceiptsExportItem(item.id, item.type));
		}
	}
}

export class OCRResult {
	amount_total: number;
	btw;
	vat = [];
	btw_number: string;
	merchant;
	merchant_name: string;
	location_address: string;
	location_zip_code: string;
	location_phone: string;
	payment_method: string;
	payment_unit: string;
	payment_card_number: string;
	payment_card_bank: string;
	date: Date | string;
	time: string;
	website: string;
	barcode?: any;
	invoice_number: string;
	document_type: string;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			this.amount_total = Math.round(this.amount_total * 100) || 0;

			if (data.vat && data.vat.length > 0) {
				this.vat = [];
				data.vat.map((item) => {
					this.vat.push(new ReceiptVatItem({ amount: Math.round(item.amount_vat * 100), percentage: Math.round(item.percentage * 100) }));
				});
			} else {
				this.vat = [new ReceiptVatItem()];
			}

			this.payment_unit = data.payment_unit || null;
			this.date = Date.parse(data.date) > 0 ? new Date(data.date) : null;
			this.document_type = data.document_type || null;
			this.payment_method = data.payment_method || null;
			this.merchant = data.merchant || null;
			this.invoice_number = data.invoice_number;
			this.payment_card_number = data.payment_card_number;
		}
	}

	clone(): OCRResult {
		return cloneDeep(this);
	}
}

export class OCRFile {
	file_type: string;
	ocr_result: OCRResult;
	ocr_regions: any[];
	ocr_log: string;

	static fromData(data): OCRFile[] {
		return data.map((item) => new OCRFile(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.ocr_result) {
				this.ocr_result = new OCRResult(data.ocr_result);
			}
		}
	}

	getFileType() {
		return this.file_type;
	}
}

export class ReviewResult {
	id: string;
	user_email: string;
	user_name: string;
	finished: boolean;
	claim_date: Date;
	review_date: Date;
	amount_total: number;
	vat_old?: any;
	vat?: any;
	btw_number: string;
	merchant: string;
	location_address: string;
	location_zip_code: string;
	location_city: string;
	payment_method: string;
	payment_unit: string;
	payment_card_number: string;
	date: string;
	coc: string;
	invoice_number: string;
	document_type: string;

	static fromData(data): ReviewResult[] {
		return data.map((item) => new ReviewResult(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.claim_date) {
				this.claim_date = Date.parse(data.claim_date) > 0 ? new Date(data.claim_date) : null;
			}

			if (data.review_date) {
				this.review_date = Date.parse(data.review_date) > 0 ? new Date(data.review_date) : null;
			}
		}
	}
}

export class ReceiptUserPreferences {
	language: string;
	currency: string;
	vatmode: boolean;
	ocr: boolean;
	paymentinfo: boolean;
	travel_expense_declaration_cents_per_km: number;
	financetype: boolean;
	receipt_type: boolean;
	groups: boolean;
	tags: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	getCurrency() {
		return this.currency;
	}
}

export class Review {
	id: string;
	file_type: string;
	files: OCRFile[];
	reviews_needed: number;
	ocr_result: OCRResult;
	ocr_regions: any[];
	ocr_log: string;
	review_results: ReviewResult[];
	create_date: Date;
	receipt_user_role: number;
	receipt_type: string;
	receipt_finance_type: string;
	receipt_is_invoice: boolean;
	receipt_user_preferences: ReceiptUserPreferences;
	weight: number;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.create_date) {
				this.create_date = Date.parse(data.create_date) > 0 ? new Date(data.create_date) : null;
			}

			if (data.files) {
				this.files = OCRFile.fromData(data.files);
			}

			if (data.review_results) {
				this.review_results = ReviewResult.fromData(data.review_results);
			}

			if (data.receipt_user_preferences) {
				this.receipt_user_preferences = new ReceiptUserPreferences(data.receipt_user_preferences);
			} else {
				this.receipt_user_preferences = new ReceiptUserPreferences();
			}

			if (data.ocr_result) {
				this.ocr_result = new OCRResult(data.ocr_result);
			}
		}
	}

	getID() {
		return this.id;
	}

	getOCRResult() {
		return this.ocr_result;
	}

	getFiles(): OCRFile[] {
		return this.files;
	}

	isProUser(): boolean {
		// tslint:disable-next-line:no-bitwise
		return (this.receipt_user_role & UserRole.Pro) === UserRole.Pro;
	}

	isCompanyUser(): boolean {
		// tslint:disable-next-line:no-bitwise
		return (this.receipt_user_role & UserRole.Company) === UserRole.Company;
	}

	getReceiptType() {
		return this.receipt_type;
	}

	getFinanceType() {
		return this.receipt_finance_type;
	}

	isInvoice(): boolean {
		return this.receipt_is_invoice;
	}

	getReceiptUserPreferences() {
		return this.receipt_user_preferences;
	}
}

export class ReviewInfo {
	amount: boolean;
	date: boolean;
	vat: boolean;
	currency: boolean;
	invoice_number: boolean;
	payment_method: boolean;
	account_number: boolean;
	merchant: boolean;
	document_type: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class OCRReview {
	review: Review;
	info: ReviewInfo;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.review) {
				this.review = new Review(data.review);
			}

			if (data.info) {
				this.info = new ReviewInfo(data.info);
			}
		}
	}

	getReview(): Review {
		return this.review;
	}

	getReviewInfo(): ReviewInfo {
		return this.info;
	}
}

export class DocumentType {
	Title: string;
	Value: string;

	constructor(data = null) {
		Object.assign(this, data);
	}

	setTitle(title: string) {
		this.Title = title;
		return this;
	}

	setValue(value: string) {
		this.Value = value;
		return this;
	}
}

export class DocumentTypes {
	DocumentTypes: Map<string, DocumentType>;

	constructor(data = null) {
		Object.assign(this, data);

		if (data) {
			const documentTypes = new Map<string, DocumentType>();
			if (data.DocumentTypes) {
				Object.keys(data.DocumentTypes).forEach((key) => {
					return documentTypes.set(key, new DocumentType(data.DocumentTypes[key]));
				});
			}
			this.DocumentTypes = documentTypes;
		}
	}

	toArray(): DocumentType[] {
		const documentTypes = [];
		if (this.DocumentTypes && this.DocumentTypes.size > 0) {
			Array.from(this.DocumentTypes.entries()).forEach((entry) => {
				documentTypes.push(entry[1].setValue(entry[0]));
			});
		}
		return documentTypes;
	}
}

export class OCRStat {
	ReviewsNeeded: number;
	Count: number;

	static fromData(data): OCRStat[] {
		return data.map((item) => new OCRStat(item));
	}

	constructor(data = null) {
		Object.assign(this, data);
	}

	getCount() {
		return this.Count;
	}
}

export class DuplicateMessage {
	message: string;
	receiptId: string;

	constructor(message: string, receiptId: string = null) {
		this.message = message;
		this.receiptId = receiptId;
	}
}

export class MatchSupplier {
	id: string;

	static fromData(data): MatchSupplier[] {
		return data.map((item) => new MatchSupplier(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export enum ExtraInputField {
	START_TO_DATES = 'start_to_dates',
	START_TO_DATES_WITH_TIME = 'start_to_dates_with_time',
	MULTIPLE_DATES = 'multiple_dates',
	HOURS_MINUTES = 'hours_minutes',
}
