import {
	Component,
	ContentChildren,
	Directive,
	ElementRef,
	EventEmitter,
	Input,
	Optional,
	Output,
	QueryList,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { ModalOptions, ModalService } from '~/app/services/modal.service';
import { BaseModalComponent, ModalCloseConfirmationOptions } from '~/app/shared/ui/modal/base-modal.component';
import { isValueSet } from '#/util/values';
import { Subscription } from 'rxjs';

@Directive({ selector: '[app-modal-header]' })
export class ModalHeaderDirective {}

@Directive({ selector: '[app-modal-body]' })
export class ModalBodyDirective {}

@Directive({ selector: '[app-modal-footer]' })
export class ModalFooterDirective {}

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['modal.component.scss'],
})
export class ModalComponent {
	@Input() modalOptions: ModalOptions = {};
	@Input() closeConfirmation: boolean = false;
	@Input() closeConfirmationOptions: ModalCloseConfirmationOptions = {};
	@Input() variant: 'modalRight' | 'modalXl' | 'modalAlmostFullScreen';
	@Input() modalBodyFullHeight: boolean = false;
	@Output() onDismiss = new EventEmitter<void>();
	@Output() onClose = new EventEmitter<void>();

	@ViewChild('modal') modalElementRef: ElementRef;

	@ContentChildren(ModalHeaderDirective, { read: TemplateRef }) header: QueryList<TemplateRef<any>>;
	@ContentChildren(ModalBodyDirective, { read: TemplateRef }) body: QueryList<TemplateRef<any>>;
	@ContentChildren(ModalFooterDirective, { read: TemplateRef }) footer: QueryList<TemplateRef<any>>;
	private dismissedObservable: Subscription;
	private closedObservable: Subscription;

	constructor(private modalService: ModalService, @Optional() public baseModal: BaseModalComponent) {
		if (isValueSet(baseModal)) {
			baseModal._openModalEmitter.subscribe(() => this.openModal());
			baseModal._closeModalEmitter.subscribe(() => this.closeModal());
			baseModal._dismissModalEmitter.subscribe(() => this.dismissModal());
		}
	}

	ngOnDestroy() {
		this.dismissedObservable?.unsubscribe?.();
		this.closedObservable?.unsubscribe?.();
	}

	public dismissModal() {
		this.modalService.dismiss(this.modalElementRef);
	}

	public closeModal() {
		this.modalService.close(this.modalElementRef);
	}

	public openModal(): void {
		const modalOptions: ModalOptions = this.baseModal?.modalOptions ?? this.modalOptions;
		modalOptions.beforeDismiss = () => this.modalCloseConfirmation();
		modalOptions.windowClass = this.variant ? this.variant : undefined;
		const ref = this.modalService.open(this.modalElementRef, modalOptions);
		this.dismissedObservable = ref.dismissed.subscribe(() => {
			this.onDismiss.emit();
		});
		this.closedObservable = ref.closed.subscribe(() => {
			this.onClose.emit();
		});
	}

	private modalCloseConfirmation() {
		const closeConfirmation = this.baseModal?.closeConfirmation ?? this.closeConfirmation;
		if (closeConfirmation) {
			return this.modalService.requestConfirmation(
				this.baseModal?.closeConfirmationOptions?.title ?? this.closeConfirmationOptions?.title,
				this.baseModal?.closeConfirmationOptions?.message ?? this.closeConfirmationOptions?.message,
				this.baseModal?.closeConfirmationOptions?.confirmLabel ?? this.closeConfirmationOptions?.confirmLabel,
			);
		}
		return true;
	}
}
