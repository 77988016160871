import { DeclarationStatusFlag, OCRStatus, ReceiptBookingStatus } from '#/models/transaction/receipt';
import { BookingLineFrontendModel } from '#/models/transaction/booking-line/frontendModel';
import { AuthorizationFlowFrontendModel } from '#/models/transaction/authorization-flow/frontendModel';
import { ConversationMessage } from '#/models/transaction/conversationItem';
import { frontendToApi } from './transformer';
import { TransactionApiModel } from './apiModel';
import { TravelRoute } from '#/models/transaction/travelRoute';
import { FinanceType } from '#/models/transaction/financeType';
import { PreTransactionFrontendModel } from '#/models/transaction/pre-transaction/frontendModel';
import { TransactionStatus } from '#/models/transaction/transactionStatus.model';
import { AmountWithCurrency } from '#/models/transaction/amountWithCurrency';
import { AccountingStatus } from '#/models/accounting-integrations/accounting-integration-v2';

export class TransactionFrontendModel extends PreTransactionFrontendModel {
	report: string;
	transactionInterfaceId: string;
	transactionStatus: TransactionStatus;
	dates: Array<string>; // YYYY-MM-DD
	isInvoice: boolean;
	invoiceNumber: string;
	country: string;

	tipAmount: AmountWithCurrency;
	amountWithoutTip: AmountWithCurrency;

	travelRoute: TravelRoute;
	amountOfMinutes: number;

	status: DeclarationStatusFlag;
	authorizationFlow: AuthorizationFlowFrontendModel;
	noAttachmentAvailable: boolean;
	dueDate: string;

	integration: string;
	division: string;
	journal: string;
	integrationRelation: string;
	paymentCondition: string;
	integrationPaymentMethod: string;
	paymentMethod: string;
	customPaymentMethod: string;
	accountNumber: string;

	bookingDate: string; // YYYY-MM-DD
	isBooked: boolean;
	bookingStatus: ReceiptBookingStatus;
	bookingLines: Array<BookingLineFrontendModel>;
	duplicateDocuments: Array<string>;
	ocrChecks: {
		merchantBankAccountNumbers: Array<string>;
	};
	needsOcrReview: boolean;
	receiptDataManuallyValidated: boolean;
	conversation: Array<ConversationMessage>;
	ocrStatus: OCRStatus;
	validatedBookingLines: boolean;
	folderId: string;
	tags: Array<string>;
	financeType: FinanceType;

	accountingHeaders: Record<string, any>;
	accountingBookingLines: { lines: Array<Record<string, any>> };
	accountingStatus: AccountingStatus;

	constructor(data?: any) {
		super();
		Object.assign(this, data);
	}

	public transformToApi(): TransactionApiModel {
		return frontendToApi(this);
	}
}
