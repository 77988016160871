import { CompanyCostCenterListAPIRequest, CostCenter } from '#/models/company/dimension/cost-center.model';
import { Injectable } from '@angular/core';
import { CompanyService } from '#/services/company/company.service';
import { UserService } from '~/app/modules/user/user.service';
import { CompanyCostCenterService } from '#/services/company/dimension/company-cost-center.service';
import { CompanyCostUnitListAPIRequest, CostUnit } from '#/models/company/dimension/cost-unit.model';
import { CompanyCostUnitService } from '#/services/company/dimension/company-cost-unit.service';
import { CompanyProjectListAPIRequest, Project } from '#/models/company/dimension/project.model';
import { CompanyProjectService } from '#/services/company/dimension/company-project.service';
import { Category, CompanyCategoryListAPIRequest } from '#/models/company/category.model';
import { CompanyCategoryService } from '#/services/company/company-category.service';
import { Administration, CompanyAdministrationListAPIRequest } from '#/models/company/administration.model';
import { CompanyAdministrationService } from '#/services/company/company-administration.service';
import { arrayIsSetAndFilled, asArray } from '#/util/arrays';
import { WebsocketsService } from '~/app/websockets/services/websockets.service';
import { MessageTypes, WSMessage } from '#/models/websocket.model';
import { DeclarationStatusFlag, OCRStatus, Receipt, ReceiptListAPIRequest } from '#/models/transaction/receipt';
import { InvoiceApiModel } from '#/models/transaction/invoice/apiModel';
import { ReceiptService } from './receipt.service';
import { FileService } from '#/services/file.service';
import { InputField, TransactionViewMode, UIFieldKey } from '#/models/transaction/interface/frontendModel';
import { apiToFrontend as invoiceApiToFrontend } from '#/models/transaction/invoice/transformer';
import { InvoiceFrontendModel } from '#/models/transaction/invoice/frontendModel';
import { AccountingIntegrationV1, Division, IntegrationRelation, Journal } from '#/models/company/company.model';
import { PaymentCondition } from '#/models/company/payment-condition.model';
import { TimezoneService } from '~/app/services/timezone.service';
import { CompanyIntegrationService } from '../company/company-integration.service';
import { Attachment, AttachmentType } from '#/models/transaction/attachment';
import { ConversationMessage } from '#/models/transaction/conversationItem';
import { cloneDeep } from 'lodash';
import { isValueSet } from '#/util/values';
import { stringIsSetAndFilled } from '../../util/values';
import { User } from '#/models/user/user.model';
import { TransactionApiModel } from '#/models/transaction/transaction/apiModel';
import { ReceiptApiModel } from '#/models/transaction/receipt/apiModel';
import { TransactionFrontendModel } from '#/models/transaction/transaction/frontendModel';
import { ReceiptFrontendModel } from '#/models/transaction/receipt/frontendModel';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { DateFormat } from '#/pipes/date/date-format.pipe';
import { TransactionType } from '#/models/transaction/transactionType';
import { ActivatedRoute } from '@angular/router';
import { FixedCompensationApiModel } from '#/models/transaction/fixedCompensation/apiModel';
import { CompanyPaymentMethod, PaymentMethodListAPIRequest } from '#/models/company/payment-method.model';
import { PaymentMethodService } from '#/services/company/payment-method.service';
import { IntegrationPaymentMethod } from '#/models/company/integration-payment-method.model';
import { FixedCompensationFrontendModel } from '#/models/transaction/fixedCompensation/frontendModel';
import { SubCompanyService } from '#/services/subcompany/sub-company.service';
import { FilterOnGroup, FilterOnGroupEnum } from '#/services/transaction/transaction-owner-dependant-picker.service';
import { runNextRenderCycle } from '#/util/angular';
import { AccountingBookingType } from '#/models/transaction/bookingType';
import { AccountingIntegrationV2Service } from '#/services/integration/accounting-integration-v2.service';
import { TransactionStatus } from '#/models/transaction/transactionStatus.model';
import { filterUndefinedValues } from '#/util/objects';
import { CompanyFeatureFlagsService } from '#/services/company/company-feature-flags.service';
import { PossibleCompanyFeatureFlags } from '#/models/company/possible-feature-flags';
import { OcrEnhancedFilesService } from '##/ocrEnhancedFiles/ocrEnhancedFiles.service';
import { AccountingStatus } from '#/models/accounting-integrations/accounting-integration-v2';

const fromCacheIfNewerThanMs = 60 * 1000;

export const fieldsToLockDuringOcr: Array<UIFieldKey> = [
	'merchant',
	'amount',
	'tipAmount',
	'amountWithoutTip',
	// 'purchase_date_ymd',
	'purchaseDate',
	// 'currency',
	'paymentMethod',
	'vatLines',
	'invoiceNumber',
	// 'accountNumber',
	'administration',
	'costCenter',
	'costUnit',
	'project',
	'category',
	'status',
	'authorizationFlow',
	// 'accountNumber',
	// 'payment_method_id',
	'customPaymentMethod',
];

export enum UserType {
	LOGGED_IN_USER = 'LoggedInUser',
	TX_USER = 'TxUser',
}

@Injectable({
	providedIn: 'root',
})
export class TransactionEditorService {
	private currentActiveTransactionId;
	private ocrCallbacks = new Map<string, () => void>();
	private latestDashboardFilters: ReceiptListAPIRequest;
	private lastKnownPersistedTxStates = new Map<string, TransactionFrontendModel>();

	constructor(
		private companyService: CompanyService,
		private companyIntegrationService: CompanyIntegrationService,
		private userService: UserService,
		private costCenterService: CompanyCostCenterService,
		private paymentMethodService: PaymentMethodService,
		private costUnitService: CompanyCostUnitService,
		private projectService: CompanyProjectService,
		private categoryService: CompanyCategoryService,
		private administrationService: CompanyAdministrationService,
		private receiptService: ReceiptService,
		private fileService: FileService,
		private websocketsService: WebsocketsService,
		private timezoneService: TimezoneService,
		private reportsService: ExpenseReportsService,
		private subCompanyService: SubCompanyService,
		private accountingIntegrationV2Service: AccountingIntegrationV2Service,
		private ocrEnhancedFilesService: OcrEnhancedFilesService,
		private featureFlagsService: CompanyFeatureFlagsService,
	) {
		this.websocketsService.onMessage.subscribe((message: WSMessage) => {
			if (message.type === MessageTypes.ReceiptUpdate && message.data.receipt === this.currentActiveTransactionId) {
				this.getTransaction(message.data.receipt).then((res) => {
					if (res.ocrStatus === OCRStatus.Processed) {
						this.ocrCallbacks.get(message.data.receipt)?.();
						this.ocrCallbacks.delete(message.data.receipt);
					}
				});
			}
		});
	}

	public getDynamicOptions(inputFields: InputField): { [k in UIFieldKey]?: Promise<Array<{ id: string }>> } {
		const integration = inputFields.integration?.preset;
		const division = inputFields.division?.preset;

		return {
			costCenter: this.getCostCentersByIdsForSubmitter(inputFields.costCenter?.preset),
			costUnit: this.getCostUnitsByIdsForSubmitter(inputFields.costUnit?.preset),
			project: this.getProjectsByIdsForSubmitter(inputFields.project?.preset),
			category: this.getCategoriesByIdsForSubmitter(inputFields.category?.preset),
			administration: this.getAdministrationsByIdsForSubmitter(inputFields.administration?.preset),
			customPaymentMethod: this.getCustomPaymentMethodsByIdsForSubmitter(inputFields.customPaymentMethod?.preset),

			integration: this.getIntegrationsByIds([integration].filter(stringIsSetAndFilled)),
			division:
				stringIsSetAndFilled(integration) && stringIsSetAndFilled(division)
					? this.getDivisionsByIds(integration, [division].filter(stringIsSetAndFilled))
					: Promise.resolve(null),

			journal:
				stringIsSetAndFilled(integration) && stringIsSetAndFilled(division)
					? this.getJournalsByIds(integration, division, inputFields.journal?.preset)
					: Promise.resolve(null),

			integrationRelation:
				stringIsSetAndFilled(integration) && stringIsSetAndFilled(division)
					? this.getIntegrationRelationsByIds(integration, division, inputFields.integrationRelation?.preset)
					: Promise.resolve(null),

			paymentCondition:
				stringIsSetAndFilled(integration) && stringIsSetAndFilled(division)
					? this.getPaymentConditionsByIds(integration, division, inputFields.paymentCondition?.preset)
					: Promise.resolve(null),

			integrationPaymentMethod:
				stringIsSetAndFilled(integration) && stringIsSetAndFilled(division)
					? this.getIntegrationPaymentMethodsByIds(integration, division, inputFields.integrationPaymentMethod?.preset)
					: Promise.resolve(null),
		};
	}

	public async getCustomPaymentMethodsByIdsForSubmitter(ids: Array<string>): Promise<Array<CompanyPaymentMethod>> {
		if (!arrayIsSetAndFilled(ids)) {
			return null;
		}
		const filters = new PaymentMethodListAPIRequest();
		filters.active = true;
		filters.company = this.companyService.getCompanyId();
		filters.groups = this.userService.getCurrentLoggedUser().getCompanyGroups();
		return (await this.paymentMethodService.getPaymentMethodsByIDs(filters, ids)).payment_methods;
	}

	public async getCostCentersByIdsForSubmitter(ids: Array<string>): Promise<Array<CostCenter>> {
		if (!arrayIsSetAndFilled(ids)) {
			return null;
		}
		const filters = new CompanyCostCenterListAPIRequest();
		filters.active = true;
		filters.company = this.companyService.getCompanyId();
		filters.groups = this.userService.getCurrentLoggedUser().getCompanyGroups();
		filters.ids = ids;
		return this.costCenterService.getCompanyCostCenters(filters).then((res) => res.company_costcenters);
	}

	public async getCostUnitsByIdsForSubmitter(ids: Array<string>): Promise<Array<CostUnit>> {
		if (!arrayIsSetAndFilled(ids)) {
			return null;
		}
		const filters = new CompanyCostUnitListAPIRequest();
		filters.active = true;
		filters.company = this.companyService.getCompanyId();
		filters.groups = this.userService.getCurrentLoggedUser().getCompanyGroups();
		filters.ids = ids;
		return this.costUnitService.getCompanyCostUnits(filters).then((res) => res.company_costunits);
	}

	public async getProjectsByIdsForSubmitter(ids: Array<string>): Promise<Array<Project>> {
		if (!arrayIsSetAndFilled(ids)) {
			return null;
		}
		const filters = new CompanyProjectListAPIRequest();
		filters.active = true;
		filters.company = this.companyService.getCompanyId();
		filters.groups = this.userService.getCurrentLoggedUser().getCompanyGroups();
		filters.ids = ids;
		return this.projectService.getCompanyProjects(filters).then((res) => res.company_projects);
	}

	public async getCategoriesByIdsForSubmitter(ids: Array<string>): Promise<Array<Category>> {
		if (!arrayIsSetAndFilled(ids)) {
			return null;
		}
		const filters = new CompanyCategoryListAPIRequest();
		filters.active = true;
		filters.company = this.companyService.getCompanyId();
		filters.groups = this.userService.getCurrentLoggedUser().getCompanyGroups();
		return this.categoryService.getCompanyCategoriesByIDs(filters, ids).then((res) => res.company_categories);
	}

	public async getAdministrationsByIdsForSubmitter(ids: Array<string>): Promise<Array<Administration>> {
		if (!arrayIsSetAndFilled(ids)) {
			return null;
		}
		const filters = new CompanyAdministrationListAPIRequest();
		filters.active = true;
		filters.company = this.companyService.getCompanyId();
		filters.groups = this.userService.getCurrentLoggedUser().getCompanyGroups();
		return this.administrationService.getAdministrationsByIDs(filters, ids).then((res) => res.administrations);
	}

	public async getIntegrationsByIds(ids: Array<string>): Promise<Array<AccountingIntegrationV1>> {
		if (!arrayIsSetAndFilled(ids)) {
			return null;
		}
		return this.companyIntegrationService
			.getActiveIntegrations(this.companyService.getCompanyOfLoggedUser().id)
			.then((res) => res.filter((e) => ids.includes(e.Key)));
	}
	public async getDivisionsByIds(integration: string, ids: Array<string>): Promise<Array<Division>> {
		if (!arrayIsSetAndFilled(ids)) {
			return null;
		}
		return this.companyIntegrationService.getDivisionsByIds(this.companyService.getCompanyOfLoggedUser(), integration, ids);
	}

	public async getJournalsByIds(integration: string, division: string, ids: Array<string>): Promise<Array<Journal>> {
		if (!arrayIsSetAndFilled(ids)) {
			return null;
		}
		return this.companyIntegrationService.getJournalsByIds(this.companyService.getCompanyOfLoggedUser(), integration, division, ids);
	}
	public async getIntegrationRelationsByIds(
		integration: string,
		division: string,
		ids: Array<string>,
	): Promise<Array<IntegrationRelation>> {
		if (!arrayIsSetAndFilled(ids)) {
			return null;
		}
		return this.companyIntegrationService.getIntegrationRelationsByIds(
			this.companyService.getCompanyOfLoggedUser().id,
			integration,
			division,
			ids,
		);
	}
	public async getPaymentConditionsByIds(integration: string, division: string, ids: Array<string>): Promise<Array<PaymentCondition>> {
		if (!arrayIsSetAndFilled(ids)) {
			return null;
		}
		return this.companyIntegrationService.getIntegrationPaymentConditionsByIds(
			this.companyService.getCompanyOfLoggedUser().id,
			integration,
			division,
			ids,
		);
	}

	public async getIntegrationPaymentMethodsByIds(
		integration: string,
		division: string,
		ids: Array<string>,
	): Promise<Array<IntegrationPaymentMethod>> {
		if (!arrayIsSetAndFilled(ids)) {
			return null;
		}
		return this.companyIntegrationService.getIntegrationPaymentMethodsByIds(
			this.companyService.getCompanyOfLoggedUser().id,
			integration,
			division,
			ids,
		);
	}

	public getLastKnownPersistedTxState(transactionId: string): TransactionFrontendModel {
		return this.lastKnownPersistedTxStates.get(transactionId);
	}

	public async getTransaction(transactionId: string, showNotificationOnError = true, useCache = true): Promise<TransactionFrontendModel> {
		const apiModel = await this.getTxAsApiModel(transactionId, showNotificationOnError, useCache);
		this.currentActiveTransactionId = apiModel.id;
		const result = apiModel.transformToFrontend(this.userService.getCurrentLoggedUser().id);
		const resultCopy = apiModel.transformToFrontend(this.userService.getCurrentLoggedUser().id);
		this.lastKnownPersistedTxStates.set(transactionId, resultCopy);
		return result;
	}

	public async getFullTransactionContext(transactionId: string, addedContext: TransactionFrontendModel): Promise<Record<any, any>> {
		const tx = await this.receiptService.getReceipt(transactionId, false, fromCacheIfNewerThanMs);
		let apiModel: TransactionApiModel;
		if (tx.isinvoice) {
			apiModel = new InvoiceApiModel(tx);
		} else if (tx?.type === 'travel') {
			apiModel = new FixedCompensationApiModel(tx);
		} else {
			apiModel = new ReceiptApiModel(tx);
		}
		return { ...apiModel, ...filterUndefinedValues({ ...addedContext.transformToApi() }) };
	}

	private async getTxAsApiModel(transactionId: string, showNotificationOnError = true, useCache = true): Promise<TransactionApiModel> {
		return this.receiptService.getReceipt(transactionId, showNotificationOnError, useCache ? fromCacheIfNewerThanMs : 0).then((value) => {
			let apiModel: TransactionApiModel;
			if (value.isinvoice) {
				apiModel = new InvoiceApiModel(value);
			} else if (value?.type === 'travel') {
				apiModel = new FixedCompensationApiModel(value);
			} else {
				apiModel = new ReceiptApiModel(value);
			}
			return apiModel;
		});
	}

	public async getStatusOfTransaction(transactionId: string): Promise<DeclarationStatusFlag> {
		if (!stringIsSetAndFilled(transactionId)) {
			return DeclarationStatusFlag.NotSubmitted;
		}
		return (await this.getTransaction(transactionId))?.status ?? DeclarationStatusFlag.NotSubmitted;
	}

	public async retractTransaction(transactionId: string): Promise<void> {
		return this.receiptService.retractExpense(transactionId);
	}

	public async deleteTransaction(transactionId: string): Promise<void> {
		return this.receiptService.deleteReceipt(transactionId);
	}

	public async splitTransaction(transactionId: string): Promise<void> {
		return this.receiptService.splitReceipt(transactionId).then(() => {});
	}

	public async createTransaction(tx: TransactionApiModel): Promise<TransactionApiModel> {
		const res = await this.receiptService.createReceipt(tx as any).then((e) => {
			if (e.isinvoice) {
				return new InvoiceApiModel(e);
			} else {
				return new ReceiptApiModel(e);
			}
		});
		this.currentActiveTransactionId = res.id;
		return res;
	}

	public async saveStatusChanges(tx: TransactionFrontendModel, newStatus: DeclarationStatusFlag, remark: string) {
		if (!isValueSet(newStatus)) {
			return tx;
		}
		const oldStatus = this.getLastKnownPersistedTxState(tx.id)?.status ?? null;
		if (oldStatus !== newStatus || stringIsSetAndFilled(remark)) {
			const updatedTx = await this.updateStatus(tx.id, newStatus, remark);
			return updatedTx.transformToFrontend(this.userService.getCurrentLoggedUser().id);
		}
		return tx;
	}

	public async updateStatus(id: string, status: DeclarationStatusFlag, comment: string = null): Promise<InvoiceApiModel> {
		const tx = await this.getTransaction(id);
		// because of business rules that can change the status right after saving,
		// we don't want to put it back to Submitted/toClaim because a user has added a comment during submitting
		// You are only allowed to go to ToClaim when the tx used to be NeedsInformation or Denied
		let statusToSend: DeclarationStatusFlag = status;
		if (
			(status === DeclarationStatusFlag.ToClaim && tx.status === DeclarationStatusFlag.NeedsInformation) ||
			(status === DeclarationStatusFlag.ToClaim && tx.status === DeclarationStatusFlag.Denied)
		) {
			statusToSend = status;
		} else if (status === DeclarationStatusFlag.ToClaim) {
			statusToSend = tx.status;
		}

		if (tx.status === DeclarationStatusFlag.NotSubmitted) {
			return this.receiptService.submitExpense(id).then((res) => new InvoiceApiModel(res));
		}
		return this.receiptService.submitStatus(id, { status: statusToSend, comment }).then((res) => new InvoiceApiModel(res));
	}

	public async bookTx(tx: TransactionApiModel) {
		if (stringIsSetAndFilled(tx.accounting?.authorization)) {
			return this.bookTxAav2(tx);
		}
		return this.bookTxAav1(tx);
	}

	private async bookTxAav1(tx: TransactionApiModel) {
		const timeZone = await this.timezoneService.guessTimezone();
		const transactionType: TransactionType = tx.isinvoice ? TransactionType.Invoice : TransactionType.Receipt;
		let bookingDate = tx.booking_date;
		if (!isValueSet(bookingDate)) {
			bookingDate = new Date(await this.getBookingDateBasedOnPurchaseDate(transactionType, tx.purchase_date_ymd, tx.provider));
		}
		tx.booking_date = bookingDate;
		return this.receiptService.bookExpense(tx as any, timeZone);
	}

	private async bookTxAav2(tx: TransactionApiModel) {
		return this.accountingIntegrationV2Service.bookTransaction(tx);
	}

	public addOcrDoneCallback(txId: string, onOcrDoneCallback: () => void) {
		this.ocrCallbacks.set(txId, onOcrDoneCallback);
		const interval = setInterval(() => {
			if (this.ocrCallbacks.has(txId)) {
				this.getTransaction(txId, false, false).then((tx) => {
					if (tx.ocrStatus === OCRStatus.Processed) {
						onOcrDoneCallback();
						this.ocrCallbacks.delete(txId);
						clearInterval(interval);
						return;
					}
				});
			}
		}, 7000); // failsafe to check every 7 sec if the ocr is done without websockets
	}

	// only used by web, not mobile, since mobile uploading works differently based on the platform (ios/android)
	public async addFileToTransaction(
		files: Array<File>,
		receiptId: string,
		runOcr: boolean,
		onOcrDoneCallback: () => void,
		TransactionTypeInterface: TransactionType,
	): Promise<Array<Attachment>> {
		const directOcr = runOcr;
		const asyncOCR = runOcr && !directOcr;
		if (asyncOCR) {
			this.addOcrDoneCallback(receiptId, onOcrDoneCallback);
		}

		let attachmentsUploaded: Array<Attachment>;

		const featureFlags = await this.featureFlagsService.getAllFeatureFlags();

		for (const file of files) {
			if (featureFlags.includes(PossibleCompanyFeatureFlags.OCR_ENHANCED_IMAGES)) {
				attachmentsUploaded = await this.addOcrEnhancedImageToTransaction(
					await this.fileService.addOcrEnhancedImage(file),
					receiptId,
					TransactionTypeInterface,
				);
				onOcrDoneCallback();
			} else if (file.type.startsWith(AttachmentType.IMAGE)) {
				attachmentsUploaded = this.getAttachmentsFromReceipt(await this.fileService.addImage(receiptId, file, !runOcr, directOcr));
			} else {
				attachmentsUploaded = this.getAttachmentsFromReceipt(await this.fileService.addDocument(receiptId, file, !runOcr, directOcr));
			}
		}
		if (!asyncOCR) {
			// so the uploaded attachments are rendered
			runNextRenderCycle(onOcrDoneCallback);
		}
		return attachmentsUploaded;
	}

	private getAttachmentsFromReceipt(receipt: Receipt): Array<Attachment> {
		return [...receipt.images.map((e) => Attachment.fromImage(e)), ...receipt.documents.map((e) => Attachment.fromDocument(e))];
	}

	private async addOcrEnhancedImageToTransaction(data, txId: string, transactionInterfaceType): Promise<Array<Attachment>> {
		const attachment = new Attachment({
			id: data.id,
			type: AttachmentType.OCR_ENHANCED_FILE,
			fileName: data.file?.name,
			hash: data.file?.hash,
			user: data.user,
		});

		const transaction: TransactionFrontendModel = await this.getTransaction(txId);
		transaction.attachments.push(attachment);
		transaction.ocrStatus = OCRStatus.Processed;
		//TODO line above should be removed after this ticket https://git.hub.klippa.com/klippa/SpendControl/API/-/issues/2599
		await this.updateTransaction(transaction, txId, transactionInterfaceType);
		return asArray(attachment);
	}

	public async removeFileFromTransaction(attachment: Attachment, receiptId: string): Promise<Array<Attachment>> {
		if (attachment.type === 'image') {
			return this.fileService.deleteReceiptImage(receiptId, attachment.id).then((updated_receipt) => {
				return this.getAttachmentsFromReceipt(updated_receipt);
			});
		} else {
			return this.fileService.deleteReceiptDocument(receiptId, attachment.id).then((updated_receipt) => {
				return this.getAttachmentsFromReceipt(updated_receipt);
			});
		}
	}

	public setAuthFlow(
		receiptId: string,
		authorizationFlowId: string,
		approvers: Array<string>,
		requireApproverOrder: boolean,
		requireApproverCount?: number,
	): Promise<void> {
		return this.receiptService
			.setReceiptAuthorizationFlowStatus(receiptId, authorizationFlowId, approvers, requireApproverOrder, requireApproverCount)
			.then(() => {});
	}

	public async addRemark(transactionId: string, replyText: string): Promise<Array<ConversationMessage>> {
		const transaction = await this.getTransaction(transactionId);
		return this.receiptService
			.submitStatus(transaction.id, {
				status: transaction.status,
				comment: replyText,
			})
			.then((res) => invoiceApiToFrontend(new InvoiceApiModel(res), this.userService.getCurrentLoggedUser().id).conversation);
	}

	public async getConversation(transactionId: string): Promise<Array<ConversationMessage>> {
		return (await this.getTransaction(transactionId)).conversation ?? [];
	}

	public async askForApproval(transactionId: string, approverUserId: string) {
		this.receiptService.prependToReceiptAuthorizationFlow(transactionId, approverUserId);
	}

	public async ocrReviewed(transactionIdToEdit: string): Promise<void> {
		await this.receiptService.OCRReviewed(transactionIdToEdit);
	}

	public setLatestDashboardFilters(filters: ReceiptListAPIRequest) {
		this.latestDashboardFilters = cloneDeep(filters);
	}

	public async getTxViewMode(txId: string, activatedRoute: ActivatedRoute): Promise<TransactionViewMode> {
		const user: User = this.userService.getCurrentLoggedUser();
		const userId = this.userService.getCurrentLoggedUser().id;
		const tx: TransactionFrontendModel = await this.getTransaction(txId);
		const status: DeclarationStatusFlag = tx.status;
		if (tx.needsOcrReview) {
			return TransactionViewMode.SUBMIT;
		}

		if (status === DeclarationStatusFlag.NeedsInformation && tx.user === userId) {
			return TransactionViewMode.SUBMIT;
		}

		if (status === DeclarationStatusFlag.Denied && tx.user !== userId && !user.hasCompanyFinanceRole()) {
			return TransactionViewMode.APPROVE;
		}

		if (tx.status === DeclarationStatusFlag.Accepted) {
			if (tx instanceof InvoiceFrontendModel && user.canApproveCompanyInvoices()) {
				return TransactionViewMode.APPROVE;
			}
			if (tx instanceof ReceiptFrontendModel && user.canApproveCompanyReceipts()) {
				return TransactionViewMode.APPROVE;
			}
			if (tx instanceof FixedCompensationFrontendModel && user.canApproveCompanyReceipts()) {
				return TransactionViewMode.APPROVE;
			}
		}

		if (user.hasCompanyFinanceRole()) {
			switch (status) {
				case DeclarationStatusFlag.Accepted:
					return TransactionViewMode.APPROVE;
				case DeclarationStatusFlag.Approved:
				case DeclarationStatusFlag.Claimed:
					return TransactionViewMode.FINANCE;
			}
		}
		if (tx instanceof InvoiceFrontendModel) {
			if (tx.status === DeclarationStatusFlag.ToClaim && user.hasCompanyFinanceRole()) {
				return TransactionViewMode.APPROVE;
			}
		}
		if (tx instanceof ReceiptFrontendModel || tx instanceof FixedCompensationFrontendModel) {
			if (activatedRoute.snapshot.data?.isManagingExpenses) {
				return TransactionViewMode.APPROVE;
			}
		}
		return TransactionViewMode.SUBMIT;
	}

	public async getNextTransaction(currentTxId: string): Promise<string> {
		// the context might have changed on where our current tx is.
		// Therefore, use some buffer to make sure we got the current receipt in the call
		const fetchMargin = 10;
		let filters;
		if (isValueSet(this.latestDashboardFilters)) {
			filters = new ReceiptListAPIRequest({
				...this.latestDashboardFilters,
				start: Math.max(0, Number(this.latestDashboardFilters.start) - fetchMargin),
				max: Number(this.latestDashboardFilters.max) + fetchMargin,
			});
		}
		const receipts = await this.receiptService.getReceipts(filters).then((res) => res.data.receipts);
		if (stringIsSetAndFilled(filters.report)) {
			const report = await this.reportsService.getExpenseReport(filters.report);
			const transactions: Array<TransactionFrontendModel> = await Promise.all(receipts.map((e) => this.getTransaction(e.id)));
			let needMyAttention: Array<TransactionFrontendModel>;
			if (report.status_to_show === DeclarationStatusFlag.Approved) {
				needMyAttention = transactions.filter(
					(e) => !arrayIsSetAndFilled(e.bookingLines) || !e.validatedBookingLines || e.id === currentTxId,
				);
			} else {
				needMyAttention = transactions.filter((e) => e.authorizationFlow?.myTurnToApprove || e.id === currentTxId);
			}
			const currentIndex = needMyAttention.findIndex((e) => e.id === currentTxId) ?? -1;
			const nextInLine = needMyAttention.length === currentIndex + 1 ? needMyAttention[0]?.id : needMyAttention[currentIndex + 1]?.id;
			if (currentTxId === nextInLine) {
				return;
			}
			return nextInLine;
		} else {
			const currentIndex = receipts.findIndex((e) => e.id === currentTxId) ?? -1;
			const nextInLine = receipts[currentIndex + 1]?.id;
			return nextInLine;
		}
	}

	public async canOnlyView(txId: string, viewMode: TransactionViewMode, isManagingExpenses: boolean = false): Promise<boolean> {
		if (viewMode === TransactionViewMode.SUBMIT && isManagingExpenses) {
			return true;
		}
		if (!stringIsSetAndFilled(txId)) {
			return false;
		}
		const tx = await this.getTransaction(txId);
		if (tx.status === DeclarationStatusFlag.Claimed) {
			return true;
		}

		if (tx.isBooked) {
			return true;
		}

		if (
			tx.accountingStatus === AccountingStatus.QUEUED_FOR_BATCH ||
			tx.accountingStatus === AccountingStatus.BATCH_IS_PENDING ||
			tx.accountingStatus === AccountingStatus.BATCH_ERROR ||
			tx.accountingStatus === AccountingStatus.QUEUED_FOR_BACKGROUND
		) {
			return true;
		}

		if (await this.isFinanceUserThatCanChangeAuthFlowWhenNotTheirTurn(txId)) {
			return false;
		}

		if (viewMode === TransactionViewMode.SUBMIT) {
			switch (tx.status) {
				case DeclarationStatusFlag.NeedsInformation:
					{
						if (tx instanceof InvoiceFrontendModel && this.userService.getCurrentLoggedUser().hasCompanyFinanceRole()) {
							return false;
						}
					}
					break;
				case DeclarationStatusFlag.Approved:
				case DeclarationStatusFlag.Accepted:
					return true;
			}
			if (tx instanceof InvoiceFrontendModel) {
				if (tx.status === DeclarationStatusFlag.NotSubmitted || tx.needsOcrReview) {
					// for invoices that still need submission: it doesn't matter what user interacts with it. It will not be read only
					return false;
				}
				if (tx.status === DeclarationStatusFlag.Denied && tx.transactionStatus === TransactionStatus.OPEN) {
					return false;
				}
				if (tx.status === DeclarationStatusFlag.Denied && tx.transactionStatus === TransactionStatus.ARCHIVED) {
					return true;
				}
			}
			if (this.userService.getCurrentLoggedUser().id !== tx.user) {
				return true;
			}
		}
		if (viewMode === TransactionViewMode.APPROVE) {
			if (tx.status === DeclarationStatusFlag.ToClaim) {
				// Quick fix / TODO: when tx is pending, allow user to interact with it.
				// We will fix properly when https://git.hub.klippa.com/klippa/SpendControl/API/-/issues/2137 is solved
				return false;
			}
			switch (tx.status) {
				case DeclarationStatusFlag.Approved:
				case DeclarationStatusFlag.NeedsInformation:
					return true;
			}
			if (!tx.authorizationFlow?.myTurnToApprove) {
				return true;
			}
		}
		return false;
	}

	public async getOnlyFinanceAllowedActions(txId: string): Promise<Array<UIFieldKey>> {
		const financeAllowedActions: Array<UIFieldKey> = [];
		if (await this.isFinanceUserThatModifyTransactionStatusWhenNotTheirTurn(txId)) {
			financeAllowedActions.push('status');
		}

		if (await this.isFinanceUserThatCanChangeAuthFlowWhenNotTheirTurn(txId)) {
			financeAllowedActions.push('authorizationFlow');
		}

		return financeAllowedActions;
	}

	private async isFinanceUserThatCanChangeAuthFlowWhenNotTheirTurn(txId: string): Promise<boolean> {
		const tx = stringIsSetAndFilled(txId) ? await this.getTransaction(txId) : null;
		if (
			tx?.status === DeclarationStatusFlag.Accepted &&
			!tx.authorizationFlow?.myTurnToApprove &&
			this.userService.getCurrentLoggedUser().hasCompanyFinanceRole()
		) {
			return true;
		}
		return false;
	}

	public async isFinanceUserThatModifyTransactionStatusWhenNotTheirTurn(txId: string): Promise<boolean> {
		const tx = stringIsSetAndFilled(txId) ? await this.getTransaction(txId) : null;
		if (
			tx?.isInvoice &&
			tx?.status === DeclarationStatusFlag.Accepted &&
			!tx?.authorizationFlow?.myTurnToApprove &&
			this.userService.getCurrentLoggedUser().hasCompanyFinanceRole()
		) {
			return true;
		}
		return false;
	}

	public async isTransactionArchivable(txId: string): Promise<boolean> {
		const tx = stringIsSetAndFilled(txId) ? await this.getTransaction(txId) : null;
		if (isValueSet(tx?.transactionStatus) && tx.transactionStatus === TransactionStatus.ARCHIVED) {
			return false;
		}
		if (!(tx instanceof InvoiceFrontendModel)) {
			return false;
		}
		return (
			isValueSet(tx.status) &&
			tx.status === DeclarationStatusFlag.Denied &&
			(this.userService.getCurrentLoggedUser().hasCompanyFinanceRole() ||
				(this.userService.getCurrentLoggedUser().hasCompanyInvoiceSubmitterRole() &&
					tx.user === this.userService.getCurrentLoggedUser().getID()))
		);
	}

	public async isTransactionReopenable(txId: string): Promise<boolean> {
		const tx = stringIsSetAndFilled(txId) ? await this.getTransaction(txId) : null;
		if (isValueSet(tx?.transactionStatus) && tx.transactionStatus === TransactionStatus.OPEN) {
			return false;
		}
		if (!(tx instanceof InvoiceFrontendModel)) {
			return false;
		}
		return (
			isValueSet(tx.status) &&
			tx.status === DeclarationStatusFlag.Denied &&
			(this.userService.getCurrentLoggedUser().hasCompanyFinanceRole() ||
				(this.userService.getCurrentLoggedUser().hasCompanyInvoiceSubmitterRole() &&
					tx.user === this.userService.getCurrentLoggedUser().getID()))
		);
	}

	public async canExcludeFromReport(txId: string): Promise<boolean> {
		if (!stringIsSetAndFilled(txId)) {
			return true;
		}
		const tx = await this.getTransaction(txId);
		return this.reportsService.canExcludeItemInReport(await this.reportsService.getExpenseReport(tx.report));
	}

	public async showOCRResultsVerifiedButton(txType: TransactionType, txId: string): Promise<boolean> {
		if (txType !== TransactionType.Invoice) {
			return false;
		}
		if (!stringIsSetAndFilled(txId)) {
			return false;
		}
		return await this.hasBookingLinesBeforeApprovalWithoutOCRVerifiedResults(txType, txId);
	}

	public async hasBookingLinesBeforeApprovalWithoutOCRVerifiedResults(txType: TransactionType, txId: string): Promise<boolean> {
		const company = this.companyService.getCompanyOfLoggedUser();
		if (company?.modules?.booking_lines_before_approved?.enabled !== true) {
			return false;
		}
		if (txType !== TransactionType.Invoice) {
			return false;
		}
		if (!stringIsSetAndFilled(txId)) {
			return true;
		}
		const tx = await this.getTransaction(txId);
		if (tx.status !== DeclarationStatusFlag.ToClaim && tx.status !== DeclarationStatusFlag.NotSubmitted) {
			return false;
		}
		if (tx.receiptDataManuallyValidated) {
			return false;
		}
		return true;
	}

	public async getBookingDateBasedOnPurchaseDate(
		transactionType: TransactionType,
		purchaseDateYMD: string,
		integrationId: string,
	): Promise<string> {
		// As the placeholder for the bookingDate, we use the purchaseDate or date of today.
		// If bookingDate is left null, backend will fill it in for us when doing the actual booking
		switch (transactionType) {
			case TransactionType.Invoice:
				return new DateFormat().transform(purchaseDateYMD);
			case TransactionType.Receipt:
				const auth = this.companyService.getCompanyOfLoggedUser()?.getAuthorizationByKey(integrationId);
				return auth?.book_expenses_with_purchase_date ? purchaseDateYMD : new DateFormat().transform(new Date());
		}
	}

	public shouldShowRemarkField(status: DeclarationStatusFlag): boolean {
		switch (status) {
			// TODO: we dont have support for comments yet for unsubmitted receipts on the backend
			/*
			case undefined:
			case null:
			case DeclarationStatusFlag.NotSubmitted:
			 */
			case DeclarationStatusFlag.ToClaim:
			case DeclarationStatusFlag.Denied:
			case DeclarationStatusFlag.Accepted:
			case DeclarationStatusFlag.Approved:
			case DeclarationStatusFlag.NeedsInformation:
			case DeclarationStatusFlag.Claimed:
				return true;
		}
		return false;
	}

	public async updateTransaction(
		values: TransactionFrontendModel,
		transactionIdToEdit: string,
		transactionType: TransactionType,
		dataBasedOnPrefills: Partial<InputField> = {},
	): Promise<TransactionApiModel> {
		const oldTx = await this.getTransaction(transactionIdToEdit);
		const oldMergedWithDataBasedOnPrefills = { ...oldTx, ...dataBasedOnPrefills };
		const dataBasedOnPrefillsMergedWithValues = { ...oldMergedWithDataBasedOnPrefills, ...values };

		let combined;
		switch (transactionType) {
			case TransactionType.Invoice:
				combined = new InvoiceFrontendModel(dataBasedOnPrefillsMergedWithValues);
				break;
			case TransactionType.Receipt:
				combined = new ReceiptFrontendModel(dataBasedOnPrefillsMergedWithValues);
				break;
			case TransactionType.FixedCompensation:
				combined = new FixedCompensationFrontendModel(dataBasedOnPrefillsMergedWithValues);
				break;
		}
		const result = combined.transformToApi();
		result.id = transactionIdToEdit;
		return this.receiptService.updateReceipt(result as any).then((e) => {
			switch (transactionType) {
				case TransactionType.Invoice:
					return new InvoiceApiModel(e);
				case TransactionType.FixedCompensation:
					return new FixedCompensationApiModel(e);
				case TransactionType.Receipt:
				default:
					return new ReceiptApiModel(e);
			}
		});
	}

	public async getGroupToFilterOn(txType: TransactionType): Promise<FilterOnGroup> {
		if (txType === TransactionType.Invoice && (await this.subCompanyService.userIsParentCompanyUser())) {
			return FilterOnGroupEnum.TxUser;
		}

		if (txType === TransactionType.Invoice) {
			return FilterOnGroupEnum.LoggedInUser;
		}

		return FilterOnGroupEnum.TxUser;
	}

	public getBookingType(txType: TransactionType): AccountingBookingType {
		switch (txType) {
			case TransactionType.Receipt:
			case TransactionType.FixedCompensation:
				return AccountingBookingType.EXPENSE;
			case TransactionType.Invoice:
				return AccountingBookingType.INVOICE;
		}
		throw new Error('Can not determine booking type');
	}
}
