import { Component, Input, Output, EventEmitter } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-active-state-picker',
	templateUrl: './active-state-picker.component.html',
})
export class ActiveStatePickerComponent {
	@Output() activeChange = new EventEmitter<boolean>();

	activeValue: boolean;

	activeItems = [
		{ id: true, Label: this.translate.instant('Yes') },
		{ id: false, Label: this.translate.instant('No') },
	];

	constructor(private translate: TranslateService) {}

	@Input()
	get active() {
		return this.activeValue;
	}

	set active(val) {
		this.activeValue = val;
		this.activeChange.emit(this.activeValue);
	}
}
