import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { Order } from '#/models/utils/order';
import { objectToQueryParamString } from '#/util/objects';
import { TransactionType } from '#/models/transaction/transactionType';

export function getFullPath(route: ActivatedRouteSnapshot) {
	return (
		'/' +
		route.pathFromRoot
			.map((r) => {
				let path = r.routeConfig?.path;
				if (stringIsSetAndFilled(path)) {
					// replace path params. For example ':id' to '5fc75636f7005c78940f04d6'
					Object.entries(r.params).forEach(([key, value]) => {
						path = path.replace(`:${key}`, value);
					});
					return path;
				}
				return null;
			})
			.filter(stringIsSetAndFilled)
			.join('/')
	);
}

@Injectable({
	providedIn: 'root',
})
export class RouteUtilsService {
	private routesWithQueryParams: Record<string, string> = {};
	private routeData: Record<string, any> = {};

	constructor(private router: Router) {}

	public navigateToParentRoute(currentRoute: ActivatedRoute) {
		let parent;
		if (currentRoute.snapshot.routeConfig.path === '') {
			// we have to go up 2 levels when the path is an empty string, because the parent will be the same route as the empty string (current) route
			parent = currentRoute.parent.parent;
		} else {
			parent = currentRoute.parent;
		}
		// skip unnamed routes when looking for the parent to go to
		while (!isValueSet(parent.snapshot.routeConfig?.data?.routeName) && !isValueSet(parent.snapshot.routeConfig?.data?.dynamicRouteName)) {
			parent = parent.parent;
		}
		const fullPath = getFullPath(parent.snapshot);
		const fullPathWithQueryParams = stringIsSetAndFilled(this.routesWithQueryParams[fullPath])
			? `${fullPath}?${this.routesWithQueryParams[fullPath]}`
			: fullPath;
		this.router.navigateByUrl(fullPathWithQueryParams);
	}

	public navigateToDashboard() {
		this.router.navigateByUrl('/dashboard');
	}

	public async navToUrlWithForcedReload(url?: string, replaceUrl = false): Promise<boolean> {
		let urlToGoTo = stringIsSetAndFilled(url) ? url : this.router.url;
		if (!urlToGoTo.startsWith('/')) {
			// Probably a better way to do it, but I want to change the last part of the url (after the last '/') to the new url that was provided
			const splitted = this.router.url.split('/');
			splitted[splitted.length - 1] = url;
			urlToGoTo = splitted.join('/');
		}
		// TODO: bit hacky, using an empty route to force reloading
		await this.router.navigateByUrl('/empty', { skipLocationChange: true, replaceUrl });
		return this.router.navigateByUrl(urlToGoTo, { replaceUrl });
	}

	public setQueryParamsForVisitedUrl(url: string, queryParamsString: string): void {
		this.routesWithQueryParams[url] = queryParamsString;
	}

	public setRouteData(data: Record<string, any>): void {
		this.routeData = data;
	}
	public consumeRouteData(): Record<string, any> {
		const result = this.routeData;
		this.routeData = {};
		return result;
	}

	public getRouteWithParams(rootUrl: string, params: Record<string, any>): string {
		const queryParams: string = objectToQueryParamString(params);
		return `${rootUrl}?${queryParams}`;
	}

	public getUpdateRouteWithNewParamValue(url: string, params: Record<string, any>, updatedParam: Record<string, any>): string {
		const queryParams: string = objectToQueryParamString({ ...params, ...updatedParam });
		return `${url}?${queryParams}`;
	}
}
