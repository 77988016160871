<ng-container>
	<div class="modal-header" *ngIf="showHeader">
		<h4 class="modal-title" translate>Export</h4>
		<button (click)="closeModal()" aria-label="Close" class="close" type="button">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<ngx-async-template [promise]="exportPromise" *ngIf="showHeader">
		<ng-template ngx-async-error ngx-async-inactive>
			<p class="modal-subtitle">
				<ng-container [ngTemplateOutlet]="headerMessage"></ng-container>
			</p>
		</ng-template>
	</ngx-async-template>

	<div class="modal-body">
		<ngx-async-template [promise]="exportPromise">
			<ng-template ngx-async-error ngx-async-inactive>
				<klp-form [formGroup]="exportTemplateForm" *ngIf="isProUser || userHasCompany">
					<div class="exportTemplatePickerContainer">
						<klp-form-element [caption]="'Export template' | translate" direction="vertical">
							<app-form-export-template-picker
								[exportType]="exportType"
								formControlName="exportTemplate"
								(ngModelChange)="selectedTemplateChanged($event)"
							>
							</app-form-export-template-picker>
						</klp-form-element>
						<a *ngIf="!isCompanyAdmin" class="manageTemplateLink" routerLink="/settings/export-templates" target="_blank" translate
							>Manage templates</a
						>
						<a *ngIf="isCompanyAdmin" class="manageTemplateLink" routerLink="/dm/settings/export-templates" target="_blank" translate
							>Manage templates</a
						>
					</div>
				</klp-form>

				<app-export-form-configuration
					variant="normalExport"
					[disabled]="hasSelectedTemplate()"
					[exportIds]="exportIds"
					[exportType]="exportType"
					[companyExport]="companyExport"
					[exportPromise]="exportPromise"
					[(ngModel)]="exportConfig"
				></app-export-form-configuration>

				<klp-form [formGroup]="saveForm" *ngIf="!hasSelectedTemplate() && isExportFormat('pdf', 'xlsx', 'csv')">
					<klp-form-element [caption]="'Save column settings' | translate">
						<klp-form-checkbox class="toggleCheckbox" formControlName="save_to_user_settings"></klp-form-checkbox>
					</klp-form-element>

					<klp-form-element [caption]="'Save as new template' | translate">
						<klp-form-toggle formControlName="save_as_new_template"></klp-form-toggle>
					</klp-form-element>

					<klp-form-element *ngIf="saveForm.get('save_as_new_template').value === true" [caption]="'Name of your new template' | translate">
						<klp-form-text-input formControlName="new_template_name"></klp-form-text-input>
					</klp-form-element>
				</klp-form>

				<klp-form [formGroup]="exportForm">
					<ng-container *ngIf="isExportFormat('pdf', 'csv', 'xlsx', 'ubl', 'datev')">
						<klp-form-element direction="vertical">
							<klp-form-caption>
								<span class="text-bold" translate>Share with </span>
								<span *ngIf="!isEmailRequired" translate> (optional) </span>
							</klp-form-caption>
							<app-form-multiple-email formControlName="email"></app-form-multiple-email>
						</klp-form-element>

						<klp-form-element [caption]="'Add files as attachments to email' | translate">
							<klp-form-checkbox class="toggleCheckbox" formControlName="email_add_attachments"></klp-form-checkbox>
						</klp-form-element>
					</ng-container>
				</klp-form>
			</ng-template>

			<ng-template ngx-async-pending>
				<div>
					<div translate>Please wait while we export your data</div>
					<klp-form-loading-indicator variant="spinner"></klp-form-loading-indicator>
				</div>
			</ng-template>

			<ng-template ngx-async-success let-value="value">
				<span translate
					>Your export was successful! The recipients have been emailed. You can also directly download the export here:
				</span>
				<a [attr.href]="value" target="_blank">{{ value }}</a>
			</ng-template>
		</ngx-async-template>
	</div>

	<ngx-async-template [promise]="exportPromise">
		<ng-template ngx-async-error ngx-async-inactive>
			<div class="modal-footer ctas">
				<app-button (click)="resetSettings()" variant="redFilled"><span translate>Reset</span></app-button>
				<app-button (click)="emitExport()" variant="greenFilled"><span translate>Export</span></app-button>
			</div>
		</ng-template>
	</ngx-async-template>
</ng-container>
