import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { ApiExportColumn, ApiExportNode, ExportColumnValues, ExportFormat, ExportType } from '#/models/company/exporterInterfaces';
import { ExportCustomIntegrationService } from '~/app/modules/company/services/exporters/custom-integration.service';
import { CompanyService } from '#/services/company/company.service';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { ExportTemplateColumn } from '#/models/user/user-properties';
import { flatten } from 'lodash';
import { ExportTemplateUserRole } from '~/app/modules/export-templates/models/export-template.model';

@Injectable({
	providedIn: 'root',
})
export class ColumnsService {
	constructor(
		private apiService: APIService,
		private bookingColumnService: ExportCustomIntegrationService,
		private companyService: CompanyService,
	) {}

	private mergeApiColumnsAndUserSettingsColumns(
		apiCols: Array<ApiExportColumn>,
		_userSettingsCols: Array<ExportTemplateColumn> = [],
	): Array<ExportColumnValues> {
		const userSettingsCols = _userSettingsCols || [];
		const cols: ExportColumnValues[] = userSettingsCols
			.map((userSettingCol) => {
				const columnFromApi = apiCols.find((apiCol) => apiCol.key === userSettingCol.value);
				if (!columnFromApi) {
					// ignore columns that dont exist anymore
					return;
				}

				return {
					label: columnFromApi.label,
					value: columnFromApi.key,
					type: 'value',
					enabled: userSettingCol.enabled,
					showCurrencyToggle: columnFromApi.showCurrencySymbol,
					showDateFormatPicker: columnFromApi.showCustomDateFormat,
					showCustomLabelInput: columnFromApi.showCustomLabel,
					add_currency_symbol: userSettingCol.add_currency_symbol,
					date_format: userSettingCol.date_format,
					custom_label: userSettingCol.custom_label,
				};
			})
			.filter((col) => isValueSet(col));

		// add cols that are not saved in the user settings
		apiCols.forEach((columnFromApi) => {
			const exists = userSettingsCols.some((userSetting) => userSetting.value === columnFromApi.key);
			if (!exists) {
				cols.push({
					label: columnFromApi.label,
					value: columnFromApi.key,
					type: 'value',
					enabled: !columnFromApi.disabledByDefault,
					showCurrencyToggle: columnFromApi.showCurrencySymbol,
					showDateFormatPicker: columnFromApi.showCustomDateFormat,
					showCustomLabelInput: columnFromApi.showCustomLabel,
					add_currency_symbol: false,
					date_format: null,
					custom_label: '',
				});
			}
		});
		return cols;
	}

	public async getReceiptColumnsFromApi(exportType: ExportType, exportFormat: ExportFormat): Promise<any> {
		const companyID = this.companyService.getCompanyOfLoggedUser()?.getID();
		let url: string;
		if (stringIsSetAndFilled(companyID)) {
			url = `export/columns/company/${companyID}/user/${exportType}/${exportFormat}`;
		} else {
			url = `export/columns/user/${exportType}/${exportFormat}`;
		}

		return await this.apiService.getFromApi(url).then((res) => res.data);
	}

	public async getReceiptAsGlobalAdminFromApi(exportType: ExportType, exportFormat: ExportFormat): Promise<any> {
		const url = `export/columns/${exportType}/${exportFormat}`;
		return await this.apiService.getFromApi(url).then((res) => res.data);
	}

	public async getRegularColumnsAsGlobalAdminPatchedBy(
		exportType: ExportType,
		exportFormat: ExportFormat,
		cols,
	): Promise<Array<ExportColumnValues>> {
		return await this.getReceiptAsGlobalAdminFromApi(exportType, exportFormat).then((res) => {
			const allColumns: Array<ApiExportColumn> = flatten(
				res.Columns.map((column: ApiExportNode) => {
					return column.columns;
				}),
			);
			return this.mergeApiColumnsAndUserSettingsColumns(allColumns, cols);
		});
	}

	public async getTravelColumnsAsGlobalAdminPatchedBy(
		exportType: ExportType,
		exportFormat: ExportFormat,
		cols,
	): Promise<Array<ExportColumnValues>> {
		return await this.getReceiptAsGlobalAdminFromApi(exportType, exportFormat).then((res) => {
			const allColumns: Array<ApiExportColumn> = flatten(
				res.TravelColumns.map((column: ApiExportNode) => {
					return column.columns;
				}),
			);
			return this.mergeApiColumnsAndUserSettingsColumns(allColumns, cols);
		});
	}

	public async getReceiptAndTravelColumnsAsGlobalAdminPatchedBy(
		exportType: ExportType,
		exportFormat: ExportFormat,
		cols,
	): Promise<Array<ExportColumnValues>> {
		return await this.getReceiptAsGlobalAdminFromApi(exportType, exportFormat).then((res) => {
			const allColumns: Array<ApiExportColumn> = flatten(
				res.TravelAndReceiptColumns.map((column: ApiExportNode) => {
					return column.columns;
				}),
			);
			return this.mergeApiColumnsAndUserSettingsColumns(allColumns, cols);
		});
	}

	public async getReportColumnsPatchedBy(exportType: ExportType, exportFormat: ExportFormat, cols): Promise<Array<ExportColumnValues>> {
		return await this.getReceiptColumnsFromApi(exportType, exportFormat).then((res) => {
			const allColumns: Array<ApiExportColumn> = flatten(
				res.ReportColumns.map((column: ApiExportNode) => {
					return column.columns;
				}),
			);
			return this.mergeApiColumnsAndUserSettingsColumns(allColumns, cols);
		});
	}

	public async getRegularColumnsPatchedBy(exportType: ExportType, exportFormat: ExportFormat, cols): Promise<Array<ExportColumnValues>> {
		return await this.getReceiptColumnsFromApi(exportType, exportFormat).then((res) => {
			const allColumns: Array<ApiExportColumn> = flatten(
				res.Columns.map((column: ApiExportNode) => {
					return column.columns;
				}),
			);
			return this.mergeApiColumnsAndUserSettingsColumns(allColumns, cols);
		});
	}

	public async getTravelColumnsPatchedBy(exportType: ExportType, exportFormat: ExportFormat, cols): Promise<Array<ExportColumnValues>> {
		return await this.getReceiptColumnsFromApi(exportType, exportFormat).then((res) => {
			const allColumns: Array<ApiExportColumn> = flatten(
				res.TravelColumns.map((column: ApiExportNode) => {
					return column.columns;
				}),
			);
			return this.mergeApiColumnsAndUserSettingsColumns(allColumns, cols);
		});
	}

	public async getReceiptAndTravelColumnMixedPatchedBy(
		exportType: ExportType,
		exportFormat: ExportFormat,
		cols,
	): Promise<Array<ExportColumnValues>> {
		return await this.getReceiptColumnsFromApi(exportType, exportFormat).then((res) => {
			const allColumns: Array<ApiExportColumn> = flatten(
				res.TravelAndReceiptColumns.map((column: ApiExportNode) => {
					return column.columns;
				}),
			);
			return this.mergeApiColumnsAndUserSettingsColumns(allColumns, cols);
		});
	}

	public async getCreditCardColumnsAsGlobalAdminPatchedBy(exportFormat: ExportFormat, cols): Promise<Array<ExportColumnValues>> {
		const url = `export/columns/creditcard-statement/${exportFormat}`;
		const creditCardColumnsFromApi = await this.apiService.getFromApi(url).then((value) => {
			const allColumns: Array<ApiExportColumn> = flatten(
				value.data.CreditcardColumns.map((column: ApiExportNode) => {
					return column.columns;
				}),
			);
			return allColumns;
		});
		return this.mergeApiColumnsAndUserSettingsColumns(creditCardColumnsFromApi, cols);
	}

	public async getCustomIntegrationAsGlobalAdminPatchedBy(exportFormat: ExportFormat, cols): Promise<Array<ExportColumnValues>> {
		const url = `export/columns/booking/${exportFormat}`;
		const BookingColumnsFromApi = await this.apiService.getFromApi(url).then((value) => {
			const allColumns: Array<ApiExportColumn> = flatten(
				value.data.BookingColumns.map((column: ApiExportNode) => {
					return column.columns;
				}),
			);
			return allColumns;
		});
		return this.mergeApiColumnsAndUserSettingsColumns(BookingColumnsFromApi, cols);
	}

	public async getCreditCardColumnsPatchedBy(exportFormat: ExportFormat, cols): Promise<Array<ExportColumnValues>> {
		const companyID = this.companyService.getCompanyOfLoggedUser().getID();
		const url = `export/columns/company/${companyID}/user/creditcard-statement/${exportFormat}`;
		const creditCardColumnsFromApi = await this.apiService.getFromApi(url).then((value) => {
			const allColumns: Array<ApiExportColumn> = flatten(
				value.data.CreditcardColumns.map((column: ApiExportNode) => {
					return column.columns;
				}),
			);
			return allColumns;
		});
		return this.mergeApiColumnsAndUserSettingsColumns(creditCardColumnsFromApi, cols);
	}

	public async getCustomIntegrationColumnsPatchedBy(exportFormat: ExportFormat, cols): Promise<Array<ExportColumnValues>> {
		const companyID = this.companyService.getCompanyOfLoggedUser().getID();
		const url = `export/columns/company/${companyID}/user/booking/${exportFormat}`;
		const BookingColumnsFromApi = await this.apiService.getFromApi(url).then((value) => {
			const allColumns: Array<ApiExportColumn> = flatten(
				value.data.BookingColumns.map((column: ApiExportNode) => {
					return column.columns;
				}),
			);
			return allColumns;
		});
		return this.mergeApiColumnsAndUserSettingsColumns(BookingColumnsFromApi, cols);
	}

	getXMLColumns(exportType: string, userRole: ExportTemplateUserRole): Promise<Array<ApiExportNode>> {
		let formattedExportType: string = '';
		switch (exportType) {
			case ExportType.RECEIPT: {
				formattedExportType = 'receipt';
				break;
			}
			case ExportType.BOOKING: {
				formattedExportType = 'booking';
				break;
			}
			case ExportType.CREDITCARD: {
				formattedExportType = 'creditcard-statement';
				break;
			}
			default: {
				throw new Error(`Invalid export type: ${exportType}`);
			}
		}

		return this.apiService.getFromApi(this.getXMLColumnsApiUrl(formattedExportType, userRole)).then((res) => {
			switch (exportType) {
				case ExportType.RECEIPT:
					return res.data.TravelAndReceiptColumns;
				case ExportType.BOOKING:
					return res.data.BookingColumns;
				case ExportType.CREDITCARD:
					return res.data.CreditcardColumns;
			}
		});
	}

	private getXMLColumnsApiUrl(formattedExportType: string, userRole: ExportTemplateUserRole): string {
		switch (userRole) {
			case ExportTemplateUserRole.user:
			// fallthrough
			case ExportTemplateUserRole.company: {
				const companyID = this.companyService.getCompanyOfLoggedUser().getID();
				return `export/columns/company/${companyID}/user/${formattedExportType}/xml`;
			}
			case ExportTemplateUserRole.admin: {
				return `export/columns/${formattedExportType}/xml`;
			}
		}
	}
}
