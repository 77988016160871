import { PercentageAndAmount } from '#/models/currency';
import { AmountWithCurrency } from '#/models/transaction/amountWithCurrency';
import { DeclarationStatusFlag } from '#/models/transaction/receipt';
import { isValueSet } from '#/util/values';
import { ExpenseReport } from '../expense-reports';
import { TransactionType } from '#/models/transaction/transactionType';
import { TravelRoute } from '#/models/transaction/travelRoute';
import { DatesPerAppliedCompensationRule } from '#/services/transaction/compensation-rules.service';
import { OcrTypeModel } from '#/models/ocrType.model';

export interface UIField<T> {
	label?: string;
	isVisibleOnPreTransaction: boolean;
	isEditableOnPreTransaction: boolean;
	isRequiredOnPreTransaction: boolean;
	isVisibleOnSubmit: boolean;
	isEditableOnSubmit: boolean;
	isRequiredOnSubmit: boolean;
	isVisibleOnApprove: boolean;
	isEditableOnApprove: boolean;
	isVisibleOnFinance: boolean;
	isEditableOnFinance: boolean;
	preset: T;
}

export interface InputField {
	user: UIField<string>;
	report: UIField<ExpenseReport>;
	description: UIField<string>;
	amount: UIField<AmountWithCurrency>;
	tipAmount: UIField<AmountWithCurrency>;
	amountWithoutTip: UIField<AmountWithCurrency>;
	vatLines: UIField<Array<PercentageAndAmount>>;
	// useExchangeCurrency: boolean;
	exchangeCurrency: any; // TODO
	purchaseDate: UIField<string>; // YYYY-MM-DD
	dates: UIField<Array<string>>; // YYYY-MM-DD
	merchant: UIField<Array<string>>;
	category: UIField<Array<string>>;
	administration: UIField<Array<string>>;
	costCenter: UIField<Array<string>>;
	costUnit: UIField<Array<string>>;
	project: UIField<Array<string>>;
	country: UIField<Array<string>>;
	invoiceNumber: UIField<Array<string>>;
	paymentMethod: UIField<Array<string>>;
	customPaymentMethod: UIField<Array<string>>;
	accountNumber: UIField<string>;
	dueDate: UIField<string>;

	status: UIField<Array<DeclarationStatusFlag>>;
	authorizationFlow: UIField<void>;
	askForApproval: UIField<void>;
	tags: UIField<Array<string>>;

	travelRoute: UIField<TravelRoute>;
	amountOfMinutes: UIField<number>;
	datesPerAppliedCompensationRule: UIField<DatesPerAppliedCompensationRule>;

	integration: UIField<string>;
	division: UIField<string>;
	journal: UIField<Array<string>>;
	integrationRelation: UIField<Array<string>>;
	paymentCondition: UIField<Array<string>>;
	integrationPaymentMethod: UIField<Array<string>>;
	bookingDate: UIField<string>; // YYYY-MM-DD

	accountingHeaders: UIField<void>;
	accountingBookingLines: UIField<void>;
	bookingTable: UIField<void>;

	latestRemark: UIField<void>;
	addRemark: UIField<void>;
	financeType: UIField<string>;
	folderId: UIField<string>;
}

export type UIFieldKey = keyof InputField;
export enum TransactionViewMode {
	PRE_TRANSACTION = 'preTransaction',
	SUBMIT = 'submit',
	APPROVE = 'approve',
	FINANCE = 'finance',
}

export class InterfaceFrontendModel {
	public id: string;
	public transactionType: TransactionType;
	public name: string;
	public menuCaption: string;
	public allowAttachments: boolean;
	public markNoAttachmentAvailable: boolean;
	public allowedGroups: Array<string>; // User groups
	public visualizeGoogleMaps: boolean;
	public ocrEnabled: boolean;
	public inputFields: InputField;
	public active: boolean;
	public ocrMode: OcrTypeModel;
	public usedForPreTransactions: boolean;
	public ordering: Array<Array<UIFieldKey>>;

	constructor(data = null) {
		if (isValueSet(data)) {
			this.id = data.id;
			this.setData(data);
		}
	}

	public setData(data: any) {
		this.active = data.active;
		this.transactionType = data.transactionType;
		this.name = data.name;
		this.menuCaption = data.menuCaption;
		this.allowAttachments = data.allowAttachments;
		this.allowedGroups = data.allowedGroups;
		this.markNoAttachmentAvailable = data.markNoAttachmentAvailable;
		this.visualizeGoogleMaps = data.visualizeGoogleMaps;
		this.ocrEnabled = data.ocrEnabled;
		this.ocrMode = data.ocrMode;
		this.inputFields = data.inputFields ?? {};
		this.ordering = data.ordering ?? [[]];
	}

	// TODO: Remove this when the API is improved and supports it.
	public matches(string: string): boolean {
		return this.name?.toLowerCase()?.includes(string?.toLowerCase()) || this.menuCaption?.toLowerCase()?.includes(string?.toLowerCase());
	}
}
