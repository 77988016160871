import { Component, Host, Input, Optional } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompanyService } from '#/services/company/company.service';
import { AppSelectOption, FormElementComponent } from '@klippa/ngx-enhancy-forms';
import { AdministrationStat } from '~/app/pages/authenticated/statistics/models/stats';
import { StatisticsService } from '~/app/pages/authenticated/statistics/statistics.service';
import { ReceiptListAPIRequest } from '#/models/transaction/receipt';
import { orderBy } from 'lodash';
import { User } from '#/models/user/user.model';
import { UserService } from '~/app/modules/user/user.service';
import { CompanyAdministrationService } from '#/services/company/company-administration.service';
import { Administration, CompanyAdministrationListAPIRequest } from '#/models/company/administration.model';
import { DynamicOptionsValueAccessorBase } from '~/app/shared/ui/forms/composed/pickers/dynamic/dynamic-options-picker/dynamic-options-value-accessor-base';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Order } from '#/models/utils/order';
import {
	FilterOnGroup,
	FilterOnGroupEnum,
	TransactionOwnerDependantPickerService,
	TransactionOwnerDependentPicker,
} from '#/services/transaction/transaction-owner-dependant-picker.service';
import { stringIsSetAndFilled } from '#/util/values';
import { CompanyUnitService } from '#/services/company/company-unit.service';
import { ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';

@Component({
	selector: 'app-administration-picker',
	templateUrl: './dynamic-options-picker/dynamic-options-picker.template.html',
	styleUrls: ['./dynamic-options-picker/dynamic-options-picker.template.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: AdministrationPickerComponent, multi: true }],
})
export class AdministrationPickerComponent
	extends DynamicOptionsValueAccessorBase<string, Administration>
	implements TransactionOwnerDependentPicker
{
	@Input() public txId: string;
	@Input() public filterOnGroupsOf: FilterOnGroup = FilterOnGroupEnum.LoggedInUser;
	@Input() public showStats = false;
	@Input() public filterOnCompanyGroups = true;
	@Input() public filterOnActives = true;
	@Input() public statsFilters: ReceiptListAPIRequest = null;

	private readonly user: User;
	private readonly companyId: string;
	public fetchItemsFn;
	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		private companyApiService: CompanyService,
		private statisticsService: StatisticsService,
		private userService: UserService,
		private companyAdministration: CompanyAdministrationService,
		private companyUnitService: CompanyUnitService<Administration>,
		private transactionOwnerDependantPickerService: TransactionOwnerDependantPickerService,
	) {
		super(parent, controlContainer);
		this.companyId = companyApiService.getCompanyOfLoggedUser().id;
		this.user = this.userService.getCurrentLoggedUser();
		this.placeholder = _('Select an administration');
	}

	public ngOnInit() {
		super.ngOnInit();
		if (this.showStats && this.statsFilters) {
			this.fetchItemsFn = this.fetchAdministrationStats;
		} else {
			this.fetchItemsFn = this.fetchAdministrations;
		}
	}

	public fetchAdministrationStats = async (start: number, searchQuery: string = ''): ItemsWithHasMoreResultsPromise<Administration> => {
		return this.statisticsService
			.getAdministrationStats(this.statsFilters)
			.then((stats: AdministrationStat[]) => {
				return orderBy(stats, ['Label']);
			})
			.then((res) => {
				return {
					hasMoreResults: false,
					items: res
						.map((e) => {
							return new Administration({ ...e.AdministrationObject, name: `${e.AdministrationObject.name} (${e.totalReceipts})` });
						})
						.filter((e) => e.name.toLowerCase().includes(searchQuery.toLowerCase())),
				};
			});
	};

	public fetchAdministrations = async (start: number, searchQuery: string): ItemsWithHasMoreResultsPromise<Administration> => {
		const filters = new CompanyAdministrationListAPIRequest();
		filters.company = this.companyId;
		if (this.filterOnCompanyGroups) {
			filters.groups = await this.getGroups();
		}
		if (this.filterOnActives) {
			filters.active = true;
		}
		filters.start = start;
		filters.search = searchQuery;
		filters.sort = 'code';
		filters.sortorder = Order.ASCENDING;
		return this.companyAdministration.getCompanyAdministrations(filters).then((res) => ({
			hasMoreResults: res.moreresults,
			items: res.administrations.filter((e) => {
				if (!e.pickable) {
					return false;
				}
				return true;
			}),
		}));
	};

	public fetchSelectedItemsFn = (ids: Array<string>): Promise<Array<Administration>> => {
		const filters = new CompanyAdministrationListAPIRequest();
		filters.company = this.companyId;
		return this.companyAdministration.getAdministrationsByIDs(filters, ids).then((res) => res.administrations);
	};

	public mapToSelectOptionFn = (e: Administration): AppSelectOption => {
		const data = this.companyUnitService.potentiallySimplifyUnitData(e);

		return {
			id: data.id,
			name: [data.code, data.name].filter(stringIsSetAndFilled).join(' - '),
			description: data.description,
			active: data.active,
		};
	};

	public getGroups(): Promise<string[]> {
		return this.transactionOwnerDependantPickerService.getGroups(this.txId, this.filterOnGroupsOf);
	}
}
