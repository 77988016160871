import { Component, inject, TemplateRef, ViewChild } from '@angular/core';
import {
	ActionMenuBtnRefToken,
	CheckBoxRefToken,
	ConfigBtnRefToken,
	SaveBtnRefToken,
	SearchInputRefToken,
	ToggleRefToken,
} from 'wutu-data-table';

/**
 * Used for a trick to force reloading a path. Check route-utils.service.ts
 */

@Component({
	selector: 'app-external-template-deps',
	templateUrl: './externalTemplateDeps.html',
	providers: [
		{
			provide: CheckBoxRefToken,
			useFactory: () => inject(ExternalTemplateDeps, { self: true }).checkbox,
		},
		{
			provide: ConfigBtnRefToken,
			useFactory: () => inject(ExternalTemplateDeps, { self: true }).configBtn,
		},
		{
			provide: ToggleRefToken,
			useFactory: () => inject(ExternalTemplateDeps, { self: true }).toggleBtn,
		},
		{
			provide: SaveBtnRefToken,
			useFactory: () => inject(ExternalTemplateDeps, { self: true }).saveBtn,
		},
		{
			provide: ActionMenuBtnRefToken,
			useFactory: () => inject(ExternalTemplateDeps, { self: true }).actionMenuBtn,
		},
		{
			provide: SearchInputRefToken,
			useFactory: () => inject(ExternalTemplateDeps, { self: true }).searchInput,
		},
	],
})
export class ExternalTemplateDeps {
	@ViewChild('checkbox') checkbox: TemplateRef<any>;
	@ViewChild('configBtn') configBtn: TemplateRef<any>;
	@ViewChild('toggleBtn') toggleBtn: TemplateRef<any>;
	@ViewChild('saveBtn') saveBtn: TemplateRef<any>;
	@ViewChild('actionMenuBtn') actionMenuBtn: TemplateRef<any>;
	public searchQuery: string;
	@ViewChild('searchInput') searchInput: TemplateRef<any>;

	// TODO: hack, because this is called from a random span in the template, not sure how to do this better
	setQuery(query: string) {
		this.searchQuery = query;
	}
}
