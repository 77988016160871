<td class="table-end-left-padding no-click" (click)="$event.stopPropagation()">
	<div class="checkbox">
		<input
			[attr.id]="'receipt-checkbox-' + receipt.id"
			[attr.name]="'receipt-checkbox-' + receipt.id"
			type="checkbox"
			(click)="checkBoxClicked($event)"
			[(ngModel)]="checkboxes[receipt.id]"
			[value]="true"
		/>
		<label [attr.for]="'receipt-checkbox-' + receipt.id"></label>
	</div>
</td>

<td *ngFor="let column of columns" [ngSwitch]="column.id">
	<div *ngSwitchCase="'report'" class="relative mrg-top-15 text-nowrap no-click">
		<div *ngIf="receipt.report && report">
			<a href="/reports/{{ report.id }}">
				<span class="title text-dark text-center">{{ report.description }}</span>
			</a>
		</div>
	</div>

	<div *ngSwitchCase="'description'" class="list-info mrg-top-10">
		<table-row-icon
			*ngIf="!compactMode()"
			[description]="receipt.description"
			[merchant]="receipt.merchant"
			[isTravelDeclaration]="receipt.isTravelDeclaration()"
		>
		</table-row-icon>
		<div class="info" [style.padding-left]="compactMode() ? 0 : ''">
			<span class="title" *ngIf="receipt.getDescription()">{{ receipt.getDescription() }}</span>
			<span class="light-title" *ngIf="!receipt.getDescription() && !receipt.getMerchantID()" translate>No description</span>
			<span class="sub-title" *ngIf="receipt.getMerchantID()">
				<app-merchant-label [MerchantID]="receipt.getMerchantID()"></app-merchant-label
			></span>
		</div>
	</div>

	<div *ngSwitchCase="'user'" class="relative mrg-top-15 text-nowrap">
		<span class="title text-center">{{ receipt.getExpenseStatusUser() }}</span>
	</div>

	<div *ngSwitchCase="'attachments_count'" class="relative mrg-top-15 text-center text-nowrap">
		<span class="title text-center">{{ receipt.getAttachmentCount() }}</span>
	</div>

	<div *ngSwitchCase="'group'" class="relative mrg-top-15">
		<span class="title"><app-folder-label *ngIf="receipt.getGroupID()" [GroupID]="receipt.getGroupID()"></app-folder-label></span>
	</div>

	<div *ngSwitchCase="'purchasedate'" class="mrg-top-15 text-nowrap">
		<span class="title">{{ receipt.getFormattedPurchaseDate() }}</span>
	</div>

	<div *ngSwitchCase="'dueDate'" class="mrg-top-15 text-nowrap">
		<span class="title">{{ receipt.getFormattedDueDate() }}</span>
	</div>

	<div *ngSwitchCase="'updatedate'" class="mrg-top-15 text-nowrap">
		<span class="title">{{ receipt.getUpdateDate() | dateFormat }}</span>
	</div>

	<div *ngSwitchCase="'createdate'" class="mrg-top-15 text-nowrap">
		<span class="title">{{ receipt.getCreateDate() | dateFormat }}</span>
	</div>

	<div *ngSwitchCase="'submit_date'" class="mrg-top-15 text-nowrap">
		<span class="title">{{ receipt.getExpenseStatusDate() | dateFormat }}</span>
	</div>

	<div *ngSwitchCase="'amount'" class="mrg-top-15 text-nowrap">
		<b class="text-dark font-size-16 receipt-amount">
			{{ [receipt.getAmount(), receipt.currency] | convertToAmountWithCurrencyType | amountWithCurrency }}
		</b>
	</div>

	<div *ngSwitchCase="'vat_amount'" class="mrg-top-15 text-nowrap">
		<b class="text-dark font-size-16 receipt-amount">
			{{ [receipt.getVATAmount(), receipt.currency] | convertToAmountWithCurrencyType | amountWithCurrency }}
		</b>
	</div>

	<div *ngSwitchCase="'invoice_number'" class="mrg-top-15 text-nowrap">
		<span class="receipt-amount">{{ receipt.invoice_number }}</span>
	</div>

	<div *ngSwitchCase="'companyadministration'" class="mrg-top-15 text-nowrap">
		<span class="title">
			<app-company-administration-label [administrationId]="receipt.companyadministration"></app-company-administration-label>
		</span>
	</div>

	<div *ngSwitchCase="'expense_status'" class="mrg-top-15 text-nowrap">
		<span class="title text-center">
			<app-receipt-expense-status
				data-cy="expenseStatus"
				[receipt]="receipt"
				[status]="receipt.declarationstatus?.status"
			></app-receipt-expense-status>
		</span>
	</div>

	<div *ngSwitchCase="'current_approver'" class="mrg-top-15 text-nowrap">
		<span class="title">
			<app-receipt-current-approver *ngIf="hasAcceptedStatus()" [receiptId]="receipt.id"></app-receipt-current-approver>
		</span>
	</div>

	<div *ngSwitchCase="'lastexportdate'" class="mrg-top-15 text-nowrap">
		<span class="title" *ngIf="receipt.getLastExportDate()">{{ receipt.getLastExportDate() | dateFormat: true }}</span>
	</div>

	<div *ngSwitchCase="'lastcompanyexportdate'" class="mrg-top-15 text-nowrap">
		<span class="title" *ngIf="receipt.getLastCompanyExportDate()">{{ receipt.getLastCompanyExportDate() | dateFormat: true }}</span>
	</div>

	<div *ngSwitchCase="'finance_type'" class="relative text-center">
		<span class="title text-center receipt-amount-symbol font-size-30">
			<span [class]="receipt.getFinanceType()">{{ receipt.getFinanceTypeSymbol() }}</span>
		</span>
	</div>

	<div *ngSwitchCase="'booking_status'" class="mrg-top-15 text-nowrap no-click">
		<span class="title text-center">
			<app-receipt-booking-status [(receipt)]="receipt" [company]="company" [integrations]="integrations"></app-receipt-booking-status>
		</span>
	</div>

	<div *ngSwitchCase="'paymentmethod'" class="mrg-top-15 text-nowrap">
		<span class="title text-center">
			<app-payment-method-label *ngIf="receipt.paymentmethod" [paymentMethod]="receipt.paymentmethod"></app-payment-method-label>
		</span>
	</div>

	<div *ngSwitchCase="'payment_method_id'" class="mrg-top-15 text-nowrap">
		<span class="title text-center">
			<app-company-payment-method-label
				*ngIf="receipt.payment_method_id"
				[payment_method_id]="receipt.payment_method_id"
			></app-company-payment-method-label>
		</span>
	</div>

	<div *ngSwitchCase="'accountnumber'" class="mrg-top-15 text-nowrap">
		<span class="title text-center">
			{{ receipt.accountnumber }}
		</span>
	</div>

	<div *ngSwitchCase="'companycategory'" class="mrg-top-15 text-nowrap">
		<span class="title text-center">
			<app-company-category-label
				*ngIf="receipt.companycategory"
				[category]="receipt.companycategory"
				[company]="company"
			></app-company-category-label>
		</span>
	</div>

	<div *ngSwitchCase="'companycostcenter'" class="mrg-top-15 text-nowrap">
		<span class="title text-center">
			<app-company-cost-center-label
				*ngIf="receipt.companycostcenter"
				[costcenter]="receipt.companycostcenter"
				[company]="company"
			></app-company-cost-center-label>
		</span>
	</div>

	<div *ngSwitchCase="'companycostunit'" class="mrg-top-15 text-nowrap">
		<span class="title text-center">
			<app-company-cost-unit-label
				*ngIf="receipt.companycostunit"
				[costunit]="receipt.companycostunit"
				[company]="company"
			></app-company-cost-unit-label>
		</span>
	</div>

	<div *ngSwitchCase="'companyproject'" class="mrg-top-15 text-nowrap">
		<span class="title text-center">
			<app-company-project-label
				*ngIf="receipt.companyproject"
				[project]="receipt.companyproject"
				[company]="company"
			></app-company-project-label>
		</span>
	</div>

	<div *ngSwitchDefault class="mrg-top-15 text-nowrap">
		<span class="title text-center" *ngIf="receipt.declarationstatushistory && receipt.declarationstatushistory[0]">{{
			receipt.declarationstatushistory[0].date | dateFormat: true
		}}</span>
	</div>
</td>

<ng-template #duplicateTooltip>
	<app-duplicate-document-warning-deprecated
		[receipt]="receipt"
		(duplicateReceiptChange)="duplicateReceipt = $event"
	></app-duplicate-document-warning-deprecated>
</ng-template>

<td class="table-end-right-padding text-center no-click">
	<div class="mrg-top-10" *ngIf="receipt.shouldShowDuplicateWarning()">
		<div class="duplicateReceiptButtonContainer btn btn-icon btn-flat btn-rounded text-warning" [ngbTooltip]="duplicateTooltip">
			<i class="ti-alert"></i>
			<button
				*ngIf="duplicateReceipt"
				class="duplicateReceiptButton"
				(click)="openReceipt(duplicateReceipt)"
				title="{{ 'Open duplicate receipt' | translate }}"
			></button>
		</div>
	</div>
</td>

<td class="table-end-right-padding text-center no-click">
	<div class="mrg-top-10">
		<button
			class="btn btn-icon btn-flat btn-rounded dropdown-toggle"
			role="button"
			[attr.id]="'receipt-dropdown-' + receipt.id"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
		>
			<i class="ti-more-alt"></i>
		</button>
		<div class="dropdown-menu" [attr.aria-labelledby]="'receipt-dropdown-' + receipt.id">
			<button
				class="dropdown-item"
				*ngIf="tableType === 'personal' && receipt.isExpensable(user) && receipt.userCanSubmitExpense(user) && !isAdministrationOffice()"
				(click)="openSubmitExpensesModal($event)"
				translate
			>
				Submit Expense
			</button>
			<button
				class="dropdown-item"
				*ngIf="tableType === 'personal' && receipt.isExpensable(user) && receipt.userCanSubmitExpense(user) && isAdministrationOffice()"
				(click)="openSubmitExpensesModal($event)"
				translate
			>
				Submit document
			</button>
			<button
				class="dropdown-item"
				*ngIf="tableType === 'personal' && receipt.isExpensable(user) && receipt.userCanRetractExpense(user) && !isAdministrationOffice()"
				(click)="openRetractExpensesModal($event)"
				translate
			>
				Retract Expense
			</button>
			<button
				class="dropdown-item"
				*ngIf="tableType === 'personal' && receipt.isExpensable(user) && receipt.userCanRetractExpense(user) && isAdministrationOffice()"
				(click)="openRetractExpensesModal($event)"
				translate
			>
				Retract document
			</button>
			<button class="dropdown-item" *ngIf="receipt.userCanShare(user)" (click)="openShareModal($event)" translate>Share</button>
			<button
				class="dropdown-item"
				*ngIf="tableType === 'personal' && receipt.userCanEdit(user) && user.getPreferences().groups"
				(click)="openMoveModal($event)"
				translate
			>
				Move
			</button>
			<button class="dropdown-item" (click)="openDownloadImagesModal($event)" *ngIf="receipt.hasImages()" translate>Download images</button>
			<button class="dropdown-item" (click)="openExport.emit(receipt.id)" translate>Export</button>
			<button class="dropdown-item" *ngIf="receipt.userCanEdit(user)" (click)="openReceipt(receipt)" translate>Edit</button>
			<button
				class="dropdown-item"
				*ngIf="(tableType === 'expenses' || tableType === 'invoices') && receipt.isBookable() && user.canBookCompanyReceipts()"
				(click)="openBookModal($event)"
				translate
			>
				Book
			</button>
			<button
				class="dropdown-item"
				*ngIf="tableType === 'personal' || tableType === 'invoices' || financeCanDuplicate()"
				(click)="openDuplicateModal($event)"
				translate
			>
				Duplicate
			</button>
			<button
				class="dropdown-item"
				*ngIf="
					receipt.userCanEdit(user, tableType) &&
					!receipt.hasOCRStatus() &&
					(receipt.hasDocuments() || receipt.hasImages()) &&
					user.getPreferences().ocr &&
					user.canUseOCR()
				"
				(click)="openForceOCRModal($event)"
				translate
			>
				Run OCR scan
			</button>
			<div class="dropdown-divider" *ngIf="(tableType === 'personal' || tableType === 'invoices') && receipt.userCanDelete(user)"></div>
			<button
				class="dropdown-item"
				*ngIf="(tableType === 'personal' || tableType === 'invoices') && receipt.userCanDelete(user)"
				(click)="openDeleteModal($event)"
				translate
			>
				Delete
			</button>
		</div>
	</div>
</td>
