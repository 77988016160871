import {
	apiToFrontend as transactionApiToFrontend,
	frontendToApi as transactionFrontendToApi,
} from '#/models/transaction/transaction/transformer';
import { FixedCompensationFrontendModel } from '#/models/transaction/fixedCompensation/frontendModel';
import { FixedCompensationApiModel } from './apiModel';
import { isValueSet } from '#/util/values';
import { format } from 'date-fns';
import { arrayIsSetAndFilled, removeDuplicatesFromArray } from '#/util/arrays';
import { getEarliestStringDateFromArray } from '#/util/date';

export function apiToFrontend(apiModel: FixedCompensationApiModel, loggedUserId: string): FixedCompensationFrontendModel {
	const result: FixedCompensationFrontendModel = transactionApiToFrontend(
		apiModel,
		loggedUserId,
		FixedCompensationFrontendModel,
	) as FixedCompensationFrontendModel;
	result.datesPerAppliedCompensationRule = isValueSet(apiModel.compensationRules)
		? new Map(Object.entries(apiModel.compensationRules))
		: null;
	result.amountOfMinutes = apiModel.amount_of_minutes;

	const extraDates: Array<string> = arrayIsSetAndFilled(apiModel.traveldeclaration?.extra_dates)
		? apiModel.traveldeclaration.extra_dates.map((e) => format(e, 'yyyy-MM-dd'))
		: [];

	const allDates = removeDuplicatesFromArray([
		...(apiModel.dates ?? []),
		...extraDates,
		...(apiModel.traveldeclaration?.extra_dates_ymd ?? []),
		format(apiModel.purchasedate, 'yyyy-MM-dd'),
		apiModel.purchase_date_ymd,
	]);
	result.dates = allDates;
	return result;
}

export function frontendToApi(frontendModel: FixedCompensationFrontendModel): FixedCompensationApiModel {
	const result: FixedCompensationApiModel = transactionFrontendToApi(frontendModel) as FixedCompensationApiModel;

	result.isinvoice = false;
	if (isValueSet(frontendModel.travelRoute)) {
		result.type = 'travel';
	}

	result.compensationRules = isValueSet(frontendModel.datesPerAppliedCompensationRule)
		? Object.fromEntries(frontendModel.datesPerAppliedCompensationRule)
		: null;
	result.amount_of_minutes = frontendModel.amountOfMinutes;

	if (arrayIsSetAndFilled(frontendModel.dates)) {
		result.purchase_date_ymd = getEarliestStringDateFromArray(frontendModel.dates);
	} else if (isValueSet(frontendModel.purchaseDate)) {
		result.purchase_date_ymd = frontendModel.purchaseDate;
	}

	return result;
}
