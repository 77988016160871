import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CompanyComponent } from '../../../../company.component';
import { User } from '#/models/user/user.model';
import { AddCompanyUser, DeleteCompanyUser, UpdateCompanyUser } from '#/models/company/company.actions';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedCompanySettingsModule } from '../../../shared-company-settings.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UiModule } from '~/app/shared/ui/ui.module';
import { SortablejsModule } from 'ngx-sortablejs';
import { CompanyUI } from '../../../../ui/ui.module';
import { PipesModule } from '~/app/helpers/pipes/pipes.module';
import { Update } from '~/app/modules/user/models/user.actions';
import { UserService } from '~/app/modules/user/user.service';
import { Themed } from '#/providers/themed';
import { MergeUsersFromData, PartialUsersService, UserType } from '#/services/user/partial-users.service';
import { NotificationService } from '~/app/services/notification.service';
import { isValueSet } from '#/util/values';

@Component({
	selector: 'app-user-edit-modal',
	templateUrl: './user-edit-modal.component.html',
	providers: [Themed],
	standalone: true,
	imports: [
		RouterModule,
		CommonModule,
		FormsModule,
		SharedCompanySettingsModule,
		NgSelectModule,
		TranslateModule,
		ReactiveFormsModule,
		CommonModule,
		NgbDatepickerModule,
		UiModule,
		SortablejsModule,
		CompanyUI,
		PipesModule,
	],
})
export class UserEditModalComponent extends CompanyComponent implements OnInit {
	@Input() editUser: User;
	@Input() selectedUsers: [{}];
	@Input() isPartialUser: boolean = false;
	@Output() onDismiss: EventEmitter<void> = new EventEmitter<void>();
	@Output() onSuccess: EventEmitter<void> = new EventEmitter<void>();

	public new = true;
	public editableUser: User = new User({ status: null, companydetails: {} });

	public adjustRole = false;
	public emptyCostcenter = false;
	public emptyGroups = false;
	public emptyModuleLeveledTravelExpenseCompensationPerKM = false;
	public bulkEdit = false;

	public docType: string = _('expenses');

	private usersList = [];
	private totalUsers = 0;
	private succeededUsers = 0;
	private failedUsers = 0;

	constructor(
		public activeModal: NgbActiveModal,
		private userService: UserService,
		public themed: Themed,
		public partialUsersService: PartialUsersService,
		public notificationService: NotificationService,
		private translationService: TranslateService,
	) {
		super();
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.setUpForm();

		if (this.isAdministrationOffice()) {
			this.docType = _('documents');
		}

		this.docType = this.getTranslation(this.docType);
	}

	private setUpForm(): void {
		if (this.isPartialUser) {
			this.new = true;
			this.adjustRole = true;
			this.editableUser.userrole = this.editUser.userrole;
			this.editableUser.name = this.editUser.name;
			this.editableUser.companygroups = this.editUser.companygroups;
			this.editableUser.manager = this.editUser.manager;
			this.editableUser.finance = this.editUser.finance;
			this.editableUser.invoice_submitter = this.editUser.invoice_submitter;
			this.editableUser.invoice_approver = this.editUser.invoice_approver;
			this.editableUser.expense_submitter = this.editUser.expense_submitter;
			this.editableUser.admin = this.editUser.admin;
			this.editableUser.status = true;
			return;
		}
		if (this.getSelectedUserIds().length <= 1 && isValueSet(this.editUser)) {
			this.new = false;
			this.adjustRole = true;
			this.editableUser = this.editUser.clone();
			return;
		}
		if (this.getSelectedUserIds().length >= 2) {
			this.bulkEdit = true;
			this.new = false;
			// This is to bypass form validation.
			this.editableUser.email = 'example@example.com';
			this.editableUser.name = 'example';
			this.editableUser.company_manager_of = [];

			this.getSelectedUsers();
			return;
		}
		this.new = true;
		this.adjustRole = true;
		this.editableUser.status = true;

		if (this.company && this.company.can_use_expenses) {
			this.editableUser.expense_submitter = true;
		} else if (this.company && this.company.canbookinvoices) {
			this.editableUser.invoice_submitter = true;
		}
	}

	public close(): void {
		this.onDismiss.emit();
	}

	public allowCostCenterChange(event): void {
		this.emptyCostcenter = !this.emptyCostcenter;
		if (this.emptyCostcenter) {
			this.editableUser.companydetails.costcenter = null;
		}
	}

	public allowModuleLeveledTravelExpenseCompensationPerKM(event): void {
		this.emptyModuleLeveledTravelExpenseCompensationPerKM = !this.emptyModuleLeveledTravelExpenseCompensationPerKM;
		if (this.emptyModuleLeveledTravelExpenseCompensationPerKM) {
			this.editableUser.companydetails.module_leveled_travel_expense_compensation_per_km = null;
		}
	}

	public allowGroupChange(event): void {
		this.emptyGroups = !this.emptyGroups;
		if (this.emptyGroups) {
			this.editableUser.companygroups = null;
		}
	}

	public allowRoleChange(event): void {
		if (this.editableUser.manager) {
			this.editableUser.managerRole = false;
		}
		if (this.editableUser.finance) {
			this.editableUser.financeRole = false;
		}
		if (this.editableUser.admin) {
			this.editableUser.adminRole = false;
		}
		if (this.editableUser.expense_submitter) {
			this.editableUser.expenseSubmitterRole = false;
		}
		if (this.editableUser.invoice_submitter) {
			this.editableUser.invoiceSubmitterRole = false;
		}
		if (this.editableUser.invoice_approver) {
			this.editableUser.invoiceApproverRole = false;
		}
		this.adjustRole = !this.adjustRole;
	}

	public getSelectedUsers(): void {
		this.users.forEach((user: User): void => {
			for (const key in this.selectedUsers) {
				// we do not allow bulk edit of partial users
				if (user.id === key && this.selectedUsers[key] && user.type !== UserType.Partial) {
					this.usersList.push(user);
				}
			}
		});

		/* Set initial active state in case multiple items have been selected.
      If only non-active items have been selected, initial state: inactive.
      If only active items have been selected, initial state: active.
      In all other cases: active.
     */
		const mapped = this.usersList.map((i) => i.status);
		const states = Array.from(new Set(mapped));
		if (states.length === 1) {
			this.editableUser.status = states[0];
		}
	}

	public async submit(): Promise<void> {
		if (this.new || this.isPartialUser) {
			return this.create();
		}
		if (!this.bulkEdit) {
			return this.edit();
		}
		if (this.bulkEdit) {
			return this.editBulk();
		}
	}

	private create(): void {
		this.companyMiscService
			.createUser(this.company, this.editableUser)
			.then((user) => {
				if (this.isPartialUser) {
					return this.mergeNewUserWithPartialUser(user);
				}

				const message = _('User created successfully');
				this.store.dispatch(new AddCompanyUser(user));
				this.notificationService.success(this.getTranslation(message));
				this.onSuccess.emit();
				this.close();
			})
			.catch((e) => {
				if (e.error.code === 110036) {
					this.notificationService.warning(this.getTranslation(_('User already exists')), {
						timeout: 5000,
						buttons: [
							{
								text: this.getTranslation(_('Invite user to company')),
								action: () => {
									this.companyMiscService
										.inviteUser(this.company, this.editableUser)
										.then((user) => {
											this.notificationService.success(
												this.getTranslation(
													_('The user has been invited to your company. The user has to accept this invitation in their email account.'),
												),
												{
													timeout: 5000,
												},
											);
											this.close();
										})
										.catch(() => {});
								},
							},
						],
					});
				}
			});
	}

	private mergeNewUserWithPartialUser(user: User): any {
		const mergePartialUserData: MergeUsersFromData = {
			sourceUsers: [this.editUser.id],
			targetUser: user.id,
		};
		return this.partialUsersService
			.mergeUsers(this.company.getID(), mergePartialUserData)
			.then(() => {
				this.notificationService.success(this.translationService.instant(_('User successfully updated')));
				this.store.dispatch(new AddCompanyUser(user));
				this.onSuccess.emit();
				this.close();
			})
			.catch((e) => {
				this.notificationService.error(
					this.translationService.instant(_('Something went wrong when updating the selected user. Please contact support for help')),
				);
				this.onDismiss.emit();
				throw e;
			});
	}

	private edit(): void {
		this.companyMiscService
			.patchUser(this.company, this.editableUser)
			.then((user) => {
				const message = _('User updated successfully');
				if (this.userService.getCurrentLoggedUser().id === user.id) {
					this.store.dispatch(new Update({ user }));
				}
				this.store.dispatch(new UpdateCompanyUser(user));
				this.notificationService.success(this.getTranslation(message));
				this.onSuccess.emit();
				this.close();
			})
			.catch((e) => {});
	}

	private async editBulk(): Promise<void> {
		const promiseArray = [];
		this.usersList.forEach((originalUser) => {
			if (this.emptyGroups) {
				originalUser.companygroups = [];
			} else if (this.editableUser.companygroups !== undefined) {
				originalUser.companygroups = this.editableUser.companygroups;
			}

			if (this.emptyCostcenter) {
				originalUser.companydetails.costcenter = null;
			} else if (this.editableUser.companydetails.costcenter !== undefined) {
				originalUser.companydetails.costcenter = this.editableUser.companydetails.costcenter;
			}

			if (this.emptyModuleLeveledTravelExpenseCompensationPerKM) {
				originalUser.companydetails.module_leveled_travel_expense_compensation_per_km = null;
			} else if (
				this.editableUser.companydetails.module_leveled_travel_expense_compensation_per_km !== undefined &&
				this.editableUser.companydetails.module_leveled_travel_expense_compensation_per_km !== null
			) {
				originalUser.companydetails.module_leveled_travel_expense_compensation_per_km =
					this.editableUser.companydetails.module_leveled_travel_expense_compensation_per_km;
			}

			if (this.adjustRole) {
				originalUser.manager = this.editableUser.manager;
				originalUser.finance = this.editableUser.finance;
				originalUser.expense_submitter = this.editableUser.expense_submitter;
				originalUser.invoice_submitter = this.editableUser.invoice_submitter;
				originalUser.invoice_approver = this.editableUser.invoice_approver;
				if (this.userService.getCurrentLoggedUser().getID() !== originalUser.id) {
					originalUser.admin = this.editableUser.admin;
				}

				originalUser.company_manager_of = this.editableUser.company_manager_of;
			}

			if (this.editableUser.status !== null) {
				originalUser.status = this.editableUser.status;
			}

			promiseArray.push(
				Promise.resolve(
					this.companyMiscService
						.patchUser(this.company, originalUser)
						.then((user) => {
							this.totalUsers++;
							this.succeededUsers++;
							this.store.dispatch(new UpdateCompanyUser(user));
						})
						.catch((e) => {
							this.failedUsers++;
							this.totalUsers++;
						}),
				),
			);
		});

		await Promise.all(promiseArray).then((e) => {
			this.translate.set('succeededUsers', this.succeededUsers.toString());
			this.translate.set('totalUsers', this.totalUsers.toString());
			const message = this.translate.instant(_('%succeededUsers% of %totalUsers% have been successfully updated'), {
				succeededUsers: this.succeededUsers.toString(),
				totalUsers: this.totalUsers.toString(),
			});
			this.onSuccess.emit();
			this.notificationService.success(this.getTranslation(message));
			this.close();
		});
	}

	public async confirmDelete(): Promise<void> {
		const promiseArray = [];
		const message = this.translate.instant(_('Are you sure you want to delete this user?'));
		const res = window.confirm(message);
		if (res && this.getSelectedUserIds().length <= 1) {
			this.companyMiscService
				.deleteUser(this.company, this.editableUser)
				.then((r) => {
					this.store.dispatch(new DeleteCompanyUser(this.editableUser));
					this.notificationService.success(this.getTranslation(_('User deleted successfully')));
					this.close();
				})
				.catch((e) => {
					this.apiNotificationService.handleAPIError(e);
				});
		} else if (res && this.getSelectedUserIds().length >= 2) {
			this.usersList.forEach((user) => {
				promiseArray.push(
					Promise.resolve(
						this.companyMiscService
							.deleteUser(this.company, user)
							.then((r) => {
								this.totalUsers++;
								this.succeededUsers++;
								this.store.dispatch(new DeleteCompanyUser(this.editableUser));
							})
							.catch((e) => {
								this.failedUsers++;
								this.totalUsers++;
							}),
					),
				);
			});
			await Promise.all(promiseArray).then((e) => {
				this.notificationService.success(
					this.translate.instant(_('%succeededUsers% of %totalUsers% have been successfully deleted!'), {
						succeededUsers: this.succeededUsers.toString(),
						totalUsers: this.totalUsers.toString(),
					}),
				);
				this.onSuccess.emit();
				this.close();
			});
		}
	}

	public getSelectedUserIds(): Array<string> {
		if (this.selectedUsers !== undefined) {
			return Object.keys(this.selectedUsers).filter((u) => this.selectedUsers[u]);
		} else {
			return [];
		}
	}

	public showUserRoleWarning(): boolean {
		return (
			this.editableUser &&
			this.editableUser.status &&
			!this.editableUser.expense_submitter &&
			!this.editableUser.manager &&
			!this.editableUser.invoice_submitter &&
			!this.editableUser.invoice_approver &&
			!this.editableUser.finance &&
			!this.editableUser.admin &&
			(this.getSelectedUserIds().length === 1 || this.adjustRole)
		);
	}
}
