import { Component, Host, Input, Optional, SimpleChanges } from '@angular/core';
import { CompanyService } from '#/services/company/company.service';
import { AppSelectOption, FormElementComponent } from '@klippa/ngx-enhancy-forms';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompanyCategoryService } from '#/services/company/company-category.service';
import { Category, CategoryTransactionType, CompanyCategoryListAPIRequest } from '#/models/company/category.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
	FilterOnGroup,
	FilterOnGroupEnum,
	TransactionOwnerDependantPickerService,
	TransactionOwnerDependentPicker,
} from '#/services/transaction/transaction-owner-dependant-picker.service';
import { stringIsSetAndFilled } from '#/util/values';
import { Order } from '#/models/utils/order';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { CompanyUnitService } from '#/services/company/company-unit.service';
import { DynamicOptionsValueAccessorBase } from '~/app/shared/ui/forms/composed/pickers/dynamic/dynamic-options-picker/dynamic-options-value-accessor-base';
import { ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';

@Component({
	selector: 'app-category-picker',
	templateUrl: './dynamic-options-picker/dynamic-options-picker.template.html',
	styleUrls: ['./dynamic-options-picker/dynamic-options-picker.template.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: CategoryPickerComponent, multi: true }],
})
export class CategoryPickerComponent extends DynamicOptionsValueAccessorBase<string, Category> implements TransactionOwnerDependentPicker {
	@Input() public filterOnActives: boolean = true;
	@Input() public divisionToFilterOn: string;
	@Input() public typesToFilterOn: Array<CategoryTransactionType> = [];
	@Input() public txId: string;
	@Input() public filterOnGroupsOf: FilterOnGroup = FilterOnGroupEnum.LoggedInUser;

	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		private companyCategoryService: CompanyCategoryService,
		protected companyService: CompanyService,
		private companyUnitService: CompanyUnitService<Category>,
		protected transactionOwnerDependantPickerService: TransactionOwnerDependantPickerService,
	) {
		super(parent, controlContainer);
		this.placeholder = _('Select a category');
	}

	public ngOnChanges(simpleChanges: SimpleChanges) {
		if (simpleChanges.divisionToFilterOn) {
			this._ext_refetchOptions();
			this._ext_deselectInvalidItems();
		}
	}

	public fetchItemsFn = async (start: number, searchQuery: string): ItemsWithHasMoreResultsPromise<Category> => {
		const filters = new CompanyCategoryListAPIRequest();
		filters.start = start;
		filters.search = searchQuery;
		filters.sort = 'code';
		filters.sortorder = Order.ASCENDING;
		if (this.filterOnActives) {
			filters.active = true;
		}
		filters.company = this.companyService.getCompanyOfLoggedUser().id;
		filters.groups = await this.getGroups();
		if (stringIsSetAndFilled(this.divisionToFilterOn)) {
			filters.division = this.divisionToFilterOn;
		}
		if (arrayIsSetAndFilled(this.typesToFilterOn)) {
			filters.types = this.typesToFilterOn;
		}
		return this.companyCategoryService.getCompanyCategories(filters).then((res) => ({
			hasMoreResults: res.moreresults,
			items: res.company_categories,
		}));
	};

	public fetchSelectedItemsFn = (ids: Array<string>): Promise<Array<Category>> => {
		const filters = new CompanyCategoryListAPIRequest();
		filters.company = this.companyService.getCompanyOfLoggedUser().id;
		if (stringIsSetAndFilled(this.divisionToFilterOn)) {
			filters.division = this.divisionToFilterOn;
		}
		return this.companyCategoryService.getCompanyCategoriesByIDs(filters, ids).then((res) => res.company_categories);
	};

	public mapToSelectOptionFn = (e: Category): AppSelectOption => {
		const data = this.companyUnitService.potentiallySimplifyUnitData(e);

		return {
			id: data.id,
			name: [data.code, data.name].filter(stringIsSetAndFilled).join(' - '),
			description: data.description,
			active: data.active,
		};
	};

	public getGroups(): Promise<string[]> {
		return this.transactionOwnerDependantPickerService.getGroups(this.txId, this.filterOnGroupsOf);
	}
}
