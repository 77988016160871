import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { stringIsSetAndFilled } from '#/util/values';
import { PossibleCompanyFeatureFlags } from '#/models/company/possible-feature-flags';
import { UserService } from '~/app/modules/user/user.service';

@Injectable({
	providedIn: 'root',
})
export class CompanyFeatureFlagsService {
	private cache = new Map<string, Array<PossibleCompanyFeatureFlags>>();
	constructor(private apiService: APIService, private userService: UserService) {}

	public async getAllFeatureFlags(companyId: string = null): Promise<Array<PossibleCompanyFeatureFlags>> {
		const compId = stringIsSetAndFilled(companyId) ? companyId : this.userService.getCurrentLoggedUser().company;
		if (!stringIsSetAndFilled(compId)) {
			return Promise.resolve([]);
		}
		if (this.cache.has(compId)) {
			return this.cache.get(compId);
		}
		const flags = await this.apiService.getFromApi(`company/${compId}/featureflags`).then((res) => res.data);
		this.cache.set(compId, flags);
		return this.cache.get(compId);
	}

	public async saveAllFeatureFlags(
		companyId: string,
		flags: Array<PossibleCompanyFeatureFlags>,
	): Promise<Array<PossibleCompanyFeatureFlags>> {
		const savedFlags = await this.apiService
			.patchToApi(`company/${companyId}/featureflags`, {
				flags: flags,
			})
			.then((res) => res.data);
		this.cache.set(companyId, savedFlags);
		return this.cache.get(companyId);
	}

	public async companyHasSpecificFeatureFlagEnabled(featureFlag: PossibleCompanyFeatureFlags, companyId: string = null): Promise<boolean> {
		const _companyId = stringIsSetAndFilled(companyId) ? companyId : this.userService.getCurrentLoggedUser().company;
		const companyFeatureFlags = await this.getAllFeatureFlags(_companyId);
		return companyFeatureFlags.includes(featureFlag);
	}
}
