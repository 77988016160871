import { Component, Input, OnInit } from '@angular/core';
import { CompanyPickerComponent } from '../../../modules/company/components/company-picker.component';

@Component({
	selector: 'app-company-payment-method-label',
	templateUrl: './company-payment-method-label.component.html',
})
export class CompanyPaymentMethodLabelComponent extends CompanyPickerComponent implements OnInit {
	public value: string;

	@Input()
	public payment_method_id = '';

	getCompanyPaymentMethodLabel() {
		const payment_method = this.companyPaymentMethods.find((c) => c.getID() === this.payment_method_id);
		if (payment_method) {
			return payment_method.getName();
		}
		return '';
	}
}
