import { Component, Host, Input, Optional, SimpleChanges, ViewChild } from '@angular/core';
import { AppSelectOption, FormElementComponent } from '@klippa/ngx-enhancy-forms';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AccountingIntegrationV1, IntegrationRelation } from '#/models/company/company.model';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { DynamicOptionsValueAccessorBase } from '~/app/shared/ui/forms/composed/pickers/dynamic/dynamic-options-picker/dynamic-options-value-accessor-base';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CompanyService } from '#/services/company/company.service';
import { CompanyIntegrationService } from '#/services/company/company-integration.service';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { ModalComponent } from '~/app/shared/ui/modal/modal.component';
import { Attachment } from '#/models/transaction/attachment';
import { ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';

@Component({
	selector: 'app-form-integration-relation-picker',
	templateUrl: './integration-relation-picker.component.html', // custom template because of special styling for the options
	styleUrls: ['./integration-relation-picker.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: IntegrationRelationPickerComponent, multi: true }],
})
export class IntegrationRelationPickerComponent extends DynamicOptionsValueAccessorBase<string, IntegrationRelation> {
	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		private companyService: CompanyService,
		private companyIntegrationService: CompanyIntegrationService,
	) {
		super(parent, controlContainer);
		this.placeholder = _('Select a relation');
	}
	@Input() integrationId: string;
	@Input() divisionId: string;
	@Input() attachments: Array<Attachment>;
	@Input() transactionId: string;
	@Input() isInvoice: boolean;
	@ViewChild('addCustomRelation') addCustomRelation: ModalComponent;

	private needsThreeCharactersBeforeSearching: boolean = false;

	public newCustomRelationFieldName: string;
	public supportsCreateCustomRelationPromise: Promise<boolean>;

	public async ngOnChanges(simpleChanges: SimpleChanges): Promise<void> {
		if (this.initiated) {
			if (simpleChanges.integrationId || simpleChanges.divisionId || simpleChanges.selectableItems) {
				this._ext_refetchOptions();
				if (isValueSet(this.picker)) {
					this.picker._ext_deselectInvalidItems();
				}
			}
		}

		const integrations: Array<AccountingIntegrationV1> = await this.companyIntegrationService.getAllIntegrations(
			this.companyService.getCompanyOfLoggedUser().id,
		);

		this.supportsCreateCustomRelationPromise = this.supportsCreateCustomRelation(integrations);
	}

	private hasRequirements(): boolean {
		return stringIsSetAndFilled(this.integrationId) && stringIsSetAndFilled(this.divisionId);
	}

	fetchItemsFn = (start: number, searchQuery: string): ItemsWithHasMoreResultsPromise<IntegrationRelation> => {
		if (!this.hasRequirements()) {
			return;
		}
		this.needsThreeCharactersBeforeSearching =
			this.companyService.getCompanyOfLoggedUser()?.authorizations?.get(this.integrationId)?.integration_configuration?.relation_search ===
			'1';
		if (this.needsThreeCharactersBeforeSearching && !(searchQuery?.length >= 3)) {
			return Promise.resolve({ hasMoreResults: false, items: null });
		}
		return this.companyIntegrationService
			.getIntegrationRelations(this.companyService.getCompanyOfLoggedUser(), this.integrationId, this.divisionId, start, searchQuery)
			.then((res) => {
				return {
					hasMoreResults: res.moreresults,
					items: res.relations,
				};
			});
	};

	fetchSelectedItemsFn = (ids: Array<string>): Promise<Array<IntegrationRelation>> => {
		if (!this.hasRequirements()) {
			return Promise.resolve([]);
		}
		return this.companyIntegrationService.getIntegrationRelationsByIds(
			this.companyService.getCompanyOfLoggedUser().id,
			this.integrationId,
			this.divisionId,
			ids,
		);
	};

	mapToSelectOptionFn = (e: IntegrationRelation): AppSelectOption => {
		return {
			id: e.Identifier,
			name: e.Description,
			// @ts-ignore
			Address: e.Address,
			Phone: e.Phone,
			Email: e.Email,
		};
	};

	getPlaceholderTextFn = (): string => {
		if (this.needsThreeCharactersBeforeSearching) {
			return _('Type at least 3 characters to start searching...');
		}
		return this.placeholder;
	};

	public preselectNewRelation(newRelation: IntegrationRelation): void {
		this.setInnerValueAndNotify(newRelation.id);
	}

	public async supportsCreateCustomRelation(integrations: Array<AccountingIntegrationV1>): Promise<boolean> {
		if (!arrayIsSetAndFilled(integrations)) {
			return false;
		}

		return integrations.some((integration) => {
			if (integration.getKey() === this.integrationId) {
				const capability = integration.getUIDefinition().Capabilities?.CreateCustomRelation;
				if (isValueSet(capability)) {
					return true;
				}
			}
			return false;
		});
	}

	public openCreateCustomRelationModal(): void {
		this.addCustomRelation.openModal();
	}

	public closeCreateCustomRelationModal(): void {
		this.addCustomRelation.closeModal();
	}

	public setNewCustomRelationFieldName(newOptionName: string): void {
		this.newCustomRelationFieldName = newOptionName;
	}

	public canCreateCustomRelation(): boolean {
		return isValueSet(this.divisionId) && isValueSet(this.integrationId);
	}
}
