<div *ngIf="bulkEdit">
	<div class="form-group mb-0 row">
		<div class="col-xxl-12">
			<p translate>
				By entering field values via bulk edit, all selected users will be overwritten with the new values. If you don't want to overwrite a
				value, leave the field empty and the current values will stay in place. If you want to change a field value on all users to empty,
				toggle the 'empty field' switch after the field.
			</p>
			<p translate>Incomplete users cannot be bulk edited</p>
		</div>
	</div>

	<div class="form-group row mb-0">
		<div class="col-xxl-9 offset-xxl-3">
			<div class="row">
				<div class="col-2 offset-10">
					<label class="control-label text-bold text-dark"
						><span translate>Empty</span>
						<app-info-tooltip
							[placement]="'left'"
							class="ml-3"
							title="{{ 'Indicates if the values should be empty for the selected users.' | translate }}"
						></app-info-tooltip
					></label>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="getSelectedUserIds().length <= 1" class="form-group row">
	<label class="col-md-4 control-label text-bold text-dark mrg-top-10" for="name" translate>Name</label>
	<div class="col-md-8">
		<input
			type="text"
			class="form-control"
			id="name"
			name="name"
			[(ngModel)]="editableUser.name"
			[attr.disabled]="environment.feature_flags.disable_edit_user_edit_name ? true : null"
		/>
	</div>
</div>

<div *ngIf="getSelectedUserIds().length <= 1" class="form-group row">
	<label class="col-md-4 control-label text-bold text-dark mrg-top-10" for="email" translate>Email</label>
	<div class="col-md-8">
		<input type="text" class="form-control" id="email" name="email" [(ngModel)]="editableUser.email" [disabled]="!new" />
	</div>
</div>

<div *ngIf="getSelectedUserIds().length <= 1 && !new" class="form-group row">
	<label
		class="col-md-4 control-label text-bold text-dark mrg-top-10"
		for="klippa_email"
		translate
		[translateParams]="{ product_title: themed.getThemeProductTitle() }"
		>%product_title% email</label
	>
	<div class="col-md-8">
		<input
			type="text"
			class="form-control"
			id="klippa_email"
			name="klippa_email"
			[(ngModel)]="editableUser.inboundmailkey + '@' + environment.mail_domain_expenses"
			[disabled]="true"
		/>
	</div>
</div>

<div *ngIf="getSelectedUserIds().length <= 1 && !environment.feature_flags.disable_edit_user_titlelabel" class="form-group row">
	<label class="col-md-4 control-label text-bold text-dark mrg-top-10" for="titlelabel">{{ company.getTitleLabel() }}</label>
	<div class="col-md-8">
		<input type="text" class="form-control" id="titlelabel" name="titlelabel" [(ngModel)]="editableUser.companydetails.title" />
	</div>
</div>

<div *ngIf="getSelectedUserIds().length <= 1 && !environment.feature_flags.disable_edit_user_IBAN" class="form-group row">
	<label class="col-md-4 control-label text-bold text-dark mrg-top-10" for="accountnumber" translate>IBAN</label>
	<div class="col-md-8">
		<input
			type="text"
			class="form-control"
			id="accountnumber"
			name="accountnumber"
			[(ngModel)]="editableUser.companydetails.accountnumber"
		/>
	</div>
</div>

<div class="form-group row" *ngIf="!environment.feature_flags.disable_edit_user_groups">
	<label class="col-md-4 control-label text-bold text-dark mrg-top-10" for="groups">{{ translationReplaceService.groups }}</label>
	<div class="col-md-8">
		<div class="row">
			<div [ngClass]="bulkEdit ? 'col-10' : 'col-12'">
				<app-company-group-picker [disabled]="emptyGroups" id="groups" [(groups)]="editableUser.companygroups"></app-company-group-picker>
			</div>
			<div *ngIf="bulkEdit" class="col-2 toggle-checkbox toggle-primary toggle-sm mrg-top-10">
				<input type="checkbox" name="empty-group" id="empty-group" (click)="allowGroupChange($event)" [(ngModel)]="emptyGroups" />
				<label for="empty-group"></label>
			</div>
		</div>
	</div>
</div>

<div class="form-group row" *ngIf="!environment.feature_flags.disable_edit_user_defaultcostcenter">
	<label
		class="col-md-4 control-label text-bold text-dark mrg-top-10"
		for="accountnumber"
		translate
		[translateParams]="{ item: translationReplaceService.costCenter }"
		>Default %item%</label
	>
	<div class="col-md-8">
		<div class="row">
			<div [ngClass]="bulkEdit ? 'col-10' : 'col-12'">
				<app-company-cost-center-picker
					[disabled]="emptyCostcenter"
					id="costcenter"
					[(costCenter)]="editableUser.companydetails.costcenter"
				></app-company-cost-center-picker>
			</div>
			<div *ngIf="bulkEdit" class="col-2 toggle-checkbox toggle-primary toggle-sm mrg-top-10">
				<input
					type="checkbox"
					name="empty-costcenter"
					id="empty-costcenter"
					(click)="allowCostCenterChange($event)"
					[(ngModel)]="emptyCostcenter"
				/>
				<label for="empty-costcenter"></label>
			</div>
		</div>
	</div>
</div>

<div
	class="form-group row"
	*ngIf="
		company?.modules?.leveled_travel_expense_compensation?.enabled &&
		company?.modules?.leveled_travel_expense_compensation?.user &&
		!environment.feature_flags.disable_edit_user_travelexpense
	"
>
	<label class="col-md-4 control-label text-bold text-dark mrg-top-10" for="leveled_travel_expense_compensation_user">{{
		user.preferences.show_miles_instead_of_km
			? ('Travel expense compensation in cents per mile' | translate)
			: ('Travel expense compensation in cents per kilometer' | translate)
	}}</label>
	<div class="col-md-8">
		<div class="row">
			<div [ngClass]="bulkEdit ? 'col-10' : 'col-12'">
				<input
					*ngIf="user.preferences.show_miles_instead_of_km"
					type="text"
					class="form-control"
					id="leveled_travel_expense_compensation_user"
					name="leveled_travel_expense_compensation_user"
					app-restrict-input="float"
					[(ngModel)]="editableUser.companydetails.moduleLeveledTravelExpenseCompensationPerMile"
					[disabled]="emptyModuleLeveledTravelExpenseCompensationPerKM"
				/>
				<input
					*ngIf="!user.preferences.show_miles_instead_of_km"
					type="text"
					class="form-control"
					id="leveled_travel_expense_compensation_user"
					name="leveled_travel_expense_compensation_user"
					app-restrict-input="float"
					[(ngModel)]="editableUser.companydetails.moduleLeveledTravelExpenseCompensationPerKM"
					[disabled]="emptyModuleLeveledTravelExpenseCompensationPerKM"
				/>
			</div>
			<div *ngIf="bulkEdit" class="col-2 toggle-checkbox toggle-primary toggle-sm mrg-top-10">
				<input
					type="checkbox"
					name="empty-module-leveled-travel-expense-compensation-per-km"
					id="empty-module-leveled-travel-expense-compensation-per-km"
					(click)="allowModuleLeveledTravelExpenseCompensationPerKM($event)"
					[(ngModel)]="emptyModuleLeveledTravelExpenseCompensationPerKM"
				/>
				<label for="empty-module-leveled-travel-expense-compensation-per-km"></label>
			</div>
		</div>
	</div>
</div>

<div *ngIf="getSelectedUserIds().length >= 2" class="form-group row">
	<label class="col-10 col-md-4 control-label text-bold text-dark mrg-top-10" for="role"
		>{{ 'Change user roles' | translate }}
		<app-info-tooltip class="ml-3" title="{{ 'Allows you to change user roles in bulk.' | translate }}"></app-info-tooltip
	></label>
	<div class="col-2 col-md-8">
		<div class="toggle-checkbox toggle-primary checkbox-inline toggle-sm mrg-top-10">
			<input type="checkbox" name="role" id="role" [(ngModel)]="adjustRole" (click)="allowRoleChange($event)" />
			<label for="role"></label>
		</div>
	</div>
</div>

<div class="row">
	<div class="col-md-4 col-6">
		<div class="form-group row" *ngIf="company.canUseExpenses() || editableUser.expense_submitter">
			<label class="col-md-12 control-label text-bold text-dark mrg-top-10" for="expense_submitter"
				><span translate>Expense submitter</span>
				<app-info-tooltip class="ml-3" title="{{ 'A company expense submitter can submit expenses.' | translate }}"></app-info-tooltip
			></label>
		</div>
		<div class="form-group row" *ngIf="company.canUseExpenses() || editableUser.manager">
			<label class="col-md-12 control-label text-bold text-dark mrg-top-10" for="manager"
				><span translate>Expense Manager</span>
				<app-info-tooltip
					class="ml-3"
					title="{{ 'A company expense manager can view, edit and change the status of expenses.' | translate }}"
				></app-info-tooltip
			></label>
		</div>
		<div class="form-group row" *ngIf="company.canUseInvoices() || editableUser.invoice_submitter">
			<label class="col-md-12 control-label text-bold text-dark mrg-top-10" for="invoice_submitter"
				><span translate>Invoice submitter</span>
				<app-info-tooltip class="ml-3" title="{{ 'A company invoice submitter can add invoices.' | translate }}"></app-info-tooltip
			></label>
		</div>
		<div class="form-group row" *ngIf="company.canUseInvoices() || editableUser.invoice_approver">
			<label class="col-md-12 control-label text-bold text-dark mrg-top-10" for="invoice_approver"
				><span translate>Invoice approver</span>
				<app-info-tooltip
					class="ml-3"
					title="{{ 'A company invoice approver can approve invoices up to status approved.' | translate }}"
				></app-info-tooltip
			></label>
		</div>
		<div class="form-group row">
			<label class="col-md-12 control-label text-bold text-dark mrg-top-10" for="finance"
				><span translate>Finance</span>
				<app-info-tooltip
					class="ml-3"
					title="{{
						'With the user role finance approved expense and invoices can be processed into the bookkeeping platform. With just the user role finance you can not approve expenses from other users.'
							| translate
					}}"
				></app-info-tooltip
			></label>
		</div>
		<div class="form-group row">
			<label class="col-md-12 control-label text-bold text-dark mrg-top-10" for="admin"
				><span translate>Admin</span>
				<app-info-tooltip class="ml-3" title="{{ 'A company admin can alter company settings.' | translate }}"></app-info-tooltip
			></label>
		</div>
	</div>

	<div class="col-md-2 col-6">
		<div class="form-group row" *ngIf="company.canUseExpenses() || editableUser.expense_submitter">
			<div class="col-md-12">
				<div class="toggle-checkbox toggle-primary checkbox-inline toggle-sm mrg-top-10">
					<input
						type="checkbox"
						name="expense_submitter"
						id="expense_submitter"
						*ngIf="adjustRole"
						[(ngModel)]="editableUser.expense_submitter"
						[disabled]="!adjustRole"
					/>
					<label for="expense_submitter"></label>
				</div>
			</div>
		</div>
		<div class="form-group row" *ngIf="company.canUseExpenses() || editableUser.manager">
			<div class="col-md-12">
				<div class="toggle-checkbox toggle-primary checkbox-inline toggle-sm mrg-top-5">
					<input
						type="checkbox"
						name="manager"
						id="manager"
						*ngIf="adjustRole"
						[(ngModel)]="editableUser.manager"
						[disabled]="!adjustRole"
					/>
					<label for="manager"></label>
				</div>
			</div>
		</div>
		<div class="form-group row" *ngIf="company.canUseInvoices() || editableUser.invoice_submitter">
			<div class="col-md-12">
				<div class="toggle-checkbox toggle-primary checkbox-inline toggle-sm mrg-top-5">
					<input
						type="checkbox"
						name="invoice_submitter"
						id="invoice_submitter"
						*ngIf="adjustRole"
						[(ngModel)]="editableUser.invoice_submitter"
						[disabled]="!adjustRole"
					/>
					<label for="invoice_submitter"></label>
				</div>
			</div>
		</div>
		<div class="form-group row" *ngIf="company.canUseInvoices() || editableUser.invoice_approver">
			<div class="col-md-12">
				<div class="toggle-checkbox toggle-primary checkbox-inline toggle-sm mrg-top-5">
					<input
						type="checkbox"
						name="invoice_approver"
						id="invoice_approver"
						*ngIf="adjustRole"
						[(ngModel)]="editableUser.invoice_approver"
						[disabled]="!adjustRole"
					/>
					<label for="invoice_approver"></label>
				</div>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-md-12">
				<div class="toggle-checkbox toggle-primary checkbox-inline toggle-sm mrg-top-5">
					<input
						type="checkbox"
						name="finance"
						id="finance"
						*ngIf="adjustRole"
						[(ngModel)]="editableUser.finance"
						[disabled]="!adjustRole"
					/>
					<label for="finance"></label>
				</div>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-md-12">
				<div class="toggle-checkbox toggle-primary checkbox-inline toggle-sm mrg-top-5">
					<input type="checkbox" name="admin" id="admin" *ngIf="adjustRole" [(ngModel)]="editableUser.admin" [disabled]="!adjustRole" />
					<label for="admin"></label>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-6 col-6 d-flex">
		<div class="form-group row d-md-flex align-items-md-center w-100">
			<label class="col-md-4 control-label text-bold text-dark mrg-top-10"><span translate>User can</span></label>
			<div class="col-md-8">
				<div class="my-1" *ngIf="company.canUseExpenses()">
					<app-boolean-badge
						[label]="'Submit %docType%' | translate: { docType: docType }"
						[boolean]="editableUser.canSubmitCompanyReceipts()"
					></app-boolean-badge>
				</div>
				<div class="my-1" *ngIf="company.canUseExpenses()">
					<app-boolean-badge
						[label]="'See %docType%' | translate: { docType: docType }"
						[boolean]="editableUser.canSeeCompanyReceipts()"
					></app-boolean-badge>
				</div>
				<div class="my-1" *ngIf="company.canUseExpenses()">
					<app-boolean-badge
						[label]="'Decline %docType%' | translate: { docType: docType }"
						[boolean]="editableUser.canDeclineCompanyReceipts()"
					></app-boolean-badge>
				</div>
				<div class="my-1" *ngIf="company.canUseExpenses()">
					<app-boolean-badge
						[label]="'Accept %docType%' | translate: { docType: docType }"
						[boolean]="editableUser.canAcceptCompanyReceipts()"
					></app-boolean-badge>
				</div>
				<div class="my-1" *ngIf="company.canUseExpenses()">
					<app-boolean-badge
						[label]="'Approve %docType%' | translate: { docType: docType }"
						[boolean]="editableUser.canApproveCompanyReceipts()"
					></app-boolean-badge>
				</div>
				<div class="my-1" *ngIf="company.canUseInvoices()">
					<app-boolean-badge
						[label]="'Submit invoices' | translate"
						[boolean]="editableUser.canSubmitCompanyInvoices()"
					></app-boolean-badge>
				</div>
				<div class="my-1" *ngIf="company.canUseInvoices()">
					<app-boolean-badge
						[label]="'Approve invoices' | translate"
						[boolean]="editableUser.canApproveCompanyInvoices()"
					></app-boolean-badge>
				</div>
				<div class="my-1" *ngIf="company.canUseInvoices()">
					<app-boolean-badge
						[label]="'Process invoices' | translate"
						[boolean]="editableUser.canProcessCompanyReceipts()"
					></app-boolean-badge>
				</div>
				<div class="my-1" *ngIf="company.canUseInvoices()">
					<app-boolean-badge [label]="'Book invoices' | translate" [boolean]="editableUser.canBookCompanyReceipts()"></app-boolean-badge>
				</div>
				<div class="my-1" *ngIf="company.canUseExpenses()">
					<app-boolean-badge
						[label]="'Process %docType%' | translate: { docType: docType }"
						[boolean]="editableUser.canProcessCompanyReceipts()"
					></app-boolean-badge>
				</div>
				<div class="my-1" *ngIf="company.canUseExpenses()">
					<app-boolean-badge
						[label]="'Book %docType%' | translate: { docType: docType }"
						[boolean]="editableUser.canBookCompanyReceipts()"
					></app-boolean-badge>
				</div>
				<div class="my-1">
					<app-boolean-badge
						[label]="'Manage company' | translate: { docType: docType }"
						[boolean]="editableUser.canManageCompany()"
					></app-boolean-badge>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="!bulkEdit" class="form-group row">
	<label class="col-md-4 control-label text-bold text-dark mrg-top-10" for="active"
		><span translate>Active</span>
		<app-info-tooltip
			class="ml-3"
			title="{{
				'Indicates if the employee has an active account, thereby allowing him or her to log in and declare receipts.' | translate
			}}"
		></app-info-tooltip
	></label>
	<div class="col-md-8">
		<div class="toggle-checkbox toggle-primary checkbox-inline toggle-sm mrg-top-10">
			<input type="checkbox" name="active" id="active" [(ngModel)]="editableUser.status" />
			<label for="active"></label>
		</div>
	</div>
</div>

<div *ngIf="bulkEdit" class="form-group row">
	<label class="col-xxl-3 control-label text-bold text-dark mrg-top-10" for="active" translate>Active</label>
	<div class="col-xxl-9">
		<div class="row">
			<div class="col-6">
				<app-active-state-picker [(active)]="editableUser.status"></app-active-state-picker>
			</div>
		</div>
	</div>
</div>
<div class="alert alert-danger" *ngIf="showUserRoleWarning()" translate>Active users need to have at least one user role.</div>

<div class="modal-footer d-flex justify-content-between">
	<div>
		<button
			*ngIf="!environment.feature_flags.disable_delete_users && !new"
			class="btn btn-outline-danger btn-sm"
			(click)="confirmDelete()"
			translate
		>
			Delete
		</button>
	</div>
	<div>
		<button class="btn btn-default btn-sm" (click)="close()" translate>Cancel</button>
		<button class="btn btn-primary btn-sm" (click)="submit()" [disabled]="showUserRoleWarning()">
			{{ new ? ('Create' | translate) : ('Edit' | translate) }}
		</button>
	</div>
</div>
