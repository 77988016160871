import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company } from '#/models/company/company.model';
import { DeclarationStatus, DeclarationStatusFlag, Receipt } from '#/models/transaction/receipt';
import { ActionsMenuActionEnum, ActionsMenuInterface } from '~/app/shared/ui/actions-menu/actions-menu';
import { ColumnsInterface } from '~/app/shared/ui/table/table';
import { ExpenseReport } from '#/models/transaction/expense-reports';
import { stringIsSetAndFilled, truncateString } from '#/util/values';
import { emptyDate } from '#/util/mongoDB';
import { TransactionInterfaceService } from '#/services/transaction/transaction-interface.service';
import { TransactionType } from '#/models/transaction/transactionType';

@Component({
	selector: '[table-row]', // Needs to be attribute to keep HTML structure required for data-tables.
	templateUrl: './table-row.component.html',
	styleUrls: ['./table-row.component.scss'],
})
export class TableRowComponent implements OnInit {
	// Cells store the final data per columns being rendered.
	public cells = [];
	public showRowActions = false;
	public emptyDate = emptyDate;

	// Columns receive the information of which columns to render, what type they are and what slug is needed.
	@Input() public columns: Array<ColumnsInterface>;
	@Input() public rowActions: ActionsMenuInterface[];
	@Input() public row: Receipt | ExpenseReport;
	@Input() public company: Company;
	@Input() public itemName: string;
	@Input() public itemsName: string;
	@Input() public rowSelected: boolean;

	@Output() toggleRow: EventEmitter<string> = new EventEmitter();
	@Output() clickRow: EventEmitter<string> = new EventEmitter();
	@Output() onRowAction: EventEmitter<{ action: ActionsMenuActionEnum; id: string }> = new EventEmitter();

	constructor(private transactionInterfaceService: TransactionInterfaceService) {}

	public clickRowHandler() {
		this.clickRow.emit(this.row.id);
	}

	public clickOpenReceipt(id: string) {
		this.clickRow.emit(id);
	}

	public toggleRowSelection() {
		this.toggleRow.emit(this.row.id);
	}

	public toggleRowActions() {
		this.showRowActions = !this.showRowActions;
	}

	public rowActionHandler(action: ActionsMenuInterface) {
		const id = this.row.id;
		this.onRowAction.emit({ action: action.action, id });
		this.showRowActions = false;
	}

	public hasAcceptedStatus(): boolean {
		if (this.row instanceof Receipt) {
			return (this.row?.declarationstatus?.status as DeclarationStatusFlag) === DeclarationStatusFlag.Accepted;
		}
		if (this.row instanceof ExpenseReport) {
			return (this.row.expense_status?.current_status?.status as string) === DeclarationStatusFlag.Accepted;
		}
		return false;
	}

	// Add cell context for row data that exists in columns
	addCellContext() {
		this.columns.forEach((column: ColumnsInterface) => {
			if (!column.enabled) {
				return;
			}
			const cell = {};
			cell['type'] = column.type;
			// Switch for types that need multiple values.
			switch (column.type) {
				// Type to use slug also as type.
				case 'slug':
					cell['context'] = this.row[this.row[column.slug]];
					cell['type'] = this.row[column.slug];
					break;
				case 'description':
					cell['context'] = this.row[column.slug];
					cell['merchant'] = this.row[column.merchant_slug];
					cell['compact_mode'] = column.compact_mode;
					break;
				case 'administration':
					cell['context'] = this.row[column.slug];
					break;
				case 'date-range':
					cell['start'] = this.row[column.slug_start];
					cell['end'] = this.row[column.slug_end];
					break;
				case 'link':
					cell['url'] = '/' + column.link_prefix + '/' + this.row[column.link_slug];
					cell['context'] = this.row[column.slug];
					break;
				case 'amount':
					cell['amount'] = this.row[column.slug];
					// get currency per row.
					if (column.currency_slug) {
						cell['currency'] = this.row[column.currency_slug];
					} else {
						cell['currency'] = this.row.currency;
					}
					break;
				case 'vat':
					cell['amount'] = 0;
					if (column.currency_slug) {
						cell['currency'] = this.row[column.currency_slug];
					} // get currency per row.
					if (column.currency) {
						cell['currency'] = column.currency;
					} // get currency from column.
					if (this.row[column?.items_slug]) {
						this.row[column.items_slug].forEach((items) => {
							cell['amount'] += items.amount;
						});
					}
					break;
				case 'text':
					cell['context'] = column.text;
					break;
				case 'total':
					cell['context'] = `${this.row[column.slug]} ${this.itemsName}`;
					break;
				case 'status':
				case 'status_to_show':
					// Status can be either ReceiptDeclarationStatus or DeclarationStatusFlag, we make sure the passed object is ReceiptDeclarationStatus.
					if (!this.row[column.slug]) {
						cell['context'] = null;
						break;
					} // No status
					if (this.row[column.slug].status) {
						cell['context'] = this.row[column.slug];
						cell['authorizationFlow'] = this.row['authorization_flow'];
						break;
					} // ReceiptDeclarationStatus
					const status = new DeclarationStatus();
					status.status = this.row[column.slug];
					cell['context'] = status.status;
					cell['authorizationFlow'] = this.row['authorization_flow'];
					break;
				case 'booking_status': {
					if (column.booking_data_slug) {
						cell['booking_data'] = this.row[column.booking_data_slug];
					}
					if (column.slug) {
						cell['booking_status'] = this.row[column.slug];
					}
					cell['declaration_status'] = this.row[column.declaration_status_slug];
					break;
				}
				case 'document': {
					// @ts-ignore
					cell['show'] = this.row.transaction_interface_type === TransactionType.Receipt;
					if (this.row['attachments_count'] > 0) {
						cell['context'] = 'hasAttachments';
					} else if (this.row['noDocumentAvailable']) {
						cell['context'] = 'noDocumentAvailable';
					} else {
						cell['context'] = 'missing';
					}
					break;
				}
				case 'paymentmethod': {
					cell['context'] = this.row['paymentmethod'];
					break;
				}
				case 'payment_method_id': {
					cell['context'] = this.row['payment_method_id'];
					break;
				}
				default:
					cell['context'] = this.row[column.slug];
			}
			if (stringIsSetAndFilled(cell['context'])) {
				cell['context'] = truncateString(cell['context'], 50);
			}
			this.cells.push(cell);
		});
	}

	ngOnInit(): void {
		this.addCellContext();
	}
}
