import { Component, Host, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { ColumnsService } from '~/app/modules/company/services/exporters/columns.service';
import { v4 as generateId } from 'uuid';
import { isNullOrUndefined, isValueSet } from '#/util/values';
import { ApiExportNode, ExportColumnValues, ExportType } from '#/models/company/exporterInterfaces';
import { FormElementComponent, ValueAccessorBase } from '@klippa/ngx-enhancy-forms';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NodeSettings, TreeNode } from '~/app/shared/ui/custom-xml-export/interfaces/treeNode';
import { TreeDragDropComponent } from '~/app/shared/ui/custom-xml-export/components/tree-drag-drop/tree-drag-drop.component';
import { TreeLogicService } from '~/app/shared/ui/custom-xml-export/services/tree-logic.service';
import { ExportTemplateUserRole } from '~/app/modules/export-templates/models/export-template.model';
import { NotificationService } from '~/app/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
	selector: 'app-custom-xml-export',
	templateUrl: './custom-xml-export.component.html',
	styleUrls: ['./custom-xml-export.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: CustomXmlExportComponent, multi: true }],
})
export class CustomXmlExportComponent extends ValueAccessorBase<Array<ExportColumnValues>> implements OnInit {
	@Input() exportType: ExportType;
	@Input() userRole: ExportTemplateUserRole = null;
	nodes: Array<TreeNode>;
	xmlItemsPromise: Promise<Array<ApiExportNode>>;
	xmlStructure: Array<TreeNode>;

	@ViewChild('xmlStructureTree') xmlStructureTree: TreeDragDropComponent;
	@ViewChild('xmlItemsTree') xmlItemsTree: TreeDragDropComponent;

	constructor(
		private columnsService: ColumnsService,
		private treeLogicService: TreeLogicService,
		private notificationService: NotificationService,
		private translate: TranslateService,
		@Host() @Optional() protected parent: FormElementComponent,
		@Host() @Optional() protected controlContainer: ControlContainer,
	) {
		super(parent, controlContainer);
	}

	async ngOnInit(): Promise<void> {
		super.ngOnInit();
		await this.initXmlItems();
	}

	async initXmlItems() {
		this.xmlItemsPromise = this.getColumns();
		this.nodes = [this.treeLogicService.getCustomFolderNode(), this.treeLogicService.getCustomValueNode()];
		this.nodes.push(...this.buildTreeNodesFromAllowedColumns(await this.xmlItemsPromise, null));
		this.treeLogicService.setStructureTree(this.nodes);
	}

	async getColumns(): Promise<Array<ApiExportNode>> {
		const xmlStructureNodes: Array<ApiExportNode> = await this.columnsService.getXMLColumns(this.exportType, this.userRole);
		return xmlStructureNodes;
	}

	async writeValue(value: Array<ExportColumnValues>) {
		super.writeValue(value);
		if (isValueSet(this.xmlItemsPromise)) {
			await this.xmlItemsPromise;
			this.xmlStructure = this.buildTreeNodesFromExportColumnValues(this.innerValue);
		}
	}

	updateInnerValue(value: Array<TreeNode>) {
		const exportColumnValues = this.buildExportColumnValuesFromTreeNodes(value);
		this.setInnerValueAndNotify(exportColumnValues);
	}

	buildTreeNodesFromAllowedColumns(data: Array<ApiExportNode>, parent: TreeNode): Array<TreeNode> {
		return data.map((exportNode) => {
			const node: TreeNode = {
				id: generateId(),
				type: 'node',
				name: exportNode.folderName,
				label: exportNode.key,
				value: exportNode.key,
				parent: parent,
				children: [],
				settings: new NodeSettings(),
				attachedData: exportNode,
				isNodeToPreventNodeBecomingLeaf: false,
				isCustomValue: false,
			};

			// Create children
			const children = exportNode.columns.map((column) => {
				const child: TreeNode = {
					id: generateId(),
					type: 'leaf',
					name: column.name,
					label: column.label,
					value: column.key,
					parent: node,
					children: [],
					settings: new NodeSettings(),
					attachedData: column,
					isNodeToPreventNodeBecomingLeaf: false,
					isCustomValue: false,
				};
				child.settings.allowedContext = this.getAllowedContext(node);
				return child;
			});

			// Create subFolders recursively
			let subFolders = [];
			if (exportNode.subFolders) {
				subFolders = this.buildTreeNodesFromAllowedColumns(exportNode.subFolders, node);
			}

			// Set the children of the treeNode
			node.children = [...subFolders, ...children];

			// Set allowed context
			node.settings.allowedContext = this.getAllowedContext(node);

			return node;
		});
	}

	buildTreeNodesFromExportColumnValues(data: Array<ExportColumnValues>, parent: TreeNode = null) {
		const treeNodes: Array<TreeNode> = [];
		data?.forEach((exportColumnValue) => {
			if (exportColumnValue.type !== 'Attribute') {
				const correspondingNode = this.findTreeNodeCorrespondingToExportColumn(exportColumnValue, this.nodes);

				if (isNullOrUndefined(correspondingNode)) {
					return;
				}

				const exportColumnAttributes: Array<ExportColumnValues> = this.getExportColumnAttributes(exportColumnValue);
				const nodeAttributes = exportColumnAttributes?.map((exportCol) => {
					const isDynamicValue = exportCol.sub_column[0].type === 'Value';
					const attributeValue = isDynamicValue
						? this.treeLogicService.findNodeInStructureTreeByValue(exportCol.sub_column[0].value)
						: exportCol.sub_column[0].value; // Either get the TreeNode for the dynamic attribute or the value of the attribute itself.
					return {
						name: exportCol.custom_label,
						value: attributeValue,
						isDynamicValue: isDynamicValue,
					};
				});

				const contextValues = exportColumnValue.context.split(',').slice(1);
				const allowedContext = this.getAllowedContext(correspondingNode);
				const contextSettings = contextValues.map((contextValue) => {
					return allowedContext.find((contextNode) => contextNode.value === contextValue);
				});

				const nodeSettings: NodeSettings = {
					custom_label: exportColumnValue.custom_label,
					context: contextSettings.length > 0 ? contextSettings : [],
					allowedContext: allowedContext,
					value: exportColumnValue.type === 'Attribute' ? exportColumnValue.value : null,
					dateFormat: exportColumnValue.date_format,
					attributes: nodeAttributes,
				};

				const value = exportColumnValue.type === 'Node' ? exportColumnValue.context.split(',')[0] : exportColumnValue.value;

				const treeNode: TreeNode = {
					attachedData: correspondingNode.attachedData,
					children: [],
					id: generateId(),
					isCustomValue: exportColumnValue.type === 'CustomColumn',
					isNodeToPreventNodeBecomingLeaf: false,
					label: correspondingNode.label,
					name: correspondingNode.name,
					parent: parent,
					settings: nodeSettings,
					type: correspondingNode.type,
					value: value,
				};

				let treeNodeChildren = [];
				if (exportColumnValue.sub_column) {
					treeNodeChildren = this.buildTreeNodesFromExportColumnValues(exportColumnValue.sub_column, treeNode);
				}

				treeNode.children = treeNodeChildren;
				treeNodes.push(treeNode);
			}
		});
		return treeNodes;
	}

	buildExportColumnValuesFromTreeNodes(nodes: Array<TreeNode>): Array<ExportColumnValues> {
		const xmlExportStructure = [];
		nodes?.forEach((node) => {
			if (!node.isNodeToPreventNodeBecomingLeaf) {
				let nodeType;
				if (node.isCustomValue) {
					nodeType = 'CustomColumn';
				} else if (node.type === 'leaf') {
					nodeType = 'Value';
				} else if (node.type === 'node') {
					nodeType = 'Node';
				}

				const attributeNodes = [];
				if (node.settings.attributes) {
					node.settings.attributes.forEach((attribute) => {
						let dynamicAttributeSubcolumns: Array<ExportColumnValues>;
						if (attribute.isDynamicValue) {
							const dynamicAttributeNode: TreeNode = attribute.value as TreeNode;
							dynamicAttributeSubcolumns = [
								{
									type: 'Value',
									label: dynamicAttributeNode.label,
									custom_label: '',
									value: dynamicAttributeNode.value,
									enabled: true,
									add_currency_symbol: false,
									date_format: '',
								},
							];
						} else {
							dynamicAttributeSubcolumns = [
								{
									type: 'CustomColumn',
									label: null,
									custom_label: '',
									value: attribute.value,
									enabled: true,
									add_currency_symbol: false,
									date_format: '',
								},
							];
						}
						const attributeNode: ExportColumnValues = {
							type: 'Attribute',
							label: 'Attribute',
							custom_label: attribute.name,
							enabled: true,
							add_currency_symbol: false,
							date_format: '',
							sub_column: dynamicAttributeSubcolumns,
						};
						attributeNodes.push(attributeNode);
					});
				}

				const subColumnNodes = [...attributeNodes, ...this.buildExportColumnValuesFromTreeNodes(node.children)];

				const xmlExportColumn: ExportColumnValues = {
					type: nodeType,
					label: node.label,
					custom_label: node.settings.custom_label,
					enabled: true,
					add_currency_symbol: false,
					date_format: node.settings.dateFormat,
					sub_column: subColumnNodes.length > 0 ? subColumnNodes : null,
				};

				if (nodeType === 'Node') {
					if (node.settings.context?.length > 0) {
						xmlExportColumn.context = node.value + ',' + node.settings.context.map((contextNode) => contextNode.value).join(',');
					} else {
						xmlExportColumn.context = node.value;
					}
				} else {
					xmlExportColumn.value = node.settings.value || node.value;
				}

				xmlExportStructure.push(xmlExportColumn);
			}
		});
		return xmlExportStructure;
	}

	findTreeNodeCorrespondingToExportColumn(exportColumn: ExportColumnValues, treeNodes: Array<TreeNode>): TreeNode {
		// The ExportColumnValue doesn't have all the necessary data to build TreeNodes.
		// We match an ExportColumnValue with a TreeNode from the provided TreeNodes array to get all the necessary data.
		let result;
		treeNodes.forEach((node) => {
			if (
				exportColumn.type === 'Node' &&
				(node.value === exportColumn.context.split(',')[0] || (node.label === 'custom_folder' && exportColumn.label === 'custom_folder'))
			) {
				result = node;
			} else if (node.value === exportColumn.value || (node.label === 'custom_value' && exportColumn.label === 'custom_value')) {
				result = node;
			}
			if (node.children) {
				const subResult = this.findTreeNodeCorrespondingToExportColumn(exportColumn, node.children);
				if (subResult) {
					result = subResult;
				}
			}
		});
		return result;
	}

	getExportColumnAttributes(exportColumn: ExportColumnValues): Array<ExportColumnValues> {
		return exportColumn.sub_column?.filter((col) => col.type === 'Attribute');
	}

	getAllowedContext(node: TreeNode): Array<TreeNode> {
		const allowedContext: Array<TreeNode> = [];
		if (isValueSet(node.children)) {
			node.children
				.filter((child) => child.type === 'node')
				.forEach((child) => {
					const allowedContextOfChild: Array<TreeNode> = [];
					if (isValueSet(child.children)) {
						allowedContextOfChild.push(...this.getAllowedContext(child));
					}
					allowedContext.push(child, ...allowedContextOfChild);
				});
		}
		return allowedContext;
	}

	neverAllowDrop(srcNode: TreeNode, destNode: TreeNode, treeNodes: Array<TreeNode>): boolean {
		return false;
	}

	allowDrop = (srcNode: TreeNode, destNode: TreeNode, treeNodes: Array<TreeNode>): boolean => {
		if (this.disabled) {
			// If the component is disabled within a form, don't allow dropping
			return false;
		}

		const allowDrop = this.treeLogicService.allowDropBuilderTree(srcNode, destNode, treeNodes);
		if (!allowDrop) {
			this.notificationService.error(this.translate.instant(_('You are not allowed to drop that node there')));
		}
		return allowDrop;
	};

	deleteNodeFromXmlStructureTree(treeNode: TreeNode) {
		this.xmlStructureTree.deleteNode(treeNode);
	}

	toggleExpandStateStructureTree() {
		this.xmlStructureTree.toggleExpansionForAllNodes();
	}

	isStructureTreeExpanded(): boolean {
		return this.xmlStructureTree?.IsExpanded();
	}

	toggleExpandStateItemsTree() {
		this.xmlItemsTree.toggleExpansionForAllNodes();
	}

	isItemsTreeExpanded(): boolean {
		return this.xmlItemsTree?.IsExpanded();
	}
}
