import { Component, Host, Input, Optional } from '@angular/core';
import { AppSelectOption, FormElementComponent } from '@klippa/ngx-enhancy-forms';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DynamicOptionsValueAccessorBase } from '~/app/shared/ui/forms/composed/pickers/dynamic/dynamic-options-picker/dynamic-options-value-accessor-base';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Merchant } from '#/models/merchant';
import { MerchantService } from '~/app/modules/merchant/merchant.service';
import { ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';
import { stringIsSetAndFilled, stringOrArrayIsSetAndEmpty, useIfArrayIsSetWithOneItem } from '#/util/values';
import { arrayIsSetAndFilled } from '#/util/arrays';

@Component({
	selector: 'app-form-merchant-picker',
	templateUrl: '/merchant-picker.component.html',
	styleUrls: ['/merchant-picker.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: MerchantPickerComponent, multi: true }],
})
export class MerchantPickerComponent extends DynamicOptionsValueAccessorBase<string, Merchant> {
	@Input() public filterOnMerchantIds: string[];

	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		private merchantService: MerchantService,
	) {
		super(parent, controlContainer);
		this.placeholder = _('Select a merchant');
	}

	fetchItemsFn = (start: number, searchQuery: string = ''): ItemsWithHasMoreResultsPromise<Merchant> => {
		const filterMerchantsFn = (e) => {
			if (!stringIsSetAndFilled(e.title)) {
				return false;
			}
			return e.title?.toLowerCase().includes(searchQuery.toLowerCase());
		};

		return this.merchantService.getAllAvailableMerchants().then((res: Array<Merchant>) => {
			let filteredMerchants: Array<Merchant> = res.filter(filterMerchantsFn);

			if (arrayIsSetAndFilled(this.filterOnMerchantIds)) {
				const tempMerchants: Array<Merchant> = filteredMerchants.filter((item: Merchant) => this.filterOnMerchantIds.includes(item.id));
				if (stringOrArrayIsSetAndEmpty(this.innerValue)) {
					this.setInnerValueAndNotify(useIfArrayIsSetWithOneItem(tempMerchants)?.id);
				}
				filteredMerchants = tempMerchants;
			}
			return {
				hasMoreResults: false,
				items: filteredMerchants,
			};
		});
	};

	fetchSelectedItemsFn = async (ids: Array<string>): Promise<Array<Merchant>> => {
		return Promise.all(ids.map((id: string) => this.merchantService.getMerchant(id).then((merchant: Merchant) => merchant)));
	};

	mapToSelectOptionFn = (e: Merchant): AppSelectOption => {
		const name: string = stringIsSetAndFilled(e?.title) ? e.title : e?.synonyms?.[0];
		return {
			id: e?.id,
			name: name ?? 'UNKNOWN',
		};
	};

	public async createCustomMerchant(newMerchantName: string): Promise<void> {
		const newMerchant: Merchant = new Merchant({
			title: newMerchantName,
			synonyms: [],
			reviewed: false,
			ignored: false,
		});
		const newlyCreatedMerchantId: string = (await this.merchantService.createMerchant(newMerchant))?.id;

		if (this.multiple && arrayIsSetAndFilled(this.innerValue)) {
			this.setInnerValueAndNotify([newlyCreatedMerchantId, ...this.innerValue]);
		} else {
			this.setInnerValueAndNotify(newlyCreatedMerchantId);
		}
	}
}
