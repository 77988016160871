import { ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AppSelectOption, MultipleValueAccessorBase } from '@klippa/ngx-enhancy-forms';
import { DynamicOptionsPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/dynamic-options-picker/dynamic-options-picker.component';

@Component({
	selector: '',
	template: '',
})
export abstract class DynamicOptionsValueAccessorBase<T, S> extends MultipleValueAccessorBase<T> {
	protected initiated: boolean = false;
	@ViewChild(DynamicOptionsPickerComponent) picker: DynamicOptionsPickerComponent<S>;
	@Input() clearable = true;
	@Input() placeholder: string;
	@Input() prefix: string;
	@Input() newOptionFooterPrefix: string;
	@Input() selectableItems: Array<S>;
	@Input() dropdownPosition: 'top' | 'bottom' | 'left' | 'right' | 'auto' = null;
	@Input() orientation: 'vertical' | 'horizontal' = 'horizontal';
	@Input() truncateOptions = false;
	@Input() autoSelectOnSingleResult = false;
	@Output() onOpened: EventEmitter<void> = new EventEmitter<void>();
	@Output() onClosed: EventEmitter<void> = new EventEmitter<void>();
	@Output() onFooterClick: EventEmitter<string> = new EventEmitter<string>();
	@Input() customSearchFn: (term: string, item: { id: string; name: string; description: string }) => boolean = () => true;

	ngOnInit() {
		super.ngOnInit();
		this.initiated = true;
	}

	public abstract fetchItemsFn(start: number, searchQuery: string): ItemsWithHasMoreResultsPromise<S>;
	public abstract fetchSelectedItemsFn(ids: Array<T>): Promise<Array<S>>;
	public abstract mapToSelectOptionFn(e: S): AppSelectOption;
	public getPlaceholderTextFn = (): string => {
		return this.placeholder;
	};
	public _ext_refetchOptions(): void {
		this.picker?._ext_refetchOptions();
	}
	public _ext_deselectInvalidItems(): void {
		this.picker?._ext_deselectInvalidItems();
	}

	public sortValuesFn(a: S, b: S) {
		return 0;
	}

	public _onFooterClick($event): void {
		this.onFooterClick.emit($event);
	}
}
