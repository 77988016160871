import { Injectable } from '@angular/core';
import { UserService } from '~/app/modules/user/user.service';
import { APIService } from '~/app/api/services/api.service';
import { CompanyService } from '#/services/company/company.service';

@Injectable({
	providedIn: 'root',
})
export class ImpersonationService {
	constructor(private userService: UserService, private companyService: CompanyService, private apiService: APIService) {}

	public hasImpersonationSettings(): boolean {
		const company = this.companyService.getCompanyOfLoggedUser();

		if (!company?.modules?.impersonation?.enabled) {
			return false;
		}
		const user = this.userService.getCurrentLoggedUser();
		if (user.hasCompanyManagerRole() || user.hasCompanyFinanceRole() || user.invoiceApproverRole || user.invoiceSubmitterRole) {
			return true;
		}
		return false;
	}

	public isCompanyAdminAllowedToImpersonate(): boolean {
		const company = this.companyService.getCompanyOfLoggedUser();
		const user = this.userService.getCurrentLoggedUser();

		return user.hasCompanyAdminRole() && company?.modules?.impersonation?.adminsCanImpersonateUsers;
	}

	public async getImpersonationSettings(): Promise<{ [k in string]: number }> {
		const myUser = await this.userService.getCurrentUserParsed();
		return myUser.allowImpersonation;
	}

	public async saveImpersonationSettings(impersonators: { [k in string]: number }): Promise<{ [k in string]: number }> {
		const myUser = await this.userService.getCurrentUserParsed();
		myUser.allowImpersonation = impersonators;
		return this.userService.updateUser(myUser).then((res) => res.allowImpersonation);
	}

	public async getListOfPeopleICanImpersonate(): Promise<Array<string>> {
		if (!(await this.hasImpersonationSettings())) {
			return [];
		}
		return this.apiService.getFromApi(`user/impersonate`).then((res) => res.data.users);
	}

	public async impersonate(userId: string): Promise<void> {
		return this.apiService.postToApi(`company/${this.userService.getCurrentLoggedUser().company}/user/${userId}/impersonate`);
	}
}
