import { useIfArrayIsSetWithOneItem, useIfStringIsSet } from '#/util/values';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { PreTransactionFrontendModel } from './frontendModel';
import { PreTransactionApiModel } from './apiModel';
import { Attachment } from '#/models/transaction/attachment';

export function apiToFrontend(
	apiModel: PreTransactionApiModel,
	loggedUserId: string,
	transactionClass: typeof PreTransactionFrontendModel,
): PreTransactionFrontendModel {
	const result = new transactionClass();
	result.id = apiModel.id;
	result.user = apiModel.user;
	result.description = apiModel.description;
	result.transactionInterfaceId = apiModel.transactionInterfaceId;
	if (apiModel.originalCurrency) {
		result.useExchangeCurrency = true;
		result.exchangeCurrency = {
			fromAmountWithCurrency: {
				amount: apiModel.originalAmount,
				currency: apiModel.originalCurrency,
			},
			exchangeRate: apiModel.exchangeRate,
			toAmountWithCurrency: {
				amount: apiModel.amount,
				currency: apiModel.currency,
			},
		};
	} else {
		result.useExchangeCurrency = false;
		result.amount = {
			amount: apiModel.amount,
			currency: apiModel.currency !== 'XXX' ? apiModel.currency : null,
		};
	}

	if (arrayIsSetAndFilled(apiModel.vatItems)) {
		result.vatLines = apiModel.vatItems.map((e) => ({
			percentage: e.vatPercentage / 100,
			amountWithCurrency: {
				amount: e.amount,
				currency: useIfStringIsSet(e.currency) ?? useIfStringIsSet(apiModel.originalCurrency) ?? apiModel.currency,
			},
		}));
	} else {
		result.vatLines = [];
	}

	result.purchaseDate = apiModel.purchaseDate;

	result.merchant = useIfStringIsSet(apiModel.merchant);
	result.project = useIfStringIsSet(apiModel.project);
	result.costUnit = useIfStringIsSet(apiModel.costUnit);
	result.costCenter = useIfStringIsSet(apiModel.costCenter);
	result.administration = useIfStringIsSet(apiModel.administration);
	result.category = useIfStringIsSet(apiModel.category);
	result.attachments = useIfArrayIsSetWithOneItem([
		apiModel.ocrEnhancedFiles.map((e) => {
			return Attachment.fromOcrEnhancedFile(e);
		}),
	]);

	return result;
}

export function frontendToApi(frontendModel: PreTransactionFrontendModel): PreTransactionApiModel {
	// TODO dont set values if they are undefined. Null is fine though
	const result = new PreTransactionApiModel();
	result.id = frontendModel.id;
	result.user = frontendModel.user;
	result.description = frontendModel.description;
	result.transactionInterfaceId = frontendModel.transactionInterfaceId;

	result.merchant = frontendModel.merchant;
	result.project = frontendModel.project;
	result.costUnit = frontendModel.costUnit;
	result.costCenter = frontendModel.costCenter;
	result.administration = frontendModel.administration;
	result.category = frontendModel.category;

	result.purchaseDate = frontendModel.purchaseDate;

	if (frontendModel.useExchangeCurrency) {
		result.amount = Math.round(frontendModel.exchangeCurrency.toAmountWithCurrency?.amount);
		result.currency = frontendModel.exchangeCurrency.toAmountWithCurrency?.currency;
		result.exchangeRate = frontendModel.exchangeCurrency.exchangeRate;
		result.originalCurrency = frontendModel.exchangeCurrency.fromAmountWithCurrency?.currency;
		result.originalAmount = frontendModel.exchangeCurrency.fromAmountWithCurrency?.amount;
	} else {
		result.amount = frontendModel.amount?.amount;
		result.currency = frontendModel.amount?.currency;
	}

	if (arrayIsSetAndFilled(frontendModel.vatLines)) {
		result.vatItems = frontendModel.vatLines
			.filter((e) => {
				return Number.isFinite(e.percentage) || Number.isFinite(e.amountWithCurrency.amount);
			})
			.map((e) => ({
				amount: e.amountWithCurrency?.amount,
				vatPercentage: e.percentage * 100, // bug in BE, it wants it multiplied by 100
				currency: useIfStringIsSet(frontendModel.exchangeCurrency?.toAmountWithCurrency?.currency) ?? frontendModel.amount?.currency,
			}));
	}
	if (arrayIsSetAndFilled(frontendModel.attachments)) {
		result.ocrEnhancedFiles = frontendModel.attachments.map((attachment: Attachment) => attachment.id);
	}

	return result;
}
