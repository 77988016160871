<klp-form-select
	[options]="paymentMethods"
	[(ngModel)]="innerValue"
	(ngModelChange)="setInnerValueAndNotify($event)"
	placeholder="{{ 'Select payment method' | translate }} "
	[ngClass]="{ showErrors: isInErrorState() }"
	[multiple]="multiple"
	[clearable]="true"
	[disabled]="disabled"
>
</klp-form-select>
