import { Injectable } from '@angular/core';
import { environment } from '~/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthManagerService } from '../../services/auth-manager.service';
import { UpdateKey } from '../../modules/user/models/user.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { Router } from '@angular/router';
import { LoginModalService } from '~/app/services/login-modal.service';
import { MfaConfigModalService } from '~/app/services/mfa-config-modal.service';
import { firstValueFrom, noop } from 'rxjs';

interface Header {
	key: string;
	value: string;
}

const API_PREFIX = '/api/v1/';

const unresolvedPromise = new Promise(noop);

@Injectable({
	providedIn: 'root',
})
export class APIService {
	static BASE_URL = environment.api_url;

	static ERROR_UNKNOWN_KEY = 100009;
	static ERROR_TOKEN_EXPIRED = 100014;

	private refreshPromise: Promise<any>;
	private requestCache = new Map<string, { timestamp: number; data: Promise<any> }>();

	constructor(
		protected http: HttpClient,
		public authManagerService: AuthManagerService,
		protected store: Store<AppState>,
		private router: Router,
		private loginModalService: LoginModalService,
		private mfaModalService: MfaConfigModalService,
	) {
		this.refreshPromise = null;
	}

	static handleErrorResponse(httpErrorResponse: HttpErrorResponse) {
		return Promise.reject(httpErrorResponse);
	}

	public clearCache() {
		this.requestCache.clear();
	}

	getAuthManagerService() {
		return this.authManagerService;
	}

	async headers(authManagerService: AuthManagerService = this.authManagerService, auth: boolean): Promise<HttpHeaders> {
		if (auth) {
			return await authManagerService.getHeaders();
		} else {
			return new HttpHeaders({ 'X-Api-Version': AuthManagerService.API_VERSION });
		}
	}

	async getFromApi(
		url,
		fromCacheIfNewerThanMsAgo: number = 0,
		authManagerService: AuthManagerService = this.authManagerService,
		auth: boolean = true,
		extraHeaders?: Header[],
	) {
		return this.get(API_PREFIX + url, fromCacheIfNewerThanMsAgo, authManagerService, auth, extraHeaders);
	}

	async get(
		url,
		fromCacheIfNewerThanMsAgo: number = 0,
		authManagerService: AuthManagerService = this.authManagerService,
		auth: boolean = true,
		extraHeaders?: Header[],
		retryTimes: number = 10,
	) {
		if (retryTimes === 0) {
			throw new Error('max retry reached');
		}

		if (this.hasInCache(url, fromCacheIfNewerThanMsAgo)) {
			return this.getFromCache(url);
		}

		let result;
		try {
			const resultPromise = this.headers(authManagerService, auth).then((headers) => {
				if (extraHeaders) {
					headers = extraHeaders.reduce((_, header) => headers.set(header.key, [header.value]), headers);
				}
				return firstValueFrom(this.http.get(APIService.BASE_URL + url, { headers: headers }));
			});
			this.putInCache(url, resultPromise);
			result = await resultPromise;
		} catch (e) {
			await this.handleError(e, authManagerService);
			result = await this.get(url, fromCacheIfNewerThanMsAgo, authManagerService, auth, extraHeaders, retryTimes - 1);
		}
		return result;
	}

	public async getBlobFromApi(
		url,
		authManagerService: AuthManagerService = this.authManagerService,
		auth: boolean = true,
		retryTimes: number = 10,
	): Promise<Blob> {
		return this.getBlob(API_PREFIX + url, authManagerService, auth, retryTimes);
	}

	async getBlob(url, authManagerService: AuthManagerService = this.authManagerService, auth: boolean = true, retryTimes: number = 10) {
		if (retryTimes === 0) {
			throw new Error('max retry reached');
		}
		const headers = await this.headers(authManagerService, auth);

		try {
			return await firstValueFrom(this.http.get(APIService.BASE_URL + url, { headers: headers, responseType: 'blob' }));
		} catch (e) {
			await this.handleError(e, authManagerService);
			return await this.getBlob(url, authManagerService, auth, retryTimes - 1);
		}
	}

	async postBlob(
		url,
		body,
		authManagerService: AuthManagerService = this.authManagerService,
		auth: boolean = true,
		retryTimes: number = 10,
	): Promise<Blob> {
		if (retryTimes === 0) {
			throw new Error('max retry reached');
		}
		const headers = await this.headers(authManagerService, auth);

		try {
			return await firstValueFrom(this.http.post(APIService.BASE_URL + url, body, { headers: headers, responseType: 'blob' }));
		} catch (e) {
			await this.handleError(e, authManagerService);
			return await this.postBlob(url, body, authManagerService, auth, retryTimes - 1);
		}
	}

	async postToApi(
		url,
		body = {},
		fromCacheIfNewerThanMsAgo: number = 0,
		authManagerService: AuthManagerService = this.authManagerService,
		auth: boolean = true,
		extraHeaders?: Header[],
	) {
		return this.post(API_PREFIX + url, body, fromCacheIfNewerThanMsAgo, authManagerService, auth, extraHeaders);
	}

	async post(
		url,
		body,
		fromCacheIfNewerThanMsAgo: number = 0,
		authManagerService: AuthManagerService = this.authManagerService,
		auth: boolean = true,
		extraHeaders?: Header[],
		retryTimes: number = 10,
	) {
		if (retryTimes === 0) {
			throw new Error('max retry reached');
		}

		const cacheKey = 'POST~' + url + JSON.stringify(body);
		if (this.hasInCache(cacheKey, fromCacheIfNewerThanMsAgo)) {
			return this.getFromCache(cacheKey);
		}
		try {
			const resultPromise = this.headers(authManagerService, auth).then((headers) => {
				if (extraHeaders) {
					extraHeaders.map((header) => headers.append(header.key, [header.value]));
				}
				return firstValueFrom(this.http.post(APIService.BASE_URL + url, body, { headers: headers }));
			});
			if (fromCacheIfNewerThanMsAgo > 0) {
				this.putInCache(cacheKey, resultPromise);
			}
			return await resultPromise;
		} catch (e) {
			await this.handleError(e, authManagerService);
			return await this.post(url, body, fromCacheIfNewerThanMsAgo, authManagerService, auth, extraHeaders, retryTimes - 1);
		}
	}

	async patchToApi(url, body = {}, authManagerService: AuthManagerService = this.authManagerService, auth: boolean = true) {
		return this.patch(API_PREFIX + url, body, authManagerService, auth);
	}

	async patch(url, body, authManagerService: AuthManagerService = this.authManagerService, auth: boolean = true, retryTimes: number = 10) {
		if (retryTimes === 0) {
			throw new Error('max retry reached');
		}

		const headers = await this.headers(authManagerService, auth);
		try {
			return await firstValueFrom(this.http.patch(APIService.BASE_URL + url, body, { headers: headers }));
		} catch (e) {
			await this.handleError(e, authManagerService);
			return await this.patch(url, body, authManagerService, auth, retryTimes - 1);
		}
	}

	async put(url, body, authManagerService: AuthManagerService = this.authManagerService, auth: boolean = true, retryTimes: number = 10) {
		if (retryTimes === 0) {
			throw new Error('max retry reached');
		}

		const headers = await this.headers(authManagerService, auth);
		try {
			return await firstValueFrom(this.http.put(APIService.BASE_URL + url, body, { headers: headers }));
		} catch (e) {
			await this.handleError(e, authManagerService);
			return await this.put(url, body, authManagerService, auth, retryTimes - 1);
		}
	}

	deleteToApi(url, authManagerService: AuthManagerService = this.authManagerService, auth: boolean = true) {
		return this.delete(API_PREFIX + url, authManagerService, auth);
	}

	async delete(url, authManagerService: AuthManagerService = this.authManagerService, auth: boolean = true, retryTimes: number = 10) {
		if (retryTimes === 0) {
			throw new Error('max retry reached');
		}

		const headers = await this.headers(authManagerService, auth);
		try {
			return await firstValueFrom(this.http.delete(APIService.BASE_URL + url, { headers: headers }));
		} catch (e) {
			await this.handleError(e, authManagerService);
			return await this.delete(url, authManagerService, auth, retryTimes - 1);
		}
	}

	// If it is rejected, it indicates that we don't want to try the request again.
	async handleError(httpErrorResponse: HttpErrorResponse, authManagerService: AuthManagerService) {
		const errorCode = httpErrorResponse?.error?.code;
		switch (errorCode) {
			case 100018:
				this.router.navigate(['/login'], { queryParams: { mfa: true } });
				throw httpErrorResponse;
			case 100019:
				this.router.navigate(['/enable-mfa']);
				throw httpErrorResponse;
			case 100020:
				this.loginModalService.show({ beforeDismiss: () => this.loginModalService.redirectToDashBoardOnDismissAndNoLogin() });

				return new Promise<void>((resolve, reject) => {
					this.loginModalService.addSuccessfulLoginListener(resolve);
					this.loginModalService.addRedirectToDashBoardOnDismissAndNoLoginListener(reject);
				});
			case 100001: // Error code for missing auth key.
			case 100021: // Error code for refresh token expired.
				await this.redirectToLogin();
				return unresolvedPromise;
			case 100022:
				// This error code is thrown in the following case:
				// The user is an SSO user, the user is a company admin, the user has not configured MFA, the users sudo access has expired and
				// they need to revalidate their session. In this case, the user is prompted to configure MFA
				this.mfaModalService.showMfaModal({ beforeDismiss: () => this.mfaModalService.redirectToDashBoardOnDismissAndNoConfig() });

				return new Promise<void>((resolve, reject) => {
					this.mfaModalService.addSuccessfulMFAConfigurationListener(resolve);
					this.mfaModalService.addRedirectToDashBoardOnDismissAndNoConfigListener(reject);
				});
			default:
				try {
					await this.handleTokenExpired(httpErrorResponse, authManagerService);
				} catch (e) {
					return APIService.handleErrorResponse(e);
				}
		}
	}

	handleTokenExpired(httpErrorResponse: HttpErrorResponse, authManagerService: AuthManagerService) {
		const refreshResponses = [APIService.ERROR_UNKNOWN_KEY, APIService.ERROR_TOKEN_EXPIRED];
		if (httpErrorResponse.status === 401 && refreshResponses.includes(httpErrorResponse.error.code)) {
			if (this.refreshPromise == null) {
				const payload = {
					RefreshToken: authManagerService.getRefreshToken(),
				};
				this.refreshPromise = this.post('/api/v1/user/refreshToken', payload, 0, authManagerService)
					.then((r) => {
						this.refreshPromise = null;
						authManagerService.setKey(r['data']['key']['key']);
						authManagerService.setSecret(r['data']['key']['secret']);
						authManagerService.setRefreshToken(r['data']['refresh_token']);
						this.store.dispatch(new UpdateKey({ key: r['data']['key']['key'], secret: r['data']['key']['secret'] }));
						return r;
					})
					.catch(async (e) => {
						this.refreshPromise = null;
						await this.redirectToLogin();
						throw e;
					});
			}

			return this.refreshPromise;
		}
		return Promise.reject(httpErrorResponse);
	}

	private async redirectToLogin(): Promise<void> {
		await this.authManagerService.logOut();
		window.location.href = '/login?sessionExpired=true';
	}

	private hasInCache(cacheKey: string, fromCacheIfNewerThanMsAgo: number) {
		if (!(fromCacheIfNewerThanMsAgo > 0)) {
			return false;
		} else {
			return (
				this.requestCache.has(cacheKey) && this.requestCache.get(cacheKey).timestamp > new Date().getTime() - fromCacheIfNewerThanMsAgo
			);
		}
	}

	private putInCache(cacheKey: string, promise: Promise<any>) {
		this.requestCache.set(cacheKey, {
			timestamp: new Date().getTime(),
			data: promise,
		});
		promise.catch((e) => {
			this.deleteFromCache(cacheKey);
			throw e;
		});
	}

	private getFromCache(cacheKey: string): Promise<any> {
		return this.requestCache.get(cacheKey).data;
	}

	private deleteFromCache(cacheKey: string): void {
		this.requestCache.delete(cacheKey);
	}

	public deleteFromCacheWhereUrlStartsWith(url: string) {
		const allKeys = Array.from(this.requestCache.keys());
		const matchingUrls = allKeys.filter((e) => {
			return e.startsWith(API_PREFIX + url);
		});
		matchingUrls.forEach((e) => this.requestCache.delete(e));
	}
}
