import { InvoiceSuggestionApiModel } from '#/models/transaction/invoice-suggestion/apiModel';
import { InvoiceSuggestionFrontendModel } from '#/models/transaction/invoice-suggestion/frontendModel';
import { stringIsSetAndFilled } from '#/util/values';

export function apiToFrontend(apiModel: InvoiceSuggestionApiModel): InvoiceSuggestionFrontendModel {
	const result = new InvoiceSuggestionFrontendModel();
	result.accountNumber = parseSuggestion(apiModel.accountnumber);
	result.administration = parseSuggestion(apiModel.companyadministration);
	result.category = parseSuggestion(apiModel.companycategory);
	result.costCenter = parseSuggestion(apiModel.companycostcenter);
	result.costUnit = parseSuggestion(apiModel.companycostunit);
	result.project = parseSuggestion(apiModel.companyproject);
	result.customPaymentMethod = parseSuggestion(apiModel.payment_method_id);
	result.paymentMethod = parseSuggestion(apiModel.paymentmethod);
	return Object.entries(result)
		.filter(([key, val]) => val !== undefined)
		.reduce((acc, cur) => {
			acc[cur[0]] = cur[1];
			return acc;
		}, new InvoiceSuggestionFrontendModel());
}

function parseSuggestion(s: string) {
	if (stringIsSetAndFilled(s)) {
		return s;
	}
	if (s === '') {
		// means a suggestion to leave it empty
		return null;
	}
	// means we don't have a suggestion
	return undefined;
}
