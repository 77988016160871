import { Injectable } from '@angular/core';
import { Currency } from '#/models/currency';
import { stringIsSetAndFilled } from '#/util/values';
import { APIService } from '~/app/api/services/api.service';
import { convertDateToYMD } from '#/util/date';

@Injectable({
	providedIn: 'root',
})
export class CurrencyService {
	private currencyCache = new Map<string, Currency>();

	constructor(private apiService: APIService) {}

	getCurrencyFromCache(code: string): Currency {
		if (this.currencyCache.has(code)) {
			return this.currencyCache.get(code);
		} else {
			return null;
		}
	}

	getCurrency(code: string): Promise<Currency> {
		if (this.currencyCache.has(code)) {
			return Promise.resolve(this.currencyCache.get(code));
		}
		return this.getCurrenciesFromAPI().then((currencies) => currencies.get(code));
	}

	public preloadCurrencies(): Promise<Array<Currency>> {
		return this.getCurrencies();
	}

	async getCurrencies(searchQuery: string = null): Promise<Currency[]> {
		let result: Array<Currency>;
		if (this.currencyCache.size > 0) {
			result = Array.from(this.currencyCache.values());
		} else {
			result = await this.getCurrenciesFromAPI().then((items) => Array.from(items.values()));
		}
		if (stringIsSetAndFilled(searchQuery)) {
			result = result.filter((e) => e.matches(searchQuery));
		}
		return result;
	}

	getExchangeCurrencies(): Promise<any> {
		return this.apiService.get('/api/v1/receipt/exchangeCurrencies', 60 * 1000);
	}

	getCurrenciesByCodes(codes: string[]): Promise<Array<Currency>> {
		return this.apiService.post('/api/v1/currencies/list', { codes }).then((res) => res.data.Currencies.map((e) => new Currency(e)));
	}

	private getCurrenciesFromAPI(): Promise<Map<string, Currency>> {
		return this.apiService.get('/api/v1/currencies', 60 * 1000).then((res) => {
			const currencies = res.data.Currencies;
			return this.getExchangeCurrencies().then((exchangeCurrencies) => {
				this.currencyCache.clear();
				currencies.forEach((currency) => {
					const currencyItem = new Currency(currency);
					currencyItem.Exchangeable = exchangeCurrencies.data.indexOf(currencyItem.Code) !== -1;
					this.currencyCache.set(currency.Code, currencyItem);
				});
				return this.currencyCache;
			});
		});
	}

	public async getCurrencyExchangeRate(exchangeFrom: string, exchangeTo: string, date: Date): Promise<{ exchange_rate: number }> {
		return this.apiService
			.get('/api/v1/receipt/exchangerates?from=' + exchangeFrom + '&to=' + exchangeTo + '&date=' + convertDateToYMD(date))
			.then((r) => r.data);
	}
}
