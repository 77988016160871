import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ExpenseReport, ReportBookingData } from '#/models/transaction/expense-reports';
import { Company, AccountingIntegrationV1 } from '#/models/company/company.model';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/reducers';
import { CompanyState } from '#/models/company/company.reducer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { CompanyService } from '#/services/company/company.service';
import { stringIsSetAndFilled, useIfStringIsSet } from '#/util/values';
import { CompanyAdministrationService } from '#/services/company/company-administration.service';
import { Administration } from '#/models/company/administration.model';
import { subscribeToFormChanges } from '#/util/forms';
import { convertDateToYMD } from '#/util/date';

@Component({
	selector: 'app-expense-report-book-modal',
	templateUrl: './expense-report-book-modal.html',
	styleUrls: ['./expense-report-book-modal.scss'],
})
export class ExpenseReportBookModal implements OnInit, OnDestroy {
	@Input() report: ExpenseReport;
	@Output() public onDismiss: EventEmitter<void> = new EventEmitter<void>();
	@Output() public onSave: EventEmitter<void> = new EventEmitter<void>();
	protected destroyCallbacks = [];
	public integrations: Array<AccountingIntegrationV1>;

	public expenseReportBookForm: FormGroup = this.formBuilder.group({
		provider: [null, Validators.required],
		division: [null, Validators.required],
		journal: [null, Validators.required],
		relation: null,
		bookingDate: null,
	});

	public isEdit = false;
	public company: Company;

	constructor(
		private store: Store<AppState>,
		public translate: TranslateService,
		public formBuilder: FormBuilder,
		private expenseReportsService: ExpenseReportsService,
		private companyService: CompanyService,
		private companyAdministration: CompanyAdministrationService,
	) {}

	public async ngOnInit(): Promise<void> {
		if (this.report.booking_data.provider) {
			this.isEdit = true;
		}
		this.company = this.companyService.getCompanyOfLoggedUser();

		this.expenseReportBookForm.patchValue({
			provider: useIfStringIsSet(this.report.booking_data.provider),
			division: useIfStringIsSet(this.report.booking_data.division),
			journal: useIfStringIsSet(this.report.booking_data.journal),
			relation: useIfStringIsSet(this.report.booking_data.relation),
			bookingDate: useIfStringIsSet(this.report.booking_data.booking_date)
				? convertDateToYMD(new Date(this.report.booking_data.booking_date))
				: undefined,
		});

		if (!this.isEdit) {
			const administration = await this.companyAdministration.getCompanyAdministration(this.company.id, this.report.administration);
			await this.setDefaults(administration);
			await this.getSuggestions();
		}

		this.setUpFormListeners();
	}

	private setUpFormListeners(): void {
		subscribeToFormChanges<keyof typeof this.expenseReportBookForm.value>(this.expenseReportBookForm).subscribe((changes) => {
			if (changes.has('provider')) {
				this.integrationChanged();
			}
			if (changes.has('division')) {
				this.divisionChanged();
			}
		});
	}

	private divisionChanged(): void {
		this.getSuggestions();
	}

	private async getSuggestions(): Promise<void> {
		const divisionId = this.expenseReportBookForm.get('division').value;

		if (stringIsSetAndFilled(divisionId)) {
			const suggestions = await this.expenseReportsService.getBookingSuggestions(
				this.companyService.getCompanyOfLoggedUser().id,
				divisionId,
			);
			this.expenseReportBookForm.patchValue({
				journal: suggestions.journal,
			});
		}
	}

	private setDefaults(administration: Administration) {
		const companySubscription = this.store.select('company').subscribe((val: CompanyState) => {
			this.integrations = val.integrations;
		});

		let providerToPrefill: string = administration.provider;
		if (!stringIsSetAndFilled(providerToPrefill)) {
			if (this.company.authorizations.size === 1) {
				providerToPrefill = Array.from(this.company.authorizations.keys())[0];
			}
		}
		const division = administration.remote_id || this.company?.authorizations?.get(providerToPrefill)?.defaults?.Division;
		this.expenseReportBookForm.patchValue({
			provider: providerToPrefill,
			division,
		});

		this.destroyCallbacks.push(() => {
			companySubscription.unsubscribe();
		});
	}

	bookReport = async (values) => {
		const valuesTransformed = new ReportBookingData({
			booking_date: stringIsSetAndFilled(values.bookingDate) ? new Date(values.bookingDate).toISOString() : undefined,
			provider: values.provider,
			division: values.division,
			journal: values.journal,
			relation: values.relation,
		});

		this.expenseReportsService.updateBookingDataExpenseReport(this.report.id, valuesTransformed).then(() => {
			this.onSave.emit();
		});
	};

	public closeModal() {
		this.onDismiss.emit();
	}

	public ngOnDestroy(): void {
		this.destroyCallbacks.forEach((unsubscribe) => unsubscribe());
	}

	private integrationChanged() {
		this.expenseReportBookForm.get('journal').setValue(null);
		this.expenseReportBookForm.get('division').setValue(null);
	}
}
