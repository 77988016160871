import { Agreement } from '#/models/user/user.model';
import { DeclarationStatusFlag, ExtraInputField, ReceiptsExportColumn } from '#/models/transaction/receipt';
import { isValueSet, numberIsSet, stringIsSetAndFilled } from '#/util/values';
import { roundToX } from '#/util/numbers';
import { Address } from '#/models/address.model';
import { isEqual, isFunction, isUndefined, omit, omitBy } from 'lodash';
import {
	CompanyModule,
	CompanyModuleAutomaticallyBookExpenses,
	CompanyModuleAutomaticallyBookInvoices,
	CompanyModuleAutomaticallySubmitInboundMail,
	CompanyModuleBookingLinesBeforeApproved,
	CompanyModuleCardReports,
	CompanyModuleCategoryForInvoices,
	CompanyModuleCorrectionFields,
	CompanyModuleCorrectionFieldsCounters,
	CompanyModuleCreditCardManualUpload,
	CompanyModuleCreditcardStatements,
	CompanyModuleCustomLogo,
	CompanyModuleDatevExportType,
	CompanyModuleExpenseReports,
	CompanyModuleFinanceCanDuplicate,
	CompanyModuleGenerateGroupPerUser,
	CompanyModuleHideCrisp,
	CompanyModuleHideIntegrations,
	CompanyModuleHideStatistics,
	CompanyModuleHistoryToBookkeeping,
	CompanyModuleImpersonation,
	CompanyModuleOCR,
	CompanyModulePaymentMethods,
	CompanyModulePaymentMethodTransactions,
	CompanyModuleReceiptCountry,
	CompanyModuleShowXml,
	CompanyModuleSpanishCompliance,
	CompanyModuleVelos,
} from '#/models/company/basicBooleanModules';
import { ExportRequest } from '#/models/company/exporterInterfaces';
import { PossibleCompanyFeatureFlags } from '#/models/company/possible-feature-flags';
import { CostUnit } from '#/models/company/dimension/cost-unit.model';
import { CostCenter } from '#/models/company/dimension/cost-center.model';
import { Project } from '#/models/company/dimension/project.model';
import { CompanyObjectListAPIRequest } from '#/models/company/company-object-api-request.model';
import { ListAPIRequest, QueryParam } from '#/models/listApiRequest.model';
import { Category } from '#/models/company/category.model';
import { Frequency } from '#/models/utils/frequency';
import { CompensationType } from '#/models/compensation-type';
import { IntegrationPaymentMethodTypes } from '#/models/integrationPaymentMethodTypes';
import { LicensingInformation } from '#/models/company/licensing-information';
import { CreatorType } from '../transaction/creatorType.model';

/* @ todo check all classes for missing properties. */

export class TableColumn {
	key: string;
	label: string;
	sortable = true;
	tooltip?: string;

	constructor(key: string, label: string, sortable = true, tooltip = null) {
		this.key = key;
		this.label = label;
		this.sortable = sortable;
		this.tooltip = tooltip;
	}

	getKey() {
		return this.key;
	}

	getLabel() {
		return this.label;
	}

	isSortable() {
		return this.sortable;
	}
}

export class DMSuggestion {
	ledger: string;
	journal: string;
	vatcode: string;
	relationnumber: string;

	static fromData(data): DMSuggestion[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new DMSuggestion(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	getLedger(): string {
		return this.ledger;
	}

	getJournal(): string {
		return this.journal;
	}

	getVATCode(): string {
		return this.vatcode;
	}

	getRelationNumber(): string {
		return this.relationnumber;
	}
}

export class DMBookingSuggestion {
	receipt_count: number;
	suggestion: DMSuggestion;

	static fromData(data): DMBookingSuggestion[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new DMBookingSuggestion(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.suggestion) {
				this.suggestion = new DMSuggestion(data.suggestion);
			}
		}
	}

	getReceiptCount(): number {
		return this.receipt_count;
	}

	getSuggestion(): DMSuggestion {
		return this.suggestion;
	}
}

export class AMSuggestion {
	provider: string;
	division: string;

	static fromData(data): AMSuggestion[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new AMSuggestion(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	getProvider(): string {
		return this.provider;
	}

	getDivision(): string {
		return this.division;
	}
}

export class AMBookingSuggestion {
	receipt_count: number;
	suggestion: AMSuggestion;

	static fromData(data): AMBookingSuggestion[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new AMBookingSuggestion(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.suggestion) {
				this.suggestion = new AMSuggestion(data.suggestion);
			}
		}
	}

	getReceiptCount(): number {
		return this.receipt_count;
	}

	getSuggestion(): AMSuggestion {
		return this.suggestion;
	}
}

export class GeneralLedgerData {
	general_ledgers: Array<GeneralLedger> = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.general_ledgers) {
				this.general_ledgers = GeneralLedger.fromData(data.general_ledgers);
			}
		}
	}
}

export class GeneralLedger {
	id: string;
	Identifier: string;
	Code: string;
	Description: string;

	static fromData(data): GeneralLedger[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new GeneralLedger(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
		this.id = this.Identifier;
	}

	getIdentifier() {
		return this.Identifier;
	}

	getCode() {
		return this.Code;
	}

	getDescription() {
		return this.Description;
	}
}

export class JournalData {
	journals: Array<Journal> = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.journals) {
				this.journals = Journal.fromData(data.journals);
			}
		}
	}
}

export class Journal {
	Identifier: string;
	Code: string;
	Description: string;
	id: string;

	static fromData(data): Journal[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new Journal(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			this.id = this.Identifier;
		}
	}

	getIdentifier() {
		return this.Identifier;
	}

	getCode() {
		return this.Code;
	}

	getDescription() {
		return this.Description;
	}
}

export class DivisionData {
	divisions: Array<Division> = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.divisions) {
				this.divisions = Division.fromData(data.divisions);
			}
		}
	}
}

export class Division {
	id: string;
	Identifier: string;
	Code: string;
	Description: string;
	Currency: string;
	Address: Address;
	Current: boolean;
	Main: boolean;

	static fromData(data): Division[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new Division(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			this.id = this.Identifier;
		}
	}

	getIdentifier() {
		return this.Identifier;
	}

	getCode() {
		return this.Code;
	}
}

export class ConfigurationFieldSelectOption {
	Key: string;
	Value: string;

	static fromData(data): ConfigurationFieldSelectOption[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new ConfigurationFieldSelectOption(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class VATCodeData {
	vats: Array<VATCode> = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.vats) {
				this.vats = VATCode.fromData(data.vats);
			}
		}
	}
}

export class VATCode {
	id: string;
	Identifier: string;
	Code: string;
	Description: string;
	Percentage: number;
	BookVATIncluded: boolean;
	BookVATReverseCharged: boolean;

	static fromData(data): VATCode[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new VATCode(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
		this.id = this.Identifier;
	}

	getIdentifier(): string {
		return this.Identifier;
	}

	getCode(): string {
		return this.Code;
	}

	getDescription(): string {
		return this.Description;
	}

	getPercentage(): number {
		return this.Percentage;
	}

	getBookVATIncluded(): boolean {
		return this.BookVATIncluded;
	}

	getBookVATReverseCharged(): boolean {
		return this.BookVATReverseCharged;
	}
}

export interface IntegrationPurchaseOrderLine {
	Identifier: string;
	Code: string;
	Description: string;
	Amound: number;
}

export class IntegrationPurchaseOrderData {
	purchase_orders: Array<IntegrationPurchaseOrder> = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.purchase_orders) {
				this.purchase_orders = IntegrationPurchaseOrder.fromData(data.purchase_orders);
			}
		}
	}
}

export class IntegrationPurchaseOrder {
	Identifier: string;
	Code: string;
	Name: string;
	Description: string;
	RelationName: string;
	TotalAmound: number;
	PurchaseOrderLines: IntegrationPurchaseOrderLine[];

	static fromData(data): IntegrationPurchaseOrder[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new IntegrationPurchaseOrder(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	GetIdentifier() {
		return this.Identifier;
	}
	GetCode() {
		return this.Code;
	}
	GetName() {
		return this.Name;
	}
	GetDescription() {
		return this.Description;
	}
	GetRelationName() {
		return this.RelationName;
	}
	GetTotalAmound() {
		return this.TotalAmound;
	}
	GetPurchaseOrderLines() {
		return this.PurchaseOrderLines;
	}
}

export class IntegrationCostCenter {
	Identifier: string;
	Code: string;
	Description: string;

	static fromData(data): IntegrationCostCenter[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new IntegrationCostCenter(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	getIdentifier(): string {
		return this.Identifier;
	}
}

export class IntegrationCostUnit {
	Identifier: string;
	Code: string;
	Description: string;

	static fromData(data): IntegrationCostUnit[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new IntegrationCostUnit(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class IntegrationDimensionDataPaginated {
	dimension_data: Array<IntegrationDimensionData> = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.dimension_data) {
				this.dimension_data = IntegrationDimensionData.fromData(data.dimension_data);
			}
		}
	}
}

export class IntegrationDimensionData {
	IsActive: boolean;
	Identifier: string;
	Name: string;
	name: string;
	Description: string;
	description: string;
	FromDate: string;
	ToDate: string;
	Code: string;
	code: string;
	RemoteIdentifier: string;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			this.name = this.Name;
			this.description = this.Description;
			this.code = this.Code;
		}
	}

	static fromData(data): IntegrationDimensionData[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new IntegrationDimensionData(item));
	}
}

export class IntegrationDimensionDataCount {
	Count: number;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class IntegrationDimensionInfoData {
	dimensions: Array<IntegrationDimensionInfo> = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.dimensions) {
				this.dimensions = IntegrationDimensionInfo.fromData(data.dimensions);
			}
		}
	}
}

export class IntegrationDimensionInfo {
	Identifier: string;
	DisplayNameSingle: string;
	DisplayNameMultiple: string;
	Localize: boolean;
	Searchable: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	static fromData(data): IntegrationDimensionInfo[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new IntegrationDimensionInfo(item));
	}
}

export class IntegrationDimension {
	info = new IntegrationDimensionInfo();
	data: IntegrationDimensionData[] = [];

	value: string | undefined;

	constructor(data = null) {
		if (data) {
			if (data.info) {
				this.info = new IntegrationDimensionInfo(data.info);
			}
			if (data.data) {
				this.data = data.data.map((item) => new IntegrationDimensionData(item));
			}
			if (data.value) {
				this.value = data.value;
			}
		}
	}

	getDisplayName(isMultiple: boolean = false) {
		return (
			(isMultiple ? this.info.DisplayNameMultiple || this.info.DisplayNameSingle : this.info.DisplayNameSingle) || this.info.Identifier
		);
	}
}

export class IntegrationProject {
	Identifier: string;
	Code: string;
	Description: string;

	static fromData(data): IntegrationProject[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new IntegrationProject(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class IntegrationRelationData {
	relations: Array<IntegrationRelation> = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.relations) {
				this.relations = IntegrationRelation.fromData(data.relations);
			} else {
				this.relations = [];
			}
		}
	}
}

export class IntegrationRelation {
	id: string;
	Identifier: string;
	Code: string;
	Description: string;
	Name: string;
	Address: Address;
	Website: string;
	COC: string;
	VAT: string;
	Email: string;
	Phone: string;
	BankAccounts?: string[];
	PaymentCondition?: string;
	payment_method: string;

	static fromData(data): IntegrationRelation[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new IntegrationRelation(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.Address) {
				this.Address = new Address(data.Address);
			}
			this.id = this.Identifier;
		}
	}
}

export class CompanyGroup {
	id: string;
	code: string;
	name: string;
	createdate: Date;
	updatedate: Date;
	active: boolean;
	description: string;
	locked: boolean;
	assignAll: boolean;
	creator: { type: CreatorType };

	static fromData(data): CompanyGroup[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new CompanyGroup(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			if (data.createdate) {
				this.createdate = Date.parse(data.createdate) > 0 ? new Date(data.createdate) : null;
			}
			if (data.updatedate) {
				this.updatedate = Date.parse(data.updatedate) > 0 ? new Date(data.updatedate) : null;
			}
		}
	}

	getID() {
		return this.id;
	}

	getCode() {
		return this.code;
	}

	getName() {
		return this.name;
	}

	get Label() {
		return this.getName();
	}

	getCreateDate() {
		return this.createdate;
	}

	getUpdateDate() {
		return this.updatedate;
	}

	isActive() {
		return this.active;
	}

	getDescription() {
		return this.description;
	}

	isLocked() {
		return this.locked;
	}

	getTitle() {
		return (this.code && this.code + ' - ') + this.name;
	}

	clone(): CompanyGroup {
		return new CompanyGroup(JSON.parse(JSON.stringify(this)));
	}
}

export class CompanyPreferences {
	travel_expense_declaration_cents_per_km: number;
	syncadministrations: boolean;
	synccostcenters: boolean;
	synccostunits: boolean;
	syncprojects: boolean;
	dm_relation_selector: boolean;
	module_leveled_travel_expense_compensation_order?: string[];
	users_cant_approve_own_expenses: boolean;
	users_cant_approve_own_invoices: boolean;
	expense_manager_can_only_see_assigned_expenses: boolean;
	invoice_approver_can_only_see_assigned_invoices: boolean;
	finance_can_only_see_assigned_invoices: boolean;
	has_icon: boolean;
	force_mfa: boolean;
	sync_cost_center_groups: boolean;
	sync_cost_unit_groups: boolean;
	sync_project_groups: boolean;

	static fromData(data): CompanyPreferences[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new CompanyPreferences(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	get travelExpenseDeclarationCentsPerKm(): string {
		return roundToX(this.travel_expense_declaration_cents_per_km, 2).toString(10);
	}

	set travelExpenseDeclarationCentsPerKm(cents: string) {
		if (!stringIsSetAndFilled(cents)) {
			cents = '0';
		}
		cents = cents.replace(/,/g, '.');
		const value = parseFloat(cents);
		this.travel_expense_declaration_cents_per_km = isNaN(value) ? 0 : value;
	}

	get travelExpenseDeclarationCentsPerMile(): string {
		return roundToX(this.travel_expense_declaration_cents_per_km / 0.621371192, 2).toString(10);
	}

	set travelExpenseDeclarationCentsPerMile(cents: string) {
		if (cents === null || cents === '') {
			cents = '0';
		}
		cents = cents.replace(/,/g, '.');
		const value = parseFloat(cents) * 0.621371192;
		this.travel_expense_declaration_cents_per_km = isNaN(value) ? 0 : value;
	}
}

export class DefaultPreferences {
	language: string;
	currency: string;
	vatmode?: boolean;
	paymentinfo?: boolean;
	receipt_type?: boolean;
	groups?: boolean;
	tags?: boolean;
	financetype?: boolean;
	notification_frequency?: Frequency;
	notification_moment?: Frequency;
	notification_frequency_finance?: Frequency;
	notification_moment_finance?: Frequency;
	show_miles_instead_of_km: boolean;
	force_preferences?: DefaultPreferencesForcePreferences;

	static fromData(data): DefaultPreferences[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new DefaultPreferences(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (this.groups === null) {
				this.groups = true;
			}
			if (this.tags === null) {
				this.tags = true;
			}
			if (this.vatmode === null) {
				this.vatmode = true;
			}
			if (this.paymentinfo === null) {
				this.paymentinfo = false;
			}
			if (this.receipt_type === null) {
				this.receipt_type = false;
			}
			if (this.financetype === null) {
				this.financetype = false;
			}
			if (data.force_preferences) {
				this.force_preferences = new DefaultPreferencesForcePreferences(data.force_preferences);
			} else {
				this.force_preferences = new DefaultPreferencesForcePreferences(null);
			}
		}
	}
}

export class DefaultPreferencesForcePreferences {
	language: boolean;
	currency: boolean;
	vatmode: boolean;
	ocr: boolean;
	paymentinfo: boolean;
	receipt_type: boolean;
	groups: boolean;
	tags: boolean;
	ocr_mode: boolean;
	financetype: boolean;
	notification_frequency: boolean;
	notification_moment: boolean;
	notification_frequency_finance: boolean;
	notification_moment_finance: boolean;
	show_miles_instead_of_km: boolean;

	static fromData(data): DefaultPreferencesForcePreferences[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new DefaultPreferencesForcePreferences(item));
	}

	constructor(data = null) {
		// Defaults.
		this.language = false;
		this.currency = false;
		this.vatmode = false;
		this.ocr = false;
		this.paymentinfo = false;
		this.receipt_type = false;
		this.groups = false;
		this.tags = false;
		this.ocr_mode = false;
		this.financetype = false;
		this.notification_frequency = false;
		this.notification_moment = false;
		this.show_miles_instead_of_km = false;
		this.notification_frequency_finance = false;
		this.notification_moment_finance = false;

		if (data) {
			Object.assign(this, data);
		}
	}
}

export class AuthorizationProfile {
	id: string;
	name: string;
	username: string;

	static fromData(data): AuthorizationProfile[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new AuthorizationProfile(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class AuthorizationDefaults {
	Division?: string;
	GLAccount?: string;
	Journal?: string;
	VATCode?: string;
	CombineBookings: boolean;

	static fromData(data): AuthorizationDefaults[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new AuthorizationDefaults(item));
	}

	constructor(data = null) {
		if (!data) {
			return;
		}
		this.Division = data.Division || undefined;
		this.GLAccount = data.GLAccount || undefined;
		this.Journal = data.Journal || undefined;
		this.VATCode = data.VATCode || undefined;
		this.CombineBookings = !!data.CombineBookings;
	}

	getDivision() {
		return this.Division;
	}

	getGLAAccount() {
		return this.GLAccount;
	}

	getJournal() {
		return this.Journal;
	}

	getVATCode() {
		return this.VATCode;
	}
}

export class Authorization {
	id: string;
	connectiondate: Date;
	profile: AuthorizationProfile;
	defaults: AuthorizationDefaults;
	username: string;
	combine_bookings: boolean;
	book_expenses_with_purchase_date: boolean;
	match_expense_relation_on_email: boolean;
	match_expense_relation_on_account_code: boolean;
	allow_deferred: boolean;
	book_in_background: boolean;
	integration_configuration: { [key: string]: string };
	allowed_divisions: string[];
	parent_company_id: string;
	combine_attachments: boolean;

	static fromData(data): Authorization[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new Authorization(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			if (data.profile) {
				this.profile = new AuthorizationProfile(data.profile);
			}
			if (data.defaults) {
				this.defaults = new AuthorizationDefaults(data.defaults);
			}
			if (!data.integration_configuration) {
				this.integration_configuration = {};
			}
		}
	}

	getID(): string {
		return this.id;
	}

	getDefaults(): AuthorizationDefaults {
		return this.defaults;
	}

	clone(): Authorization {
		return new Authorization(JSON.parse(JSON.stringify(this)));
	}
}

export class CompanyTrial {
	istrial: boolean;
	trialend: Date;
	emailsent: boolean;
	schedule_demo_email_sent: boolean;

	static fromData(data): CompanyTrial[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new CompanyTrial(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			if (data.trialend) {
				this.trialend = Date.parse(data.trialend) > 0 ? new Date(data.trialend) : null;
			}
		}
	}

	clone(): CompanyTrial {
		return new CompanyTrial(JSON.parse(JSON.stringify(this)));
	}
}

export class CompanyModuleTravelExpenseSplitTaxedUntaxed extends CompanyModule {
	public taxed_cents_per_km: number;
	public untaxed_cents_per_km: number;

	get taxedCentsPerKm(): string {
		if (typeof this.taxed_cents_per_km !== 'undefined' && this.taxed_cents_per_km !== null) {
			return roundToX(this.taxed_cents_per_km, 2).toString(10);
		}

		return null;
	}

	set taxedCentsPerKm(cents: string) {
		if (cents && cents !== '') {
			cents = cents.replace(/,/g, '.');
			const value = parseFloat(cents);
			this.taxed_cents_per_km = isNaN(value) ? 0 : value;
		} else {
			this.taxed_cents_per_km = null;
		}
	}

	get untaxedCentsPerKm(): string {
		if (typeof this.untaxed_cents_per_km !== 'undefined' && this.untaxed_cents_per_km !== null) {
			return roundToX(this.untaxed_cents_per_km, 2).toString(10);
		}

		return null;
	}

	set untaxedCentsPerKm(cents: string) {
		if (cents && cents !== '') {
			cents = cents.replace(/,/g, '.');
			const value = parseFloat(cents);
			this.untaxed_cents_per_km = isNaN(value) ? 0 : value;
		} else {
			this.untaxed_cents_per_km = null;
		}
	}
}

export class CompanyModuleReceiptPresetChainedPreset {
	preset_code: string;
	copy_fields_over: string[];
	open_if: {
		check_box?: string;
	};

	constructor(data?: any) {
		if (data) {
			Object.assign(this, data);
		}
		if (!this.open_if) {
			this.open_if = {};
		}
	}
}

export class CompanyModuleReceiptPresetsPreset {
	public code: string;
	public menu_label: string;
	public hide_in_menu: boolean;
	public help_text: string;
	public amount: number;
	public receipt_type: string;
	public finance_type: string;
	public description: string;
	public currency: string;
	public merchants: string[];
	public companyadministrations: string[];
	public companycategories: string[];
	public companycostcenters: string[];
	public companycostunits: string[];
	public companyprojects: string[];
	public countries: string[];
	public payment_methods: string;
	public payment_method_ids: string[];
	public extra_fields: string[];
	// deprecated
	public merchant: string;
	public companyadministration: string;
	public companycategory: string;
	public companycostcenter: string;
	public companycostunit: string;
	public companyproject: string;
	public country: string;
	// end deprecating
	public next_preset: string;
	public next_preset_copy_amount: boolean;
	public next_preset_copy_amount_exchanged: boolean;
	public hide_fields: string[];
	public rename_fields: { [key: string]: string };
	public require_fields: string[];
	public preset_chain: CompanyModuleReceiptPresetChainedPreset[];
	public skip_save = false;
	public skip_ocr = false;

	static fromData(data): CompanyModuleReceiptPresetsPreset[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new CompanyModuleReceiptPresetsPreset(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}

		if (data.preset_chain) {
			this.preset_chain = data.preset_chain.map((item) => new CompanyModuleReceiptPresetChainedPreset(item));
		}
		if (!this.rename_fields) {
			this.rename_fields = {};
		}
	}

	getLabel() {
		if (this.menu_label && this.menu_label !== '') {
			return this.menu_label;
		} else {
			return this.description;
		}
	}

	isRequiredField(fieldName: string): boolean {
		if (this.require_fields) {
			return this.require_fields.includes(fieldName);
		}
		return false;
	}

	shouldShowExtraField(fieldName: ExtraInputField): boolean {
		return this.extra_fields && this.extra_fields.includes(fieldName) === true;
	}

	shouldHideField(fieldName: string): boolean {
		if (this.hide_fields) {
			return this.hide_fields.includes(fieldName);
		}
		return false;
	}

	clone(): CompanyModuleReceiptPresetsPreset {
		return new CompanyModuleReceiptPresetsPreset(JSON.parse(JSON.stringify(this)));
	}
}

export class CompanyModuleReceiptPresets extends CompanyModule {
	public remove_default_add_options: boolean;
	public presets: CompanyModuleReceiptPresetsPreset[];

	static fromData(data): CompanyModuleReceiptPresets[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new CompanyModuleReceiptPresets(item));
	}

	constructor(data = null) {
		super(data);
		if (data) {
			Object.assign(this, data);

			if (data.presets) {
				this.presets = CompanyModuleReceiptPresetsPreset.fromData(data.presets);
			}
		}
	}
}

export class CompanyModuleLeveledTravelExpenseCompensation extends CompanyModule {
	public user: boolean;
	public category: boolean;
	public cost_center: boolean;
	public cost_unit: boolean;
	public project: boolean;
}

export class CompanyModuleAutomaticallyGenerateBookingLines extends CompanyModule {
	public require_category: boolean;
}

export class CompanyModuleRenameCompanyUnitsSingularAndPluralText {
	singular: string;
	plural: string;

	constructor(data?: any) {
		if (data) {
			Object.assign(this, data);
		}
	}

	public get(plural: boolean, fallback: string) {
		if (plural && this.plural) {
			return this.plural;
		} else if (!plural && this.singular) {
			return this.singular;
		}
		return fallback;
	}
}

export class CompanyModuleAutomaticlyGenerateExports extends CompanyModule {
	when = 0; // Day of the week 1-7 or 0 for running every day
	time = 1; // Hour of the day 1-24
	receiptStatuses: DeclarationStatusFlag[] = [];
	setStatusTo: DeclarationStatusFlag | undefined;
	lastExport: ''; // The last export time
	settings?: ExportRequest; // The export settings

	constructor(data?: any) {
		super(data);

		if (!data) {
			return;
		}

		Object.assign(this, data);

		this.settings = null;
		if (data.settings) {
			this.settings = data.settings;
		}
	}
}

export class CompanyModuleRenameCompanyUnits extends CompanyModule {
	costUnit = new CompanyModuleRenameCompanyUnitsSingularAndPluralText();
	costCenter = new CompanyModuleRenameCompanyUnitsSingularAndPluralText();
	project = new CompanyModuleRenameCompanyUnitsSingularAndPluralText();
	category = new CompanyModuleRenameCompanyUnitsSingularAndPluralText();
	group = new CompanyModuleRenameCompanyUnitsSingularAndPluralText();
	country = new CompanyModuleRenameCompanyUnitsSingularAndPluralText();
	administration = new CompanyModuleRenameCompanyUnitsSingularAndPluralText();

	constructor(data?: any) {
		super(data);

		if (!data) {
			return;
		}
		if (data.administration) {
			this.administration = new CompanyModuleRenameCompanyUnitsSingularAndPluralText(data.administration);
		}
		if (data.costUnit) {
			this.costUnit = new CompanyModuleRenameCompanyUnitsSingularAndPluralText(data.costUnit);
		}
		if (data.costCenter) {
			this.costCenter = new CompanyModuleRenameCompanyUnitsSingularAndPluralText(data.costCenter);
		}
		if (data.project) {
			this.project = new CompanyModuleRenameCompanyUnitsSingularAndPluralText(data.project);
		}
		if (data.category) {
			this.category = new CompanyModuleRenameCompanyUnitsSingularAndPluralText(data.category);
		}
		if (data.group) {
			this.group = new CompanyModuleRenameCompanyUnitsSingularAndPluralText(data.group);
		}
		if (data.country) {
			this.country = new CompanyModuleRenameCompanyUnitsSingularAndPluralText(data.country);
		}
	}
}

export class CompanyModuleCreateNewUserRequiredFieldFields {
	name = false;
	email = false;
	iban = false;
	groups = false;
	defaultCostCenter = false;
	companyTitle = false;

	constructor(data?: any) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class CompanyModuleCreateNewUserRequiredField extends CompanyModule {
	requiredFields = new CompanyModuleCreateNewUserRequiredFieldFields();

	constructor(data?: any) {
		super(data);

		if (data && data.requiredFields) {
			this.requiredFields = new CompanyModuleCreateNewUserRequiredFieldFields(data.requiredFields);
		}
	}
}

export class CompanyModuleSimplifyCompanyUnits extends CompanyModule {
	hide_name = false;
	hide_code = false;
	hide_description = false;

	constructor(data?: any) {
		super(data);

		if (data) {
			Object.assign(this, data);
		}
	}
}

interface CompensationSubRule {
	time_unit: string;
	compensation: number;
}

export class CompanyModuleCompensationRulesRule {
	public label: string;
	public types?: string[];
	public companygroups?: string[];
	public companycategories?: string[];
	public companyadministrations?: string[];
	public companycostcenters?: string[];
	public companycostunits?: string[];
	public companyprojects?: string[];
	public countries?: string[];
	public after_date?: Date;
	public before_date?: Date;
	public compensation: number;
	public editable_by_user = false;
	public compensationRuleType: CompensationType;
	public sub_rules: Array<CompensationSubRule>;
	public active: boolean;

	static fromData(data): CompanyModuleCompensationRulesRule[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new CompanyModuleCompensationRulesRule(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.after_date) {
				this.after_date = Date.parse(data.after_date) > 0 ? new Date(data.after_date) : null;
			}

			if (data.before_date) {
				this.before_date = Date.parse(data.before_date) > 0 ? new Date(data.before_date) : null;
			}
		}
	}

	get compensationValue() {
		if (this.compensation) {
			return this.compensation / 100;
		}
		return undefined;
	}

	set compensationValue(val) {
		if (val && typeof val === 'string') {
			// @ts-ignore
			val = val.replace(',', '.');
		}
		let newVal = Math.round(val * 100);
		if (isNaN(newVal)) {
			newVal = 0;
		}

		this.compensation = newVal;
	}
}

export class CompanyModuleCompensationRules extends CompanyModule {
	public rules: CompanyModuleCompensationRulesRule[];

	static fromData(data): CompanyModuleCompensationRules[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new CompanyModuleCompensationRules(item));
	}

	constructor(data = null) {
		super(data);
		if (data) {
			Object.assign(this, data);

			if (data.rules) {
				this.rules = CompanyModuleCompensationRulesRule.fromData(data.rules);
			} else {
				this.rules = [];
			}
		}
	}
}

export class CompanyModuleRegisterStartEndDate extends CompanyModule {
	enabled_for_receipts: boolean;
	enabled_for_travel: boolean;
	register_on_location_for_travel: boolean;

	static fromData(data): CompanyModuleRegisterStartEndDate[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new CompanyModuleRegisterStartEndDate(item));
	}

	constructor(data = null) {
		super(data);
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class CompanyModules {
	OCR = new CompanyModuleOCR();
	correction_fields = new CompanyModuleCorrectionFields();
	correction_fields_counters = new CompanyModuleCorrectionFieldsCounters();
	travel_expense_split_taxed_untaxed = new CompanyModuleTravelExpenseSplitTaxedUntaxed();
	leveled_travel_expense_compensation = new CompanyModuleLeveledTravelExpenseCompensation();
	payment_methods = new CompanyModulePaymentMethods();
	payment_method_transactions = new CompanyModulePaymentMethodTransactions();
	receipt_presets = new CompanyModuleReceiptPresets();
	automatically_generate_booking_lines = new CompanyModuleAutomaticallyGenerateBookingLines();
	hide_integrations = new CompanyModuleHideIntegrations();
	generate_group_per_user = new CompanyModuleGenerateGroupPerUser();
	creditcard_statements = new CompanyModuleCreditcardStatements();
	automatically_submit_inbound_mail = new CompanyModuleAutomaticallySubmitInboundMail();
	automatically_book_expenses = new CompanyModuleAutomaticallyBookExpenses();
	automatically_book_invoices = new CompanyModuleAutomaticallyBookInvoices();
	booking_lines_before_approved = new CompanyModuleBookingLinesBeforeApproved();
	history_to_bookkeeping = new CompanyModuleHistoryToBookkeeping();
	datev_export_type = new CompanyModuleDatevExportType();
	category_for_invoices = new CompanyModuleCategoryForInvoices();
	rename_company_units = new CompanyModuleRenameCompanyUnits();
	custom_logo = new CompanyModuleCustomLogo();
	create_new_user_required_field = new CompanyModuleCreateNewUserRequiredField();
	automatically_generate_exports = new CompanyModuleAutomaticlyGenerateExports();
	simplify_company_units = new CompanyModuleSimplifyCompanyUnits();
	compensation_rules = new CompanyModuleCompensationRules();
	register_start_end_date = new CompanyModuleRegisterStartEndDate();
	hide_statistics = new CompanyModuleHideStatistics();
	report = new CompanyModuleExpenseReports();
	country = new CompanyModuleReceiptCountry();
	show_xml_drag_drop = new CompanyModuleShowXml();
	hide_crisp_chat = new CompanyModuleHideCrisp();
	finance_can_duplicate = new CompanyModuleFinanceCanDuplicate();
	cardReports = new CompanyModuleCardReports();
	creditcardManualUpload = new CompanyModuleCreditCardManualUpload();
	velos = new CompanyModuleVelos();
	spanishCompliance = new CompanyModuleSpanishCompliance();
	impersonation = new CompanyModuleImpersonation();

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.OCR) {
				this.OCR = new CompanyModuleOCR(data.OCR);
			}
			if (data.correction_fields) {
				this.correction_fields = new CompanyModuleCorrectionFields(data.correction_fields);
			}
			if (data.correction_fields_counters) {
				this.correction_fields_counters = new CompanyModuleCorrectionFieldsCounters(data.correction_fields_counters);
			}
			if (data.travel_expense_split_taxed_untaxed) {
				this.travel_expense_split_taxed_untaxed = new CompanyModuleTravelExpenseSplitTaxedUntaxed(data.travel_expense_split_taxed_untaxed);
			}
			if (data.leveled_travel_expense_compensation) {
				this.leveled_travel_expense_compensation = new CompanyModuleLeveledTravelExpenseCompensation(
					data.leveled_travel_expense_compensation,
				);
			}
			if (data.payment_methods) {
				this.payment_methods = new CompanyModulePaymentMethods(data.payment_methods);
			}
			if (data.payment_method_transactions) {
				this.payment_method_transactions = new CompanyModulePaymentMethodTransactions(data.payment_method_transactions);
			}
			if (data.receipt_presets) {
				this.receipt_presets = new CompanyModuleReceiptPresets(data.receipt_presets);
			}
			if (data.automatically_generate_booking_lines) {
				this.automatically_generate_booking_lines = new CompanyModuleAutomaticallyGenerateBookingLines(
					data.automatically_generate_booking_lines,
				);
			}
			if (data.hide_integrations) {
				this.hide_integrations = new CompanyModuleHideIntegrations(data.hide_integrations);
			}
			if (data.generate_group_per_user) {
				this.generate_group_per_user = new CompanyModuleGenerateGroupPerUser(data.generate_group_per_user);
			}
			if (data.creditcard_statements) {
				this.creditcard_statements = new CompanyModuleCreditcardStatements(data.creditcard_statements);
			}
			if (data.automatically_submit_inbound_mail) {
				this.automatically_submit_inbound_mail = new CompanyModuleAutomaticallySubmitInboundMail(data.automatically_submit_inbound_mail);
			}
			if (data.automatically_book_expenses) {
				this.automatically_book_expenses = new CompanyModuleAutomaticallyBookExpenses(data.automatically_book_expenses);
			}
			if (data.automatically_book_invoices) {
				this.automatically_book_invoices = new CompanyModuleAutomaticallyBookInvoices(data.automatically_book_invoices);
			}
			if (data.category_for_invoices) {
				this.category_for_invoices = new CompanyModuleCategoryForInvoices(data.category_for_invoices);
			}
			if (data.booking_lines_before_approved) {
				this.booking_lines_before_approved = new CompanyModuleBookingLinesBeforeApproved(data.booking_lines_before_approved);
			}
			if (data.history_to_bookkeeping) {
				this.history_to_bookkeeping = new CompanyModuleHistoryToBookkeeping(data.history_to_bookkeeping);
			}
			if (data.datev_export_type) {
				this.datev_export_type = new CompanyModuleDatevExportType(data.datev_export_type);
			}
			if (data.rename_company_units) {
				this.rename_company_units = new CompanyModuleRenameCompanyUnits(data.rename_company_units);
			}
			if (data.custom_logo) {
				this.custom_logo = new CompanyModuleCustomLogo(data.custom_logo);
			}
			if (data.create_new_user_required_field) {
				this.create_new_user_required_field = new CompanyModuleCreateNewUserRequiredField(data.create_new_user_required_field);
			}
			if (data.automatically_generate_exports) {
				this.automatically_generate_exports = new CompanyModuleAutomaticlyGenerateExports(data.automatically_generate_exports);
			}
			if (data.simplify_company_units) {
				this.simplify_company_units = new CompanyModuleSimplifyCompanyUnits(data.simplify_company_units);
			}
			if (data.compensation_rules) {
				this.compensation_rules = new CompanyModuleCompensationRules(data.compensation_rules);
			}
			if (data.register_start_end_date) {
				this.register_start_end_date = new CompanyModuleRegisterStartEndDate(data.register_start_end_date);
			}
			if (data.hide_statistics) {
				this.hide_statistics = new CompanyModuleHideStatistics(data.hide_statistics);
			}
			if (data.report) {
				this.report = new CompanyModuleExpenseReports(data.report);
			}
			if (data.show_xml_drag_drop) {
				this.show_xml_drag_drop = new CompanyModuleShowXml(data.show_xml_drag_drop);
			}
			if (data.hide_crisp_chat) {
				this.hide_crisp_chat = new CompanyModuleShowXml(data.hide_crisp_chat);
			}
			if (data.finance_can_duplicate) {
				this.finance_can_duplicate = new CompanyModuleShowXml(data.finance_can_duplicate);
			}
			if (isValueSet(data.creditcardManualUpload)) {
				this.creditcardManualUpload = new CompanyModuleCreditCardManualUpload(data.creditcardManualUpload);
			}
			if (isValueSet(data.velos)) {
				this.velos = new CompanyModuleVelos(data.velos);
			}
			if (isValueSet(data.spanishCompliance)) {
				this.spanishCompliance = new CompanyModuleSpanishCompliance(data.spanishCompliance);
			}
			if (isValueSet(data.impersonation)) {
				this.impersonation = new CompanyModuleImpersonation(data.impersonation);
			}
		}
	}
}

export enum CompanyType {
	Company = 0,
	SubCompany = 1,
}

export class CompanyData {
	companies: Array<Company> = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.companies) {
				this.companies = Company.fromData(data.companies);
			}
		}
	}
}

export class Company {
	id: string;
	createdate: Date;
	updatedate: Date;
	active: boolean;
	name: string;
	titlelabel: string;
	authorizations: Map<string, Authorization>;
	defaultpreferences: DefaultPreferences;
	preferences: CompanyPreferences;
	isadministrationoffice: boolean;
	canbookinvoices: boolean;
	can_use_expenses: boolean;
	can_have_sub_companies: boolean;
	webhook?: any;
	is_demo: boolean;
	parentcompany?: string;
	trial: CompanyTrial;
	agreements: Agreement[];
	paid_for_users: number;
	active_users: number;
	modules: CompanyModules;
	feature_flags: Array<PossibleCompanyFeatureFlags>;
	categories: Category[]; // Deprecated.
	sso_settings: { status: boolean };
	user_sync_settings: { status: boolean };
	licensingInfo: LicensingInformation;

	static fromData(data): Company[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new Company(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			if (data.createdate) {
				this.createdate = Date.parse(data.createdate) > 0 ? new Date(data.createdate) : null;
			}
			if (data.updatedate) {
				this.updatedate = Date.parse(data.updatedate) > 0 ? new Date(data.updatedate) : null;
			}

			const authorizations = new Map<string, Authorization>();
			if (data.authorizations) {
				const auth_keys = Object.keys(data.authorizations);
				auth_keys.forEach((authorization_key) =>
					authorizations.set(authorization_key, new Authorization(data.authorizations[authorization_key])),
				);
			}
			this.authorizations = authorizations;

			if (data.defaultpreferences) {
				this.defaultpreferences = new DefaultPreferences(data.defaultpreferences);
			}
			if (data.preferences) {
				this.preferences = new CompanyPreferences(data.preferences);
			}
			if (data.trial) {
				this.trial = new CompanyTrial(data.trial);
			} else {
				this.trial = new CompanyTrial();
			}
			if (data.agreements) {
				this.agreements = Agreement.fromData(data.agreements, 'company');
			}

			if (data.feature_flags) {
				this.feature_flags = data.feature_flags;
			}

			if (data.modules) {
				this.modules = new CompanyModules(data.modules);
			}
			if (this.modules?.leveled_travel_expense_compensation?.enabled) {
				const availableLevels: string[] = ['user', 'category', 'cost_center', 'cost_unit', 'project'];

				// Check whether we have levels available.
				if (!isValueSet(this.preferences.module_leveled_travel_expense_compensation_order)) {
					// Default order is user,category,cost_center,cost_unit,project.
					this.preferences.module_leveled_travel_expense_compensation_order = availableLevels;
				} else {
					// Filter out disabled levels.
					this.preferences.module_leveled_travel_expense_compensation_order =
						this.preferences.module_leveled_travel_expense_compensation_order.filter((v) => availableLevels.indexOf(v) > -1);

					// Add missing levels.
					const missingLevels = availableLevels.filter(
						(v) => this.preferences.module_leveled_travel_expense_compensation_order.indexOf(v) === -1,
					);
					if (missingLevels.length > 0) {
						this.preferences.module_leveled_travel_expense_compensation_order.push(...missingLevels);
					}
				}
			}
		}
	}

	getID(): string {
		return this.id;
	}

	getName(): string {
		return this.name;
	}

	getIsActive(): boolean {
		return this.active;
	}

	getTitleLabel(): string {
		return this.titlelabel;
	}

	isDemo(): boolean {
		return this.is_demo;
	}

	hasIntegration(integration: AccountingIntegrationV1): boolean {
		return this.authorizations?.has(integration.getKey());
	}

	hasAuthorizations(): boolean {
		return this.authorizations != null && this.authorizations.size > 0;
	}

	getAuthorizationKeys(): string[] {
		if (this.authorizations) {
			return Array.from(this.authorizations.keys());
		}
		return [];
	}

	getAuthorization(integration: AccountingIntegrationV1): Authorization {
		return this.authorizations.get(integration.getKey());
	}

	getAuthorizationByKey(integrationKey: string): Authorization {
		if (!integrationKey || integrationKey === '') {
			return null;
		}
		return this.authorizations.get(integrationKey);
	}

	getActiveUsers(): number {
		return this.active_users;
	}

	getPaidForUsers(): number {
		return this.paid_for_users;
	}

	get paidForUsers(): string {
		if (this.paid_for_users !== undefined) {
			return this.paid_for_users.toString(10);
		}
		return '0';
	}

	set paidForUsers(users: string) {
		this.paid_for_users = parseInt(users, 10);
	}

	getTrial(): CompanyTrial {
		return this.trial;
	}

	isTrial(): boolean {
		if (this.getTrial() && this.getTrial().istrial) {
			return true;
		}

		return false;
	}

	showModuleSettings(): boolean {
		return this.modules.leveled_travel_expense_compensation && this.modules.leveled_travel_expense_compensation.enabled != null;
	}

	public modulePaymentMethodsIsEnabled(): boolean {
		return this.modules?.payment_methods?.enabled;
	}

	moduleReportDeclarationsIsEnabled(): boolean {
		return this.modules && this.modules.report && this.modules.report.enabled;
	}

	public getAmountOfReportModulesEnabled(): number {
		let result = 0;
		if (this.modules?.report?.enabled) {
			result += 1;
		}
		if (this.modules?.cardReports?.enabled) {
			result += 1;
		}
		return result;
	}

	modulePaymentMethodTransactionsIsEnabled(): boolean {
		return this.modules && this.modules.payment_method_transactions && this.modules.payment_method_transactions.enabled;
	}

	moduleHideIntegrationsIsEnabled(): boolean {
		return this.modules && this.modules.hide_integrations && this.modules.hide_integrations.enabled;
	}

	moduleCreditcardStatementsIsEnabled(): boolean {
		return this.modules && this.modules.creditcard_statements && this.modules.creditcard_statements.enabled;
	}

	public moduleSpanishComplianceIsEnabled(): boolean {
		return this.modules?.spanishCompliance?.enabled;
	}

	hideCompanyUnitDescription(): boolean {
		return this.modules && this.modules.simplify_company_units && this.modules.simplify_company_units.hide_description;
	}

	getCompanyUnitBindLabel(): 'Name' | 'Code' | 'Label' {
		if (this.modules && this.modules.simplify_company_units && this.modules.simplify_company_units.enabled) {
			if (this.modules.simplify_company_units.hide_code) {
				return 'Name';
			} else if (this.modules.simplify_company_units.hide_name) {
				return 'Code';
			}
		}
		return 'Label';
	}

	clone(): Company {
		const newCompany = new Company(JSON.parse(JSON.stringify(this)));

		/* We need to clone the Map of authorizations in this way because ES6 map does not support cloning. */
		if (this.authorizations && this.authorizations.size > 0) {
			newCompany.authorizations = new Map<string, Authorization>();
			this.authorizations.forEach((authorization, key) => {
				newCompany.authorizations.set(key, authorization.clone());
			});
		}

		return newCompany;
	}

	canUseInvoices(): boolean {
		return this.canbookinvoices;
	}

	canUseExpenses(): boolean {
		return this.can_use_expenses;
	}

	canHaveSubCompanies(): boolean {
		return this.can_have_sub_companies;
	}

	canUsePaymentMethods(): boolean {
		return this.modulePaymentMethodsIsEnabled();
	}

	canUseCreditcardStatements(): boolean {
		return this.moduleCreditcardStatementsIsEnabled();
	}

	// TODO: Check if this methods is still called with the correct Args
	getTravelExpenseCompensationPerKM(
		userCompensation,
		category?: Category,
		costCenter?: CostCenter,
		costUnit?: CostUnit,
		project?: Project,
	): number {
		const compensations: number[] = [];

		// Add compensation for each level.
		if (this.modules.leveled_travel_expense_compensation && this.modules.leveled_travel_expense_compensation.enabled) {
			this.preferences.module_leveled_travel_expense_compensation_order.forEach((level) => {
				if (level === 'user' && userCompensation !== null && !isNaN(userCompensation) && userCompensation > 0) {
					compensations.push(userCompensation);
				}
				if (level === 'category' && category) {
					if (
						numberIsSet(category.module_leveled_travel_expense_compensation_per_km) &&
						category.module_leveled_travel_expense_compensation_per_km !== 0
					) {
						compensations.push(category.module_leveled_travel_expense_compensation_per_km);
					}
				}
				if (level === 'cost_center' && costCenter) {
					if (
						numberIsSet(costCenter.module_leveled_travel_expense_compensation_per_km) &&
						costCenter.module_leveled_travel_expense_compensation_per_km !== 0
					) {
						compensations.push(costCenter.module_leveled_travel_expense_compensation_per_km);
					}
				}
				if (level === 'cost_unit' && costUnit) {
					if (
						numberIsSet(costUnit.module_leveled_travel_expense_compensation_per_km) &&
						costUnit.module_leveled_travel_expense_compensation_per_km !== 0
					) {
						compensations.push(costUnit.module_leveled_travel_expense_compensation_per_km);
					}
				}
				if (level === 'project' && project) {
					if (
						numberIsSet(project.module_leveled_travel_expense_compensation_per_km) &&
						project.module_leveled_travel_expense_compensation_per_km !== 0
					) {
						compensations.push(project.module_leveled_travel_expense_compensation_per_km);
					}
				}
			});
		}

		// Always add company compensation.
		const companyCompensation = this.preferences.travel_expense_declaration_cents_per_km;
		compensations.push(companyCompensation);

		return compensations[0];
	}

	getTravelExpenseCompensationSource(
		userCompensation,
		category?: Category,
		costCenter?: CostCenter,
		costUnit?: CostUnit,
		project?: Project,
	): string {
		const compensationSources = [];

		// Add compensation for each level.
		if (this.modules.leveled_travel_expense_compensation && this.modules.leveled_travel_expense_compensation.enabled) {
			this.preferences.module_leveled_travel_expense_compensation_order.forEach((level) => {
				if (level === 'user' && userCompensation !== null && !isNaN(userCompensation) && userCompensation > 0) {
					compensationSources.push('user');
				}
				if (level === 'category' && category) {
					if (
						!isNaN(category.module_leveled_travel_expense_compensation_per_km) &&
						category.module_leveled_travel_expense_compensation_per_km !== 0
					) {
						compensationSources.push('category');
					}
				}
				if (level === 'cost_center' && costCenter) {
					if (
						!isNaN(costCenter.module_leveled_travel_expense_compensation_per_km) &&
						costCenter.module_leveled_travel_expense_compensation_per_km !== 0
					) {
						compensationSources.push('cost_center');
					}
				}
				if (level === 'cost_unit' && costUnit) {
					if (
						!isNaN(costUnit.module_leveled_travel_expense_compensation_per_km) &&
						costUnit.module_leveled_travel_expense_compensation_per_km !== 0
					) {
						compensationSources.push('cost_unit');
					}
				}
				if (level === 'project' && project) {
					if (
						!isNaN(project.module_leveled_travel_expense_compensation_per_km) &&
						project.module_leveled_travel_expense_compensation_per_km !== 0
					) {
						compensationSources.push('project');
					}
				}
			});
		}

		// Always add company compensation.
		compensationSources.push('company');

		return compensationSources[0];
	}
}

export class BusinessRuleParameter {
	public key: string;
	public value: string | string[];

	static fromData(data): BusinessRuleParameter[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new BusinessRuleParameter(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class BusinessRuleCondition {
	public type: string;
	public description: string;
	public parameters: BusinessRuleParameter[] = [];

	static fromData(data): BusinessRuleCondition[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new BusinessRuleCondition(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.parameters) {
				this.parameters = BusinessRuleParameter.fromData(data.parameters);
			}
		}
	}
}

export enum BusinessRuleActionTypes {
	updateStatus = 'update_status',
	notification = 'notification',
	authorizationFlow = 'authorization_flow',
}

export class BusinessRuleAction {
	public type: BusinessRuleActionTypes;
	public description: string;
	public parameters: BusinessRuleParameter[] = [];

	static fromData(data): BusinessRuleAction[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new BusinessRuleAction(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.parameters) {
				this.parameters = BusinessRuleParameter.fromData(data.parameters);
			}
		}
	}
}

export enum BusinessRuleTargets {
	EVERYONE = 'everyone',
	USERS = 'users',
	GROUPS = 'groups',
}
export class BusinessRuleTarget {
	public type: BusinessRuleTargets;
	public description: string;
	public ids: string[] = [];

	static fromData(data): BusinessRuleTarget[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new BusinessRuleTarget(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export enum DocumentType {
	RECEIPT = 'receipt',
	REPORT = 'report',
	INVOICE = 'invoice',
	EXPENSE = 'expense',
}

export class BusinessRuleData {
	business_rules: Array<BusinessRule> = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.business_rules) {
				this.business_rules = BusinessRule.fromData(data.business_rules);
			}
		}
	}
}

export class BusinessRule {
	id: string;
	createdate: Date;
	updatedate: Date;
	description: string;
	skip_in_review: boolean;
	active: boolean;
	actions: BusinessRuleAction[];
	conditions_operator: string;
	conditions: BusinessRuleCondition[] = [];
	targets: BusinessRuleTarget[] = [];
	order: number;
	type?: DocumentType.REPORT | DocumentType.RECEIPT | DocumentType.EXPENSE | DocumentType.INVOICE; // When expense report module is active a user can choose this to filter the available settings.

	static fromData(data): BusinessRule[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new BusinessRule(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			['createdate', 'updatedate'].forEach((s) => {
				if (data[s]) {
					this[s] = Date.parse(data[s]) > 0 ? new Date(data[s]) : null;
				}
			});

			if (data.actions) {
				this.actions = BusinessRuleAction.fromData(data.actions);
			}

			if (data.conditions) {
				this.conditions = BusinessRuleCondition.fromData(data.conditions);
				if (this.conditions && this.conditions.length > 0) {
					for (const i in this.conditions) {
						const condition = this.conditions[i];
						if (
							condition.parameters.length >= 3 &&
							condition.parameters[1].key === 'comparison_operator' &&
							(condition.parameters[1].value === 'IN' ||
								condition.parameters[1].value === 'NOT IN' ||
								condition.parameters[1].value === 'CONTAINS IN' ||
								condition.parameters[1].value === 'NOT CONTAINS IN')
						) {
							const compareValue = condition.parameters[2].value;
							if (!Array.isArray(compareValue) && typeof compareValue === 'string') {
								this.conditions[i].parameters[2].value = compareValue.split('<VALUE_SEPARATOR>');
							}
						}
					}
				}
			}

			if (data.targets) {
				this.targets = BusinessRuleTarget.fromData(data.targets);
			}
		}
	}

	clone(): BusinessRule {
		return new BusinessRule(JSON.parse(JSON.stringify(this)));
	}
}

export class AuthorizationFlow {
	id: string;
	createdate: Date;
	updatedate: Date;
	description: string;
	document_type: string;
	active: boolean;
	approvers: string[];
	require_approver_order: boolean;
	require_approver_count?: number;

	static fromData(data): AuthorizationFlow[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new AuthorizationFlow(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			['createdate', 'updatedate'].forEach((s) => {
				if (data[s]) {
					this[s] = Date.parse(data[s]) > 0 ? new Date(data[s]) : null;
				}
			});
		}
	}

	clone(): AuthorizationFlow {
		return new AuthorizationFlow(JSON.parse(JSON.stringify(this)));
	}

	get requireApproverCount(): string {
		if (typeof this.require_approver_count !== 'undefined' && this.require_approver_count !== null) {
			return this.require_approver_count.toString(10);
		}

		return null;
	}

	set requireApproverCount(count: string) {
		if (count && count !== '') {
			this.require_approver_count = parseInt(count, 10);
		} else {
			this.require_approver_count = null;
		}
	}
}

export class FieldLabels {
	key: string;
	security_code: string;

	static fromData(data): FieldLabels[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new FieldLabels(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class UIDefinitionCapabilities {
	Journals: boolean;
	VATInclExcl: boolean;
	VATPercentages: boolean;
	VATPercentagesByDate: boolean;
	VATReverseCharge: boolean;
	DisableVAT: boolean;
	CostCenters: boolean;
	CostUnits: boolean;
	Projects: boolean;
	CombineBookings: boolean;
	RelationLookupByEmail: boolean;
	RelationLookupByAccountCode: boolean;
	Deferred: boolean;
	PurchaseOrders: boolean;
	PurchaseOrderLines: boolean;
	DescriptionPerLine: boolean;
	CrossDimensionBooking: boolean;
	GAccount: GAccountCapability | undefined | null;
	CreateCustomRelation: CreateCustomRelationCapability | undefined | null;
	BankAccountInRelation: boolean;
	DisableDimensionIfPurchaseOrderSelected: boolean;
	DisableGLAccountIfPurchaseOrderSelected: boolean;
	PurchaseOrderPerLine: boolean;
	BookingDate: boolean;
	BookingDatePerLine: boolean;
	DimensionDataCount: boolean;
	FetchRelationAfterSelect: boolean;
	PaymentConditions: boolean;
	IntegrationPaymentMethods: boolean;
	PaymentMethods: boolean;

	constructor(data: any) {
		if (!data) {
			return;
		}
		Object.assign(this, data);

		if (data.GAccount) {
			data.GAccount = new GAccountCapability(data.GAccount);
		}
		if (data.CreateCustomRelation) {
			data.CreateCustomRelation = new CreateCustomRelationCapability(data.CreateCustomRelation);
		}
	}
}

export class GAccountCapability {
	PerLine: boolean;
	UsesPercentage: boolean;

	constructor(data: any) {
		if (!data) {
			return;
		}
		Object.assign(this, data);
	}
}

export class CreateCustomRelationCapability {
	CreateOrg: boolean;
	CreatePerson: boolean;
	Fields: CreateCustomRelationCapabilityField[];

	constructor(data: any) {
		if (!data) {
			return;
		}
		Object.assign(this, data);

		if (data.Fields) {
			this.Fields = data.Fields.map((field) => new CreateCustomRelationCapabilityField(field));
		}
	}
}

export enum CustomRelationFieldShow {
	PersonAndOrg = 0,
	Person = 1,
	Org = 2,
}

export enum CustomRelationFieldType {
	String = 0,
	Number = 1,
	Dropdown = 2,
}

export class CreateCustomRelationCapabilityField {
	Key?: string;
	Label: string;
	Show: CustomRelationFieldShow | undefined;
	Type: CustomRelationFieldType | undefined;
	Width: number | undefined;
	Group: CreateCustomRelationCapabilityField[] | undefined | null;
	GroupHorizontal: CreateCustomRelationCapabilityField[] | undefined | null;
	Validation: CustomRelationFieldValidation | undefined | null;

	// Note that this value is NOT set by the api, this value will be set if the user start typing
	Value: string;

	constructor(data: any) {
		if (!data) {
			return;
		}
		Object.assign(this, data);

		if (data.Group) {
			this.Group = data.Group.map((field) => new CreateCustomRelationCapabilityField(field));
		}
		if (data.GroupHorizontal) {
			this.GroupHorizontal = data.GroupHorizontal.map((field) => new CreateCustomRelationCapabilityField(field));
		}
		if (data.Validation) {
			this.Validation = new CustomRelationFieldValidation(data.Validation);
		}
	}

	isValid() {
		if (!this.Validation) {
			return true;
		}

		if (this.Validation.MinLen && (!this.Value || this.Value.length < this.Validation.MinLen)) {
			return false;
		}
		if (this.Validation.MaxLen && this.Value && this.Value.length >= this.Validation.MaxLen) {
			return false;
		}

		return true;
	}
}

export class CustomRelationFieldValidation {
	MinLen: number | undefined;
	MaxLen: number | undefined;

	constructor(data: any) {
		if (!data) {
			return;
		}
		Object.assign(this, data);
	}
}

export class UIDefinitionConfigurationOption {
	Key: string;
	Label: string;
	Description: string;
	Type: string;

	static fromData(data): UIDefinitionConfigurationOption[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new UIDefinitionConfigurationOption(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class UIDefinition {
	Label: string;
	Description: string;
	ConnectDescription: string;
	FieldLabels: FieldLabels;
	Capabilities: UIDefinitionCapabilities;
	SetupConfigurationFields: UIDefinitionConfigurationOption[];
	ConfigurationFields: UIDefinitionConfigurationOption[];
	combineAttachments: boolean;
	supportsAttachments: boolean;

	static fromData(data): UIDefinition[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new UIDefinition(item));
	}

	constructor(data = null) {
		if (!data) {
			return;
		}
		Object.assign(this, data);
		if (data.FieldLabels) {
			this.FieldLabels = new FieldLabels(data.FieldLabels);
		}
		if (data.SetupConfigurationFields) {
			this.SetupConfigurationFields = UIDefinitionConfigurationOption.fromData(data.SetupConfigurationFields);
		}
		if (data.ConfigurationFields) {
			this.ConfigurationFields = UIDefinitionConfigurationOption.fromData(data.ConfigurationFields);
		}
	}
}

export class AccountingIntegrationV1 {
	id: string;
	AuthenticationType: string;
	Key: string;
	Label: string;
	UIDefinition: UIDefinition;

	static fromData(data): Array<AccountingIntegrationV1> {
		if (data == null) {
			return [];
		}
		return data
			.sort((a, b) => {
				if (a.Label < b.Label) {
					return -1;
				} else if (a.Label > b.Label) {
					return 1;
				}
				return 0;
			})
			.map((item) => new AccountingIntegrationV1(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			this.id = this.Key;
			if (data.UIDefinition) {
				this.UIDefinition = new UIDefinition(data.UIDefinition);
			}
		}
	}

	getKey() {
		return this.Key;
	}

	getLabel() {
		return this.Label;
	}

	getUIDefinition() {
		return this.UIDefinition;
	}
}

export class CreditcardStatementFile {
	id: string;
	filename: string;

	blob?: Blob;
	file?: File;
	loading = false;

	static fromData(data): CreditcardStatementFile[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new CreditcardStatementFile(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	getFilename() {
		return this.filename;
	}
}

export class CreditcardStatementLine {
	description: string;
	date: Date;
	amount: number;
	matched_receipt?: string;
	status: 'Open' | 'Matched';
	customer_name: string;
	customer_number: string;
	customer_card_number: string;

	static fromData(data): CreditcardStatementLine[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new CreditcardStatementLine(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.date) {
				this.date = Date.parse(data.date) > 0 ? new Date(data.date) : null;
			}
		}
	}

	getDescription() {
		return this.description;
	}

	getAmount() {
		return this.amount;
	}

	getDate() {
		return this.date;
	}

	getMatchedReceipt() {
		return this.matched_receipt;
	}

	getStatus() {
		return this.status;
	}

	hasReceipt() {
		return this.isMatched() && this.getMatchedReceipt() != null;
	}

	isMatched() {
		return this.getStatus() === 'Matched';
	}

	getCustomer() {
		let customer = this.customer_name;
		if (this.customer_number !== '') {
			if (customer !== '') {
				customer += ' (' + this.customer_number + ')';
			} else {
				customer = this.customer_number;
			}
		}

		if (this.customer_card_number !== '') {
			if (customer !== '') {
				customer += ': ' + this.customer_card_number;
			} else {
				customer = this.customer_card_number;
			}
		}

		return customer;
	}
}

export class CreditcardStatement {
	id: string;
	createdate: Date;
	updatedate: Date;
	company: string;
	description: string;
	account_number: string;
	amount: number;
	currency: string;
	start_date: Date;
	end_date: Date;
	file: CreditcardStatementFile;
	lines: CreditcardStatementLine[] = [];
	users: string[] = [];
	merchants: string[] = [];
	status: 'Open' | 'Closed';

	static fromData(data): CreditcardStatement[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new CreditcardStatement(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.file) {
				this.file = new CreditcardStatementFile(data.file);
			}

			if (data.lines) {
				this.lines = CreditcardStatementLine.fromData(data.lines);
			}

			['createdate', 'updatedate', 'start_date', 'end_date'].forEach((s) => {
				if (data[s]) {
					this[s] = Date.parse(data[s]) > 0 ? new Date(data[s]) : null;
				}
			});
		}
	}

	getID() {
		return this.id;
	}

	getDescription() {
		return this.description;
	}

	getTitle() {
		return this.getDescription();
	}

	getFilename() {
		if (this.file) {
			return this.file.getFilename();
		}
		return null;
	}

	getCreatedate() {
		return this.createdate;
	}

	getStartDate() {
		return this.start_date;
	}

	getEndDate() {
		return this.end_date;
	}

	getUsers() {
		return this.users;
	}

	getLines() {
		return this.lines;
	}

	getCurrency() {
		return this.currency;
	}

	isClosed(): boolean {
		return this.status === 'Closed';
	}

	getProgress(): string {
		const linesMatched = this.getLines().filter((l) => l.isMatched()).length;
		const linesTotal = this.getLines().length;
		return `${linesMatched} / ${linesTotal}`;
	}

	clone(): CreditcardStatement {
		return new CreditcardStatement(JSON.parse(JSON.stringify(this)));
	}

	isEqualTo(creditcardStatement: CreditcardStatement): boolean {
		const thisStatement = new CreditcardStatement(omitBy(omitBy(this.clone(), isUndefined), isFunction));
		const compareStatement = new CreditcardStatement(omitBy(omitBy(creditcardStatement.clone(), isUndefined), isFunction));

		const skipPaths = [];
		skipPaths.push('file.blob');
		skipPaths.push('file.loading');

		const cleanStatement = omit(thisStatement, skipPaths);
		const cleanCompareStatement = omit(compareStatement, skipPaths);

		return isEqual(cleanStatement, cleanCompareStatement);
	}
}

export class CreditcardStatementData {
	creditcard_statements: CreditcardStatement[] = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.creditcard_statements) {
				this.creditcard_statements = CreditcardStatement.fromData(data.creditcard_statements);
			}
		}
	}
}

export class CreditcardStatementUploadRequest {
	file: File;
	description = '';
	users: string[] = [];
	start_date: string;
	end_date: string;
}

export class CompanySubCompaniesData {
	companies: Company[] = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.companies) {
				this.companies = Company.fromData(data.companies);
			}
		}
	}
}

export class CompanySubCompaniesListAPIRequest extends ListAPIRequest {
	public company: string; // parent company id
	public name: string;
	public id: string; // subcompany id

	getURL(): string {
		return `/api/v1/company/${this.company}/sub_companies`;
	}

	getQueryParams(): QueryParam[] {
		const params = super.getQueryParams();
		params.push({ key: 'name', value: this.name });
		params.push({ key: 'id', value: this.id });
		return params;
	}
}

export class CreditcardStatementListAPIRequest extends ListAPIRequest {
	status: 'open' | 'closed';
	user: string;
	merchant: string;
	description: string;
	company: Company;

	getURL(): string {
		if (this.company) {
			return `/api/v1/company/${this.company.getID()}/creditcardStatement/`;
		}
		return '/api/v1/creditcardStatement';
	}

	getQueryParams(): QueryParam[] {
		const params = super.getQueryParams();
		params.push({ key: 'status', value: this.status });
		params.push({ key: 'user', value: this.user });
		params.push({ key: 'merchant', value: this.merchant });
		params.push({ key: 'description', value: this.description });
		return params;
	}
}

export class CompanyGroupData {
	company_groups: CompanyGroup[] = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.company_groups) {
				this.company_groups = CompanyGroup.fromData(data.company_groups);
			}
		}
	}
}

export class CompanyGroupListAPIRequest extends CompanyObjectListAPIRequest {
	getURL(): string {
		if (this.list) {
			if (this.company && this.company !== '') {
				return `/api/v1/company/${this.company}/companyGroup/list`;
			}
			return `/api/v1/companyGroup/list`;
		}

		if (this.company && this.company !== '') {
			return `/api/v1/company/${this.company}/companyGroup`;
		}
		return `/api/v1/companyGroup`;
	}
}

export class CompanyCustomIntegrationDimension {
	id: string;
	key: string;
	nameSingle: string;
	nameMultiple: string;
	companyID: string;

	constructor(data) {
		Object.assign(this, data);
	}
}

export class CompanyCustomIntegrationDimensionData {
	id: string;
	division: string;
	code: string;
	name: string;
	status: number;
	fromDate?: Date;
	toDate?: Date;
	companyID: string;
	dimensionKey: string;

	constructor(data) {
		if (!data) {
			return;
		}

		Object.assign(this, data);

		this.fromDate = data.fromDate && Date.parse(data.fromDate) > 0 ? new Date(data.fromDate) : undefined;
		this.toDate = data.toDate && Date.parse(data.toDate) > 0 ? new Date(data.toDate) : undefined;
	}
}

interface CompanyCustomIntegrationBookingBooking {
	description: string;
	journal: string;
	generalLedger: string;
	purchaseOrderLine: string;
	dimensions: { [key: string]: string };
	vat: string;
	amount: number;
	vatAmount: number;
	vatPercentage: number;
	deferredFrom?: Date;
	deferredTo?: Date;
}

interface CompanyCustomIntegrationBookingAttachmentRef {
	id: string;
	mimeType: string;
	filename: string;
	link: string;
}

export class CompanyCustomIntegrationBooking {
	id: string;
	companyID: string;
	division: string;
	description: string;
	paymentReference: string;
	journal: string;
	currency: string;
	purchaseOrder: string;
	invoiceNumber: string;
	date?: Date;
	receiptType: string;
	financeType: string;
	relation: string;
	bookAsInvoice: boolean;
	bookings: CompanyCustomIntegrationBookingBooking[];
	attachments: CompanyCustomIntegrationBookingAttachmentRef[];
	combineBookings: boolean;
	bookedByEmail: string;
	paymentMethod: string;
	paymentMethodCode: string;
	documentURL: string;
	categoryCode: string;

	constructor(data) {
		if (!data) {
			return;
		}

		Object.assign(this, data);

		this.date = data.date && Date.parse(data.date) > 0 ? new Date(data.date) : undefined;
		this.bookings =
			data &&
			data.bookings &&
			data.bookings.map((item) => ({
				...item,
				deferredFrom: item.deferredFrom && Date.parse(item.deferredFrom) > 0 ? new Date(item.deferredFrom) : undefined,
				deferredTo: item.deferredTo && Date.parse(item.deferredTo) > 0 ? new Date(item.deferredTo) : undefined,
			}));
	}
}

export interface CompanyCustomIntegrationVATPercentage {
	value: number;
	fromDate?: Date;
	toDate?: Date;
}

export class CustomIntegrationListFilterAPIRequest {
	limit = 50;
	page = 1;

	sort?: string[];

	activeFilters: number;

	updateActiveFilters(toCheck: any[]) {
		this.activeFilters = toCheck.reduce((acc, item) => {
			if (item === undefined || item === null) {
				return acc;
			}
			if ((typeof item === 'string' || Array.isArray(item)) && item.length === 0) {
				return acc;
			}
			return acc + 1;
		}, 0);
	}

	getListAPIBody() {
		const skip = (this.page - 1) * this.limit;
		return {
			limit: this.limit,
			skip: skip,
			sort: this.sort,
		};
	}
}

export class CustomIntegrationDateSelectAPIRequest {
	after?: Date;
	before?: Date;

	constructor(data = null) {
		if (data && data.from) {
			this.after = data.from instanceof Date ? data.from : Date.parse(data.from);
		}
		if (data && data.to) {
			this.before = data.to instanceof Date ? data.to : Date.parse(data.to);
		}
	}

	toAPIBody() {
		return {
			after: this.after && this.after.toISOString(),
			before: this.before && this.before.toISOString(),
		};
	}
}

export class CompanyCustomIntegrationAPIRequestFilter extends CustomIntegrationListFilterAPIRequest {
	division: string;
	status?: number;
	ids: string[];
	fromDate = new CustomIntegrationDateSelectAPIRequest();
	toDate = new CustomIntegrationDateSelectAPIRequest();
	code: string;
	name: string;
	vatInclude?: boolean;
	vatReverseChanges?: boolean;

	constructor(data = null) {
		super();
		if (!data) {
			return;
		}

		Object.assign(this, data);
		this.fromDate = new CustomIntegrationDateSelectAPIRequest(data.fromDate);
		this.toDate = new CustomIntegrationDateSelectAPIRequest(data.toDate);

		this.updateActiveFilters();
	}

	updateActiveFilters() {
		super.updateActiveFilters([
			this.division,
			this.status,
			this.ids,
			this.fromDate && this.fromDate.after,
			this.fromDate && this.fromDate.before,
			this.toDate && this.toDate.after,
			this.toDate && this.toDate.before,
			this.code,
			this.name,
			this.vatInclude,
			this.vatReverseChanges,
		]);
	}

	reset() {
		this.division = undefined;
		this.status = undefined;
		this.ids = undefined;
		this.fromDate = new CustomIntegrationDateSelectAPIRequest();
		this.toDate = new CustomIntegrationDateSelectAPIRequest();
		this.code = undefined;
		this.name = undefined;
		this.vatInclude = undefined;
		this.vatReverseChanges = undefined;
	}

	toAPIBody() {
		return {
			integration: {
				division: this.division || undefined,
				status: this.status,
				ids: this.ids,
				fromDate: this.fromDate && this.fromDate.toAPIBody(),
				toDate: this.toDate && this.toDate.toAPIBody(),
				code: this.code || undefined,
				name: this.name || undefined,
				vatInclude: this.vatInclude,
				vatReverseChanges: this.vatReverseChanges,
			},
			list: this.getListAPIBody(),
		};
	}
}

export class CompanyCustomIntegrationBookingsAPIRequestFilter extends CustomIntegrationListFilterAPIRequest {
	ids?: string[];
	receiptIDs?: string[];
	division?: string;
	description?: string;
	paymentReference?: string;
	currency?: string;
	purchaseOrder?: string;
	invoiceNumber?: string;
	date = new CustomIntegrationDateSelectAPIRequest();
	bookingDate = new CustomIntegrationDateSelectAPIRequest();
	exported?: boolean;
	lastExported = new CustomIntegrationDateSelectAPIRequest();
	receiptType?: string;
	financeType?: string;
	relation?: string;
	bookAsInvoice?: boolean;
	bookedByEmail?: string;
	paymentMethod?: string;
	paymentMethodCode?: string;
	categoryCode?: string;
	integrationPaymentMethods?: Array<string>;
	integrationPaymentMethodTypes?: Array<IntegrationPaymentMethodTypes>;

	constructor(data = null) {
		super();
		if (!data) {
			return;
		}

		Object.assign(this, data);
		this.date = new CustomIntegrationDateSelectAPIRequest(data.date);
		this.lastExported = new CustomIntegrationDateSelectAPIRequest(data.lastExported);

		this.updateActiveFilters();
	}

	updateActiveFilters() {
		super.updateActiveFilters([
			this.ids,
			this.receiptIDs,
			this.division,
			this.description,
			this.paymentReference,
			this.currency,
			this.purchaseOrder,
			this.invoiceNumber,
			this.date && this.date.after,
			this.date && this.date.before,
			this.exported,
			this.lastExported && this.lastExported.after,
			this.lastExported && this.lastExported.before,
			this.receiptType,
			this.financeType,
			this.relation,
			this.bookAsInvoice,
			this.bookedByEmail,
			this.paymentMethod,
			this.paymentMethodCode,
			this.categoryCode,
			this.integrationPaymentMethods,
			this.integrationPaymentMethodTypes,
		]);
	}

	reset() {
		this.ids = undefined;
		this.receiptIDs = undefined;
		this.division = undefined;
		this.description = undefined;
		this.paymentReference = undefined;
		this.currency = undefined;
		this.purchaseOrder = undefined;
		this.invoiceNumber = undefined;
		this.date = new CustomIntegrationDateSelectAPIRequest();
		this.exported = undefined;
		this.lastExported = new CustomIntegrationDateSelectAPIRequest();
		this.receiptType = undefined;
		this.financeType = undefined;
		this.relation = undefined;
		this.bookAsInvoice = undefined;
		this.bookedByEmail = undefined;
		this.paymentMethod = undefined;
		this.paymentMethodCode = undefined;
		this.categoryCode = undefined;
		this.integrationPaymentMethods = undefined;
		this.integrationPaymentMethodTypes = undefined;
	}

	toAPIBody() {
		return {
			integration: {
				ids: this.ids,
				receiptIDs: this.receiptIDs,
				division: this.division || undefined,
				description: this.description || undefined,
				paymentReference: this.paymentReference || undefined,
				currency: this.currency || undefined,
				purchaseOrder: this.purchaseOrder || undefined,
				invoiceNumber: this.invoiceNumber || undefined,
				date: this.date && this.date.toAPIBody(),
				bookingDate: this.bookingDate && this.bookingDate.toAPIBody(),
				exported: this.exported,
				lastExported: this.lastExported && this.lastExported.toAPIBody(),
				receiptType: this.receiptType || undefined,
				financeType: this.financeType || undefined,
				relation: this.relation || undefined,
				bookAsInvoice: this.bookAsInvoice,
				bookedByEmail: this.bookedByEmail || undefined,
				paymentMethod: this.paymentMethod || undefined,
				paymentMethodCode: this.paymentMethodCode || undefined,
				categoryCode: this.categoryCode || undefined,
				integrationPaymentMethods: this.integrationPaymentMethods || undefined,
				integrationPaymentMethodTypes: this.integrationPaymentMethodTypes || undefined,
			},
			list: this.getListAPIBody(),
		};
	}
}

export class CompanyCustomIntegrationData {
	id: string;
	division?: string;
	code: string;
	name: string;
	status: number;
	vatInclude: boolean;
	vatReverseCharge: boolean;
	vatPercentages: CompanyCustomIntegrationVATPercentage[];
	email: string;
	cocNumber: string;
	vatNumber: string;
	bankAccount: string;
	paymentCondition: string;
	paymentMethod: string;
	companyID: string;
	address: Address;
	type: string;
	active: number;
	description: string;

	constructor(data) {
		if (!data) {
			return;
		}

		Object.assign(this, data);

		if (data.vatPercentages) {
			this.vatPercentages = data.vatPercentages.map((item) => ({
				value: item.value,
				fromDate: item.fromDate && Date.parse(item.fromDate) > 0 ? new Date(item.fromDate) : undefined,
				toDate: item.toDate && Date.parse(item.toDate) > 0 ? new Date(item.toDate) : undefined,
			}));
		}
	}
}

export class DimensionLimitationMatch {
	constructor(data) {
		if (data) {
			Object.assign(this, data);
		}
	}
	dimensionType: string;
	when: string;
	isNot: boolean;
	match: string;
	field: string;

	static fromData(data): DimensionLimitationMatch[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new DimensionLimitationMatch(item));
	}

	valueMatches(value: string): boolean {
		if (!value) {
			return false;
		}

		let matches = false;

		const valueLower = value.toLowerCase();
		const matchLower = this.match.toLowerCase();

		if (this.when === 'Equal') {
			matches = valueLower === matchLower;
		} else if (this.when === 'Contains') {
			matches = valueLower.indexOf(matchLower) !== -1;
		} else if (this.when === 'EndsWith') {
			matches = matchLower.length >= valueLower.length && matchLower.substr(matchLower.length - valueLower.length) === valueLower;
		} else if (this.when === 'StartsWith') {
			matches = valueLower.substring(0, matchLower.length) === matchLower;
		}

		if (this.isNot) {
			return !matches;
		}
		return matches;
	}
}

export class DimensionLimitationAllowed {
	constructor(data) {
		if (data) {
			Object.assign(this, data);
		}
	}
	when: string;
	isNot: boolean;
	match: string;

	static fromData(data): DimensionLimitationAllowed[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new DimensionLimitationAllowed(item));
	}

	valueMatches(value: string): boolean {
		let matches = false;

		const valueLower = value.toLowerCase();
		const matchLower = this.match.toLowerCase();

		if (this.when === 'Equal') {
			matches = valueLower === matchLower;
		} else if (this.when === 'Contains') {
			matches = valueLower.indexOf(matchLower) !== -1;
		} else if (this.when === 'EndsWith') {
			matches = matchLower.length >= valueLower.length && matchLower.substr(matchLower.length - valueLower.length) === valueLower;
		} else if (this.when === 'StartsWith') {
			matches = valueLower.substring(0, matchLower.length) === matchLower;
		}

		if (this.isNot) {
			return !matches;
		}
		return matches;
	}
}

export class DimensionLimitation {
	constructor(data) {
		if (data) {
			Object.assign(this, data);
			if (data.createdate) {
				this.createdate = Date.parse(data.createdate) > 0 ? new Date(data.createdate) : null;
			}
			if (data.updatedate) {
				this.updatedate = Date.parse(data.updatedate) > 0 ? new Date(data.updatedate) : null;
			}
			if (data.matches) {
				this.matches = DimensionLimitationMatch.fromData(data.matches);
			}
			if (data.allowed) {
				this.allowed = DimensionLimitationAllowed.fromData(data.allowed);
			}
		}
	}
	id: string;
	createdate: Date;
	updatedate: Date;
	active: boolean;
	matches: DimensionLimitationMatch[];
	allowed: DimensionLimitationAllowed[];
	targetType: string;
	targetField: string;

	static fromData(data): DimensionLimitation[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new DimensionLimitation(item));
	}

	getID() {
		return this.id;
	}

	isActive() {
		return this.active;
	}

	clone(): DimensionLimitation {
		return new DimensionLimitation(JSON.parse(JSON.stringify(this)));
	}
}

export class CompanyDimensionLimitationData {
	company_dimension_limitations: DimensionLimitation[] = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.company_dimension_limitations) {
				this.company_dimension_limitations = DimensionLimitation.fromData(data.company_dimension_limitations);
			}
		}
	}
}

export class CompanyDimensionLimitationListAPIRequest extends CompanyObjectListAPIRequest {
	targetType: string;

	getQueryParams(): QueryParam[] {
		const parts = super.getQueryParams();
		if (this.targetType !== undefined) {
			if (this.targetType) {
				parts.push({ key: 'targetType', value: this.targetType });
			}
		}
		return parts;
	}
	getURL(): string {
		if (this.list) {
			if (this.company && this.company !== '') {
				return `/api/v1/company/${this.company}/companyDimensionLimitation/list`;
			}
			return `/api/v1/companyDimensionLimitation/list`;
		}
		if (this.company && this.company !== '') {
			return `/api/v1/company/${this.company}/companyDimensionLimitation`;
		}
		return `/api/v1/companyDimensionLimitation`;
	}
}

export class IntegrationBookingsExportRequest {
	columns: ReceiptsExportColumn[] = [];
	csv_separator: string;
	decimal_separator: string;
	email: string;
	items: string[] = [];
	type: string;
	save_template: boolean;
	ubl_attachments: boolean;
	ubl_combine_attachments: boolean;
	ubl_add_pdf: boolean;
	email_add_attachments: boolean;
	timezone: string;
	template?: string;

	constructor(data?: any) {
		if (data) {
			Object.assign(this, data);
			this.columns = (data && data.columns && data.columns.map((column) => new ReceiptsExportColumn(column))) || [];
		}
	}
}
