import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CompanyDashboardComponent } from './dm-dashboard/company-dashboard.component';
import { ReportDeclarationsListComponent } from './report-declarations/components/list/report-declarations-list.component';
import { ReportDeclarationsSingleComponent } from './report-declarations/components/single/report-declarations-single.component';
import { ReportDeclarationsManageListComponent } from './report-declarations/components/manage-list/report-declarations-manage-list.component';
import { ReportDeclarationsManageSingleComponent } from './report-declarations/components/manage-single/report-declarations-manage-single.component';
import { ThemeConstants } from '../../shared/config/theme-constant';
import { NgbDateAdapter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDateStringAdapter } from '../../util/date';
import { ReceiptModule } from '../../modules/receipt/receipt.module';
import { MerchantModule } from '../../modules/merchant/merchant.module';
import { UserModule } from '../../modules/user/user.module';
import { FolderModule } from '../../modules/folder/folder.module';
import { NvD3Module } from 'ng2-nvd3';
import { OAuthAuthorizedConnectComponent } from './oauth-authorized-connect/oauth-authorized-connect.component';
import { OAuthAuthorizeComponent } from './oauth-authorize/oauth-authorize.component';
import { UiModule } from '../../shared/ui/ui.module';
import { IconModule } from '../../modules/icon/icon.module';
import { SettingsModule } from './settings/settings.module';
import { AuthenticatedComponent } from './authenticated.component';
import { FoldersComponent } from './folders/folders.component';
import { InboxComponent } from './inbox/inbox.component';
import { InboxModule } from '../../modules/inbox/inbox.module';
import { CompanyModule } from '../../modules/company/company.module';
import { CompanyInvoicesComponent } from './company-invoices/company-invoices.component';
import { TagsComponent } from './tags/tags.component';
import { TagModule } from '../../modules/tag/tag.module';
import { ScopeView } from './oauth-authorize/scope-view/scope-view';
import { ExpenseReportsModule } from '../../modules/expense-reports/expense-reports.module';
import { ExportTemplatesModule } from '~/app/modules/export-templates/export-templates.module';
import { ExportModule } from '~/app/modules/export/export.module';
import { ModalModule } from '~/app/shared/ui/modal/modal.module';
import { CompanySettingsModule } from '~/app/modules/company/components/company-settings/company-settings.module';
import { ExpenseReportBookModal } from '~/app/shared/ui/expense-report-book-modal/expense-report-book-modal';
import { IntegrationRelationModule } from '~/app/shared/ui/forms/composed/pickers/dynamic/integration-relation-picker/integration-relation.module';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		NgbModule,
		UiModule,
		ReceiptModule,
		InboxModule,
		MerchantModule,
		SettingsModule,
		UserModule,
		FolderModule,
		TagModule,
		NvD3Module,
		IconModule,
		CompanyModule,
		ExportTemplatesModule,
		ExpenseReportsModule,
		ExportModule,
		ModalModule,
		CompanySettingsModule,
		ModalModule,
		IntegrationRelationModule,
	],
	declarations: [
		OAuthAuthorizedConnectComponent,
		OAuthAuthorizeComponent,
		DashboardComponent,
		AuthenticatedComponent,
		FoldersComponent,
		TagsComponent,
		InboxComponent,
		CompanyDashboardComponent,
		CompanyInvoicesComponent,
		ReportDeclarationsListComponent,
		ReportDeclarationsSingleComponent,
		ScopeView,
		ReportDeclarationsManageListComponent,
		ReportDeclarationsManageSingleComponent,
		ExpenseReportBookModal,
	],
	exports: [
		OAuthAuthorizedConnectComponent,
		OAuthAuthorizeComponent,
		DashboardComponent,
		AuthenticatedComponent,
		FoldersComponent,
		TagsComponent,
		InboxComponent,
		CompanyDashboardComponent,
		CompanyInvoicesComponent,
		ReportDeclarationsListComponent,
		ReportDeclarationsSingleComponent,
		ScopeView,
		ReportDeclarationsManageListComponent,
		ReportDeclarationsManageSingleComponent,
	],
	providers: [ThemeConstants, { provide: NgbDateAdapter, useClass: NgbDateStringAdapter }],
})
export class AuthenticatedModule {}
