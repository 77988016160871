import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { ExpenseReport, PaginatedExpenseReports, PaginatedExpenseReportsRequest } from '#/models/transaction/expense-reports';
import { AuthenticatedComponent } from '~/app/pages/authenticated/authenticated.component';
import { ActivatedRoute } from '@angular/router';
import { ColumnsInterface, DataTablesParameters } from '~/app/shared/ui/table/table';
import { ActionService, ColumnService, FilterService } from './report-declarations-manage-list.service';
import { SelectActionEnum } from '~/app/shared/ui/select-action/select-action';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { FilterEnum } from '~/app/shared/ui/filters/filter';
import { Subscription } from 'rxjs';
import { Order } from '#/models/utils/order';
import { arrayIsSetAndFilled, getObjectFromKeyValuePairs } from '#/util/arrays';
import { ColumnSortingLocalStorageInterface, DashboardColumnSortingService } from '~/app/services/dashboard-column-sorting.service';
import { ExportFormModal } from '~/app/modules/export/export-form-modal/export-form-modal.component';
import { ModalComponent } from '~/app/shared/ui/modal/modal.component';
import { SelectActionComponent } from '~/app/shared/ui/select-action/select-action.component';
import { Plugin, PluginsService } from '~/app/modules/company/components/company-settings/plugin-settings/plugins.service';
import { ReportType } from '#/models/reportType.model';
import { PageStateService } from '#/services/util/page-state.service';
import { SelectAction } from '#/models/utils/tables';
import { RouteUtilsService } from '#/services/util/route-utils.service';

const DEPRECATED_COLUMN_SORTING_LOCALSTORAGE_KEY = 'column_configuration_reports_manage_list';
const COLUMN_SORTING_LOCALSTORAGE_KEY = 'report-declarations-manage-list_v2022-07-12';
const FILTERS_LOCALSTORAGE_KEY = 'filters-report-declarations-manage-list-v2022-07';

export enum CardUploadModal {
	CREDIT_CARD_VELOS, // Velos plugin with type  'velos-import' or 'velos-import2' required;
	CARD_STATEMENT_MANUAL_UPLOAD, // Module `creditcardManualUpload` needs to be enabled;
}

@Component({
	templateUrl: './report-declarations-manage-list.component.html',
	styleUrls: ['./report-declarations-manage-list.component.scss'],
})
export class ReportDeclarationsManageListComponent extends AuthenticatedComponent implements OnInit, OnDestroy {
	constructor(
		private expenseReportsService: ExpenseReportsService,
		private route: ActivatedRoute,
		private columnService: ColumnService,
		private actionService: ActionService,
		private filterService: FilterService,
		private dashboardColumnSortingService: DashboardColumnSortingService,
		private pluginsService: PluginsService,
		private pageStateService: PageStateService,
		private routeUtilsService: RouteUtilsService,
	) {
		super();
		this.pageStateService.initPageState();
	}

	@ViewChild('EditAuthFlowModal') EditAuthFlowModal: ElementRef;
	@ViewChild('importCreditcardFilesModal') importCreditcardFilesModal: ModalComponent;
	@ViewChild('exportFormModal') exportFormModal: ExportFormModal;
	@ViewChild(SelectActionComponent) selectActionComponent: SelectActionComponent;
	@ViewChild('manualCardUploadModal') manualCardUploadModal: ModalComponent;

	public reportDeclarationsPromise: Promise<PaginatedExpenseReports>;
	public currentReport: ExpenseReport;
	public columns: ColumnsInterface[];
	public filterOptions: FilterEnum[];
	public filtersLocalStorageKey: string = FILTERS_LOCALSTORAGE_KEY;
	private queryParamsSubscription: Subscription = new Subscription();

	public showFilters = false;
	public showSelectActions = false;
	public paginatedExpenseReportsRequest = new PaginatedExpenseReportsRequest(0, 25);
	public selected: {
		rows: Array<string>;
		all: boolean;
	};
	public exportIds: Array<string>;

	private enabledPlugins: Array<Plugin & { id: string }>;
	private predefinedColumns: ColumnsInterface[];
	private prefixedColumns: ColumnsInterface[];
	private endColumns: ColumnsInterface[];
	private routeSubscription: Subscription = new Subscription();
	private reports: ExpenseReport[];

	async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.paginatedExpenseReportsRequest.expenses = true;
		this.paginatedExpenseReportsRequest.company = this.company.id;
		this.paginatedExpenseReportsRequest.sort = 'start_date';
		this.paginatedExpenseReportsRequest.sortorder = Order.DESCENDING;

		this.routeSubscription = this.route.data.subscribe((params) => {
			if (params.tab === 'todo') {
				this.paginatedExpenseReportsRequest.statusses = ['Todo'];
				this.filterOptions = this.filterService.getFilterOptions().filter((e) => {
					switch (e) {
						case FilterEnum.authorization_flow:
						case FilterEnum.statusses:
							return false;
						default:
							return true;
					}
				});
			}
			if (params.tab === 'all') {
				this.paginatedExpenseReportsRequest.statusses = [];
				this.filterOptions = this.filterService.getFilterOptions();
			}
		});
		this.routeSubscription.unsubscribe();

		this.setPredefinedColumns();

		if (!this.dashboardColumnSortingService.hasColumnsSavedLocally(COLUMN_SORTING_LOCALSTORAGE_KEY)) {
			this.dashboardColumnSortingService.setFirstTimeLocalStorageColumnOrder(
				COLUMN_SORTING_LOCALSTORAGE_KEY,
				(this.localStorageService.get(DEPRECATED_COLUMN_SORTING_LOCALSTORAGE_KEY) as ColumnSortingLocalStorageInterface)?.sortedColumns ??
					this.columnService.getColumns(),
				DEPRECATED_COLUMN_SORTING_LOCALSTORAGE_KEY,
			);
		}
		this.columns = this.dashboardColumnSortingService.getColumnsBasedOnSortingOrder(
			COLUMN_SORTING_LOCALSTORAGE_KEY,
			this.predefinedColumns,
			this.prefixedColumns,
			this.endColumns,
		);

		await this.setFilteredReports();
		this.enabledPlugins = await this.pluginsService.getActivePluginsAsArray();

		this.queryParamsSubscription = this.route.queryParams.subscribe(async (params) => {
			await this.updateAPIRequestBasedOnQueryParams();
			this.reportDeclarationsPromise = this.getAndProcessReports();
		});
	}

	// Function that receives the callback from the select action.
	executeRequestedSelectAction(action: SelectActionEnum) {
		switch (action) {
			case SelectActionEnum.edit:
				this.currentReport = this.reports.find((x) => x.id === this.selected.rows[0]);
				this.openEditAuthFlowModal();
				break;
			case SelectActionEnum.export:
				this.setSelectedReportIds();
				this.exportFormModal.openExportModal();
				break;
			default:
				throw new Error(`Missing action to preform: '${action}'`);
		}
		if (!arrayIsSetAndFilled(this.selected.rows)) {
			this.closeSelectActions();
		}
	}

	private async updateAPIRequestBasedOnQueryParams(): Promise<void> {
		if (stringIsSetAndFilled(this.route.snapshot.queryParams.itemsPerPage)) {
			this.paginatedExpenseReportsRequest.max = Number(this.route.snapshot.queryParams.itemsPerPage);
		}

		if (stringIsSetAndFilled(this.route.snapshot.queryParams.page)) {
			this.paginatedExpenseReportsRequest.start =
				(Number(this.route.snapshot.queryParams.page) - 1) * this.paginatedExpenseReportsRequest.max;
		}

		if (stringIsSetAndFilled(this.route.snapshot.queryParams.sort)) {
			this.paginatedExpenseReportsRequest.sort = this.route.snapshot.queryParams.sort;
		}

		if (stringIsSetAndFilled(this.route.snapshot.queryParams.sortOrder)) {
			this.paginatedExpenseReportsRequest.sortorder = this.route.snapshot.queryParams.sortOrder;
		}
	}

	openSelectActions(event: any) {
		this.selected = event;
		this.showSelectActions = true;
		setTimeout(() => {
			// timeout because the action component needs to be rendered first based on `this.showSelectActions = true;`
			this.selectActionComponent.openActionList();
		});
	}

	closeSelectActions() {
		this.showSelectActions = false;
	}

	openFilters() {
		this.showFilters = true;
	}

	closeFilters() {
		this.showFilters = false;
	}

	openEditAuthFlowModal() {
		this.modalService.open(this.EditAuthFlowModal);
	}

	closeEditAuthFlowModal() {
		this.modalService.close(this.EditAuthFlowModal);
		// delay it a bit to get the newest auth flow
		setTimeout(() => {
			this.reportDeclarationsPromise = this.getAndProcessReports();
		}, 500);
	}

	getSelectActions = () => {
		const selectedReports = this.selected.rows.map((selection) => this.reports.find((e) => e.id === selection));
		return this.actionService.getSelectActions(this.user, selectedReports);
	};
	getRowActions = () => {
		return [];
	};

	// This function obtains all the reports, requested and adds the currency and dimension required for the table.
	private async getAndProcessReports(): Promise<PaginatedExpenseReports> {
		delete this.paginatedExpenseReportsRequest.type;
		switch (this.route.snapshot.data.variant) {
			case ReportType.CARD:
				this.paginatedExpenseReportsRequest.type = ReportType.CARD;
				break;
			case ReportType.OUT_OF_POCKET:
				this.paginatedExpenseReportsRequest.type = ReportType.OUT_OF_POCKET;
				break;
		}

		return this.expenseReportsService
			.getExpenseReports(this.paginatedExpenseReportsRequest)
			.then((expenseReports: PaginatedExpenseReports) => {
				this.reports = expenseReports.reports;
				return expenseReports;
			});
	}

	private async setFilteredReports(): Promise<void> {
		const localStoragefilters: PaginatedExpenseReportsRequest = this.localStorageService.get(FILTERS_LOCALSTORAGE_KEY);
		if (isValueSet(localStoragefilters)) {
			const allowedFilterFields = Object.entries(localStoragefilters).filter(([key]) => this.filterOptions.includes(key as FilterEnum));
			Object.assign(this.paginatedExpenseReportsRequest, getObjectFromKeyValuePairs(allowedFilterFields));
		}
		await this.updateAPIRequestBasedOnQueryParams();
		this.reportDeclarationsPromise = this.getAndProcessReports();
	}

	navigateTable(dataTablesParameters: DataTablesParameters) {
		this.paginatedExpenseReportsRequest.start = dataTablesParameters.start;
		this.paginatedExpenseReportsRequest.max = dataTablesParameters.length;
		this.reportDeclarationsPromise = this.getAndProcessReports();
	}

	updateRequestFilters(request: PaginatedExpenseReportsRequest) {
		const allowedFilterFields = Object.entries(request).filter(([key]) => this.filterOptions.includes(key as FilterEnum));
		Object.assign(this.paginatedExpenseReportsRequest, getObjectFromKeyValuePairs(allowedFilterFields));
		this.reportDeclarationsPromise = this.getAndProcessReports();
		this.closeFilters();
	}

	setPredefinedColumns() {
		this.predefinedColumns = this.columnService.getColumns();
		this.prefixedColumns = this.predefinedColumns.filter((e) => e.position === 'pre');
		this.endColumns = this.predefinedColumns.filter((e) => e.position === 'end');
	}

	sortTable(sortedColumnsObj) {
		this.columns = sortedColumnsObj.sortedColumns;
		this.dashboardColumnSortingService.setColumnSortingToLocalStorage(sortedColumnsObj.sortedColumns, COLUMN_SORTING_LOCALSTORAGE_KEY);
		this.reportDeclarationsPromise = this.getAndProcessReports();
	}

	onRowClicked(id: string) {
		this.router.navigate([`./${id}`], { relativeTo: this.route });
	}

	public onTabTodoClicked(): void {
		const newUrl: string = this.routeUtilsService.getUpdateRouteWithNewParamValue(
			`${this.getParentUrl()}/todo`,
			this.route.snapshot.queryParams,
			{
				page: 1,
			},
		);
		this.router.navigateByUrl(newUrl);

		this.paginatedExpenseReportsRequest.statusses = ['Todo'];
		this.reportDeclarationsPromise = this.getAndProcessReports();
	}

	public onTabAllClicked(): void {
		const newUrl: string = this.routeUtilsService.getUpdateRouteWithNewParamValue(
			`${this.getParentUrl()}/all`,
			this.route.snapshot.queryParams,
			{
				page: 1,
			},
		);
		this.router.navigateByUrl(newUrl);
		this.paginatedExpenseReportsRequest.statusses = [];
		this.reportDeclarationsPromise = this.getAndProcessReports();
	}

	private getParentUrl(): string {
		switch (this.route.snapshot.data.variant) {
			case ReportType.CARD:
				return '/manage-reports/card';
			case ReportType.OUT_OF_POCKET:
				return '/manage-reports/out-of-pocket';
			default:
				return '/manage-reports';
		}
	}

	public fetchSortedColumns(event: { sortingProperty: string; sortingOrder: Order }) {
		this.paginatedExpenseReportsRequest.sort = event.sortingProperty;
		this.paginatedExpenseReportsRequest.sortorder = event.sortingOrder;
		this.reportDeclarationsPromise = this.getAndProcessReports();
	}

	public updateRouteWithSortingParams(event: { sortingProperty: string; sortingOrder: Order }): void {
		const newUrl: string = this.routeUtilsService.getUpdateRouteWithNewParamValue(
			this.router.url.split('?')[0],
			this.route.snapshot.queryParams,
			{
				sort: event.sortingProperty,
				sortOrder: event.sortingOrder,
			},
		);
		this.router.navigateByUrl(newUrl);
	}

	private setSelectedReportIds(): void {
		this.exportIds = this.selected.rows;
	}

	public openImportCreditcardFilesModal(): void {
		this.importCreditcardFilesModal.openModal();
	}

	public closeImportCreditcardFilesModal(): void {
		this.importCreditcardFilesModal.closeModal();
		this.reportDeclarationsPromise = this.getAndProcessReports();
	}

	public isCardDashboard(): boolean {
		return this.route.snapshot.data.variant === ReportType.CARD;
	}

	public openUploadModal(): void {
		if (this.getCardModalToShow() === CardUploadModal.CARD_STATEMENT_MANUAL_UPLOAD) {
			this.openCardManualCardUploadModal();
		} else if (this.getCardModalToShow() === CardUploadModal.CREDIT_CARD_VELOS) {
			this.openImportCreditcardFilesModal();
		}
	}

	public getCardModalToShow(): CardUploadModal | null {
		if (!this.user.hasCompanyFinanceRole() || !this.isCardDashboard()) {
			return null;
		}

		if (this.company.modules?.velos?.enabled) {
			const pluginTypes = ['velos-import', 'velos-import2'];
			if (this.enabledPlugins?.some((p) => pluginTypes.includes(p.type))) {
				return CardUploadModal.CREDIT_CARD_VELOS;
			}
		}

		if (this.company.modules?.creditcardManualUpload?.enabled) {
			return CardUploadModal.CARD_STATEMENT_MANUAL_UPLOAD;
		}

		return null;
	}

	private openCardManualCardUploadModal(): void {
		this.manualCardUploadModal.openModal();
	}

	public selectAllReportsOnAllPages = async (selectAction: SelectAction): Promise<Array<string>> => {
		this.selected = await this.expenseReportsService.getSelectedForAllExpenseReports(selectAction, this.paginatedExpenseReportsRequest);
		return this.selected.rows;
	};

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.queryParamsSubscription.unsubscribe();
	}
}
