import { Component, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyComponent } from '~/app/modules/company/components/company.component';
import { Observable, Subscription } from 'rxjs';
import { Receipt, ReceiptListAPIRequest } from '#/models/transaction/receipt';
import * as fromReceipt from '../../../modules/receipt/models/receipt.reducer';
import { select } from '@ngrx/store';
import { needsReceiptListUpdate, WSMessage } from '#/models/websocket.model';
import { AddReceipts, ClearReceipts } from '~/app/modules/receipt/models/receipt.actions';
import { cloneDeep } from 'lodash';
import { importReceiptModalInBackground } from '~/app/modules/receipt/components/modals/lazy';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ConfirmModalComponent } from '~/app/shared/ui/confirm-modal/confirm-modal.component';
import { ReceiptFiltersService, SEARCH_FILTER_LOCALSTORAGE_KEY } from '~/app/modules/receipt/receipt-filters.service';
import { Order } from '#/models/utils/order';
import { InterfaceFrontendModel as TransactionInterfaceModel } from '#/models/transaction/interface/frontendModel';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { PossibleCompanyFeatureFlags } from '#/models/company/possible-feature-flags';
import { CompanyFeatureFlagsService } from '#/services/company/company-feature-flags.service';
import { TransactionInterfaceService } from '#/services/transaction/transaction-interface.service';
import { TransactionEditorService } from '#/services/transaction/transaction-editor.service';
import { TransactionType } from '#/models/transaction/transactionType';
import { PageStateService } from '#/services/util/page-state.service';
import { DashboardChecklistTab } from '#/models/dashboardChecklist';
import { RouteUtilsService } from '#/services/util/route-utils.service';

@Component({
	selector: 'app-company-invoices',
	templateUrl: './company-invoices.component.html',
	styleUrls: ['./company-invoices.component.scss'],
})
export class CompanyInvoicesComponent extends CompanyComponent implements OnInit, OnDestroy {
	@ViewChild('receiptsTable') receiptsTable;
	receipts: Observable<Receipt[]>;
	receiptsPerPage = 25;
	currentPage = 1;
	receiptCount = 0;
	initialLoaded = false;
	checkboxes: {};
	checkAll: {
		checked: boolean;
	};
	filtered = false;
	allowedUserStates = [];
	protected activeTab = 'todo';
	protected subscription;
	todoCount: number = null;
	transactionInterfaces: Array<TransactionInterfaceModel> = undefined;
	searchSubscription: Subscription;
	private queryParamsSubscription: Subscription = new Subscription();

	constructor(
		protected route: ActivatedRoute,
		protected receiptFiltersService: ReceiptFiltersService,
		private transactionEditorService: TransactionEditorService,
		private transactionInterfaceService: TransactionInterfaceService,
		private companyFeatureFlagsService: CompanyFeatureFlagsService,
		private pageStateService: PageStateService,
		private routeUtilsService: RouteUtilsService,
	) {
		super();
		this.receipts = this.store.select('receipt').pipe(select(fromReceipt.selectAllReceipts));
		this.checkboxes = {};
		this.checkAll = { checked: false };

		this.subscription = this.route.params.subscribe((params) => {
			this.activeTab = params.tab;
			if (this.filters !== undefined && this.user) {
				this.setDeclarationstatus(params.tab);
				this.loadReceipts();
			}
		});
	}

	get filters() {
		return this.receiptFiltersService.filters;
	}

	get defaultFilters() {
		return this.receiptFiltersService.defaultFilters;
	}

	set filters(filters) {
		this.receiptFiltersService.filters = filters;
	}

	set defaultFilters(filters) {
		this.receiptFiltersService.defaultFilters = filters;
	}

	ngOnInit(): void {
		super.ngOnInit();

		const websocketSubscription = this.websocketsService.onMessage.subscribe((message: WSMessage) => {
			// Refresh receipts list, might be the receipt was in our current page.
			if (needsReceiptListUpdate(message.type)) {
				// only reload the table if we have no modals open
				// otherwise we lose all kinds of subscriptions that cause the 'next receipt' function to be killed in the open modal
				if (this.pageStateService.isPageStateSaved()) {
					this.currentPage = this.pageStateService.getPageNumber();
				}
				this.loadReceipts(false, true);
			}
		});

		this.destroyCallbacks.push(() => {
			websocketSubscription.unsubscribe();
		});

		const receiptsPerPage = this.localStorageService.get('dashboard_receipts_per_page') as number;
		if (receiptsPerPage != null) {
			this.receiptsPerPage = Number(receiptsPerPage);
		}

		if (isValueSet(this.company)) {
			this.companyFeatureFlagsService.getAllFeatureFlags(this.company.id).then((flags) => {
				if (flags.includes(PossibleCompanyFeatureFlags.TRANSACTION_INTERFACES)) {
					this.transactionInterfaceService.getInterfaces(true, false, true, null, true).then((res) => {
						this.transactionInterfaces = res.filter((e) => {
							return e.transactionType === TransactionType.Invoice && this.user.canSubmitCompanyInvoices();
						});
					});
				}
			});
		}
		this.filters = new ReceiptListAPIRequest();
		this.filters.sortorder = Order.DESCENDING;
		this.filters.sort = 'purchasedate';
		this.filters.company = true;
		this.filters.isinvoice = '1';
		this.filters.declarations = true;
		this.filters.companyID = this.company.id;
		this.defaultFilters = this.filters.clone();
		this.filters = Object.assign(this.filters, new ReceiptListAPIRequest(JSON.parse(this.localStorageService.get('filters-invoices'))));

		if (!stringIsSetAndFilled(this.filters.search) && stringIsSetAndFilled(this.localStorageService.get(SEARCH_FILTER_LOCALSTORAGE_KEY))) {
			this.filters.search = this.localStorageService.get(SEARCH_FILTER_LOCALSTORAGE_KEY);
		}

		this.updateStateBasedOnQueryParams();
		this.setDeclarationstatus(this.activeTab);
		this.filters.company = true;
		this.filters.isinvoice = '1';
		this.filters.declarations = true;
		this.filters.companyID = this.company.id;

		this.allowedUserStates = this.user.getViewableUserStates('invoices');
		this.pageStateService.initPageState();
		this.loadReceipts();

		this.queryParamsSubscription = this.route.queryParams.subscribe((params) => {
			this.updateStateBasedOnQueryParams();
			this.loadReceipts();
		});

		this.searchSubscription = this.receiptFiltersService.searchSubject.subscribe(() => {
			this.updateFilters();
		});
		this.destroyCallbacks.push(() => {
			this.searchSubscription.unsubscribe();
		});

		this.on(
			this.subCompanyServiceDeprecated.subCompanyChanged.subscribe((company) => {
				this.updateFilters();
			}),
		);

		importReceiptModalInBackground();
	}

	private updateStateBasedOnQueryParams(): void {
		if (stringIsSetAndFilled(this.route.snapshot.params.tab)) {
			if (this.route.snapshot.params.tab === DashboardChecklistTab.TODO.toLowerCase()) {
				this.filters.predefinedFilter = DashboardChecklistTab.TODO;
			} else if (this.route.snapshot.params.tab === DashboardChecklistTab.ALL.toLowerCase()) {
				this.filters.predefinedFilter = null;
				this.showAllTabMessage();
			}
		}

		if (stringIsSetAndFilled(this.route.snapshot.queryParams.itemsPerPage)) {
			this.filters.max = Number(this.route.snapshot.queryParams.itemsPerPage);
			this.receiptsPerPage = Number(this.route.snapshot.queryParams.itemsPerPage);
		}

		if (stringIsSetAndFilled(this.route.snapshot.queryParams.page)) {
			this.filters.start = (Number(this.route.snapshot.queryParams.page) - 1) * (this.filters.max ?? this.receiptsPerPage);
			this.currentPage = Number(this.route.snapshot.queryParams.page);
		}

		if (stringIsSetAndFilled(this.route.snapshot.queryParams.sort)) {
			this.filters.sort = this.route.snapshot.queryParams.sort;
		}

		if (stringIsSetAndFilled(this.route.snapshot.queryParams.sortOrder)) {
			this.filters.sortorder = this.route.snapshot.queryParams.sortOrder;
		}
	}

	public updateReceiptsPerPage(): void {
		this.localStorageService.set('dashboard_receipts_per_page', this.receiptsPerPage);
		this.currentPage = 1;
		this.updateQueryParams({ itemsPerPage: this.receiptsPerPage, page: this.currentPage });
		this.checkAll = { checked: false };
		this.checkboxes = {};
		this.loadReceipts(true, false);
	}

	public updateFilters(): void {
		this.currentPage = 1;
		this.updateQueryParams({ page: this.currentPage });
		this.filtered = true;
		this.checkAll = { checked: false };
		this.checkboxes = {};
		this.loadReceipts();
	}

	private setDeclarationstatus(status: string): void {
		this.checkAll = { checked: false };
		this.checkboxes = {};

		if (status === DashboardChecklistTab.TODO.toLowerCase()) {
			this.filters.predefinedFilter = DashboardChecklistTab.TODO;
		} else if (status === DashboardChecklistTab.ALL.toLowerCase()) {
			this.filters.predefinedFilter = null;
			this.showAllTabMessage();
		}
	}

	showAllTabMessage() {
		const shownAllTabHint = this.localStorageService.get('shown-all-tab-hint');
		if (!shownAllTabHint) {
			this.notificationService.info(_('Tip: you can filter on a specific status using the "Filters" button!'), {
				timeout: 5000,
			});
			this.localStorageService.set('shown-all-tab-hint', true);
		}
	}

	public loadReceipts(resetTable = true, loadTodo = true): void {
		if (resetTable) {
			this.initialLoaded = false;
		}
		this.filters.companyID = this.company.id;
		this.filters.max = this.receiptsPerPage;
		this.filters.start = (this.currentPage - 1) * this.receiptsPerPage;
		this.filters.countActiveFilters('invoices', this.defaultFilters);

		this.transactionEditorService.setLatestDashboardFilters(this.filters);
		this.receiptAPIService.getReceipts(this.filters).then((r) => {
			this.receiptCount = r['data']['count'];
			const Receipts: any[] = r['data']['receipts'];
			const newReceipts: Receipt[] = [];
			if (Receipts != null) {
				Receipts.map((receipt) => newReceipts.push(new Receipt(receipt)));
			}
			this.store.dispatch(new ClearReceipts());
			this.store.dispatch(new AddReceipts({ receipts: newReceipts }));
			this.initialLoaded = true;
		});

		if (loadTodo) {
			this.getTodoItems();
		}
	}

	private getTodoItems(): void {
		const filters = this.filters.clone();
		filters.max = 1;
		filters.start = 0;
		filters.predefinedFilter = DashboardChecklistTab.TODO;

		this.receiptAPIService.getReceipts(filters).then((r) => {
			this.todoCount = r['data']['count'];
		});
	}

	pageChanged(newPage: number): void {
		this.currentPage = newPage;
		this.checkAll = { checked: false };
		this.pageStateService.setCurrentPage(newPage);
		this.loadReceipts(false, false);
	}

	currentPageReceipts(): number {
		return Math.min(this.currentPage * this.receiptsPerPage, this.receiptCount);
	}

	selectAllReceiptsPage(filters: ReceiptListAPIRequest, per_page: number = 100, page: number = 1) {
		filters.max = per_page;
		filters.start = (page - 1) * per_page;
		this.receiptAPIService.getReceipts(filters).then((r) => {
			const Receipts: any[] = r['data']['receipts'];
			const newReceipts: Receipt[] = [];
			if (Receipts != null) {
				Receipts.forEach((receipt) => {
					this.checkboxes[receipt.id] = true;
				});
			}
			if (r['data']['moreresults']) {
				return this.selectAllReceiptsPage(filters, per_page, page + 1);
			}
		});
	}

	getSelectedCount(): number {
		return Object.keys(this.checkboxes).filter((checkbox) => this.checkboxes[checkbox]).length;
	}

	deselectAllSelected() {
		this.checkboxes = {};
		this.checkAll = { checked: false };
	}

	selectedAllReceipts() {
		this.checkAll = { checked: true };
		const filters = cloneDeep(this.filters);
		this.selectAllReceiptsPage(filters, 50);
	}

	async addReceipt(new_receipt_type: string) {
		const { ReceiptEditModalComponent } = await import(
			'~/app/modules/receipt/components/modals/receipt-edit-modal/receipt-edit-modal.component'
		);

		const modalRef = this.modalService.open(ReceiptEditModalComponent, {
			size: 'lg',
			beforeDismiss: () => {
				if (modalRef.componentInstance.hasReceiptBeenChanged()) {
					return new Promise((resolve, reject) => {
						const confirmModalRef = this.modalService.open(ConfirmModalComponent);
						confirmModalRef.componentInstance.type = 'text';
						confirmModalRef.componentInstance.title = this.translate.instant(_('Dismiss changes'));
						confirmModalRef.componentInstance.message = this.translate.instant(_('Are you sure that you want to dismiss your changes?'));
						const sub = confirmModalRef.componentInstance.result.subscribe((data) => {
							resolve(data.result);
							sub.unsubscribe();
						});
						this.destroyCallbacks.push(() => {
							sub.unsubscribe();
						});
					});
				}
			},
		});
		modalRef.componentInstance.receipt = null;
		modalRef.componentInstance.user = this.user;
		modalRef.componentInstance.new_receipt_type = new_receipt_type;
		modalRef.componentInstance.is_invoice = true;
		modalRef.componentInstance.tableType = 'invoices';

		try {
			await modalRef.result;
		} catch (err) {}
		this.loadReceipts();
	}

	async bulkUpload() {
		const { BulkUploadModalComponent } = await import(
			'../../../modules/receipt/components/modals/bulk-upload-modal/bulk-upload-modal.component'
		);

		const modalRef = this.modalService.open(BulkUploadModalComponent);
		modalRef.componentInstance.user = this.user;
		modalRef.componentInstance.is_invoice = true;

		try {
			await modalRef.result;
		} finally {
			this.loadReceipts();
		}

		const doneSubscription = modalRef.componentInstance.done.subscribe(() => {
			this.loadReceipts();
		});

		this.destroyCallbacks.push(() => {
			if (doneSubscription) {
				doneSubscription.unsubscribe();
			}
		});
	}

	async documentSplit() {
		const { DocumentSplitModalComponent } = await import(
			'../../../modules/receipt/components/modals/document-split-modal/document-split-modal.component'
		);

		const modalRef = this.modalService.open(DocumentSplitModalComponent);
		modalRef.componentInstance.user = this.user;
		modalRef.componentInstance.is_invoice = true;

		try {
			await modalRef.result;
		} finally {
			this.loadReceipts();
		}

		const doneSubscription = modalRef.componentInstance.done.subscribe(() => {
			this.loadReceipts();
		});

		this.destroyCallbacks.push(() => {
			if (doneSubscription) {
				doneSubscription.unsubscribe();
			}
		});
	}

	public navigateToTab(tab: string): void {
		const newUrl: string = this.routeUtilsService.getUpdateRouteWithNewParamValue(`/dm/invoices/${tab}`, this.route.snapshot.queryParams, {
			page: 1,
		});
		this.setDeclarationstatus(tab);
		this.loadReceipts();
		this.router.navigateByUrl(newUrl);
	}

	public updateQueryParams(newParams: Record<string, any>): void {
		const newUrl: string = this.routeUtilsService.getUpdateRouteWithNewParamValue(
			this.router.url.split('?')[0],
			this.route.snapshot.queryParams,
			newParams,
		);
		this.router.navigateByUrl(newUrl);
	}

	public updateRouteWithSortingParams(event: { sort: string; sortOrder: Order }): void {
		const newUrl: string = this.routeUtilsService.getUpdateRouteWithNewParamValue(
			this.router.url.split('?')[0],
			this.route.snapshot.queryParams,
			{
				sort: event.sort,
				sortOrder: event.sortOrder,
				page: 1,
			},
		);
		this.router.navigateByUrl(newUrl);
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.queryParamsSubscription.unsubscribe();
	}
}
