<klp-grid-container [fluid]="true">
	<app-nav-and-cta>
		<klp-gretel-bar></klp-gretel-bar>
		<div class="d-flex">
			<app-button
				variant="greenFilled"
				id="openFilters"
				aria-haspopup="true"
				aria-expanded="false"
				(click)="receiptsTable.openFilters($event)"
			>
				{{ 'Filters' | translate }}
				<span class="badge badge-white mb-0 ml-2" *ngIf="filters.activeFilters > 0">{{ filters.activeFilters }}</span>
			</app-button>
			<app-button
				data-cy="btnDropDown"
				variant="greenFilled"
				id="addDropdown"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
				*ngIf="user.canSubmitCompanyInvoices() && !isSubCompanyActive()"
			>
				<i class="ti-plus"></i> {{ 'Add' | translate }}
			</app-button>
			<div class="dropdown-menu line-height dropdown-menu-right" aria-labelledby="addDropdown">
				<ng-container *ngIf="transactionInterfaces === undefined">
					<button class="dropdown-item" (click)="addReceipt('receipt')" data-cy="btnAddInvoice">
						{{ 'Add invoice' | translate }}
					</button>
					<button class="dropdown-item" (click)="documentSplit()" *ngIf="user.canDocumentSplit()">
						{{ 'Document splitter' | translate }}
					</button>
					<button class="dropdown-item" (click)="bulkUpload()" *ngIf="user.canBulkUpload()">
						{{ 'Bulk upload' | translate }}
					</button>
				</ng-container>
				<div *ngIf="transactionInterfaces !== undefined && transactionInterfaces.length >= 1">
					<a
						*ngFor="let transactionInterface of transactionInterfaces"
						class="dropdown-item"
						routerLink="tx/new"
						[queryParams]="{ 'tx-interface-id': transactionInterface.id, 'view-mode': 'submit' }"
					>
						{{ transactionInterface.menuCaption }}
					</a>
					<a class="dropdown-item" (click)="documentSplit()" *ngIf="user.canDocumentSplit()" translate> Document splitter </a>
					<a class="dropdown-item" (click)="bulkUpload()" *ngIf="user.canBulkUpload()" translate> Bulk upload </a>
				</div>
			</div>
		</div>
	</app-nav-and-cta>
	<div class="page-title">
		<ul class="nav nav-pills mb-4" role="tablist">
			<li class="nav-item">
				<a class="nav-link" [ngClass]="{ active: activeTab === 'todo' }" role="tab" data-toggle="tab" (click)="navigateToTab('todo')"
					>{{ 'To do' | translate }} <span class="badge badge-white mb-0 ml-2" *ngIf="todoCount !== null">{{ todoCount }}</span></a
				>
			</li>
			<li class="nav-item">
				<a
					class="nav-link"
					[ngClass]="{ active: activeTab === 'all' }"
					role="tab"
					data-toggle="tab"
					(click)="navigateToTab('all')"
					translate
					>All</a
				>
			</li>
		</ul>
	</div>

	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-block">
					<app-loader-deprecated *ngIf="!initialLoaded"></app-loader-deprecated>
					<div *ngIf="initialLoaded" id="DataTables_Table_1_wrapper" class="dataTables_wrapper no-footer">
						<div class="dataTables_length" *ngIf="receiptCount > 0">
							<div class="float-left table-header-action-row">
								<label
									>{{ 'Show' | translate
									}}<select
										name="DataTables_Table_1_length"
										aria-controls="DataTables_Table_1"
										class=""
										[(ngModel)]="receiptsPerPage"
										(ngModelChange)="updateReceiptsPerPage()"
									>
										<option value="10">10</option>
										<option value="25">25</option>
										<option value="50">50</option>
										<option value="100">100</option>
									</select>
									{{ 'invoices' | translate }}</label
								>
							</div>

							<div class="float-left table-header-action-row mrg-left-25" *ngIf="getSelectedCount() > 0">
								<label>
									{{ getSelectedCount() }}
									<span *ngIf="getSelectedCount() > 1" translate>invoices selected</span>
									<span *ngIf="getSelectedCount() === 1" translate>invoice selected</span>
									<button
										class="btn btn-primary mrg-left-15 table-header-button px-3"
										(click)="selectedAllReceipts()"
										translate
										*ngIf="getSelectedCount() === (receipts | async).length && getSelectedCount() != receiptCount"
									>
										Select invoices on all pages
									</button>
									<button
										class="btn btn-primary mrg-left-15 table-header-button px-3"
										(click)="deselectAllSelected()"
										translate
										*ngIf="getSelectedCount() > (receipts | async).length || getSelectedCount() == receiptCount"
									>
										Deselect all selected invoices
									</button>
								</label>
							</div>
						</div>
						<app-receipts-table
							#receiptsTable
							[receipts]="receipts | async"
							[tableType]="'invoices'"
							[filters]="filters"
							[defaultFilters]="defaultFilters"
							[(checkboxes)]="checkboxes"
							[(checkAll)]="checkAll"
							(filtersChanged)="updateFilters()"
							(onSortingChange)="updateFilters(); updateRouteWithSortingParams($event)"
							[filtered]="filtered"
							(receiptsChanged)="loadReceipts($event)"
						></app-receipts-table>
						<div
							class="dataTables_info"
							role="status"
							aria-live="polite"
							[innerHTML]="
								'Showing %countmin% to %countmax% of %counttotal% invoices'
									| translate
										: { countmin: (currentPage - 1) * receiptsPerPage + 1, countmax: currentPageReceipts(), counttotal: receiptCount }
							"
							*ngIf="receiptCount > 0"
						></div>
						<app-pager
							[currentPage]="currentPage"
							[totalItems]="receiptCount"
							[itemsPerPage]="receiptsPerPage"
							[maxSize]="7"
							(pageChange)="pageChanged($event); updateQueryParams({ page: $event })"
							*ngIf="receiptCount > 0"
						></app-pager>
					</div>
				</div>
			</div>
		</div>
	</div>
</klp-grid-container>
