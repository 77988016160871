import { Inject, Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { DOCUMENT } from '@angular/common';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { NotificationService } from '~/app/services/notification.service';
import {
	DocumentSplit,
	DocumentSplitSubmit,
	MatchSupplier,
	Receipt,
	ReceiptAuthorizationFlowStatus,
	ReceiptListAPIRequest,
	ReceiptsExportRequest,
} from '#/models/transaction/receipt';
import { SharingResponse } from '#/models/sharing';
import { MessageTypes, WSMessage } from '#/models/websocket.model';
import { WebsocketsService } from '~/app/websockets/services/websockets.service';
import { isValueSet } from '#/util/values';
import { SelectAction, SelectedRows } from '#/models/utils/tables';

@Injectable({
	providedIn: 'root',
})
export class ReceiptService {
	private receiptCache = new Map<string, { timestamp: number; data: Promise<Receipt> }>();
	public receipt;

	constructor(
		protected apiService: APIService,
		@Inject(DOCUMENT) protected document: any,
		protected notifications: APINotificationsService,
		protected notificationService: NotificationService,
		private websocketService: WebsocketsService,
	) {
		this.websocketService.onMessageMyselfIncluded.subscribe((message: WSMessage) => {
			switch (message.type) {
				case MessageTypes.ReceiptUpdate:
				case MessageTypes.ReceiptRemove:
				case MessageTypes.ReceiptDeclaration:
				case MessageTypes.ReceiptDeclarationUpdate:
				case MessageTypes.ReceiptDeclarationDisable:
				case MessageTypes.ReceiptDeclarationEnable:
					this.receiptCache.delete(message.data.receipt);
					this.apiService.deleteFromCacheWhereUrlStartsWith('receipt/suggestions');
			}
		});
	}

	getReceipts(request: ReceiptListAPIRequest) {
		return this.apiService
			.get(request.getRequestURL())
			.then((res) => {
				res.data?.receipts?.forEach((receipt) => {
					this.receiptCache.set(receipt.id, { timestamp: new Date().getTime(), data: Promise.resolve(new Receipt(receipt)) });
				});
				return res;
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				throw r;
			});
	}

	getAllReceipts(request: ReceiptListAPIRequest, results: Receipt[] = []): Promise<Receipt[]> {
		if (!request.start) {
			request.start = 0;
		}
		if (!request.max) {
			request.max = 100;
		}
		return this.apiService
			.get(request.getRequestURL())
			.then((r) => {
				results = results.concat(Receipt.fromData(r.data.receipts ?? []));
				if (r.data.moreresults) {
					request.start += request.max;
					return this.getAllReceipts(request, results);
				} else {
					return Promise.resolve(results);
				}
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	getReceipt(id: string, showNotificationOnError = true, fromCacheIfNewerThanMsAgo: number = 0): Promise<Receipt> {
		if (fromCacheIfNewerThanMsAgo > 0) {
			const cached = this.receiptCache.get(id);
			if (cached && cached.timestamp + fromCacheIfNewerThanMsAgo > new Date().getTime()) {
				return cached.data;
			}
		}
		const promise = this.apiService
			.get('/api/v1/receipt/' + id)
			.then((r) => {
				return new Receipt(r['data']);
			})
			.catch((r) => {
				this.receiptCache.delete(id);
				if (showNotificationOnError) {
					this.notifications.handleAPIError(r);
				}
				throw r;
			});
		this.receiptCache.set(id, { timestamp: new Date().getTime(), data: promise });
		return promise;
	}

	public async getSelectedForAllReceipts(selectAction: SelectAction, request: ReceiptListAPIRequest): Promise<SelectedRows> {
		if (selectAction === 'deselectAll') {
			return { all: false, rows: [] };
		}
		request.start = null;
		const rows = await this.getAllReceipts(request).then((res) => res?.map((e) => e.id));
		return { all: true, rows };
	}

	getReceiptAuthorizationFlowStatus(id: string): Promise<ReceiptAuthorizationFlowStatus> {
		return this.apiService
			.get('/api/v1/receipt/' + id + '/authorizationFlow')
			.then((r) => {
				const receiptAuthorizationFlowStatus = new ReceiptAuthorizationFlowStatus(r['data']);
				return Promise.resolve(receiptAuthorizationFlowStatus);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	setReceiptAuthorizationFlowStatus(
		id: string,
		authorizationFlowID: string,
		approvers: string[],
		requireApproverOrder: boolean,
		requireApproverCount?: number,
	): Promise<Receipt> {
		this.receiptCache.delete(id);
		this.apiService.deleteFromCacheWhereUrlStartsWith('receipt/suggestions');
		const body = {
			authorization_flow_id: authorizationFlowID,
			approvers: approvers,
			require_approver_order: requireApproverOrder,
			require_approver_count: requireApproverCount >= 1 ? requireApproverCount : approvers?.length,
		};
		return this.apiService
			.post('/api/v1/receipt/' + id + '/authorizationFlow', body)
			.then((r) => {
				return new Receipt(r['data']);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				throw r;
			});
	}

	prependToReceiptAuthorizationFlow(id: string, approver: string): Promise<Receipt> {
		this.receiptCache.delete(id);
		this.apiService.deleteFromCacheWhereUrlStartsWith('receipt/suggestions');
		const body = {
			prepend_user: approver,
		};
		return this.apiService
			.post('/api/v1/receipt/' + id + '/authorizationFlow/prepend', body)
			.then((r) => {
				const updated_receipt = new Receipt(r['data']);
				return Promise.resolve(updated_receipt);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	deleteReceipt(id: string) {
		this.receiptCache.delete(id);
		this.apiService.deleteFromCacheWhereUrlStartsWith('receipt/suggestions');
		return this.apiService
			.delete('/api/v1/receipt/' + id)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	createReceipt(receipt: Receipt): Promise<Receipt> {
		// Detect new merchant, if it starts with new-.
		if (receipt.merchant && receipt.merchant.startsWith('new-')) {
			receipt.custommerchant = receipt.merchant.substr(4);
			receipt.merchant = '';
		}

		let url = '/api/v1/receipt';
		if (receipt.group && receipt.group !== '') {
			url += '/group/' + receipt.group;
		}

		return this.apiService
			.post(url, receipt)
			.then((r) => {
				this.apiService.deleteFromCacheWhereUrlStartsWith('receipt/suggestions');
				const updated_receipt = new Receipt(r['data']);
				return updated_receipt;
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				throw r;
			});
	}

	updateReceipt(receipt: Receipt, displayErrors = true): Promise<Receipt> {
		this.receiptCache.delete(receipt.id);
		this.apiService.deleteFromCacheWhereUrlStartsWith('receipt/suggestions');
		// Detect new merchant, if it starts with new-.
		if (isValueSet(receipt.merchant) && receipt.merchant.startsWith('new-')) {
			receipt.custommerchant = receipt.merchant.substr(4);
			receipt.merchant = '';
		}
		if (receipt.group === null) {
			receipt.group = '';
		}
		receipt.needs_ocr_review = false;
		return this.apiService
			.patch('/api/v1/receipt/' + receipt.id, receipt)
			.then((r) => {
				const result = new Receipt(r['data']);
				result.VATincluded = receipt.VATincluded;
				return result;
			})
			.catch((r) => {
				if (displayErrors) {
					this.notifications.handleAPIError(r);
				}
				throw r;
			});
	}

	submitExpense(id: string, isTag?: boolean) {
		this.receiptCache.delete(id);
		this.apiService.deleteFromCacheWhereUrlStartsWith('receipt/suggestions');
		return this.apiService.post('/api/v1/receipt/' + id + '/declaration', null).catch((r) => {
			if (!isTag) {
				this.notifications.handleAPIError(r);
			}
			return Promise.reject(r);
		});
	}

	OCRReviewed(id: string) {
		return this.apiService.post('/api/v1/receipt/' + id + '/ocr_reviewed', null);
	}

	forceOCR(id: string) {
		return this.apiService.post('/api/v1/receipt/' + id + '/forceOCR', null).catch((r) => {
			this.notifications.handleAPIError(r);
			return Promise.reject(r);
		});
	}

	resubmitExpense(id: string, status, comment) {
		this.receiptCache.delete(id);
		this.apiService.deleteFromCacheWhereUrlStartsWith('receipt/suggestions');
		const payload = {
			status: status,
			comment: comment,
		};
		return this.apiService.patch(`/api/v1/receipt/${id}/declaration`, payload).catch((r) => {
			this.notifications.handleAPIError(r);
			return Promise.reject(r);
		});
	}

	retractExpense(id: string, isTag?: boolean) {
		this.receiptCache.delete(id);
		this.apiService.deleteFromCacheWhereUrlStartsWith('receipt/suggestions');
		return this.apiService.delete('/api/v1/receipt/' + id + '/declaration').catch((r) => {
			if (!isTag) {
				this.notifications.handleAPIError(r);
			}
			return Promise.reject(r);
		});
	}

	submitStatus(id: string, status: object) {
		this.receiptCache.delete(id);
		this.apiService.deleteFromCacheWhereUrlStartsWith('receipt/suggestions');
		const payload = status;
		return this.apiService
			.patch('/api/v1/receipt/' + id + '/declaration', payload)
			.then((r) => {
				return new Receipt(r.data);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				throw r;
			});
	}

	exportReceipts(request: ReceiptsExportRequest) {
		return this.apiService
			.post('/api/v1/receipt/export', request)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	acceptShare(id: string, trustedcontact: boolean): Promise<SharingResponse> {
		const payload = {
			trustedcontact: trustedcontact,
		};
		return this.apiService
			.post(`/api/v1/receipt/${id}/acceptshare`, payload)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	declineShare(id: string): Promise<SharingResponse> {
		return this.apiService
			.post(`/api/v1/receipt/${id}/declineshare`, null)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	postSplitDocumentFile(file: File): Promise<DocumentSplit> {
		const formData = new FormData();
		formData.append('document', file);
		return this.apiService
			.post('/api/v1/receipt/documentSplitter', formData)
			.then((r) => {
				const documentsplit = new DocumentSplit(r['data']);
				return Promise.resolve(documentsplit);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	postSplitDocument(token: string, payload: DocumentSplitSubmit): Promise<Receipt[]> {
		return this.apiService
			.post('/api/v1/receipt/documentSplitter/' + token, payload)
			.then((r) => {
				const receipts = r['data']['receipts'] as any[];
				return Promise.resolve(receipts.map((receipt_data) => new Receipt(receipt_data)));
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	getDocumentSplitterThumbnail(token: string, page: number): Promise<Blob> {
		return this.apiService
			.getBlob('/api/v1/receipt/documentSplitter/' + token + '/thumbnails/' + page)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	splitReceipt(receiptId: string): Promise<Receipt[]> {
		const url = `receipt/${receiptId}/split`;
		return this.apiService.postToApi(url).then((r) => r.data.new_receipts);
	}

	bookExpense(receipt: Receipt, timezone: string): Promise<Receipt> {
		const bookBody = {
			provider: receipt.provider,
			division: receipt.division,
			original_division: receipt.original_division,
			journal: receipt.journal,
			bookings: receipt.bookings,
			gAccount: receipt.gAccount,
			booking_date: receipt.booking_date,
			timezone: timezone,
			payment_condition: receipt.payment_condition,
			payment_method: receipt.integration_payment_method,
		};

		const url = `/api/v1/receipt/${receipt.id}/integrations/book`;

		return this.apiService
			.post(url, bookBody)
			.then((r) => {
				const updated_receipt = new Receipt(r['data']);
				return Promise.resolve(updated_receipt);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	removeDuplicate(receiptId: string, duplicateId: string): Promise<any> {
		return this.apiService
			.delete(`/api/v1/receipt/${receiptId}/removeDuplicate/${duplicateId}`)
			.then((r) => {
				const updatedReceipt = new Receipt(r['data']);
				return Promise.resolve(updatedReceipt);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	duplicateReceiptByFinance(receiptId: string): Promise<Receipt> {
		return this.apiService
			.postToApi(`receipt/${receiptId}/duplicate`)
			.then((result) => {
				return new Receipt(result.data);
			})
			.catch((error) => {
				this.notifications.handleAPIError(error);
				throw error;
			});
	}

	inviteUser(email: string): Promise<any> {
		const payload = {
			email: email,
		};
		return this.apiService
			.post('/api/v1/user/invite', payload)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {});
	}

	matchSupplier(id: string, provider: string, division: string): Promise<MatchSupplier> {
		return this.apiService
			.get(`/api/v1/receipt/${id}/matchSupplier?provider=${provider}&division=${division}`)
			.then((r) => {
				const matchSupplier = new MatchSupplier(r['data']);
				return Promise.resolve(matchSupplier);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}
}
