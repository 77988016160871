export class InvoiceSuggestionApiModel {
	accountnumber: string;
	companyadministration: string;
	companycategory: string;
	companycostcenter: string;
	companycostunit: string;
	companyproject: string;
	payment_method_id: string;
	paymentmethod: string;

	constructor(data?: any) {
		Object.assign(this, data);
	}
}
