<app-dynamic-options-picker
	[getPlaceholderTextFn]="getPlaceholderTextFn"
	[fetchItemsFn]="fetchItemsFn"
	[fetchSelectedItemsFn]="fetchSelectedItemsFn"
	[mapToSelectOptionFn]="mapToSelectOptionFn"
	[sortValuesFn]="sortValuesFn"
	[selectableItems]="selectableItems"
	[ngClass]="{ showErrors: isInErrorState() }"
	[(ngModel)]="innerValue"
	(ngModelChange)="setInnerValueAndNotify($event)"
	[multiple]="multiple"
	[disabled]="disabled"
	[clearable]="clearable"
	[dropdownPosition]="dropdownPosition"
	(onFooterClick)="createCustomMerchant($event)"
	[newOptionFooterPrefix]="'Add new merchant' | translate"
></app-dynamic-options-picker>
