export interface TreeNode {
	id: any;
	type: 'leaf' | 'node';
	name: string;
	value: string;
	label: string;
	parent: TreeNode;
	children: TreeNode[];
	settings: NodeSettings;
	attachedData: any;
	isNodeToPreventNodeBecomingLeaf: boolean;
	isCustomValue: boolean;
}

export interface FlatTreeNode extends TreeNode {
	expandable: boolean;
	level: number;
}

export class NodeSettings {
	custom_label: string;
	context: TreeNode[];
	allowedContext: TreeNode[];
	value: string;
	dateFormat: string;
	attributes: NodeAttribute[];
}

export interface NodeAttribute {
	name: string;
	value: string | TreeNode;
	isDynamicValue: boolean;
}
