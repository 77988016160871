<div
	class="app"
	[ngClass]="{
		'is-collapsed': app.layout.isMenuCollapsed,
		'is-opened': !app.layout.isMenuCollapsed,
		'forced-closed': app.layout.forceMenuCollapsed,
		rtl: app.layout.rtlActived,
		'compact-mode': user && user.getPreferences().display_mode === 'compact'
	}"
>
	<div class="layout" [ngClass]="headerSelected">
		<div [ngClass]="sidenavSelected">
			<!-- Side Nav START -->
			<div class="side-nav" sideBar>
				<div class="side-nav-inner">
					<div class="side-nav-logo">
						<ng-container *ngIf="!environment.feature_flags.new_logo_link; else alternativeLinkBlock">
							<a [href]="" [routerLink]="homepage" class="brand-logo">
								<ng-container *ngIf="!smallLogo || !largeLogo">
									<img
										class="brand-logo-collapsed"
										src="/assets/images/logo/logo-{{ theme }}-sm.png"
										srcset="/assets/images/logo/logo-{{theme}}-sm@2x.png 2x, /assets/images/logo/logo-{{theme}}-sm@3x.png 3x"
									/>
									<img
										class="brand-logo-expanded"
										src="/assets/images/logo/logo-{{ theme }}.png"
										srcset="/assets/images/logo/logo-{{theme}}@2x.png 2x, /assets/images/logo/logo-{{theme}}@3x.png 3x"
									/>
								</ng-container>
								<ng-container *ngIf="smallLogo && largeLogo">
									<img class="brand-logo-collapsed" [src]="smallLogo" />
									<img class="brand-logo-expanded" [src]="largeLogo" />
								</ng-container>
							</a>
						</ng-container>
						<ng-template #alternativeLinkBlock>
							<a [href]="environment.feature_flags.new_logo_link" class="brand-logo">
								<ng-container *ngIf="!smallLogo || !largeLogo">
									<img
										class="brand-logo-collapsed"
										src="/assets/images/logo/logo-{{ theme }}-sm.png"
										srcset="/assets/images/logo/logo-{{theme}}-sm@2x.png 2x, /assets/images/logo/logo-{{theme}}-sm@3x.png 3x"
									/>
									<img
										class="brand-logo-expanded"
										src="/assets/images/logo/logo-{{ theme }}.png"
										srcset="/assets/images/logo/logo-{{theme}}@2x.png 2x, /assets/images/logo/logo-{{theme}}@3x.png 3x"
									/>
								</ng-container>
								<ng-container *ngIf="smallLogo && largeLogo">
									<img class="brand-logo-collapsed" [src]="smallLogo" />
									<img class="brand-logo-expanded" [src]="largeLogo" />
								</ng-container>
							</a>
						</ng-template>
						<div class="mobile-toggle side-nav-toggle">
							<a href="javascript:void(0);" (click)="app.layout.isMenuCollapsed = !app.layout.isMenuCollapsed">
								<i class="ti-arrow-circle-left"></i>
							</a>
						</div>
					</div>
					<ul class="side-nav-menu scrollable pdd-top-30">
						<li
							class="nav-item dropdown"
							routerLinkActive="active"
							*ngIf="user.canUseDashboard() && !isSubCompanyActive() && !company?.modules?.cardReports?.enabled"
						>
							<a [routerLink]="['/dashboard']" data-cy="btnDashboard">
								<span class="icon-holder">
									<i class="ti-home"></i>
								</span>
								<span class="title">{{ translationReplaceService.myKlippa }}</span>
							</a>
						</li>

						<li
							class="nav-item dropdown"
							routerLinkActive="active"
							*ngIf="user.canUseDashboard() && user.canSubmitExpenses() && getAmountOfReportModulesEnabled() === 1"
						>
							<a href="" [routerLink]="['/reports']">
								<span class="icon-holder">
									<app-icon variant="folder" size="large"></app-icon>
								</span>
								<span class="title" translate>My reports</span>
							</a>
						</li>

						<li
							class="nav-item dropdown open"
							[ngClass]="{ 'd-none': !(user.canUseDashboard() && user.canSubmitExpenses() && getAmountOfReportModulesEnabled() >= 2) }"
						>
							<a class="dropdown-toggle" style="pointer-events: 'none'">
								<span class="icon-holder">
									<app-icon variant="folder"></app-icon>
								</span>
								<span class="title" translate>My reports</span>
							</a>

							<ul class="dropdown-menu-static">
								<li class="nav-item dropdown" routerLinkActive="active">
									<a routerLink="/reports/card" translate>Card</a>
								</li>
								<li class="nav-item dropdown" routerLinkActive="active">
									<a routerLink="/reports/out-of-pocket" translate>Out&nbsp;of&nbsp;pocket</a>
								</li>
							</ul>
						</li>

						<li
							class="nav-item dropdown"
							routerLinkActive="active"
							*ngIf="user && user.getPreferences().groups && !environment.feature_flags.disable_folders && !isSubCompanyActive()"
						>
							<a class="dropdown-toggle" routerLink="/folders">
								<span class="icon-holder">
									<i class="ti-folder"></i>
								</span>
								<span class="title" translate>Folders</span>
							</a>
						</li>
						<li class="nav-item dropdown" routerLinkActive="active" *ngIf="user && user.getPreferences().tags && !isSubCompanyActive()">
							<a class="dropdown-toggle" routerLink="/tags">
								<span class="icon-holder">
									<i class="ti-tag"></i>
								</span>
								<span class="title" translate>Tags</span>
							</a>
						</li>

						<li
							class="nav-item dropdown"
							routerLinkActive="active"
							*ngIf="company && company.canUseExpenses() && user.canManageExpenses() && getAmountOfReportModulesEnabled() < 2"
						>
							<a *ngIf="getAmountOfReportModulesEnabled() === 0" [routerLink]="['/dm/dashboard']">
								<span class="icon-holder">
									<i class="ti-check"></i>
								</span>
								<span class="title">{{
									isAdministrationOffice() ? ('Manage documents' | translate) : ('Manage expenses' | translate)
								}}</span>
							</a>
							<a *ngIf="getAmountOfReportModulesEnabled() === 1" [routerLink]="['/manage-reports']">
								<span class="icon-holder">
									<i class="ti-check"></i>
								</span>
								<span class="title" translate>Manage reports</span>
							</a>
						</li>

						<li
							class="nav-item dropdown open"
							[ngClass]="{ 'd-none': !(user.canManageExpenses() && getAmountOfReportModulesEnabled() >= 2) }"
						>
							<a class="dropdown-toggle" style="pointer-events: 'none'">
								<span class="icon-holder">
									<app-icon variant="folder"></app-icon>
								</span>
								<span class="title" translate>Manage reports</span>
							</a>

							<ul class="dropdown-menu-static">
								<li class="nav-item dropdown" routerLinkActive="active">
									<a routerLink="/manage-reports/card" translate>Card</a>
								</li>
								<li class="nav-item dropdown" routerLinkActive="active">
									<a routerLink="/manage-reports/out-of-pocket" translate>Out of pocket</a>
								</li>
							</ul>
						</li>

						<li class="nav-item dropdown" routerLinkActive="active" *ngIf="company && company.canUseInvoices() && user.canUseInvoices()">
							<a [routerLink]="['/dm/invoices']" data-cy="btnManageInvoices">
								<span class="icon-holder">
									<i class="ti-receipt"></i>
								</span>
								<span class="title" translate>Manage invoices</span>
							</a>
						</li>

						<li
							class="nav-item dropdown"
							routerLinkActive="active"
							*ngIf="company && company.canUseCreditcardStatements() && user.canUseCreditcardStatements()"
						>
							<a routerLink="/dm/creditcard-statements/">
								<span class="icon-holder">
									<i class="ti-credit-card"></i>
								</span>
								<span class="title" translate>Credit cards</span></a
							>
						</li>

						<li
							class="nav-item dropdown"
							routerLinkActive="active"
							*ngIf="user && (user.hasCompanyAdminRole() || user.hasCompanyFinanceRole()) && manualBookkeepingIntegrationEnabled"
						>
							<a routerLink="/dm/manual-integration/">
								<span class="icon-holder">
									<i class="ti-files"></i>
								</span>
								<span class="title" translate>Bookkeeping integration</span>
							</a>
						</li>

						<li class="nav-item dropdown" routerLinkActive="active" *ngIf="showBookingDataTab">
							<a routerLink="/dm/manual-bookkeeping-integration-data/">
								<span class="icon-holder">
									<i class="ti-files"></i>
								</span>
								<span class="title" translate>Bookkeeping data</span>
							</a>
						</li>

						<li class="nav-item dropdown" [ngClass]="{ 'd-none': !showStatisticsLink() || !showStatisticsAsDropdown() }">
							<a class="dropdown-toggle">
								<span class="icon-holder">
									<i class="ti-bar-chart"></i>
								</span>
								<span class="title" translate>Statistics</span>
								<span class="arrow">
									<i class="ti-angle-right"></i>
								</span>
							</a>

							<ul class="dropdown-menu">
								<li class="nav-item dropdown" routerLinkActive="active">
									<a routerLink="/statistics">
										<span class="subTitle" translate>Personal</span>
									</a>
								</li>
								<li class="nav-item dropdown subTitle" routerLinkActive="active">
									<a routerLink="/dm/statistics/insights">
										<span class="subTitle" translate>Company</span>
									</a>
								</li>
							</ul>
						</li>

						<li class="nav-item dropdown" routerLinkActive="active" *ngIf="showStatisticsLink() && !showStatisticsAsDropdown()">
							<a class="dropdown-toggle" [routerLink]="getStatisticsLink()">
								<span class="icon-holder">
									<i class="ti-bar-chart"></i>
								</span>
								<span class="title" translate>Statistics</span>
							</a>
						</li>

						<li class="nav-item dropdown" [ngClass]="{ 'd-none': !(showCompanySettingsDropdown(company?.id) | async) }">
							<a class="dropdown-toggle">
								<span class="icon-holder">
									<i class="ti-settings"></i>
								</span>
								<span class="title" translate>Settings</span>
								<span class="arrow">
									<i class="ti-angle-right"></i>
								</span>
							</a>

							<ul class="dropdown-menu">
								<li class="nav-item dropdown" routerLinkActive="active">
									<a routerLink="/settings">
										<span class="subTitle" translate>Personal</span>
									</a>
								</li>
								<li class="nav-item dropdown" routerLinkActive="active">
									<a routerLink="/dm/settings/" data-cy="btnCompanySettings">
										<span class="subTitle" translate>Company</span>
									</a>
								</li>
							</ul>
						</li>

						<li class="nav-item dropdown" routerLinkActive="active" *ngIf="!(showCompanySettingsDropdown(company?.id) | async)">
							<a class="dropdown-toggle" routerLink="/settings">
								<span class="icon-holder">
									<i class="ti-settings"></i>
								</span>
								<span class="title" translate>Settings</span>
							</a>
						</li>

						<li class="nav-item dropdown" [ngClass]="(showAdminDropdown(company?.id) | async) ? '' : 'd-none'">
							<a class="dropdown-toggle">
								<span class="icon-holder">
									<i class="ti-crown"></i>
								</span>
								<span class="title" translate>Admin</span>
								<span class="arrow">
									<i class="ti-angle-right"></i>
								</span>
							</a>

							<ul class="dropdown-menu">
								<li class="nav-item dropdown">
									<a routerLink="/companies/" data-cy="btnAdminCompany">
										<span class="subTitle" translate>Companies</span>
									</a>
								</li>
								<li class="nav-item dropdown">
									<a routerLink="/admin/users/">
										<span class="subTitle" translate>Users</span>
									</a>
								</li>
								<li class="nav-item dropdown">
									<a routerLink="/icons/">
										<span class="subTitle" translate>Icons</span>
									</a>
								</li>
								<li class="nav-item dropdown">
									<a routerLink="/merchants/">
										<span class="subTitle" translate>Merchants</span>
									</a>
								</li>
								<li class="nav-item dropdown">
									<a routerLink="/admin/oauth-apps/">
										<span class="subTitle" translate>OAuth Apps</span>
									</a>
								</li>
								<li class="nav-item dropdown">
									<a routerLink="/admin/export-templates/">
										<span class="subTitle" translate>Export templates</span>
									</a>
								</li>
							</ul>
						</li>

						<li class="nav-item dropdown" routerLinkActive="active">
							<a target="_blank" [attr.href]="helpLink">
								<span class="icon-holder">
									<i class="ti-help"></i>
								</span>
								<span class="title" translate>Helpdesk</span>
							</a>
						</li>

						<div class="appVersion">{{ appVersion }}</div>
					</ul>
				</div>
			</div>
			<!-- Side Nav END -->

			<!-- Page Container START -->
			<div class="page-container">
				<!-- Top Nav START -->
				<div class="header navbar">
					<div class="header-container">
						<ul class="nav-left" *ngIf="!app.layout.forceMenuCollapsed">
							<li>
								<a class="side-nav-toggle" href="javascript:void(0);" (click)="app.layout.isMenuCollapsed = !app.layout.isMenuCollapsed">
									<i class="ti-menu"></i>
								</a>
							</li>
						</ul>

						<div class="nav middleNav">
							<div class="dynamicContent">
								<ng-container [ngTemplateOutlet]="dynamicHeaderContent"></ng-container>
							</div>
							<ul class="defaultNavigationItems">
								<li *ngIf="user.canBecomePro()">
									<app-button variant="greenFilled" routerLink="/settings/pro" translate>Get more out of Klippa</app-button>
								</li>
								<li *ngIf="receiptFiltersService.filters !== undefined && showSearchBar()">
									<klp-form-text-input
										[(ngModel)]="receiptFiltersService.filters.search"
										(ngModelChange)="receiptFiltersService.queryChanged(); updateSearchParam($event)"
										[clearable]="true"
										icon="search"
										[placeholder]="
											user.hasProRole() || user.hasCompanyRole()
												? ('Description or invoice number' | translate)
												: ('Description' | translate)
										"
									></klp-form-text-input>
								</li>

								<li *ngIf="showSubCompanies()">
									<app-company-sub-company-picker></app-company-sub-company-picker>
								</li>

								<li *ngIf="user.hasReadAdminRoleOrHigher(true)">
									<div class="nav-search-bar">
										<app-user-role-switcher></app-user-role-switcher>
									</div>
								</li>

								<li app-inbox-notification class="notifications dropdown"></li>
							</ul>
						</div>

						<ul class="nav-right">
							<li class="user-profile dropdown">
								<a href="" class="dropdown-toggle" data-toggle="dropdown">
									<img class="profile-img img-fluid" *ngIf="user && user.getAvatarURL()" [src]="user && user.getAvatarURL()" alt="" />
									<div class="user-info">
										<span class="name pdd-right-5" *ngIf="user">{{ user.getDisplayName() }}</span>
										<i class="ti-angle-down font-size-10"></i>
									</div>
								</a>
								<ul class="dropdown-menu">
									<ngx-async-template [promise]="impersonationListPromise">
										<ng-template ngx-async-success let-persons="value">
											<ng-container *ngIf="persons.length > 0">
												<ng-container *ngFor="let person of persons">
													<li class="impersonationItem" (click)="impersonate(person.id)">
														<span translate>Impersonate: </span>
														<span>{{ person.caption }}</span>
													</li>
												</ng-container>
												<hr />
											</ng-container>
										</ng-template>
									</ngx-async-template>
									<li>
										<a data-cy="btnLogoutUser" href="javascript:void(0)" (click)="logoutUser()">
											<i class="ti-power-off pdd-right-10"></i>
											<span translate>Logout</span>
										</a>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
				<!-- Top Nav END -->

				<app-side-panel></app-side-panel>

				<!-- Content Wrapper START -->
				<div class="main-content">
					<router-outlet></router-outlet>
				</div>
				<!-- Content Wrapper END -->

				<!-- Footer START -->
				<footer class="content-footer">
					<div class="footer">
						<div class="copyright" [ngClass]="theme === appTheme.KLIPPA ? 'klippaAndTermsAndPolicyInfo' : 'onlyTermsAndPolicyInfo'">
							<div *ngIf="theme === appTheme.KLIPPA">{{ themed.getThemeProductTitle() }} © {{ currentYear }}</div>
							<div>
								<a [attr.href]="getTermsAndConditionsLink()" target="_blank" class="text-gray mrg-right-15" translate
									>Terms and Conditions</a
								>
								<a [attr.href]="getPrivacyStatementLink()" target="_blank" class="text-gray" translate>Privacy statement</a>
							</div>
						</div>
					</div>
				</footer>
				<!-- Footer END -->
			</div>
			<!-- Page Container END -->
		</div>
	</div>
</div>
