<klp-grid-container [fluid]="true">
	<div class="page-title">
		<div class="d-flex justify-content-between">
			<h4 class="text-left">
				{{ themed.getThemeProductTitle() }}<ng-container *ngIf="company"> / {{ company.getName() }}</ng-container>
			</h4>
			<app-button
				variant="greenFilled"
				id="openFilters"
				aria-haspopup="true"
				aria-expanded="false"
				(click)="receiptsTable.openFilters($event)"
			>
				{{ 'Filters' | translate }}
				<span class="badge badge-white mb-0 ml-2" *ngIf="filters.activeFilters > 0">{{ filters.activeFilters }}</span>
			</app-button>
		</div>
		<ul class="nav nav-pills mb-4" role="tablist">
			<li class="nav-item">
				<a class="nav-link" [ngClass]="{ active: activeTab === 'todo' }" role="tab" data-toggle="tab" (click)="navigateToTab('todo')"
					>{{ 'To do' | translate }} <span class="badge badge-white mb-0 ml-2" *ngIf="todoCount !== null">{{ todoCount }}</span></a
				>
			</li>
			<li class="nav-item">
				<a
					class="nav-link"
					[ngClass]="{ active: activeTab === 'all' }"
					role="tab"
					data-toggle="tab"
					(click)="navigateToTab('all')"
					translate
					>All</a
				>
			</li>
		</ul>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-block">
					<app-loader-deprecated *ngIf="!initialLoaded"></app-loader-deprecated>
					<div *ngIf="initialLoaded" id="DataTables_Table_1_wrapper" class="dataTables_wrapper no-footer">
						<div class="dataTables_length" *ngIf="receiptCount > 0">
							<div class="float-left table-header-action-row">
								<label
									>{{ 'Show' | translate
									}}<select
										name="DataTables_Table_1_length"
										aria-controls="DataTables_Table_1"
										class=""
										[(ngModel)]="receiptsPerPage"
										(ngModelChange)="updateReceiptsPerPage()"
									>
										<option value="10">10</option>
										<option value="25">25</option>
										<option value="50">50</option>
										<option value="100">100</option>
									</select>
									{{ 'transactions' | translate }}</label
								>
							</div>

							<div class="float-left table-header-action-row mrg-left-25" *ngIf="getSelectedCount() > 0">
								<label>
									{{ getSelectedCount() }} <span translate>transactions selected</span>
									<button
										class="btn btn-primary mrg-left-15 table-header-button px-3"
										(click)="selectedAllReceipts()"
										translate
										*ngIf="getSelectedCount() === (receipts | async).length && getSelectedCount() != receiptCount"
									>
										Select transactions on all pages
									</button>
									<button
										class="btn btn-primary mrg-left-15 table-header-button px-3"
										(click)="deselectAllSelected()"
										translate
										*ngIf="getSelectedCount() > (receipts | async).length || getSelectedCount() == receiptCount"
									>
										Deselect all selected transactions
									</button>
								</label>
							</div>
						</div>
						<app-receipts-table
							#receiptsTable
							[receipts]="receipts | async"
							[tableType]="'expenses'"
							[filters]="filters"
							[defaultFilters]="defaultFilters"
							[(checkboxes)]="checkboxes"
							[(checkAll)]="checkAll"
							(filtersChanged)="updateFilters()"
							(onSortingChange)="updateFilters(); updateRouteWithSortingParams($event)"
							[filtered]="filtered"
							(receiptsChanged)="loadReceipts($event)"
							(changePage)="pageChanged($event)"
						></app-receipts-table>
						<div
							class="dataTables_info"
							role="status"
							aria-live="polite"
							[innerHTML]="
								'Showing %countmin% to %countmax% of %counttotal% transactions'
									| translate
										: { countmin: (currentPage - 1) * receiptsPerPage + 1, countmax: currentPageReceipts(), counttotal: receiptCount }
							"
							*ngIf="receiptCount > 0"
						></div>
						<app-pager
							[currentPage]="currentPage"
							[totalItems]="receiptCount"
							[itemsPerPage]="receiptsPerPage"
							[maxSize]="7"
							(pageChange)="pageChanged($event); updateQueryParams({ page: $event })"
							*ngIf="receiptCount > 0"
						></app-pager>
					</div>
				</div>
			</div>
		</div>
	</div>
</klp-grid-container>
