import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { UserService } from '~/app/modules/user/user.service';
import { AppSelectOption, ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { filterEmptyStringValues, filterObjectOnCondition, filterUnsetValues, objectToQueryParamString } from '#/util/objects';
import { UI_TYPE } from '#/models/uiType';
import { CompanyService } from '#/services/company/company.service';
import { AccountingBookingType } from '#/models/transaction/bookingType';
import { TransactionApiModel } from '#/models/transaction/transaction/apiModel';
import { DynamicFormElementParameters } from '#/models/dynamic-form-element';
import {
	AccountingCapability,
	AccountingFormFieldType,
	AccountingFormType,
	AccountingIntegrationConfigurationField,
	AccountingIntegrationConnectionResponse,
	AccountingIntegrationV2,
	AccountingIntegrationV2Authorization,
	AccountingIntegrationV2AuthorizationUpdateRequest,
	AccountingIntegrationV2Capabilities,
	BasicConfigInfo,
	BookedBatch,
	BookedBatchesFilters,
	BookedBatchOfTransactionsResponse,
	CompleteConfigField,
	ConnectionInfo,
	IndividualPickerValue,
	QueuedBookingsFilters,
	QueuedForBookingResponse,
	StaticDashboardColumnData,
	UserInfo,
} from '#/models/accounting-integrations/accounting-integration-v2';
import { RelationType } from '#/models/accounting-integrations/accountingRelationType';
import { BookingFieldLevel } from '#/models/accounting-integrations/BookingFieldLevel';
import { apiToFrontend } from '#/models/transaction/transaction/transformer';
import { TransactionFrontendModel } from '#/models/transaction/transaction/frontendModel';
import { ImportResult } from '#/models/import-result.model';
import { memoizeFull } from '#/util/functions';
import { DefaultFilters } from '#/models/defaultFilters.model';

export interface NecessaryDataForGettingSelectedValue {
	authorizationId: string;
	fieldKey: string;
	selectedValueId: string;
	context: Record<string, any>;
	bookingType: AccountingBookingType;
}

export interface DynamicBookingField {
	uiType: UI_TYPE;
	label: string;
	key: string;
	description: string;
	level: BookingFieldLevel;
	options: {
		readOnly: boolean;
		suggestions: {
			enabled: boolean;
			overwrite: boolean;
			column: boolean;
		};
		refresh: boolean;
		creatable: boolean;
		required: boolean;
		validatable: boolean;
	};
	relations: Array<{
		key: string;
		types: Array<RelationType>;
	}>;
}

export interface DynamicAccountingIntegrationField extends DynamicBookingField {
	description: string;
	value?: any;
}

@Injectable({
	providedIn: 'root',
})
export class AccountingIntegrationV2Service {
	constructor(
		private apiService: APIService,
		private notifications: APINotificationsService,
		private userService: UserService,
		private companyService: CompanyService,
	) {}

	public getAllAccountingIntegrations(companyId: string): Promise<Array<AccountingIntegrationV2>> {
		return this.apiService
			.getFromApi(`company/${companyId}/accounting/integrations`)
			.then((r) => r.data.clients.map((e) => new AccountingIntegrationV2(e)))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getAccountingIntegrationLogo(integrationId: string, companyId: string): Promise<File> {
		return this.apiService.getBlob(`/api/v1/company/${companyId}/accounting/integrations/${integrationId}/logo`).catch((e) => {
			this.notifications.handleAPIError(e);
			throw e;
		});
	}

	public getAllV2ConnectedAccountingIntegrations(companyId: string = null): Promise<Array<AccountingIntegrationV2Authorization>> {
		return this.apiService
			.getFromApi(`company/${companyId ?? this.companyService.getCompanyId()}/accounting/authorizations`)
			.then((r) => r.data.map((e) => new AccountingIntegrationV2Authorization(e)))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getAccountingIntegrationByAuthId(
		companyId: string = null,
		authorizationId: string,
	): Promise<AccountingIntegrationV2Authorization> {
		return this.apiService
			.getFromApi(`company/${companyId}/accounting/authorizations/${authorizationId}`)
			.then((r) => r.data)
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getAccountingIntegrationByIntegrationId(integrationId: string): Promise<AccountingIntegrationV2> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		return this.apiService
			.getFromApi(`company/${companyId}/accounting/integrations/${integrationId}`)
			.then((r) => new AccountingIntegrationV2(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getAccountingIntegrationAuthFields(
		companyId: string,
		integrationId: string,
	): Promise<Array<AccountingIntegrationConfigurationField>> {
		return this.apiService
			.postToApi(`company/${companyId}/accounting/integrations/${integrationId}/authFields`, {
				context: {}, // context will always be empty at this stage
			})
			.then((r) => r.data?.map((e) => new AccountingIntegrationConfigurationField(e)))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getConnectedAccountingIntegrationAuthFields(
		companyId: string,
		authorizationId: string,
	): Promise<Array<AccountingIntegrationConfigurationField>> {
		return this.apiService
			.postToApi(`company/${companyId}/accounting/authorizations/${authorizationId}/connection/authFields`, {
				context: {}, // context will always be empty at this stage
			})
			.then((r) => r.data?.authenticationFields?.map((e) => new AccountingIntegrationConfigurationField(e)))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getConfigFieldsForConnectedV2Integration(
		companyId: string,
		authorizationId: string,
	): Promise<Array<AccountingIntegrationConfigurationField>> {
		return this.apiService
			.postToApi(`company/${companyId}/accounting/authorizations/${authorizationId}/connection/configFields`, {
				context: {}, // context will always be empty at this stage
			})
			.then((r) => r.data?.configurationFields?.map((e) => new AccountingIntegrationConfigurationField(e)))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getAccountingIntegrationAuthenticationUrl(
		companyId: string,
		integrationId: string,
		redirectUrl: string,
		authFields: Record<string, any>,
	): Promise<string> {
		return this.apiService
			.postToApi(`company/${companyId}/accounting/authorizations/dialog`, {
				integrationId: integrationId,
				authFields: authFields,
				redirectUrl: redirectUrl,
			})
			.then((r) => r.data.authenticationUrl)
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getAccountingIntegrationPreConnectionAuthenticationUrl(
		companyId: string,
		authorizationId: string,
		redirectUrl: string,
		authFields: Record<string, any>,
	): Promise<string> {
		return this.apiService
			.postToApi(`company/${companyId}/accounting/authorizations/${authorizationId}/dialog`, {
				integrationId: authorizationId,
				authFields: authFields,
				redirectUrl: redirectUrl,
			})
			.then((r) => r.data.authenticationUrl)
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public connectToAccountingIntegrationV2(
		companyId: string,
		integrationId: string,
		integrationTitle: string,
		parameters: Record<string, string>,
	): Promise<AccountingIntegrationConnectionResponse> {
		return this.apiService
			.postToApi(`company/${companyId}/accounting/authorizations/connect`, {
				integrationId: integrationId,
				description: integrationTitle,
				parameters: parameters,
			})
			.then((r) => new AccountingIntegrationConnectionResponse(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public reconnectToAccountingApiV2Integration(
		companyId: string,
		authorizationId: string,
		integrationId: string,
		integrationTitle: string,
		parameters: Record<string, string>,
	): Promise<AccountingIntegrationV2Authorization> {
		return this.apiService
			.postToApi(`company/${companyId}/accounting/authorizations/${authorizationId}/reconnect`, {
				integrationId: integrationId,
				description: integrationTitle,
				parameters: parameters,
			})
			.then((r) => new AccountingIntegrationV2Authorization(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public updateAccountingIntegrationV2Settings(
		companyId: string,
		authorizationId: string,
		authorizationInfo: AccountingIntegrationV2AuthorizationUpdateRequest,
	): Promise<AccountingIntegrationV2Authorization> {
		return this.apiService
			.patchToApi(`company/${companyId}/accounting/authorizations/${authorizationId}`, authorizationInfo)
			.then((r) => new AccountingIntegrationV2Authorization(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getConnectedV2Integration(companyId: string, authorizationId: string): Promise<AccountingIntegrationV2Authorization> {
		return this.apiService
			.getFromApi(`company/${companyId}/accounting/authorizations/${authorizationId}`)
			.then((r) => new AccountingIntegrationV2Authorization(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public deleteAccountingApiV2Integration(
		companyId: string,
		authorizationId: string,
	): Promise<{ data: []; request_id: string; result: string }> {
		return this.apiService.deleteToApi(`company/${companyId}/accounting/authorizations/${authorizationId}`).catch((e) => {
			this.notifications.handleAPIError(e);
			throw e;
		});
	}

	public getDropdownAuthOptions(
		companyId: string,
		integrationId: string,
		authField: BasicConfigInfo,
		context: Record<string, any>,
		start: number,
		searchQuery: string,
	): ItemsWithHasMoreResultsPromise<AppSelectOption> {
		const queryParams: string = objectToQueryParamString({
			start,
			search: searchQuery,
			max: 100,
		});
		const url: string = `company/${companyId}/accounting/integrations/${integrationId}/authFields/${authField.key}/values`;
		return this.apiService
			.postToApi(`${url}?${queryParams}`, {
				context: context,
			})
			.then((res) => {
				return {
					hasMoreResults: res.data.moreResults,
					items: (res.data.values ?? []).map((dropdownOption) => ({
						id: dropdownOption.identifier,
						name: dropdownOption.name,
						description: dropdownOption.description,
					})),
				};
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public async getDropdownAuthOptionsByIds(
		companyId: string,
		integrationId: string,
		authField: BasicConfigInfo,
		context: Record<string, any>,
		ids: Array<string>,
	): Promise<Array<AppSelectOption>> {
		const url: string = `company/${companyId}/accounting/integrations/${integrationId}/authFields/${authField.key}/values`;
		return this.apiService
			.postToApi(url, {
				context: context,
				ids: ids,
			})
			.then((res) =>
				res.data.values.map((dropdownOption) => ({
					id: dropdownOption.identifier,
					name: dropdownOption.name,
					description: dropdownOption.description,
				})),
			)
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getDropdownPreConnectionAuthOptions(
		companyId: string,
		authorizationId: string,
		authField: BasicConfigInfo,
		context: Record<any, string>,
		start: number,
		searchQuery: string,
	): ItemsWithHasMoreResultsPromise<AppSelectOption> {
		const queryParams: string = objectToQueryParamString({
			start,
			search: searchQuery,
			max: 100,
		});
		const url: string = `company/${companyId}/accounting/authorizations/${authorizationId}/connection/authFields/${authField.key}/values`;
		return this.apiService
			.postToApi(`${url}?${queryParams}`, {
				context: context,
			})
			.then((res) => {
				return {
					hasMoreResults: res.data.moreResults,
					items: (res.data.values ?? []).map((dropdownOption) => ({
						id: dropdownOption.identifier,
						name: dropdownOption.name,
						description: dropdownOption.description,
					})),
				};
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public async getDropdownPreConnectAuthOptionsByIds(
		companyId: string,
		authorizationId: string,
		authField: BasicConfigInfo,
		context: Record<string, any>,
		ids: Array<string>,
	): Promise<Array<AppSelectOption>> {
		const url: string = `company/${companyId}/accounting/authorizations/${authorizationId}/connection/authFields/${authField.key}/values`;
		return this.apiService
			.postToApi(url, {
				context: context,
				ids: ids,
			})
			.then((res) =>
				res.data.values.map((dropdownOption) => ({
					id: dropdownOption.identifier,
					name: dropdownOption.name,
					description: dropdownOption.description,
				})),
			)
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getDropdownConfigOptions(
		companyId: string,
		authorizationId: string,
		configField: BasicConfigInfo,
		context: Record<any, string>,
		start: number,
		searchQuery: string,
	): ItemsWithHasMoreResultsPromise<AppSelectOption> {
		const queryParams: string = objectToQueryParamString({
			start,
			search: searchQuery,
			max: 100,
		});
		const url: string = `company/${companyId}/accounting/authorizations/${authorizationId}/connection/configFields/${configField.key}/values`;
		return this.apiService
			.postToApi(`${url}?${queryParams}`, {
				context: context,
			})
			.then((res) => {
				return {
					hasMoreResults: res.data.moreResults,
					items: (res.data.values ?? []).map((dropdownOption) => ({
						id: dropdownOption.identifier,
						name: dropdownOption.name,
						description: dropdownOption.description,
					})),
				};
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public async getDropdownConfigOptionsByIds(
		companyId: string,
		authorizationId: string,
		configField: BasicConfigInfo,
		context: Record<string, any>,
		ids: Array<string>,
	): Promise<Array<AppSelectOption>> {
		const url: string = `company/${companyId}/accounting/authorizations/${authorizationId}/connection/configFields/${configField.key}/values`;
		return this.apiService
			.postToApi(url, {
				context: context,
				ids: ids,
			})
			.then((res) =>
				res.data.values.map((dropdownOption) => ({
					id: dropdownOption.identifier,
					name: dropdownOption.name,
					description: dropdownOption.description,
				})),
			)
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public createCompleteConfigurationFields(
		connectedV2Integration: AccountingIntegrationV2Authorization,
		connectedIntegrationConfigFields: Array<AccountingIntegrationConfigurationField>,
	): Array<CompleteConfigField> {
		return connectedIntegrationConfigFields
			.map((configField: AccountingIntegrationConfigurationField) => {
				const matchingFieldValue: string = connectedV2Integration.settings[configField.key];

				return {
					...configField,
					value: isValueSet(matchingFieldValue) ? matchingFieldValue : '',
				};
			})
			.map((e) => new CompleteConfigField(e));
	}

	public async getAllUIFields(
		authorizationId: string,
		bookingType: AccountingBookingType,
		context?: Record<string, any>,
	): Promise<Array<DynamicBookingField>> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		return this.apiService
			.postToApi(`${this.getAuthorizationAPIRootUrl(companyId, authorizationId)}?bookingType=${bookingType}`, {
				context: isValueSet(context) ? this.filterInvalidContext(context) : null,
			})
			.then((res) => res.data);
	}

	public async getPickerValues(
		integrationId: string,
		fieldKey: string,
		start: number,
		searchQuery: string,
		context: Record<string, any>,
		bookingType: AccountingBookingType,
	): ItemsWithHasMoreResultsPromise<AppSelectOption> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		const url: string = `
		company/${companyId}/accounting/authorizations/${integrationId}/connection/accountingFields/${fieldKey}/values`;
		const queryParams = objectToQueryParamString({
			bookingType,
			start,
			search: searchQuery,
			max: 100,
		});
		return this.apiService
			.postToApi(`${url}?${queryParams}`, {
				context: this.filterInvalidContext(context),
			})
			.then((res) => {
				return {
					hasMoreResults: res.data.moreResults,
					items: (res.data.values ?? []).map((e) => ({
						id: e.identifier,
						name: [e.code, e.name].filter(stringIsSetAndFilled).join(' - '),
						description: e.description,
					})),
				};
			});
	}

	public async getPickerValuesByIds(
		integrationId: string,
		fieldKey: string,
		ids: Array<string>,
		context: Record<string, any>,
		bookingType: AccountingBookingType,
	): Promise<Array<AppSelectOption>> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		const url: string = `
		company/${companyId}/accounting/authorizations/${integrationId}/connection/accountingFields/${fieldKey}/values?bookingType=${bookingType}`;
		return this.apiService
			.postToApi(url, {
				ids,
				context: this.filterInvalidContext(context),
			})
			.then((res) => {
				return res.data.values.map((e) => ({
					id: e.identifier,
					name: [e.code, e.name].filter(stringIsSetAndFilled).join(' - '),
					description: e.description,
				}));
			});
	}

	public async isFieldEnabled(
		integrationId: string,
		fieldKey: string,
		context: Record<string, any>,
		bookingType: AccountingBookingType,
	): Promise<boolean> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		const result = await this.apiService.postToApi(
			`company/${companyId}/accounting/authorizations/${integrationId}/connection/accountingFields/${fieldKey}/enabled?bookingType=${bookingType}`,
			{
				context: this.filterInvalidContext(context),
			},
		);
		return result.data.enabled;
	}

	public async getSelectedValueById(
		integrationId: string,
		fieldKey: string,
		id: string,
		context: Record<string, any>,
		bookingType: AccountingBookingType,
	): Promise<IndividualPickerValue> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		const url: string = `
		company/${companyId}/accounting/authorizations/${integrationId}/connection/accountingFields/${fieldKey}/values/${id}?bookingType=${bookingType}`;
		return this.apiService
			.postToApi(url, {
				context: context,
			})
			.then((res) => res.data);
	}

	public async validateField(
		integrationId: string,
		fieldKey: string,
		contexts: Record<string, Record<string, any>>,
		bookingType: AccountingBookingType,
	): Promise<Array<string>> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		const body = Object.entries(contexts).reduce((acc, [key, value]) => {
			return {
				...acc,
				[key]: this.filterInvalidContext(value),
			};
		}, {});
		const result = await this.apiService.postToApi(
			`company/${companyId}/accounting/authorizations/${integrationId}/connection/accountingFields/${fieldKey}/validate?bookingType=${bookingType}`,
			body,
		);
		return result.data.errors;
	}

	public async getCreationProperties(integrationId: string, fieldKey: string): Promise<Array<DynamicBookingField>> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		const result = await this.apiService.postToApi(
			`company/${companyId}/accounting/authorizations/${integrationId}/connection/accountingFields/${fieldKey}/create/fields`,
		);
		return result.data;
	}

	public async getPickerValuesForCreate(
		integrationId: string,
		fieldKey: string,
		createFieldKey: string,
		start: number,
		searchQuery: string,
		context: Record<string, any>,
	): ItemsWithHasMoreResultsPromise<AppSelectOption> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		const url: string = `
		company/${companyId}/accounting/authorizations/${integrationId}/connection/accountingFields/${fieldKey}/create/${createFieldKey}/values`;
		const queryParams = objectToQueryParamString({
			start,
			search: searchQuery,
			max: 100,
		});
		return this.apiService
			.postToApi(`${url}?${queryParams}`, {
				context: this.filterInvalidContext(context),
			})
			.then((res) => {
				return {
					hasMoreResults: res.data.moreResults,
					items: (res.data.values ?? []).map((e) => ({
						id: e.identifier,
						name: [e.code, e.name].filter(stringIsSetAndFilled).join(' - '),
						description: e.description,
					})),
				};
			});
	}

	public async getPickerValuesForCreateByIds(
		integrationId: string,
		fieldKey: string,
		createFieldKey: string,
		ids: Array<string>,
		context: Record<string, any>,
	): Promise<Array<AppSelectOption>> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		const url: string = `
		company/${companyId}/accounting/authorizations/${integrationId}/connection/accountingFields/${fieldKey}/create/${createFieldKey}/values`;
		return this.apiService
			.postToApi(url, {
				ids,
				context: this.filterInvalidContext(context),
			})
			.then((res) => {
				return res.data.values.map((e) => ({
					id: e.identifier,
					name: [e.code, e.name].filter(stringIsSetAndFilled).join(' - '),
					description: e.description,
				}));
			});
	}

	public async createNewFieldEntity(integrationId: string, fieldKey: string, values: Record<string, any>) {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		const url: string = `
		company/${companyId}/accounting/authorizations/${integrationId}/connection/accountingFields/${fieldKey}/create`;
		return this.apiService
			.postToApi(url, {
				fields: filterUnsetValues(values),
			})
			.then((res) => res.data.identifier);
	}

	public async validateTxForBooking(
		txId: string,
		authId: string,
		headersContext: Record<string, any>,
		linesContext: Array<Record<string, any>>,
	): Promise<{ errors: Array<string>; warnings: Array<string> }> {
		const result = await this.apiService.postToApi(`receipt/${txId}/accounting/validate`, {
			authorization: authId,
			headers: this.filterInvalidContext(headersContext),
			lines: linesContext.map((e) => this.filterInvalidContext(e)),
		});
		return {
			errors: result.data.errors,
			warnings: result.data.warnings,
		};
	}

	public async validateIntegrationConnectionField(
		fieldType: AccountingFormFieldType,
		authorizationId: string,
		fieldKey: string,
		context: Record<string, any>,
	): Promise<Array<string>> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		const result = await this.apiService.postToApi(
			`company/${companyId}/accounting/authorizations/${authorizationId}/connection/${fieldType}/${fieldKey}/validate`,
			{
				context: this.filterInvalidContext(context),
			},
		);
		return result.data.errors;
	}

	/* tslint:disable:member-ordering */
	public getSuggestionForField = memoizeFull(
		async (
			integrationId: string,
			fieldKey: string,
			receiptContext: Record<string, any>,
			bookingContext: Record<string, any>,
			prevBookingContext: Record<string, any>,
			bookingType: AccountingBookingType,
			lineIndex?: number,
		): Promise<{
			overwrite: boolean;
			value: any;
		}> => {
			const companyId: string = this.userService.getCurrentLoggedUser().company;
			const queryParams = objectToQueryParamString({ bookingType, lineIndex });
			const result = await this.apiService.postToApi(
				`company/${companyId}/accounting/authorizations/${integrationId}/connection/accountingFields/${fieldKey}/suggestions?${queryParams}`,
				{
					previous: {
						receipt: receiptContext,
						context: isValueSet(prevBookingContext)
							? this.filterInvalidContext(prevBookingContext)
							: this.filterInvalidContext(bookingContext),
					},
					current: {
						receipt: receiptContext,
						context: this.filterInvalidContext(bookingContext),
					},
				},
			);
			return {
				overwrite: result.data.suggestions[0]?.overwrites ?? false,
				value: result.data.suggestions[0]?.value,
			};
		},
	);

	/* tslint:disable:member-ordering */
	public getSuggestionForFieldsInColumn = memoizeFull(
		async (
			integrationId: string,
			fieldKey: string,
			receiptContext: Record<string, any>,
			bookingHeadersContext: Record<string, any>,
			bookingLinesContext: Array<Record<string, any>>,
			prevBookingHeaderContext: Record<string, any>,
			prevBookingLinesContext: Array<Record<string, any>>,
			bookingType: AccountingBookingType,
		): Promise<
			Array<{
				overwrite: boolean;
				value: any;
			}>
		> => {
			const companyId: string = this.userService.getCurrentLoggedUser().company;
			const result = await this.apiService.postToApi(
				`company/${companyId}/accounting/authorizations/${integrationId}/connection/accountingFields/${fieldKey}/suggestions/column?bookingType=${bookingType}`,
				{
					previous: {
						receipt: receiptContext,
						headers: this.filterInvalidContext(prevBookingHeaderContext),
						lines: prevBookingLinesContext?.map(this.filterInvalidContext),
					},
					current: {
						receipt: receiptContext,
						headers: this.filterInvalidContext(bookingHeadersContext),
						lines: bookingLinesContext.map(this.filterInvalidContext),
					},
				},
			);
			return result.data.suggestions
				.map((e) => e[0])
				.map((e) => ({
					overwrite: e.overwrites,
					value: e.value,
				}));
		},
	);

	/* tslint:disable:member-ordering */
	public getTotalAmount = memoizeFull(
		async (txId: string, integrationId: string, headers: Record<string, any>, lines: Array<Record<string, any>>): Promise<number> => {
			const result = await this.apiService.postToApi(`receipt/${txId}/accounting/totalAmount`, {
				authorization: integrationId,
				headers: this.filterInvalidContext(headers),
				lines: lines.map(this.filterInvalidContext),
			});
			return result.data.totalAmount;
		},
	);

	public async bookTransaction(tx: TransactionApiModel) {
		const result = await this.apiService
			.postToApi(`receipt/${tx.id}/accounting/book`, {
				...tx.accounting,
			})
			.catch((res) => {
				throw res.error.data;
			});
		return result;
	}

	public filterInvalidContext(context: Record<string, any>) {
		if (!isValueSet(context)) {
			return null;
		}
		return filterObjectOnCondition(context, ([key, val]) => {
			if (!isValueSet(val)) {
				return false;
			}
			if (val.hasOwnProperty('amount') && val.hasOwnProperty('currency')) {
				if (!Number.isFinite(val.amount)) {
					return false;
				}
				if (!stringIsSetAndFilled(val.currency)) {
					return false;
				}
			}
			return true;
		});
	}

	// TODO - to change when BE supports it - BE ticket https://git.hub.klippa.com/klippa/SpendControl/API/-/issues/2486
	public async getConnectedIntegrationsWithCapability(
		capability: AccountingCapability,
	): Promise<Array<AccountingIntegrationV2Authorization>> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		const connectedIntegrations: Array<AccountingIntegrationV2Authorization> = await this.getAllV2ConnectedAccountingIntegrations(
			companyId,
		);
		const accountingIntegrations: Array<AccountingIntegrationV2> = await this.getAllAccountingWithIntegrationTypes(connectedIntegrations);
		const integrationsWithCapability: Array<AccountingIntegrationV2Authorization> = connectedIntegrations.filter(
			(integration: AccountingIntegrationV2Authorization) => {
				const matchingIntegration: AccountingIntegrationV2 = accountingIntegrations.find(
					(accountingIntegrationTypes: AccountingIntegrationV2) => accountingIntegrationTypes.id === integration.integrationId,
				);
				return this.hasIntegrationCapability(matchingIntegration, capability);
			},
		);
		return integrationsWithCapability;
	}

	// TODO - to change when BE supports it - BE ticket https://git.hub.klippa.com/klippa/SpendControl/API/-/issues/2486
	public hasIntegrationCapability(integration: AccountingIntegrationV2, accountingCapability: AccountingCapability): boolean {
		if (
			accountingCapability === AccountingCapability.supportsBookingTypes ||
			accountingCapability === AccountingCapability.supportsBookingBatches
		) {
			return isValueSet(integration.capabilities[accountingCapability]);
		}

		return isValueSet(integration.capabilities[accountingCapability]) && integration.capabilities[accountingCapability];
	}

	public async getAllAccountingWithIntegrationTypes(
		connectedIntegrations: Array<AccountingIntegrationV2Authorization>,
	): Promise<Array<AccountingIntegrationV2>> {
		return await Promise.all(
			connectedIntegrations.map((integration: AccountingIntegrationV2Authorization) =>
				this.getAccountingIntegrationByIntegrationId(integration.integrationId),
			),
		);
	}

	public getQueuedBookings(
		authorizationId: string,
		filters: QueuedBookingsFilters,
	): Promise<{ bookings: Array<TransactionFrontendModel>; totalNumberOfBookings: number }> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		// if the user selected the all filter, the BE doesn't want the param at all so it should be filtered out
		const newFilters: QueuedBookingsFilters = { ...filters, status: filters.status === 'ALL' ? null : filters.status };

		const queryParams: string = objectToQueryParamString(newFilters as Record<string, any>);
		const url: string = `company/${companyId}/accounting/authorizations/${authorizationId}/batches/queue`;

		return this.apiService
			.getFromApi(`${url}?${queryParams}`)
			.then((r) => {
				return {
					bookings: r.data.queue.map((transaction) =>
						apiToFrontend(transaction, this.userService.getCurrentLoggedUser().id, TransactionFrontendModel),
					),
					totalNumberOfBookings: r.data.count,
				};
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public bookBatchOfTransactions(
		companyId: string,
		authorizationId: string,
		batchOfTransactions: Array<string>,
	): Promise<BookedBatchOfTransactionsResponse> {
		return this.apiService
			.postToApi(`company/${companyId}/accounting/authorizations/${authorizationId}/batches`, {
				transactions: batchOfTransactions,
			})
			.then((res) => res.data)
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getBookedBatches(
		authorizationId: string,
		filters: BookedBatchesFilters,
	): Promise<{ bookedBatches: Array<BookedBatch>; count: number }> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		// if the user selected the all filter, the BE doesn't want the param at all so it should be filtered out
		const newFilters: BookedBatchesFilters = { ...filters, status: filters.status === 'ALL' ? null : filters.status };
		const queryParams: string = objectToQueryParamString(newFilters as Record<string, any>);
		const url: string = `company/${companyId}/accounting/authorizations/${authorizationId}/batches`;

		return this.apiService
			.getFromApi(`${url}?${queryParams}`)
			.then((r) => {
				return {
					bookedBatches: r.data.batches.map((batch) => new BookedBatch(batch)),
					count: r.data.count,
				};
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getBatchById(
		authorizationId: string,
		batchId: string,
		filters: DefaultFilters,
	): Promise<{ batch: Array<TransactionFrontendModel>; count: number }> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		const url: string = `company/${companyId}/accounting/authorizations/${authorizationId}/batches/${batchId}/transactions`;
		const queryParams: string = objectToQueryParamString(filters as Record<string, any>);

		return this.apiService
			.getFromApi(`${url}?${queryParams}`)
			.then((r) => {
				return {
					batch: r.data.transactions?.map((transaction) =>
						apiToFrontend(transaction, this.userService.getCurrentLoggedUser().id, TransactionFrontendModel),
					),
					count: r.data.count,
				};
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public queueToBatchBooking(tx: TransactionApiModel): Promise<QueuedForBookingResponse> {
		const url: string = `receipt/${tx.id}/accounting/queueToBatch`;
		return this.apiService
			.postToApi(url, {
				...tx.accounting,
			})
			.then((res) => new QueuedForBookingResponse(res.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getIntegrationCapabilities(integrationId: string): Promise<AccountingIntegrationV2Capabilities> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		return this.apiService
			.getFromApi(`company/${companyId}/accounting/integrations/${integrationId}`)
			.then((r) => new AccountingIntegrationV2Capabilities(r.data.capabilities))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getUserInfo(companyId: string, authorizationId: string): Promise<UserInfo> {
		return this.apiService
			.getFromApi(`company/${companyId}/accounting/authorizations/${authorizationId}/connection/userInfo`)
			.then((r) => r.data);
	}

	public getIntegrationConnectionStatus(companyId: string, authorizationId: string): Promise<ConnectionInfo> {
		return this.apiService
			.getFromApi(`company/${companyId}/accounting/authorizations/${authorizationId}/connection/status`)
			.then((r) => r.data)
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	private getAuthorizationAPIRootUrl(companyId: string, authorizationId: string): string {
		return `company/${companyId}/accounting/authorizations/${authorizationId}/connection/accountingFields`;
	}

	public getBookkeepingImportTemplate(companyId: string, authorizationId: string, fieldKey: string): Promise<Blob> {
		const url: string = `/api/v1/${this.getAuthorizationAPIRootUrl(companyId, authorizationId)}/${fieldKey}/import/template`;
		return this.apiService.getBlob(url).catch((e) => {
			this.notifications.handleAPIError(e);
			throw e;
		});
	}

	public importBookkeepingImportTemplate(
		authorizationId: string,
		fieldKey: string,
		bookingType: AccountingBookingType,
		skipFirstRow: boolean,
		updateExisting: boolean,
		file: File,
		context?: Record<string, any>,
	): Promise<ImportResult> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		const url: string = `${this.getAuthorizationAPIRootUrl(companyId, authorizationId)}/${fieldKey}/import?bookingType=${bookingType}`;
		const formData = new FormData();
		formData.append('file', file);
		formData.append('skip_first_row', skipFirstRow ? '1' : '0');
		formData.append('updateExisting', updateExisting ? '1' : '0');

		return this.apiService
			.postToApi(url, formData)
			.then((res) => new ImportResult(res.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public async getDynamicDashboardData(
		integrationId: string,
		fieldKey: string,
		start: number,
		searchQuery: string,
		context: Record<string, any>,
		bookingType: AccountingBookingType,
		max: number = 100,
	): Promise<{
		count: number;
		values: Array<StaticDashboardColumnData>;
	}> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		const url: string = `
		company/${companyId}/accounting/authorizations/${integrationId}/connection/accountingFields/${fieldKey}/values`;
		const queryParams = objectToQueryParamString({
			bookingType,
			start,
			search: searchQuery,
			max: max,
		});
		return this.apiService
			.postToApi(`${url}?${queryParams}`, {
				context: this.filterInvalidContext(context),
			})
			.then((res) => {
				return {
					count: res.data.count,
					values: res.data.values.map((e) => new StaticDashboardColumnData(e)),
				};
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	private getAccountingPickerOptionsBasedOnFormType(
		inputParam: DynamicAccountingIntegrationField,
		formValues: Record<string, any>,
		integrationOrAuthId: string,
		accountingFormType: AccountingFormType,
		start: number,
		searchQuery: string,
	): ItemsWithHasMoreResultsPromise<AppSelectOption> {
		const context: Record<any, string> = filterEmptyStringValues(formValues);
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		switch (accountingFormType) {
			case AccountingFormType.AUTHORIZATION_FORM:
				return this.getDropdownAuthOptions(companyId, integrationOrAuthId, inputParam as BasicConfigInfo, context, start, searchQuery);
			case AccountingFormType.PRE_CONNECTION_AUTHORIZATION_FORM:
				return this.getDropdownPreConnectionAuthOptions(
					companyId,
					integrationOrAuthId,
					inputParam as BasicConfigInfo,
					context,
					start,
					searchQuery,
				);
			case AccountingFormType.CONFIGURATION_FORM:
				return this.getDropdownConfigOptions(companyId, integrationOrAuthId, inputParam as BasicConfigInfo, context, start, searchQuery);
		}
	}

	private getAccountingPickerOptionsByIdsBasedOnFormType(
		inputParam: DynamicAccountingIntegrationField,
		formValues: Record<string, any>,
		integrationOrAuthId: string,
		accountingFormType: AccountingFormType,
		ids: Array<string>,
	): Promise<Array<AppSelectOption>> {
		const context: Record<any, string> = filterEmptyStringValues(formValues);
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		switch (accountingFormType) {
			case AccountingFormType.AUTHORIZATION_FORM:
				return this.getDropdownAuthOptionsByIds(companyId, integrationOrAuthId, inputParam as BasicConfigInfo, context, ids);
			case AccountingFormType.PRE_CONNECTION_AUTHORIZATION_FORM:
				return this.getDropdownPreConnectAuthOptionsByIds(companyId, integrationOrAuthId, inputParam as BasicConfigInfo, context, ids);
			case AccountingFormType.CONFIGURATION_FORM:
				return this.getDropdownConfigOptionsByIds(companyId, integrationOrAuthId, inputParam as BasicConfigInfo, context, ids);
		}
	}

	/* tslint:disable:member-ordering */
	public getAccountingFieldsAsDynamicFormInputParameters = memoizeFull(
		(
			inputParam: DynamicAccountingIntegrationField,
			formValues: Record<string, any>,
			integrationOrAuthId: string,
			accountingFormType: AccountingFormType,
		): DynamicFormElementParameters => {
			if (inputParam.uiType === UI_TYPE.SELECT || inputParam.uiType === UI_TYPE.MULTI_SELECT) {
				return {
					uiType: inputParam.uiType,
					fetchItemsFn: (start: number, searchQuery: string) => {
						return this.getAccountingPickerOptionsBasedOnFormType(
							inputParam,
							formValues,
							integrationOrAuthId,
							accountingFormType,
							start,
							searchQuery,
						);
					},
					fetchSelectedItemsFn: (ids: Array<string>): Promise<Array<AppSelectOption>> => {
						return this.getAccountingPickerOptionsByIdsBasedOnFormType(
							inputParam,
							formValues,
							integrationOrAuthId,
							accountingFormType,
							ids,
						);
					},
				};
			}
			return {
				uiType: inputParam.uiType,
			};
		},
	);

	public clearCacheForConnectedIntegration(authorizationId: string): Promise<void> {
		const companyId: string = this.userService.getCurrentLoggedUser().company;
		return this.apiService
			.deleteToApi(`company/${companyId}/accounting/authorizations/${authorizationId}/connection/clearCache`)
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public async getAccountingFieldValue(
		dynamicBookingField: DynamicBookingField,
		bookingContext: Record<string, any>,
		authorizationId: string,
		bookingType: AccountingBookingType,
	): Promise<string> {
		if (
			(dynamicBookingField.uiType === UI_TYPE.SELECT || dynamicBookingField.uiType === UI_TYPE.MULTI_SELECT) &&
			Object.keys(bookingContext).includes(dynamicBookingField.key)
		) {
			const value: IndividualPickerValue = await this.getSelectOrMultiselectAccountingValue(
				this.getNecessaryDataForGettingAccountingValueById(dynamicBookingField, bookingContext, bookingType, authorizationId),
			);
			return `${value.code} - ${value.name}`;
		}

		return bookingContext[dynamicBookingField.key];
	}

	public getNecessaryDataForGettingAccountingValueById(
		dynamicBookingField: DynamicBookingField,
		bookingContext: Record<string, any>,
		bookingType: AccountingBookingType,
		authorizationId: string,
	): NecessaryDataForGettingSelectedValue {
		return {
			authorizationId: authorizationId,
			fieldKey: dynamicBookingField.key,
			selectedValueId: bookingContext[dynamicBookingField.key],
			context: bookingContext,
			bookingType: bookingType,
		};
	}

	public getBookingContext(transaction: TransactionFrontendModel): Record<string, any> {
		return this.filterInvalidContext(transaction.accountingHeaders); // we only need the headers for the Bookkeeping data as we only show the accounting headers
	}

	/* tslint:disable:member-ordering */
	public getSelectOrMultiselectAccountingValue = memoizeFull((bookkeepingData: NecessaryDataForGettingSelectedValue) => {
		return this.getSelectedValueById(
			bookkeepingData.authorizationId,
			bookkeepingData.fieldKey,
			bookkeepingData.selectedValueId,
			bookkeepingData.context,
			bookkeepingData.bookingType,
		);
	});
}
