import { Component, Input, ViewChild } from '@angular/core';
import { ExportRequest, ExportType } from '#/models/company/exporterInterfaces';
import { ExpenseReportExportRequest } from '#/models/transaction/expense-reports';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { ModalComponent } from '~/app/shared/ui/modal/modal.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { stringIsSetAndFilled } from '#/util/values';

@Component({
	selector: 'app-export-form-modal',
	templateUrl: './export-form-modal.component.html',
	styleUrls: ['./export-form-modal.component.scss'],
})
export class ExportFormModal {
	@Input() exportIds: Array<string>;
	@Input() exportType: ExportType.REPORT = ExportType.REPORT; // TODO: also support the other types of ExportType
	@Input() isCompanyExport: boolean = true;
	@Input() isEmailRequired: boolean = false;
	@ViewChild('exportModalRef') exportModalRef: ModalComponent;

	public exportUrlPromise: Promise<string>;

	constructor(private expenseReportsService: ExpenseReportsService, private translateService: TranslateService) {}

	public openExportModal(): void {
		this.exportModalRef.openModal();
	}

	public processExport(event: ExportRequest): void {
		if (this.exportType === ExportType.REPORT) {
			this.processReportExport(event);
		}
	}

	private processReportExport(event: ExportRequest): void {
		const exportRequest: ExportRequest = event;
		exportRequest.email = exportRequest.email.filter(stringIsSetAndFilled).join(',');
		const expenseReportExportRequest: ExpenseReportExportRequest = new ExpenseReportExportRequest(exportRequest);
		this.exportUrlPromise = this.expenseReportsService.exportExpenseReports(expenseReportExportRequest).then((res) => res.data.url);
	}

	public getExportTextForSingular(): string {
		switch (this.exportType) {
			case ExportType.REPORT:
				return this.translateService.instant(_('report'));
		}
	}
	public getExportTextForPlural(): string {
		switch (this.exportType) {
			case ExportType.REPORT:
				return this.translateService.instant(_('reports'));
		}
	}
}
