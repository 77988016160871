import { Component, ElementRef, EventEmitter, Host, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CollectionViewer, ListRange, SelectionModel } from '@angular/cdk/collections';
import { cloneDeep } from 'lodash';
import { v4 as generateId } from 'uuid';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElementComponent, ValueAccessorBase } from '@klippa/ngx-enhancy-forms';
import { Observable, Subscription } from 'rxjs';
import { isNullOrUndefined, isValueSet } from '#/util/values';
import { ModalService } from '~/app/services/modal.service';
import { FlatTreeNode, NodeSettings, TreeNode } from '~/app/shared/ui/custom-xml-export/interfaces/treeNode';

@Component({
	selector: 'app-tree-drag-drop',
	templateUrl: './tree-drag-drop.component.html',
	styleUrls: ['./tree-drag-drop.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: TreeDragDropComponent, multi: true }],
})
export class TreeDragDropComponent extends ValueAccessorBase<TreeNode[]> implements OnInit, OnDestroy {
	// This component creates a tree structure where the leaves and nodes can be dragged and dropped.
	// Users can drag and drop between different trees by default. The drop function of the tree being dropped on will be called.
	// The component consists of two lose Angular Material/CDK components combined. (mat-tree & cdk-drag-drop)

	// Allowing a drop can easily be regulated by using the allowDrop callback input.
	@Input() allowDrop: (srcNode: TreeNode, destNode: TreeNode, treeNodes: TreeNode[]) => boolean;
	// Disabling sorting or dropping within the same tree can be changed by using the disableSorting input.
	@Input() disableSorting: boolean = false;
	// Allowing the user to edit the settings or delete a node can be changed by setting the showNodeSettingsButtons input.
	@Input() showNodeSettingsButtons: boolean = true;
	// Expanding all the folder nodes when the nodes are loaded in can be done by setting the expandOnLoadNodes input.
	@Input() expandOnLoadNodes: boolean = false;
	private hasBeenExpanded: boolean = false;

	// By default, when a node has an empty array of children.
	// The folder node becomes a regular leaf node, preventing you from adding children to it
	// When this input is set to true, a node will be added to every folder node, preventing it from becoming a leaf
	@Input() preventFolderFromBecomingLeaf: boolean = false;

	// Emits an event with the dropped node when a drop is made on this list from a connected list
	@Output() onDropFromConnectedTree: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();

	@ViewChild('nodeSettings') nodeSettings: ElementRef;

	expansionModel = new SelectionModel<FlatTreeNode>(true);
	private isExpanded: boolean = false;

	treeControl = new FlatTreeControl<FlatTreeNode>(
		(node) => node.level,
		(node) => node.expandable,
	);

	treeFlattener = new MatTreeFlattener(
		this.transFormTreeNodeToFlatTreeNode,
		(node) => node.level,
		(node) => node.expandable,
		(node) => node.children,
	);

	// To allow drag and dropping withing a tree we use a flat tree instead of the standard nested tree.
	dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
	dataSourceChangeSubscription: Subscription;

	editSettingsNode: TreeNode;

	constructor(
		@Host() @Optional() protected parent: FormElementComponent,
		@Host() @Optional() protected controlContainer: ControlContainer,
		private modalService: ModalService,
	) {
		super(parent, controlContainer);
		this.allowDrop = () => true;
	}

	ngOnInit() {
		// Start a change listener for the datasource so we can update innerValue and update the node expansion on every change.
		this.dataSourceChangeSubscription = this.getDataSourceChangeListener().subscribe(() => {
			this.updateNodeExpansion();
			this.updateInnerValue();
		});
		this.updateDatasource(this.innerValue);
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.dataSourceChangeSubscription.unsubscribe();
		this.dataSource.disconnect();
	}

	getDataSourceChangeListener() {
		const collectionViewer: CollectionViewer = {
			viewChange: new Observable<ListRange>(),
		};
		return this.dataSource.connect(collectionViewer);
	}

	updateNodeExpansion() {
		// @ts-ignore
		this.treeControl.dataNodes = this.dataSource._flattenedData.value;
		this.treeControl.expansionModel = this.expansionModel;

		if (this.expandOnLoadNodes && this.treeControl.dataNodes.length > 0 && !this.hasBeenExpanded) {
			this.treeControl.expandAll();
			this.isExpanded = true;
			this.hasBeenExpanded = true;
		}
	}

	toggleExpansionForAllNodes() {
		if (this.isExpanded) {
			this.treeControl.collapseAll();
		} else {
			this.treeControl.expandAll();
		}
		this.isExpanded = !this.isExpanded;
	}

	IsExpanded(): boolean {
		return this.isExpanded;
	}

	updateDatasource(value: TreeNode[]) {
		if (isNullOrUndefined(value)) {
			this.dataSource.data = [];
		} else {
			this.dataSource.data = this.preventFolderFromBecomingLeaf ? this.addNodeToPreventNodeFromBecomingLeafToFolderNodes(value) : value;
		}
	}

	updateInnerValue() {
		// @ts-ignore
		this.setInnerValueAndNotify(this.dataSource._data.value);
	}

	writeValue(value: TreeNode[]) {
		this.updateDatasource(value);
		// Expand all nodes when data is being loaded into the tree
		if (this.expandOnLoadNodes) {
			this.treeControl.expandAll();
		}
		super.writeValue(this.dataSource.data);
	}

	drop(event: CdkDragDrop<FlatTreeNode[]>) {
		// Note that the drop event is called on the component that the element is dropped on
		if (event.previousContainer === event.container) {
			this.dropOnSelf(event);
		} else {
			this.dropOnConnectedTree(event);
		}
	}

	// This function describes the way we drop nodes within it's own tree
	dropOnSelf(event: CdkDragDrop<FlatTreeNode[]>) {
		const treeData = cloneDeep(this.dataSource.data);
		const visibleNodesFlattened = this.getVisibleNodesFlattened(treeData);

		// Finding the node being dragged.
		const node = event.item.data;
		const oldSiblings = this.findNodeSiblings(treeData, node.id);
		const oldSiblingIndex = oldSiblings.findIndex((oldSiblingNode) => oldSiblingNode.id === node.id);
		const nodeToInsert: TreeNode = oldSiblings[oldSiblingIndex];

		const nodeAtDest = this.getNodeAtDestAccountedForUI(nodeToInsert, visibleNodesFlattened, event);
		// If nodeAtDest has not accounted for the UI (visibleNodesFlattened[event.currentIndex] is the default value of nodeAtDest),
		// Check if nodeToInsert should be put below nodeAtDest.
		const shouldPutNodeToInsertBelowNodeAtDest =
			nodeAtDest === visibleNodesFlattened[event.currentIndex]
				? this.shouldPutNodeToInsertBelowNodeAtDest(nodeToInsert, nodeAtDest, event)
				: false;

		if (!this.shouldExecuteDrop(nodeToInsert, nodeAtDest, treeData)) {
			return;
		}

		// Determine where in the tree to insert the dragged node and on what index.
		let newSiblings;
		let insertIndex;
		if (isValueSet(nodeAtDest)) {
			// If nodeAtDest is set, we determine the location in the tree regularly.
			newSiblings = this.findNodeSiblings(treeData, nodeAtDest.id);
			insertIndex = shouldPutNodeToInsertBelowNodeAtDest ? newSiblings?.indexOf(nodeAtDest) + 1 : newSiblings?.indexOf(nodeAtDest);
		} else {
			// If there is no nodeAtDest, we put the node at the bottom of the tree at the top level.
			newSiblings = treeData;
			insertIndex = treeData.length;
		}

		this.executeDrop(oldSiblings, oldSiblingIndex, newSiblings, insertIndex, nodeToInsert, treeData);
	}

	getNodeAtDestAccountedForUI(
		nodeToInsert: TreeNode,
		visibleNodesFlattened: Array<TreeNode>,
		event: CdkDragDrop<FlatTreeNode[]>,
	): TreeNode {
		let nodeAtDest = visibleNodesFlattened[event.currentIndex];
		// These statements make sure that where we drop the node in the UI is also where it is technically dropped.
		if (this.nodeIsDroppedDownOntoExpandedNode(nodeAtDest, event)) {
			// If a user is dropping a node on an expanded node that has children and is dropping downwards:
			// Drop the node in the children of that expanded node.
			nodeAtDest = nodeAtDest.children[0];
		} else if (this.nodeIsDroppedDownOntoNodeToPreventFolderFromBecomingLeaf(nodeToInsert, nodeAtDest, event)) {
			// If a user drop a node onto a node to prevent a folder from becoming a leaf (NTPNFBL), and the user is dropping downwards:
			// Drop the node outside of the folder to prevent nodes from being below a NTPNFBL but still within that folder.
			// EX:
			// - Bookings
			// 		- Receipt
			// 		- NTPNFBL (Node is dropped on here from above)
			// 		- (Prevent node from being put here)
			// - (Put it here)
			nodeAtDest = this.getNodeAtDestAccountedForNodeToPreventFolderFromBecomingLeaf(
				nodeToInsert,
				nodeAtDest,
				event,
				visibleNodesFlattened,
			);
		}
		return nodeAtDest;
	}

	shouldPutNodeToInsertBelowNodeAtDest(nodeToInsert: TreeNode, nodeAtDest: TreeNode, event: CdkDragDrop<FlatTreeNode[]>): boolean {
		if (this.nodeIsDroppedDownOutOfParent(nodeToInsert, nodeAtDest, event)) {
			// If a user drops a node from another parent than its own, and the user is dropping downwards:
			// Place the node below the node being dropped on instead of above it.
			return true;
		}
		return false;
	}

	shouldExecuteDrop(nodeToInsert: TreeNode, nodeAtDest: TreeNode, treeData: TreeNode[]): boolean {
		if (nodeAtDest === nodeToInsert) {
			// Don't execute drop if the nodes didn't move.
			return false;
		}
		if (!this.allowDrop(nodeToInsert, nodeAtDest, treeData)) {
			// Don't execute the drop if the drop isn't allowed.
			return false;
		}
		return true;
	}

	nodeIsDroppedDownOntoExpandedNode(node: TreeNode, event: CdkDragDrop<FlatTreeNode[]>): boolean {
		const treeControlNodeAtDest = this.treeControl.dataNodes.find((treeNode) => treeNode.id === node.id);
		return this.treeControl.isExpanded(treeControlNodeAtDest) && node.children?.length > 0 && event.currentIndex > event.previousIndex;
	}

	nodeIsDroppedDownOutOfParent(nodeToInsert: TreeNode, nodeAtDest: TreeNode, event: CdkDragDrop<FlatTreeNode[]>): boolean {
		return nodeToInsert.parent !== nodeAtDest.parent && event.currentIndex > event.previousIndex;
	}

	nodeIsDroppedDownOntoNodeToPreventFolderFromBecomingLeaf(
		nodeToInsert: TreeNode,
		nodeAtDest: TreeNode,
		event: CdkDragDrop<FlatTreeNode[]>,
	): boolean {
		return (
			nodeAtDest.isNodeToPreventNodeBecomingLeaf && // Node to prevent node from becoming leaf
			event.currentIndex > event.previousIndex && // Dropped downwards
			nodeAtDest.parent !== nodeToInsert // Not dropped onto its own child
		);
	}

	getNodeAtDestAccountedForNodeToPreventFolderFromBecomingLeaf(
		nodeToInsert: TreeNode,
		nodeAtDest: TreeNode,
		event: CdkDragDrop<FlatTreeNode[]>,
		visibleNodesFlattened: Array<TreeNode>,
	): TreeNode {
		// NTPNFBL = nodeToPreventFolderFromBecomingLeaf
		if (nodeToInsert.parent === nodeAtDest.parent.parent) {
			// If the drop should be made within the same context, nodeAtDest should be the parent of the NTPNFBL.
			// This makes sure the nodeToInsert is put under the parent of the NTPNFBL.
			return visibleNodesFlattened.find((visibleNode) => visibleNode.id === nodeAtDest.parent.id);
		} else {
			// If the drop should not be made within the same context, nodeAtDest should be the next node in the tree.
			// This makes sure the nodeToInsert is dropped 1 level higher and below the NTPNFBL.
			return visibleNodesFlattened[event.currentIndex + 1];
		}
	}

	executeDrop(
		oldSiblings: TreeNode[],
		oldSiblingIndex: number,
		newSiblings: TreeNode[],
		insertIndex: number,
		nodeToInsert: TreeNode,
		treeData: TreeNode[],
	) {
		// Remove dropped node from previous location
		oldSiblings.splice(oldSiblingIndex, 1);

		// Insert dropped node in new location
		newSiblings.splice(insertIndex, 0, nodeToInsert);

		this.rebuildTreeFromData(treeData);
	}

	// This function describes the way we drop nodes from a different tree
	dropOnConnectedTree(event: CdkDragDrop<FlatTreeNode[]>) {
		this.onDropFromConnectedTree.emit(event.item.data);

		const treeData = cloneDeep(this.dataSource.data);
		const node = event.item.data;
		node.id = generateId();
		node.children = [];
		const visibleNodesFlattened = this.getVisibleNodesFlattened(treeData);

		const nodeAtDest = visibleNodesFlattened[event.currentIndex];
		if (nodeAtDest) {
			const newSiblings = this.findNodeSiblings(treeData, nodeAtDest.id);
			const insertIndex = newSiblings.findIndex((siblingNode) => siblingNode.id === nodeAtDest.id);
			newSiblings.splice(insertIndex, 0, node);
		} else {
			// If there is no nodeAtDest we can assume it is on the top level of the tree
			treeData.splice(event.currentIndex, 0, node);
		}

		if (!this.allowDrop(node, nodeAtDest, treeData)) {
			return;
		}

		// Automatically expand the node when it is dropped
		this.treeControl.expand(node);
		this.rebuildTreeFromData(treeData);
	}

	updateNode(node: TreeNode) {
		const treeData = cloneDeep(this.dataSource.data);

		const siblings = this.findNodeSiblings(treeData, node.id);
		const siblingIndex = siblings.findIndex((siblingNode) => siblingNode.id === node.id);
		siblings.splice(siblingIndex, 1, node);

		this.rebuildTreeFromData(treeData);
	}

	deleteNode(node: TreeNode) {
		const treeData = cloneDeep(this.dataSource.data);

		const siblings = this.findNodeSiblings(treeData, node.id);
		const siblingIndex = siblings.findIndex((siblingNode) => siblingNode.id === node.id);
		siblings.splice(siblingIndex, 1);

		this.rebuildTreeFromData(treeData);
	}

	rebuildTreeFromData(data: TreeNode[]) {
		const currentExpansionModel = cloneDeep(this.treeControl.expansionModel.selected);
		data = this.updateParents(data, null);
		this.dataSource.data = this.preventFolderFromBecomingLeaf ? this.addNodeToPreventNodeFromBecomingLeafToFolderNodes(data) : data;

		// The expansionModel needs to be updated after a change in the datasource to make sure the expanded nodes stay expanded.
		this.treeControl.expansionModel.clear();
		currentExpansionModel.forEach((selectedNode) => {
			const node = this.treeControl.dataNodes.find((treeNode) => treeNode.id === selectedNode.id);
			if (isValueSet(node)) {
				// If no node is found, don't expand it.
				this.treeControl.expand(node);
			}
		});
	}

	updateParents(treeNodes: Array<TreeNode>, parent: TreeNode): Array<TreeNode> {
		return treeNodes.map((node) => {
			node.parent = parent;
			if (isValueSet(node.children)) {
				this.updateParents(node.children, node);
			}
			return node;
		});
	}

	getVisibleNodesFlattened(treeNodes: TreeNode[]): TreeNode[] {
		const result = [];

		function addExpandedChildren(node: TreeNode, expandedNodes: TreeNode[]) {
			result.push(node);
			if (expandedNodes.includes(node.id)) {
				node.children?.map((childNode) => addExpandedChildren(childNode, expandedNodes));
			}
		}

		treeNodes.forEach((node) => {
			const expandedNodes = [];
			this.treeControl.expansionModel.selected.forEach((treeNode) => {
				expandedNodes.push(treeNode.id);
			});
			addExpandedChildren(node, expandedNodes);
		});

		return result;
	}

	findNodeSiblings(treeNodes: TreeNode[], id: string): TreeNode[] {
		// The tree is structured as a nested object. We want to find out in which context we need to change a node.
		// This function recursively finds the correct context of the node with the provided id in the form of an array.
		let result, subResult;
		treeNodes.forEach((node, i) => {
			if (node.id === id) {
				result = treeNodes;
			} else if (node.children) {
				subResult = this.findNodeSiblings(node.children, id);
				if (subResult) {
					result = subResult;
				}
			}
		});
		return result;
	}

	addNodeToPreventNodeFromBecomingLeafToFolderNodes(treeNodes: TreeNode[]): TreeNode[] {
		return treeNodes.map((node) => {
			if (node.type === 'node') {
				const nodeToPreventFolderFromBecomingLeaf: TreeNode = {
					id: generateId(),
					type: 'leaf',
					parent: node,
					name: '',
					label: '',
					value: '',
					children: [],
					settings: null,
					attachedData: null,
					isNodeToPreventNodeBecomingLeaf: true,
					isCustomValue: false,
				};

				if (isValueSet(node.children)) {
					node.children = this.addNodeToPreventNodeFromBecomingLeafToFolderNodes(node.children);
				}

				if (node.children && !node.children.some((child) => child.isNodeToPreventNodeBecomingLeaf)) {
					node.children.push(nodeToPreventFolderFromBecomingLeaf);
				} else if (isNullOrUndefined(node.children)) {
					node.children = [nodeToPreventFolderFromBecomingLeaf];
				}
			}
			return node;
		});
	}

	transFormTreeNodeToFlatTreeNode(node: TreeNode, level: number): FlatTreeNode {
		return {
			...node,
			level: level,
			expandable: !!node.children && node.children.length > 0,
		};
	}

	hasChild = (_: number, node: FlatTreeNode) => node.expandable;

	isNodeToPreventNodeBecomingLeaf = (_: number, node: FlatTreeNode) => node.isNodeToPreventNodeBecomingLeaf;

	openNodeSettingsModal(node: TreeNode) {
		this.editSettingsNode = node;
		this.modalService.open(this.nodeSettings);
	}

	dismissSettingsModal() {
		this.modalService.dismiss(this.nodeSettings);
	}

	saveNodeSettings(settingValues: NodeSettings) {
		this.editSettingsNode.settings = {
			custom_label: settingValues.custom_label,
			context: settingValues.context,
			allowedContext: this.editSettingsNode.settings.allowedContext,
			value: settingValues.value,
			dateFormat: settingValues.dateFormat,
			attributes: settingValues.attributes,
		};
		this.updateNode(this.editSettingsNode);
		this.modalService.close(this.nodeSettings);
	}
}
