import { Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
	@ViewChild('button') button: ElementRef<HTMLButtonElement>;

	@Input() variant: BtnVariant = 'white';
	@Input() hasPadding: boolean = true;
	@Input() size: 'tiny' | 'small' | 'medium' | 'large' | 'extraLarge' = 'medium';
	@Input() fullWidth = false;
	@Input() hasBorder = true;
	@Input() disabled = false;
	@Input() isLoading = false;
	@Input() blurAfterClick = false;
	@Input() type: 'button' | 'submit' = 'button';

	@HostBinding('class._fullWidth') get _() {
		return this.fullWidth;
	}

	onClick(event: Event) {
		if (this.disabled) {
			event.stopPropagation();
		}

		if (this.blurAfterClick) {
			this.button.nativeElement.blur();
		}
	}
}

export type BtnVariant =
	| 'white'
	| 'greenText'
	| 'blackText'
	| 'greenFilled'
	| 'greenOutlined'
	| 'linkWithBrandColor'
	| 'greenLink'
	| 'contextMenuItem'
	| 'redFilled'
	| 'redOutlined'
	| 'orangeFilled'
	| 'icon'
	| 'clear';
