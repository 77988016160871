import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { AuthManagerService } from '~/app/services/auth-manager.service';
import { ApiOCREnhancedFile, OcrEnhancedSuggestions } from '#/models/ocrEnhancedFiles.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable({
	providedIn: 'root',
})
export class OcrEnhancedFilesService {
	private baseUrl: string = 'user/gallery';
	constructor(private apiService: APIService, public authManagerService: AuthManagerService) {}

	public async getOcrEnhancedFileById(id: string): Promise<ApiOCREnhancedFile> {
		// TODO new file type being changed by BE #2887
		return await this.apiService.getFromApi(this.baseUrl + '/' + id).then((res) => res.data);
	}

	public async downloadWebOcrEnhancedFile(id: string): Promise<Blob> {
		return this.apiService.getBlobFromApi(this.baseUrl + '/' + id + '/file', this.authManagerService, true);
	}

	public async downloadMobileOcrEnhancedFile(id: string): Promise<any> {
		/* Return type: HttpResponse or HttpContent from '@nativescript/core/http' cant be used here as is only known by mobile */
		throw new Error(_('Something went wrong, our apologies. Please contact support.'));
	}
	public async getOcrEnhancedFileSuggestions(id: string): Promise<OcrEnhancedSuggestions> {
		return await this.apiService.getFromApi(this.baseUrl + '/' + id + '/suggestions');
	}

	public async postMobileFile(
		filename: string,
		mimeType: string,
		data: any,
		skipOCR = false,
		directOCR = false,
	): Promise<ApiOCREnhancedFile> {
		throw new Error(_('Something went wrong, our apologies. Please contact support.'));
	}

	public async postWebFile(file: File): Promise<ApiOCREnhancedFile> {
		const formData = new FormData();

		formData.append('file', file);
		return await this.apiService.postToApi(this.baseUrl, formData).then((res) => {
			return res.data;
		});
	}
}
