import { roundToX } from '#/util/numbers';
import { CompanyObjectListAPIRequest } from '#/models/company/company-object-api-request.model';
import { QueryParam } from '#/models/listApiRequest.model';
import { stringIsSetAndFilled } from '#/util/values';

export class Category {
	id: string;
	code: string;
	name: string;
	types: Array<CategoryTransactionType>;
	createdate: Date;
	updatedate: Date;
	groups: string[] = [];
	active: boolean;
	description: string;
	merchants: any[];
	defaults: CategoryDefaults = new CategoryDefaults();
	vat_deductible: boolean;
	module_leveled_travel_expense_compensation_per_km?: number;
	from_date?: Date;
	to_date?: Date;

	static fromData(data): Category[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new Category(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			if (data.createdate) {
				this.createdate = Date.parse(data.createdate) > 0 ? new Date(data.createdate) : null;
			}
			if (data.updatedate) {
				this.updatedate = Date.parse(data.updatedate) > 0 ? new Date(data.updatedate) : null;
			}
			if (data.defaults) {
				this.defaults = new CategoryDefaults(data.defaults);
			}
			if (data.from_date) {
				this.from_date = Date.parse(data.from_date) > 0 ? new Date(data.from_date) : null;
			}
			if (data.to_date) {
				this.to_date = Date.parse(data.to_date) > 0 ? new Date(data.to_date) : null;
			}
		}
	}

	getID() {
		return this.id;
	}

	getCode() {
		return this.code;
	}

	getName() {
		return this.name;
	}

	getGroups() {
		return this.groups;
	}

	isActive() {
		return this.active;
	}

	getDescription() {
		return this.description;
	}

	getDefaults(): CategoryDefaults {
		return this.defaults;
	}

	get Label() {
		return this.getTitle();
	}

	get Title() {
		return (this.code && this.code + ' - ') + this.name;
	}

	getTitle() {
		return (this.code && this.code + ' - ') + this.name;
	}

	get Name() {
		return this.name;
	}

	get Code() {
		return this.code;
	}

	matches(string: string): boolean {
		/** Returns whether this category matches a given search string. */
		return (
			this.getName().toLowerCase().includes(string.toLowerCase()) ||
			this.getCode().toLowerCase().includes(string.toLowerCase()) ||
			this.getDescription().toLowerCase().includes(string.toLowerCase())
		);
	}

	clone(): Category {
		return new Category(JSON.parse(JSON.stringify(this)));
	}

	get moduleLeveledTravelExpenseCompensationPerKM(): string {
		if (
			typeof this.module_leveled_travel_expense_compensation_per_km !== 'undefined' &&
			this.module_leveled_travel_expense_compensation_per_km !== null
		) {
			return roundToX(this.module_leveled_travel_expense_compensation_per_km, 2).toString(10);
		}

		return null;
	}

	set moduleLeveledTravelExpenseCompensationPerKM(cents: string) {
		if (cents && cents !== '') {
			cents = cents.replace(/,/g, '.');
			const value = parseFloat(cents);
			this.module_leveled_travel_expense_compensation_per_km = isNaN(value) ? 0 : value;
		} else {
			this.module_leveled_travel_expense_compensation_per_km = null;
		}
	}

	get moduleLeveledTravelExpenseCompensationPerMile(): string {
		if (
			typeof this.module_leveled_travel_expense_compensation_per_km !== 'undefined' &&
			this.module_leveled_travel_expense_compensation_per_km !== null
		) {
			return roundToX(this.module_leveled_travel_expense_compensation_per_km / 0.621371192, 2).toString(10);
		}

		return null;
	}

	set moduleLeveledTravelExpenseCompensationPerMile(cents: string) {
		if (cents && cents !== '') {
			cents = cents.replace(/,/g, '.');
			const value = parseFloat(cents) * 0.621371192;
			this.module_leveled_travel_expense_compensation_per_km = isNaN(value) ? 0 : value;
		} else {
			this.module_leveled_travel_expense_compensation_per_km = null;
		}
	}
}

export class CompanyCategoryData {
	company_categories: Category[] = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.company_categories) {
				this.company_categories = Category.fromData(data.company_categories);
			}
		}
	}
}

export class CompanyCategoryListAPIRequest extends CompanyObjectListAPIRequest {
	division: string;
	types: Array<CategoryTransactionType>;
	getURL(): string {
		if (this.list) {
			if (this.company && this.company !== '') {
				return `/api/v1/company/${this.company}/companyCategory/list`;
			}
			return `/api/v1/companyCategory/list`;
		}
		if (this.company && this.company !== '') {
			return `/api/v1/company/${this.company}/companyCategory`;
		}
		return `/api/v1/companyCategory`;
	}

	getQueryParams(): QueryParam[] {
		const parts = super.getQueryParams();
		if (stringIsSetAndFilled(this.division)) {
			parts.push({ key: 'division', value: this.division });
		}
		return parts;
	}
}

export class CategoryDefaults {
	Provider: string;
	Division: string;
	GLAccount: string;
	Journal: string;
	VATCode: string;

	// This field is for non-integration companies.
	ledger: string;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	getProvider() {
		return this.Provider;
	}

	getDivision() {
		return this.Division;
	}

	getGLAAccount() {
		return this.GLAccount;
	}

	getJournal() {
		return this.Journal;
	}

	getVATCode() {
		return this.VATCode;
	}

	getLedger() {
		return this.ledger;
	}
}

export enum CategoryTransactionType {
	RECEIPT = 'receipt',
	INVOICE = 'invoice',
	FIXED = 'fixed',
	TIME = 'time',
	DISTANCE = 'distance',
}
