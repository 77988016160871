import { memoize } from 'lodash';

export function memoizeFull<T extends (...args: any) => any>(fn: T) {
	return memoize(fn, (...args) => {
		return JSON.stringify(args);
	});
}

export function memoizeFullDebounced<T extends (...args: any) => any>(fn: T) {
	// TODO: actually debounce
	return memoize(fn, (...args) => {
		return JSON.stringify(args);
	});
}
