<app-dynamic-options-picker
	[getPlaceholderTextFn]="getPlaceholderTextFn"
	[fetchItemsFn]="fetchItemsFn"
	[fetchSelectedItemsFn]="fetchSelectedItemsFn"
	[mapToSelectOptionFn]="mapToSelectOptionFn"
	[sortValuesFn]="sortValuesFn"
	[selectableItems]="selectableItems"
	[ngClass]="{showErrors: isInErrorState() || inErrorState}"
	[(ngModel)]="innerValue"
	(ngModelChange)="setInnerValueAndNotify($event)"
	[multiple]="multiple"
	[prefix]="prefix"
	[disabled]="disabled"
	[clearable]="clearable"
	[dropdownPosition]="dropdownPosition"
	[orientation]="orientation"
	[truncateOptions]="truncateOptions"
	[autoSelectOnSingleResult]="autoSelectOnSingleResult"
	(onOpened)="onOpened.emit()"
	(onClosed)="onClosed.emit()"
	[customSearchFn]="customSearchFn"
	[newOptionFooterPrefix]="newOptionFooterPrefix"
	(onFooterClick)="_onFooterClick($event)"
></app-dynamic-options-picker>
