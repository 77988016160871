import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CompanyComponent } from '../../../modules/company/components/company.component';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Receipt, ReceiptListAPIRequest } from '#/models/transaction/receipt';
import * as fromReceipt from '../../../modules/receipt/models/receipt.reducer';
import { select } from '@ngrx/store';
import { needsReceiptListUpdate, WSMessage } from '#/models/websocket.model';
import { AddReceipts, ClearReceipts } from '../../../modules/receipt/models/receipt.actions';
import { cloneDeep } from 'lodash';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ReceiptFiltersService, SEARCH_FILTER_LOCALSTORAGE_KEY } from '~/app/modules/receipt/receipt-filters.service';
import { Order } from '#/models/utils/order';
import { TransactionEditorService } from '#/services/transaction/transaction-editor.service';
import { PageStateService } from '#/services/util/page-state.service';
import { Themed } from '#/providers/themed';
import { DashboardChecklistTab } from '#/models/dashboardChecklist';
import { stringIsSetAndFilled } from '#/util/values';
import { RouteUtilsService } from '#/services/util/route-utils.service';

@Component({
	selector: 'app-company-dashboard',
	templateUrl: './company-dashboard.component.html',
	providers: [Themed],
})
export class CompanyDashboardComponent extends CompanyComponent implements OnInit, OnDestroy {
	@ViewChild('receiptsTable') receiptsTable;
	protected activeTab = 'todo';
	protected subscription;
	receipts: Observable<Receipt[]>;
	receiptsPerPage: number = 25;
	currentPage = 1;
	receiptCount = 0;
	initialLoaded = false;
	checkboxes: {};
	checkAll: {
		checked: boolean;
	};
	allowedUserStates = [];
	filtered = false;
	todoCount: number = null;
	searchSubscription: Subscription;
	private queryParamsSubscription: Subscription = new Subscription();

	private destroyed = false;

	constructor(
		protected route: ActivatedRoute,
		protected receiptFiltersService: ReceiptFiltersService,
		private transactionEditorService: TransactionEditorService,
		private pageStateService: PageStateService,
		public themed: Themed,
		private routeUtilsService: RouteUtilsService,
	) {
		super();
		this.receipts = this.store.select('receipt').pipe(select(fromReceipt.selectAllReceipts));
		this.checkboxes = {};
		this.checkAll = { checked: false };

		this.subscription = this.route.params.subscribe((params) => {
			if (this.destroyed) {
				return;
			}
			this.activeTab = params.tab;
			if (this.filters !== undefined && this.user) {
				this.setDeclarationstatus(params.tab);
				this.loadReceipts();
			}
		});
	}

	get filters() {
		return this.receiptFiltersService.filters;
	}

	get defaultFilters() {
		return this.receiptFiltersService.defaultFilters;
	}

	set filters(filters) {
		this.receiptFiltersService.filters = filters;
	}

	set defaultFilters(filters) {
		this.receiptFiltersService.defaultFilters = filters;
	}

	ngOnInit(): void {
		super.ngOnInit();

		const websocketSubscription = this.websocketsService.onMessage.subscribe((message: WSMessage) => {
			// Refresh receipts list, might be the receipt was in our current page.
			if (needsReceiptListUpdate(message.type)) {
				// only reload the table if we have no modals open
				// otherwise we lose all kinds of subscriptions that cause the 'next receipt' function to be killed in the open modal
				if (this.pageStateService.isPageStateSaved()) {
					this.currentPage = this.pageStateService.getPageNumber();
				}
				this.loadReceipts(false, true);
			}
		});

		this.destroyCallbacks.push(() => {
			websocketSubscription.unsubscribe();
		});

		const receiptsPerPage: number = this.localStorageService.get('dashboard_receipts_per_page') as number;
		if (receiptsPerPage != null) {
			this.receiptsPerPage = Number(receiptsPerPage);
		}

		this.checkRole();
		this.pageStateService.initPageState();

		this.filters = new ReceiptListAPIRequest();
		this.filters.sortorder = Order.DESCENDING;
		this.filters.sort = 'purchasedate';
		this.filters.declarations = true;
		this.filters.company = true;
		this.filters.companyID = this.company.id;
		this.defaultFilters = this.filters.clone();
		this.filters = Object.assign(this.filters, new ReceiptListAPIRequest(JSON.parse(this.localStorageService.get('filters-expenses'))));

		if (!stringIsSetAndFilled(this.filters.search) && stringIsSetAndFilled(this.localStorageService.get(SEARCH_FILTER_LOCALSTORAGE_KEY))) {
			this.filters.search = this.localStorageService.get(SEARCH_FILTER_LOCALSTORAGE_KEY);
		}

		this.updateStateBasedOnQueryParams();
		this.filters.declarations = true;
		this.filters.company = true;
		this.filters.companyID = this.company.id;
		this.setDeclarationstatus(this.activeTab);

		this.allowedUserStates = this.user.getAllowedUserStates();
		this.loadReceipts();

		this.queryParamsSubscription = this.route.queryParams.subscribe((params) => {
			this.updateStateBasedOnQueryParams();
			this.loadReceipts();
		});

		this.searchSubscription = this.receiptFiltersService.searchSubject.subscribe(() => {
			this.updateFilters();
		});
		this.destroyCallbacks.push(() => {
			this.searchSubscription.unsubscribe();
		});

		this.on(
			this.subCompanyServiceDeprecated.subCompanyChanged.subscribe((company) => {
				this.updateFilters();
			}),
		);
	}

	private updateStateBasedOnQueryParams(): void {
		if (stringIsSetAndFilled(this.route.snapshot.params.tab)) {
			if (this.route.snapshot.params.tab === DashboardChecklistTab.TODO.toLowerCase()) {
				this.filters.predefinedFilter = DashboardChecklistTab.TODO;
			} else if (this.route.snapshot.params.tab === DashboardChecklistTab.ALL.toLowerCase()) {
				this.filters.predefinedFilter = null;
				this.showAllTabMessage();
			}
		}

		if (stringIsSetAndFilled(this.route.snapshot.queryParams.itemsPerPage)) {
			this.filters.max = Number(this.route.snapshot.queryParams.itemsPerPage);
			this.receiptsPerPage = Number(this.route.snapshot.queryParams.itemsPerPage);
		}
		if (stringIsSetAndFilled(this.route.snapshot.queryParams.page)) {
			this.filters.start = (Number(this.route.snapshot.queryParams.page) - 1) * (this.filters.max ?? this.receiptsPerPage);
			this.currentPage = Number(this.route.snapshot.queryParams.page);
		}
		if (stringIsSetAndFilled(this.route.snapshot.queryParams.sort)) {
			this.filters.sort = this.route.snapshot.queryParams.sort;
		}

		if (stringIsSetAndFilled(this.route.snapshot.queryParams.sortOrder)) {
			this.filters.sortorder = this.route.snapshot.queryParams.sortOrder;
		}
	}

	public updateReceiptsPerPage(): void {
		this.localStorageService.set('dashboard_receipts_per_page', this.receiptsPerPage);
		this.currentPage = 1;
		this.updateQueryParams({ itemsPerPage: this.receiptsPerPage, page: this.currentPage });
		this.checkAll = { checked: false };
		this.checkboxes = {};
		this.loadReceipts(true, false);
	}

	public updateFilters(): void {
		this.currentPage = 1;
		this.updateQueryParams({ page: this.currentPage });
		this.filtered = true;
		this.checkAll = { checked: false };
		this.checkboxes = {};
		this.loadReceipts();
	}

	loadReceipts(resetTable = true, loadTodo = true) {
		if (resetTable) {
			this.initialLoaded = false;
		}
		this.filters.companyID = this.company.id;
		this.filters.max = this.receiptsPerPage;
		this.filters.start = (this.currentPage - 1) * this.receiptsPerPage;
		this.filters.countActiveFilters('expenses', this.defaultFilters);
		this.transactionEditorService.setLatestDashboardFilters(this.filters);
		this.receiptAPIService.getReceipts(this.filters).then((r) => {
			if (this.destroyed) {
				return;
			}
			this.receiptCount = r['data']['count'];
			const Receipts: any[] = r['data']['receipts'];
			const newReceipts: Receipt[] = [];
			if (Receipts != null) {
				Receipts.map((receipt) => newReceipts.push(new Receipt(receipt)));
			}
			this.store.dispatch(new ClearReceipts());
			this.store.dispatch(new AddReceipts({ receipts: newReceipts }));
			this.initialLoaded = true;
		});

		if (loadTodo) {
			this.getTodoItems();
		}
	}

	private getTodoItems(): void {
		const filters = this.filters.clone();
		filters.predefinedFilter = DashboardChecklistTab.TODO;
		filters.max = 1;
		filters.start = 0;

		this.receiptAPIService.getReceipts(filters).then((r) => {
			if (this.destroyed) {
				return;
			}
			this.todoCount = r['data']['count'];
		});
	}

	pageChanged(newPage: number) {
		this.currentPage = newPage;
		this.checkAll = { checked: false };
		this.pageStateService.setCurrentPage(newPage);
		this.loadReceipts(false, false);
	}

	currentPageReceipts(): number {
		return Math.min(this.currentPage * this.receiptsPerPage, this.receiptCount);
	}

	selectAllReceiptsPage(filters: ReceiptListAPIRequest, per_page: number = 100, page: number = 1) {
		filters.max = per_page;
		filters.start = (page - 1) * per_page;
		this.receiptAPIService.getReceipts(filters).then((r) => {
			if (this.destroyed) {
				return;
			}
			const Receipts: any[] = r['data']['receipts'];
			const newReceipts: Receipt[] = [];
			if (Receipts != null) {
				Receipts.forEach((receipt) => {
					this.checkboxes[receipt.id] = true;
				});
			}
			if (r['data']['moreresults']) {
				return this.selectAllReceiptsPage(filters, per_page, page + 1);
			}
		});
	}

	getSelectedCount(): number {
		return Object.keys(this.checkboxes).filter((checkbox) => this.checkboxes[checkbox]).length;
	}

	deselectAllSelected() {
		this.checkboxes = {};
		this.checkAll = { checked: false };
	}

	selectedAllReceipts() {
		this.checkAll = { checked: true };
		const filters = cloneDeep(this.filters);
		this.selectAllReceiptsPage(filters, 50);
	}

	private setDeclarationstatus(status: string): void {
		this.checkAll = { checked: false };
		this.checkboxes = {};

		if (status === DashboardChecklistTab.ALL.toLowerCase()) {
			this.filters.predefinedFilter = null;
			this.showAllTabMessage();
		} else if (status === DashboardChecklistTab.TODO.toLowerCase()) {
			this.filters.predefinedFilter = DashboardChecklistTab.TODO;
		}
	}

	showAllTabMessage() {
		const shownAllTabHint = this.localStorageService.get('shown-all-tab-hint');
		if (!shownAllTabHint) {
			this.notificationService.info(_('Tip: you can filter on a specific status using the "Filters" button!'), {
				timeout: 5000,
			});
			this.localStorageService.set('shown-all-tab-hint', true);
		}
	}

	checkRole() {
		if (this.router.url === '/dm/dashboard/all') {
			let roleCount = 0;
			if (this.user.hasCompanyManagerRole()) {
				roleCount++;
			}
			if (this.user.hasCompanyFinanceRole()) {
				roleCount++;
			}
			if (this.user.hasCompanyAdminRole()) {
				roleCount++;
			}

			if (roleCount === 1) {
				if (this.user.hasCompanyManagerRole()) {
					this.router.navigateByUrl('/dm/dashboard/pending');
				} else if (this.user.hasCompanyFinanceRole()) {
					this.router.navigateByUrl('/dm/dashboard/approved');
				} else {
					this.router.navigateByUrl('/dm/dashboard/all');
				}
			} else {
				this.router.navigateByUrl('/dm/dashboard/all');
			}
		}
	}

	public navigateToTab(tab: string): void {
		const newUrl: string = this.routeUtilsService.getUpdateRouteWithNewParamValue(`/dm/dashboard/${tab}`, this.route.snapshot.queryParams, {
			page: 1,
		});
		this.setDeclarationstatus(tab);
		this.loadReceipts();
		this.router.navigateByUrl(newUrl);
	}

	public updateQueryParams(newParams: Record<string, any>): void {
		const newUrl: string = this.routeUtilsService.getUpdateRouteWithNewParamValue(
			this.router.url.split('?')[0],
			this.route.snapshot.queryParams,
			newParams,
		);
		this.router.navigateByUrl(newUrl);
	}

	public updateRouteWithSortingParams(event: { sort: string; sortOrder: Order }): void {
		const newUrl: string = this.routeUtilsService.getUpdateRouteWithNewParamValue(
			this.router.url.split('?')[0],
			this.route.snapshot.queryParams,
			{
				sort: event.sort,
				sortOrder: event.sortOrder,
				page: 1,
			},
		);
		this.router.navigateByUrl(newUrl);
	}

	public ngOnDestroy(): void {
		this.destroyed = true;
		super.ngOnDestroy();
		this.subscription.unsubscribe();
		this.queryParamsSubscription.unsubscribe();
	}
}
